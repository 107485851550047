import React, {Component} from 'react';
import ContentEditable from 'react-contenteditable';


class TMTContentEditable extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  //the following four functions from: https://www.taniarascia.com/content-editable-elements-in-javascript-react/
  pasteAsPlainText(e) {
    //e.preventDefault()
    //const text = e.clipboardData.getData('text/plain')
    //document.execCommand('insertHTML', false, text)
    e.preventDefault();
    var text = '';
    if (e.clipboardData || e.originalEvent.clipboardData) {
      text = (e.originalEvent || e).clipboardData.getData('text/plain');
    } else if (window.clipboardData) {
      text = window.clipboardData.getData('Text');
    }
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      document.execCommand('paste', false, text);
    }
  }

  handleFocus = () => {
    const {highlightText, onFocus} = this.props;
   if(onFocus) {onFocus()}
    if (highlightText) {
      setTimeout(() => {
        document.execCommand('selectAll', false, null)
      }, 0)
    }
  }

  validateNumber = event => {
    const keyCode = event.keyCode || event.which
    const string = String.fromCharCode(keyCode)
    const regex = /[0-9,]|\./
    if (!regex.test(string)) {
      event.returnValue = false
      if (event.preventDefault) event.preventDefault()
    }
  }

  handleKeyPress(event) {
    const {numeric} = this.props;
    const keyCode = event.keyCode || event.which
    if (keyCode === 13) {
      event.returnValue = false
      if (event.preventDefault) event.preventDefault()
    }
    else {
      if (numeric)
        this.validateNumber(event);
    }
  }

  handleChange(e) {
    const {onChange} = this.props;
    onChange(e);
  }

  render() {
    const {onBlur, classes, text, id, disabled, style, placeholder} = this.props;
    return (
      <ContentEditable
        id={id}
        html={text}
        onChange={this.handleChange}
        onBlur={onBlur}
        onPaste={this.pasteAsPlainText}
        onKeyPress={this.handleKeyPress}
        onFocus={this.handleFocus}
        className={classes}
        disabled={disabled}
        style={style}
        placeholder={placeholder}
      />
    )
  }
}


export default TMTContentEditable;