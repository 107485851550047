import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './david.css';
import {ToastContainer} from 'react-toastify';
import {Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './App';
import {Provider} from 'react-redux';
import {store} from './Store';
import _ from 'lodash';
import {save_Invite_Template} from './actions/AnonAssign/save_Invite_Template';
import {get_Invite_Templates} from './actions/AnonAssign/get_Invite_Templates';
import {get_Named_Invite_Templates} from './actions/Assign/get_Named_Invite_Templates';
import {save_Named_Invite_Template} from './actions/Assign/save_Named_Invite_Template';
import {setSocket} from './actions/setSocket';
import {socketDispatch} from './actions/socketDispatch';
import defaultAnonInviteTemplate
  from './components/email_templates/defaultAnonInviteTemplate';
import defaultNamedInviteTemplate
  from './components/email_templates/defaultNamedInviteTemplate';
import hasSession from './components/Common/hasSession';
import hasPermission from './components/Common/hasPermission';
import {LOGIN_USER} from './actions/_CONSTANTS';
import swal from 'sweetalert';


//---------------------DEBUGGING HELP-------------------
// store.subscribe(function () {
//  console.log('**************************************');
//  console.log(store.getState().track_Table);
//  console.log('**************************************');
// });

//debugging help in console. type: rs()
window.rs = store.getState;

//----------------------------------------------------------


//-----------serviceWorker stuff from create-react-app-----------------

// import * as serviceWorker from './serviceWorker';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

//---------------------------------------------------------------------

//important links

//https://www.codeproject.com/Articles/1238173/Deploy-React-and-Express-to-Heroku
//https://dev.to/loujaybee/using-create-react-app-with-express


// ReactDOM.render(<App />, document.getElementById('root'));


//these should be obtained by calling server on startup
// let AWS_BASEURL = 'https://s3.amazonaws.com'
// let AWS_TEST_ITEM_TRANSCRIPTION_BUCKET = "dys-pollyprompts"
// window.URLPrefix = `${AWS_BASEURL}/${AWS_TEST_ITEM_TRANSCRIPTION_BUCKET}/`

/*
 These functions make it possible to store objects in localstorage instead of just string.

 useage: localStorage.setObject('key': {})
 localStorage.getObject('key')
 localStorage.clear()
 */

Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
};

localStorage.clear();

const setupProvider = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ToastContainer
        hideProgressBar={true}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Zoom}
        autoClose={3000}
      />
      <App/>
    </Provider>
    , document.getElementById('root'));
};


let startupError = false;

window.batchColors = ['#deeaee', '#f7cac9', '#f4e1d2', '#e3eaa7', '#ffefb6', '#d5f4e6', '#e3e0cc', '#d5f7c0', '#e0e2e4', '#e4d1d1'];
window.derivativeData = [];
window.childIds = [];

window.socketEvent = document.createEvent('Event'); //create a custom event
window.socketEvent.initEvent('socket', true, true); //call it 'socket' and init it.
window.socketEventStore = {
  completeAssignment: {},
  addAssignments: {}
}

const initialize = async function () {
  try {
    let user = await hasSession()
    if (user) {
      window.email = user.email; //used for 'destroyLocks' in index.html
      store.dispatch({
        type: LOGIN_USER,
        payload: user
      })
      /*
       ****************************************************************************************************************
       * SOCKETIO
       * The app deals with two different kinds of socket events, which get handled
       * somewhat differently.
       *
       * 1) SERVER GENERATED EVENTS
       * These events are initiated from the server via messages passed from AWS
       * Lambda. They include:
       *
       * START_ASSIGNMENT
       * COMPLETE_ASSIGNMENT
       * CREATE_ITEM_RESPONSE
       * UPDATE_ITEM_RESPONSE
       *
       * They all have the signature 'user.accountId'.
       *
       * SocketIO, for some reason, often sends multiple instances of the event, so
       * we 'de-bounce' it by only accepting the first event to be received.
       *
       *
       * 2) CLIENT GENERATED EVENTS
       * These events are generated by the client. They are sent to the server which
       * then broadcasts them to all running clients who belong to the same
       * organization, including the sender.
       *
       * They all have the signature 'room-user.account.id'.
       *
       * In both cases the event, along with its data, is  passed on the the action
       * creator 'socketDispatch' which dispatches it for any interested reducer.  f
       * It is also is packaged as a custom event 'socket' and broadcast to any
       * module that needs to subscribe to it also via the action creator 'socketDispatch'
       *
       * There is equivalent logic in the Login module. The socket structure is set
       * up both here - if the user has an active session, and in Login if the user
       * is logging in directly.
       ****************************************************************************************************************
       */
      if (_.isEmpty(window.socket)) {
        window.socket = window.io.connect(window.location.origin);
        console.log('socket.io connecting from index page.')
        store.dispatch(setSocket(window.socket));
        window.socket.emit('joinRoom', `room-${user.accountId}`);//registers client as
        // belonging to room 'user-<accoundId>

        //***************************************************************
        //HANDLING SERVER GENERATED EVENTS
        window.socket.on(user.accountId, (data) => {
          console.log('server socket event: ', data);
          if (!data.count) {

            if (data.type === 'COMPLETE_ASSIGNMENT') {
              window.socketEventStore.completeAssignment = data; //store separately for Review
              // module if event is COMPLETE_ASSSIGNMENT
            }

            store.dispatch(socketDispatch(data)); //send to socket dispatch for routing

            window.socketEvent.info = data;
            document.dispatchEvent(window.socketEvent); //then trigger general socket
            // event for whoever needs it
          }
        })
        //***************************************************************
        //HANDLING CLIENT GENERATED EVENTS
        window.socket.on(`room-${user.accountId}`, (data) => { //client generated event
          console.log('socket event received', data);
          store.dispatch(socketDispatch(data));
          window.socketEvent.info = data;
          document.dispatchEvent(window.socketEvent); //dispatch general socket
          // event for whoever needs it

        })
        //***************************************************************
      }


      if (hasPermission(user.role, 'assign')) {
        let inviteTemplates = await store.dispatch(get_Invite_Templates(user.accountId))
        if (!inviteTemplates.length) {
          await store.dispatch(save_Invite_Template({
            accountId: user.accountId,
            name: 'default',
            html: defaultAnonInviteTemplate()
          }))
        }
        let namedInviteTemplates = await store.dispatch(get_Named_Invite_Templates(user.accountId))
        if (!namedInviteTemplates.length) {
          await store.dispatch(save_Named_Invite_Template({
            accountId: user.accountId,
            name: 'default',
            html: defaultNamedInviteTemplate(),
            subject: 'Invitation to participate'
          }))
        }
      }
    }
  } catch (err) {
    startupError = true;
    swal({
      title: 'System Error',
      text: 'There has been a system error. Please try to load the website again.',
      icon: 'warning'
    })

  }
}

initialize()
  .then(() => {
    if (!startupError) {
      setupProvider()
    }
  })










