import React from 'react';
import $ from 'jquery';
import {DropdownList}  from 'react-widgets';
import ReactTooltip from 'react-tooltip';
import showSystemError from '../Common/showSystemError';
import classnames from 'classnames';
import getAllSurveys from '../Common/getAllSurveys';

/*
 Notes on csv support:

 javascript promises: https://stackoverflow.com/questions/47326087/how-to-use-javascript-promises-es6-instead-of-jquery-ajax-call

 https://programmingwithmosh.com/javascript/react-file-upload-proper-server-side-nodejs-easy/

 https://stackabuse.com/reading-and-writing-csv-files-with-node-js/

 https://codeburst.io/handling-file-uploads-on-node-server-using-multer-part-a-backend-a789e0003e04

 important: how to format ajax calls with formdata as data:
 https://stackoverflow.com/questions/12755945/jquery-and-html-formdata-returns-uncaught-typeerror-illegal-invocation


 */

class AssignSelector extends React.Component {
  constructor(props) {
    super(props);
    const {clear_Assign_Table} = props;
    let oldState = localStorage.getObject('assignSelector');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('assignSelector');
    }
    else {
      clear_Assign_Table()
      this.state = {
        numRequests: 1,
        testAlternatives: [],
        testName: '',
        allDisabled: true,
        testSelectorDisabled: false,
        selectedFile: null,
        dropHover: false,
        uploadBtnHover: false,
        batches: [],
        instrumentType: '',
        numberOfQuestions: 0,
        wantsEmail: false,
        assessmentEmailTitle: ''
      }
    }
  }

  componentDidMount() {
    const {user} = this.props;
    getAllSurveys(user.accountId, 'assign')
      .then((tests) => {
          this.setState({
            testAlternatives: tests,
          });
        },
        (err) => {
          console.log(err);
          showSystemError()
        }
      )
    $('#uploadDiv').html("<i>Choose a csv file or drag and drop it here. Then click upload.</i>").css('color', 'gray');
    $('#fileBrowse').bind('change', function () {
      var fileParts = $(this).val().split("\\");
      $('#uploadDiv').text(fileParts[fileParts.length - 1]);
    });
  }

  handleRequestChange = (e) => {
    let n = e.target.value
    n = Math.min(n, 40)
    this.setState({
      numRequests: n
    });
  }

  handleRequestSubmit = () => {
    const {getAssignmentRequest} = this.props;
    getAssignmentRequest(this.state.testName, this.state.instrumentType, this.state.numRequests, null, this.state.numberOfQuestions, this.state.wantsEmail, this.state.assessmentEmailTitle);
    this.setState({testSelectorDisabled: true});
    let assignTable = document.getElementsByClassName('AssignTableContainer')[0];
    if (assignTable) {
      let assignTableHeight = assignTable.scrollHeight;
      assignTable.scrollTo(0, assignTableHeight);
    }
    // console.log($('.AssignTableContainer'))
    // $('.AssignTableContainer')[0].scrollTo('0%','100%');
  }

  handleTestSelect = (value) => {
    const {setNumBatches} = this.props;
    let testName = value;
    let selectedTest = this.state.testAlternatives.find((test) => {
      return test.testName === testName;
    })
    this.setState({
      batches: selectedTest.batches,
      testName: testName,
      allDisabled: false,
      instrumentType: selectedTest.instrumentType,
      numberOfQuestions: selectedTest.numberOfQuestions,
      wantsEmail: selectedTest.wantsEmail,
      assessmentEmailTitle: selectedTest.assessmentEmailTitle
    });
    setNumBatches(selectedTest.batches.length);
  }

  openCsvModal = () => {
    const {openModal} = this.props;
    openModal({
      name: 'csvInfo',
      title: "HOW TO FORMAT A CSV FILE FOR UPLOAD"
    })
  }

  adjustCSS = () => {
    $('.__react_component_tooltip').css('background-color','lightgoldenrodyellow').css('font-size','16px');
  }

  uploadFile = (data) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: "/upload",
        type: "POST",
        data: data,
        processData: false,
        contentType: false,
        success: (result) => {
          resolve(result.csv)
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }

  onFileSelect = (e) => {
    this.setState({
      selectedFile: e.target.files[0]
    })
  }

  handleUpload = () => {
    const {getAssignmentRequest, openModal} = this.props;
    const data = new FormData();
    data.append('file', this.state.selectedFile)
    this.uploadFile(data)
      .then((data) => {
        let row1 = data[0];
        console.log(row1);
        let hasOneName = row1.NAME;
        let hasTwoNames = row1.FIRSTNAME && row1.LASTNAME;
        if (!hasOneName && !hasTwoNames) {
          openModal({
            name: 'csvInfo',
            title: "THIS CSV IS NOT PROPERLY FORMATTED."
          })
        }
        else {
          let rows = []
          data.forEach((row) => {
            let obj = {
              participantName: hasTwoNames ?
                `${row.FIRSTNAME} ${row.LASTNAME}` :
                row.NAME,
              email: row.EMAIL || '',
              externalId: row.CUSTOM || ''
            }
            rows.push(obj);
          })
          getAssignmentRequest(this.state.testName, this.state.instrumentType, rows.length, rows, this.state.numberOfQuestions, this.state.wantsEmail, this.state.assessmentEmailTitle);
          this.setState({
            testSelectorDisabled: true
          })
          let assignTable = document.getElementsByClassName('AssignTableContainer')[0];
          if (assignTable) {
            let assignTableHeight = assignTable.scrollHeight;
            assignTable.scrollTo(0, assignTableHeight);
          }

        }
      }, (err) => {
        console.log(err);
        showSystemError()
      })
  }


  dragDropOver = () => {
    this.setState({dropHover: true})
  }

  dragDropLeave = () => {
    this.setState({dropHover: false})
  }


  handleUploadMouseEnter = () => {
    if (!this.state.allDisabled) {
      this.setState({uploadBtnHover: true})
      $('#uploadDiv').css('color', 'black').css('cursor', 'pointer');
      $('.csvInput').css('cursor', 'pointer');
    }
    if (this.state.selectedFile) {
      $('.uploadButtonSmall').css('cursor', 'pointer');
    }
  }

  handleUploadMouseLeave = () => {
    this.setState({uploadBtnHover: false})
    $('#uploadDiv').css('color', 'gray').css('cursor', 'normal');
    $('.csvInput').css('cursor', 'normal');
    $('.uploadButtonSmall').css('cursor', 'normal');
  }

  handleBatchSelect = (value) => {
    const {getExistingBatch} = this.props;
    getExistingBatch(value, this.state.testName)
    this.setState({
      testSelectorDisabled: true
    })
  }

  testSelector = () => {
    let testAlternatives = this.state.testAlternatives;
    let testNames = testAlternatives.map((test) => {
      return (
        test.testName
      )
    });
    return (
      <div style={{display: 'flex'}}>
        <div className="survey-name-prompt">
          Name: &nbsp;&nbsp;&nbsp;
        </div>
        <div className="survey-dropdown">
          <DropdownList
            data={testNames}
            value={this.state.testName}
            placeholder="Select a test or survey to use"
            disabled={this.state.testSelectorDisabled}
            onChange={this.handleTestSelect}
            id="assignTestNameInput"
          />
        </div>
      </div>
    )
  }

  currentBatchId = () => {
    const {batch} = this.props;
    let batchDisplayClasses = classnames('batchDisplay', {active: this.state.testSelectorDisabled})
    return (
      <div className={batchDisplayClasses}>
        {batch ? `Assignment Sheet#: ${batch}` : null}
      </div>
    )

  }

  batchSelector = () => {
    let batchSelectorClasses = classnames('batchSelector', {active: !this.state.testSelectorDisabled && this.state.batches.length});
    let batches = this.state.batches.map((batch) => {
      return batch.batch;
    });
    batches = ['New'].concat(batches);
    return (

      <div className={batchSelectorClasses}>
        <div className="batch-name-prompt">
          Assignment Sheet:&nbsp;&nbsp;&nbsp;
        </div>
        <div style={{width: '70%', marginTop: '4px'}}>
          <DropdownList
            data={batches}
            disabled={this.state.testSelectorDisabled}
            onChange={this.handleBatchSelect}
            defaultValue="New"
            id="batchNameInput"
          />
        </div>
      </div>
    )
  }

  topPanel = () => {
    return (
      <div className="assignHeadingTName"
           style={{fontWeight: 'normal', display: 'flex'}}>
        <div style={{width: '47%'}}>
          {this.testSelector()}
        </div>
        <div style={{width: '6%'}}>&nbsp;</div>
        <div style={{width: '47%'}}>
          {this.currentBatchId()}
          {this.batchSelector()}
        </div>
      </div>
    )
  }

  requestTicketsPanel = () => {
    return (
      <div className="assignHeadingRequest">
        <div className="spcPanel">
          <span style={{fontWeight: 'bold'}}>Request Tickets</span>

          <br></br>
          <button
            className="blueButtonSmall"
            style={{marginRight: "10px", marginTop: "22px"}}
            id="requestBtn"
            name="requestTestBtn"
            disabled={this.state.allDisabled}
            onClick={this.handleRequestSubmit}
          >REQUEST
          </button>
          <div style={{marginRight: '15px', display: 'inline'}}>
            <input
              id="numberParticipants"
              type="number"
              name="quantity"
              disabled={this.state.allDisabled}
              value={this.state.numRequests}
              onChange={this.handleRequestChange} min="1" max="40"/>
          </div>
          tickets.

          <br/>

        </div>
      </div>
    )
  }


  uploadCsvPanel = () => {
    return (
      <div className="assignHeadingCsv">
        <ReactTooltip id="csv" place="left" type="light" html={false} border={true}/>
        <div
          className="csvInfo"
         // data-tip="<span class='csvFormatting' >Click here to learn how to format csv files for uploading.</span>"
          data-tip="Click here to learn how to format csv files for uploading."
          data-for="csv"
          onMouseOver={this.adjustCSS}
          onClick={this.openCsvModal}
        >
          <i className="fa fa-info-circle" aria-hidden="true"></i>
        </div>

        <div
          className="boldText"
          style={{textAlign: 'center', paddingBottom: '3px'}}
        >
          Import a CSV File
        </div>
        <div style={{textAlign: 'center', marginBottom: '10px'}}>
        </div>
        <ReactTooltip id="csv-drop" place="left" type="dark"/>
        <div style={{display: 'inline-block'}}
        >
          <div className="dragDropBox"
               style={{backgroundColor: this.state.dropHover ? 'rgba(0, 0, 255, 0.1)' : 'transparent'}}
               data-for="csv-drop"
            //data-tip="Choose a csv file or drag and drop it here."
               data-tip=""
               onDragOver={this.dragDropOver}
               onDragLeave={this.dragDropLeave}
               onMouseOver={this.handleUploadMouseEnter}
               onMouseLeave={this.handleUploadMouseLeave}
          >
            <form method="post" action="#" id="#">
              <input
                id="fileBrowse"
                type="file"
                accept=".csv"
                className="csvInput"
                multiple=""
                disabled={this.state.allDisabled}
                name="file"
                onChange={this.onFileSelect}/>

              { this.state.uploadBtnHover ?
                <div className="uploadBackgroundHover">
                  <div id="uploadDiv" className="uploadDiv"></div>
                </div>
                :
                ( this.state.allDisabled ?
                  <div className="uploadBackgroundDisabled">
                    <div id="uploadDiv" className="uploadDiv"></div>
                  </div>
                  :
                  <div className="uploadBackgroundNormal">
                    <div id="uploadDiv" className="uploadDiv"></div>
                  </div> )
              }
            </form>
          </div>
          <div style={{display: 'inline-block'}}>
            <button
              disabled={!this.state.selectedFile || this.state.allDisabled}
              style={{position: 'relative', left: '160px', top: '-41px'}}
              id="upload-csv"
              className="uploadButtonSmall"
              onClick={this.handleUpload}>
              UPLOAD
            </button>
          </div>
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    $('#fileBrowse').unbind('change');
    if (!localStorage.getItem('assign_reset')) {
      localStorage.setObject('assignSelector', this.state);
    }
  }

  render() {

    return (
      <div className="assignHeading">
        <div className="boldText" style={{width: '100%'}}>
          {this.topPanel()}
        </div>
        <div style={{display: 'flex'}}>
          {this.requestTicketsPanel()}
          <div className="orDiv">
            <h2>or</h2>
          </div>
          {this.uploadCsvPanel()}
        </div>
      </div>
    )
  }
}

export default AssignSelector;