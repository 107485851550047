import React from "react";
import classnames from 'classnames';

/*
AutoSuggest has several options which control the behavior of the component in certain
 ways.
 handleTagSelected: a function that gets called when a tag is selected or return key hit.

 existingSuggestions: a list of suggestions or existing tabs (depending on use) that
  component presents as a dropdown list that gets filtered by what you enter in the
   input box.

 autoFocus: controls whether or not the component gets focus when mounted

 suggestionsAlwaysOpen: controls whether or not the suggestions/tags list is open when
  component is mounted and stays open.

  matchAnywhere: controls whether or not suggestions match only the beginning of the
   word or search for whatever is entered anywhere in each suggestion/tag.

  displaySelectedTag: controls whether or not, when a suggestion/tag is selected, it's
   value shows up in the input field.

   clearTagText: If set to true by the Parent function, this will cause the inputValue
    to be reset to ''.

    resetClearTagText: If clearTagText is to be used, then the resetClearTagText
     function must also be provided to reset 'clearTagText' to false after using it to
      clear the inputValue.

    parentOnFocus: hook for further onFocus processing in parent.


 */

class AutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      matches: [],
      inputText: '',
      matchAnywhere: false,
      suggestionsOpen: false,


    }
  }

  componentDidMount() {
    const {existingSuggestions, matchAnywhere, suggestionsAlwayOpen} = this.props;
    this.setState({
      suggestions: existingSuggestions,
      matchAnywhere,
      suggestionsOpen: suggestionsAlwayOpen
    });
    this.rehydrateMatches();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(prevProps, prevState)
    if(this.props.clearTagText && !prevProps.clearTagText) {
      this.clearTagText()
      this.props.resetClearTagText()
    }
  }

//   clearTagText= () =>{
//     this.setState({inputText: ''});
// }

  rehydrateMatches = () => {
    const {existingSuggestions} = this.props;
    this.setState({
      matches: existingSuggestions
    });
  }

  getMatchedSuggestions = (value) => {
    let inputValue = value.trim().toLowerCase();
    if (inputValue.length) {
      let matches;
      matches = this.state.suggestions.filter((suggestion) => {
        if (this.state.matchAnywhere) {
          return suggestion.suggestion.includes(inputValue)
        } else {
          const length = inputValue.length;
          const substring = suggestion.suggestion.toLowerCase().substring(0, length);
          return substring === inputValue;
        }
      })

      this.setState({matches: [...matches]}, () => {
      })
    } else {
      this.rehydrateMatches()
    }
  }

  handleInputChange = e => {
    let inputValue = e.target.value;
    this.setState({inputText: inputValue});
    this.getMatchedSuggestions(inputValue);
  }

  handleFocus = () => {
    const {parentOnFocus} = this.props;
    this.setState({suggestionsOpen: true});
    if(parentOnFocus){parentOnFocus()}
  }

  handleBlur =()=>{
    const {suggestionsAlwayOpen} = this.props;
    if(!suggestionsAlwayOpen){
      this.setState({suggestionsOpen: false})
    }
  }

  handleMouseDown = (e) => {
    const {handleTagSelected, displaySelectedTag} = this.props;
    handleTagSelected(e.target.outerText, false);
    this.rehydrateMatches();
    this.setState({inputText: displaySelectedTag ? e.target.outerText: ''})
  }

  handleTextSubmit = (e) => {
    const {handleTagSelected} = this.props;
    if ((e.key === 'Enter' || e.key === 'Tab') && this.state.inputText) {
      handleTagSelected(this.state.inputText, true);
      this.rehydrateMatches();
      this.setState({inputText: ''});

    }
  }

  render() {
    const {width, placeholder, maxHeight, autoFocus} = this.props;
    const suggestionsClasses = classnames('suggestions-container', {active: this.state.suggestionsOpen})
    const inputWidth = width + 'px'
    const suggestionsWidth = (parseInt(width) + 12).toString() + 'px';
    return (
      <div>
        <input className='suggestions-input'
               id='suggestions-input'
               style={{width: `${inputWidth}`}}
               type='text'
               placeholder={placeholder}
               value={this.state.inputText}
               onChange={this.handleInputChange}
               onKeyDown={this.handleTextSubmit}
               autoFocus={autoFocus}
               autoComplete='off'
               onFocus={this.handleFocus}
               onBlur={this.handleBlur}
        />
        <div className={suggestionsClasses}
             style={{
               width: `${suggestionsWidth}`,
               maxHeight: maxHeight,
               border: 'solid thin gray'
             }}>
          <div>
            {this.state.matches.map((match, index) => {
              return (
                <div
                  className='suggestion'
                  key={`match${index}`}
                  onMouseDown={this.handleMouseDown}
                >{match.suggestion}</div>
              )
            })}
          </div>

        </div>
      </div>
    );
  }
}

export default AutoSuggest;