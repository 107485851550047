import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import $ from 'jquery';
import axios from 'axios';
import {setModalInfo} from '../../../actions/setModalInfo';
import {editSurvey} from '../../../actions/Manage/editSurvey';
import {getSurveys} from '../../../actions/Manage/getSurveys';
import {cloneSurvey} from '../../../actions/Manage/cloneSurvey';
import showSystemError from '../../Common/showSystemError';
import SurveyRow from './SurveyRow';
import CloneSurveyModalController from './ModalCloneSurvey/CloneSurveyModalController';


class Surveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileLocks: null,
      showArchived: false,
      showHideButtonLabel: "Show Archived"
    };
  }

  componentDidMount = async () => {
    const {user, getSurveys} = this.props;
    window.addEventListener("resize", this.handleResize);
    try {
      await getSurveys(user.accountId)
      let fileLocks = await axios.get(`/test/getFileLocks?accountId=${user.accountId}`);
      this.setState({fileLocks: fileLocks.data});
      this.handleResize();
    } catch (err) {
      console.log(err);
      showSystemError();
    }
  }


  handleResize = () => {
    let $header = $('#header');
    let $bodyContainer = $('.body-container');
    let constantSpacer = 78;
    let topSpace = $('.subMenuMainPanel').position().top + $header.height();
    $bodyContainer.height(window.innerHeight - topSpace - constantSpacer);
  }

  cloneSurvey = (survey, clonedSurveyName) => {
    const {cloneSurvey, getSurveys, user} = this.props;
    let surveyTable = document.getElementsByClassName('surveysBodyContainerStyle')[0];
    console.log(survey, clonedSurveyName);
    cloneSurvey(survey, clonedSurveyName)
      .then(() => {
        getSurveys(user.accountId)
          .then(() => {
            surveyTable.scrollTo(0, 0);
          })
      }, (err) => {
        showSystemError()
      })
  }

  openModal = (data) => {
    const {setModalInfo, surveys} = this.props;
    data.surveys = surveys;
    setModalInfo(data);
  }

  handleEditSurvey = (id, testName, value) => {
    const {editSurvey} = this.props;
    editSurvey(id, value)
      .then(() => {
      }, (err) => {
        showSystemError()
      })

  }

  handleShowHideArchive = () => {
    if (this.state.showArchived) {
      this.setState({showHideButtonLabel: "Show Archived"});
      this.setState({showArchived: false});
    } else {
      this.setState({showHideButtonLabel: "Hide Archived"});
      this.setState({showArchived: true});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const {surveys, setModalInfo, modalInfo, user} = this.props;

    if (this.state.fileLocks === null) {
      return null;
    }
    return (
      <div id="headerContainer"
           style={{
             margin: '8px',
             border: 'solid thin #555555',
             borderRadius: '5px',
             marginTop: '10px',
             backgroundColor: '#d1e0e3'
           }}>
        <table id="header" className="surveyHeaderStyles">
          <tbody>
          <tr style={{backgroundColor: 'transparent', width: '100%'}}>
            <th style={{width: '20%'}}>Survey Name</th>
            <th style={{width: '10%'}}>Date Initiated</th>
            <th style={{width: '27%'}}>Status&nbsp;&nbsp;
              <button
                className="closeBtn blueButton"
                style={{marginTop: '9px', marginBottom: '8px'}}
                onClick={this.handleShowHideArchive}>
                  {this.state.showHideButtonLabel}
            </button>
            </th>
            <th style={{width: '12%'}}>Anonymous Surveys Completed</th>
            <th style={{width: '13%'}}>Named Surveys Completed</th>
            <th style={{width: '10%'}}>Date Survey Last Taken</th>
            <th style={{width: '8%'}}>&nbsp;</th>
          </tr>
          </tbody>
        </table>
        <div className="body-container surveysBodyContainerStyle">
          <table style={{width: '100%'}}>
            <tbody>
            {surveys.map((survey) => {
              return (
                <SurveyRow survey={survey}
                           user={user}
                           key={survey._id}
                           openModal={this.openModal}
                           handleEditSurvey={this.handleEditSurvey}
                           fileLocks={this.state.fileLocks}
                           showArchived = {this.state.showArchived}
                />
              )
            })}
            </tbody>
          </table>
        </div>
        <CloneSurveyModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.cloneSurvey}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    modalInfo: state.modalInfo,
    surveys: state.surveys
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModalInfo,
    editSurvey,
    getSurveys,
    cloneSurvey

  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
