import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toast} from 'react-toastify';
import classnames from 'classnames';
import slicedTin from '../Common/slicedTin';
import TMTContentEditable from "../Common/TMTContentEditable";
import showToastr from '../Common/showToastr';
import showSystemError from '../Common/showSystemError';
import ReactTooltip from 'react-tooltip'
import {save_AnonAssign_Table} from '../../actions/AnonAssign/save_AnonAssign_Table'
import $ from 'jquery';

class AnonAssignTable extends Component {

  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('anonAssignTable');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('anonAssignTable');
    }
    else {
      this.state = {
        group: '',
        isGroupUntouched: true,
        placeholderText: '',
        documentDirty: false,
        saveStarted: false
      }
    }
  }

  componentDidMount() {
    const {anonAssign_Table} = this.props;
    let group = anonAssign_Table.assignment.group;
    this.setState({
      group: group,
      isGroupUntouched: !group,
      placeholderText: group ? '' : 'Please enter a unique group name.'
    })
  }

  anonAssignHeader = () => {
    return (
      <table className="AssignTableHeader">
        <tbody>
        <tr>
          <th style={{width: '16%'}}
              className="spcHeaderCol">Survey Number
          </th>
          <th style={{width: '24%'}}
              className="participantNameHeaderCol">Group Name
          </th>
          <th style={{width: '60%'}}>&nbsp;</th>
        </tr>
        </tbody>
      </table>
    )
  }

  isUniqueGroupName(value) {
    const {existingGroups} = this.props;
    let proposedName = value.toLowerCase();
    return !existingGroups.find((group) => {
      return group.toLowerCase() === proposedName || proposedName === "new";
    })
  }

  onGroupNameChange = (e) => {
    this.setState({
      group: e.target.value,
      documentDirty: true
    })

  }

  onGroupNameBlur = () => {
    const {save_AnonAssign_Table} = this.props;
    if (this.isUniqueGroupName(this.state.group)) {
      this.setState({saveStarted: true})
      save_AnonAssign_Table(this.state.group)
        .then(() => {
          this.setState({
            saveStarted: false,
            documentDirty: false
          })
        }, (err) => {
          console.log(err);
          showSystemError()
        })
    }
    else {
      showToastr('fa fa-exclamation-circle', 'This group name is already taken. Please type in a unique group name.', {type: toast.TYPE.INFO})
    }

  }

  getIndicatorClass = () => {
    if (this.state.group) {
      return 'lineIndicatorGreen'
    }
    return 'lineIndicatorOrange'
  }

  getIndicatorText = () => {
    if (this.state.group) {
      return '';
    }
    return "A unique group name is required."
  }

  onFocus = () => {
    if (this.state.isGroupUntouched) {
      this.setState({
        group: '',
        isGroupUntouched: false,
        placeholderText: ''
      })
    }
  }

  adjustCSS = () => {
    $('.__react_component_tooltip').css('background-color','lightgoldenrodyellow').css('font-size','16px');
  }

  saveIndicator = () => {
    return (
      <div>
        <ReactTooltip place="left" html={false} border= {true} type="light" effect="float" id="dirty-indicator"/>
        <div className="saveAnonCreateIndicatorLocator"
             // data-tip="<span class='everyChangeSaved'>Every change you make is automatically saved.</span>"
             data-tip="Every change you make is automatically saved."
             data-for="dirty-indicator"
             onMouseOver={this.adjustCSS}
             >
          { this.state.documentDirty ?
            ( this.state.saveStarted ?
                <div className="saveIndicatorOrange">Saving...</div>
                :
                <div className="saveIndicatorOrange"></div>
            )
            :
            ( <div className="saveIndicatorGreen">All changes saved</div> )
          }
        </div>

      </div>
    )
  }

  callEmailModal = (e) => {
    const {openEmailModal, anonAssign_Table} = this.props;
    e.preventDefault();
    openEmailModal(anonAssign_Table.assignment);
  }

  callEmbedModal = (e) => {
    const {openEmbedModal, anonAssign_Table} = this.props;
    e.preventDefault();
    openEmbedModal(anonAssign_Table.assignment.TIN, anonAssign_Table.assignment.testName);
  }

  emailInstructions = () => {
    return (
      <td style={{
        width: '15%',
        backroundColor: 'transparent',
        border: 'solid thin transparent'
      }}>
        <button
          className="blueButton"
          style={{float: 'right'}}
          onClick={this.callEmailModal}
          disabled={!this.state.group}
        >EMAIL INSTRUCTIONS
        </button>
      </td>
    )
  }

  link_embed = () => {
    return (
      <td style={{
        width: '15%',
        backroundColor: 'transparent',
        border: 'solid thin transparent'
      }}>
        <button className="blueButton"
                onClick={this.callEmbedModal}
                disabled={!this.state.group}
                style={{float: 'right', marginRight: '50px'}}>LINK/EMBED
        </button>
      </td>
    )
  }

  componentWillUnmount () {
    if(!localStorage.getItem('anonAssign_reset')) {
      localStorage.setObject('anonAssignTable', this.state);
    }
    else {
      localStorage.removeItem('anonAssign_reset') //last component to unmount so remove
    }
  }

  render() {
    const {anonAssign_Table} = this.props;
    let anonTable = anonAssign_Table;
    let indicatorClass = this.getIndicatorClass();
    let indicatorText = this.getIndicatorText();
    let groupNameClasses = classnames('anonAssignDiv', {untouched: this.state.isGroupUntouched})
    return (
      <div style={{display: 'block'}}>
        {this.anonAssignHeader()}
        {this.saveIndicator()}
        <div className='AssignAnonBody' style={{paddingRight: '15px'}}>
          <div className='AssignAnonTableContainer'
               style={{width: '100%', border: 'solid thin gray', color: 'transparent'}}>
            <table style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className="anonAssignTicket">
                  <ReactTooltip id="row-indicator"
                                place="right"
                                type="dark"/>
                  <div className={indicatorClass}
                       data-tip={indicatorText}
                       data-for="row-indicator"
                  ></div>
                  <div>{slicedTin(anonTable.assignment.TIN)}</div>
                </td>
                <td className="anonAssignGroup">
                  <TMTContentEditable
                    id="group-name"
                    text={this.state.group || this.state.placeholderText}
                    classes={groupNameClasses}
                    onChange={this.onGroupNameChange}
                    onFocus={this.onFocus}
                    onBlur={this.onGroupNameBlur}
                    disabled={!anonTable.isGroupEditable}
                    highlightText={anonTable.isGroupEditable}
                  />
                </td>
                {this.emailInstructions()}
                {this.link_embed()}
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    anonAssign_Table: state.anonAssign_Table
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    save_AnonAssign_Table
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonAssignTable);