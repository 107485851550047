import React from 'react'

class TrackingRespondents extends React.Component {
    render() {
    // const {user} = this.props;
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>

          <img src="../underConstruction.png" alt=""/>

      </div>
    )
  }
}

export default TrackingRespondents;
