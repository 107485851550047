import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {edit_Create_Table_Row} from '../../actions/Create/edit_Create_Table_Row';
import {add_Create_Table_Row} from '../../actions/Create/add_Create_Table_Row';
import {save_Create_Table} from '../../actions/Create/save_Create_Table';
import {sequence_Create_Table_Row} from '../../actions/Create/sequence_Create_Table_Row';
import {del_Create_Table_Row} from '../../actions/Create/del_Create_Table_Row';
import {retrieve_Saved_Prompt} from '../../actions/Create/retrieve_Saved_Prompt';
import {setModalInfo} from '../../actions/setModalInfo';
import CreateDeleteRowModalController
  from '../Create/CreateModalDeleteRow/CreateDeleteRowModalController';
import RetrievePromptModalController
  from '../Create/RetrievePrompt/RetrievePromptModalController';
import SavePromptModalController from '../Create/SavePrompt/SavePromptModalController';
import SetGetAssessmentOptionsModalController
  from '../Create/CreateModalGetAssessmentOptions/SetGetAssessmentOptionsModalController'
import QuestionOptionsModalController
  from '../Create/QuestionOptionsModalController/QuestionOptionsModalController';
import CreateRow from './CreateRow';
import CreateHeader from './CreateHeader';
import CreateFooter from './CreateFooter';
import ReactTooltip from 'react-tooltip';
import showSystemError from '../Common/showSystemError';
import stripXMLtags from "../Common/stripXMLtags";
import $ from 'jquery';
import axios from 'axios';


class CreateTable extends React.Component {
  _isMounted = false;

  audioTranscript = []
  lastClickTime = 0;
  silenceUrl = {
    URL: `${window.DEVSTORE}/silenceHalfSecond.wav`,
    // URL: 'https://tmt-devstore.s3.amazonaws.com/silenceHalfSecond.wav',
    id: null
  }

  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('createTable');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('createTable');
    } else {
      this.state = {
        documentDirty: false,
        saveStarted: false,
        playing: false,
        rowPlaying: null,
        /* the following state properties are used to position imageURL when displayed near cursor */
        imgURL: 'https://tmt-devstore.s3.us-east-1.amazonaws.com/1pxTransparent.png',
        imgWidth: 0,
        imgHeight: 0,
        cursorX: 0,
        cursorY: 0,
        border: 'solid thick transparent',
        backgroundClr: 'transparent'
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    let selectorHeight = $('.createHeading').height();
    $('.testCheckAndSaver').height(window.innerHeight - 215 - selectorHeight);
    $('.testElementsContainer').height($('.testCheckAndSaver').height() - 30);
  }

  sortTable = () => {
    const {sequence_Create_Table_Row} = this.props;
    sequence_Create_Table_Row();
  }

  createNewRow = () => {
    const {add_Create_Table_Row, instrumentType, create_Table} = this.props;
    add_Create_Table_Row(instrumentType, create_Table.testName, create_Table.accountId);
  }

  deleteRow = (id, promptPosition, promptText) => {
    this.openDeleteModal({
      name: 'createDeleteRow',
      _id: id,
      promptPosition: promptPosition,
      promptText: promptText
    })
  }

  openDeleteModal = (data) => {
    const {setModalInfo} = this.props;
    setModalInfo(data);
  }

  savePromptToBank = async (row) => {
    try {
      let response = await axios.get(`/test/getSavedPrompts?accountId=${row.accountId}`)
      let allPrompts = response.data;
      let prompt = allPrompts.find((item) => {
        return item.prompt.promptText === row.promptText && item.prompt.testName === row.testName;
      })
      response = await axios.get(`/test/getExistingSuggestions?accountId=${row.accountId}`)
      let existingSuggestions = response.data;
      this.openSavePromptModal({
        name: 'savePromptToBank',
        inUse: !!prompt,
        existingSuggestions,
        row: row,
        tags: prompt ? prompt.tags : []
      })
    } catch (err) {
      console.log(err)
    }

  }

  retrievePrompts = async (accountId, toBeReplacedId) => {
    let retrievedPrompts = await axios.get(`/test/retrievePrompts?accountId=${accountId}`);
    let response = await axios.get(`/test/getExistingSuggestions?accountId=${accountId}`)
    this.openRetrievePromptModal({
      name: 'retrievePrompts',
      accountId,
      toBeReplacedId,
      retrievedPrompts: retrievedPrompts.data,
      existingSuggestions: response.data
    })
  }

  openRetrievePromptModal = (data) => {
    const {setModalInfo} = this.props;
    setModalInfo(data);
  }

  openSavePromptModal = (data) => {
    const {setModalInfo} = this.props;
    setModalInfo(data);
  }

  handleRetrievePrompt = async (toBeReplacedId, retrievedPrompt) => {
    const {retrieve_Saved_Prompt, user} = this.props;
    console.log(toBeReplacedId, retrievedPrompt);
    retrieve_Saved_Prompt(toBeReplacedId, retrievedPrompt.prompt)
      .then(() => {
      })
  }


  handlePromptSave = async (modalInfo) => {
    const {create_Table, user} = this.props;
    const {row, tags} = modalInfo;
    let tagsInUse = tags.map((tag) => {
      return tag.value;
    })
    let obj = {
      testName: create_Table.testName,
      row,
      tags: tagsInUse
    }

    try {
      let result = await axios.post('/test/savePromptToBank', obj)
      console.log('Prompt saved: ', result.data);
      obj = {
        accountId: user.accountId, tags: tags.filter((tag) => {
          return tag.isNew;
        })
      }
      result = await axios.post('/test/saveNewSuggestions', obj)
      console.log('New suggestions saved.', result.data);
    } catch (err) {
      console.log(err)
    }
  }


  getAssessmentOptions = (row) => {
    //console.log("CreateTable line 97 row: ", row);
    //redundant params to conform with the way SetScoring used modalInfo
    this.openGetAssessmentOptionsModal({
      name: 'getAssessmentOptions',
      question: stripXMLtags(row.promptText),
      //scoring: {...row.scoringParameters},
      promptId: row.promptId,
      row: row
    })
  }

  openGetAssessmentOptionsModal = (data) => {
    const {setModalInfo} = this.props;
    setModalInfo(data);
  }

  handleCreateOptionsModal = (row) => {
    const {setModalInfo, create_Table} = this.props;
    const data = {
      name: 'createQuestionOptions',
      question: stripXMLtags(row.promptText),
      isDeployed: create_Table.status === 'deployed',
      row
    }
    setModalInfo(data);
  }


  cancelHandler = () => {
    const {modalInfo} = this.props;
    switch (modalInfo.name) {
      case "createDeleteRow":
        console.log('This delete has NOT been executed.')
        break;
      case "getAssessmentOptions":
        console.log('scoring params unchanged');
        break;
      case 'savePromptToBank':
        break;
      case 'retrievePrompts':
        break;
      default:
        console.log("cancel handler fallthrough - shouldn't get here")
    }
  }

  confirmHandler = () => {

    const {modalInfo, del_Create_Table_Row, edit_Create_Table_Row} = this.props;
    let row = modalInfo.row;
    switch (modalInfo.name) {
      case "createDeleteRow":
        del_Create_Table_Row(modalInfo._id);
        this.handleSave();
        break;
      case "getAssessmentOptions":
        edit_Create_Table_Row(row)
        this.handleSave(row.testName);
        break;
      case 'createQuestionOptions':
        // row = {...row, ...modalInfo.row}
        edit_Create_Table_Row(modalInfo.row)
        this.handleSave(row.testName);
        break;
      case 'savePromptToBank':
        this.handlePromptSave(modalInfo)
        break;
      case 'retrievePrompts':
        this.handleRetrievePrompt(modalInfo.toBeReplacedId, modalInfo.retrievedPrompt)
        break;
      default:
        console.log("confirm handler fallthrough - shouldn't get here")
    }
  }

  handleRowChange = (data, paramChanged, doNotUpdateState) => {
    const {edit_Create_Table_Row, create_Table} = this.props;
    var dupsExist = false;
    let rows = create_Table.rows;
    let changedRow = rows.find((row) => {
      return row._id === data._id;
    })

    edit_Create_Table_Row({...changedRow, ...data});

    if (paramChanged === "promptPosition") { //check for dups if change was to promptPosition
      dupsExist = this.anyDups(data)
    }

    if (!dupsExist) {
      this.handleSave();
      return false;
    } else {
      changedRow.promptPosition = "";
      return true;
    }
  }


  anyDups = (data) => {
    const {create_Table} = this.props;
    let errors = false;
    let rows = create_Table.rows;
    if (data.promptPosition !== "") {
      for (var i = 0; i < rows.length; i++) {
        if (!rows[i].isDeleted) {
          if (data.promptPosition === rows[i].promptPosition) {
            errors = true;
            break;
          }
        }
      }
    }
    return errors;
  }

  handleSave = (testName) => {
    const {save_Create_Table, updateExistingAssignments, user} = this.props;
    this.setState({saveStarted: true});
    save_Create_Table()
      .then(() => {
        if (this._isMounted) {
          this.setState({documentDirty: false});
          this.setState({saveStarted: false});
          //this section only gets invoked when updating assessments
          if (testName) {
            console.log('saving previous assignments started');
            updateExistingAssignments(testName, false, user.accountId)
          }
        }
        console.log('Create Table Saved: ', new Date());
      }, (err) => {
        console.log(err);
        showSystemError();
      });
  }

  setDocumentDirty = () => {
    this.setState({documentDirty: true});
  }


  autoPlay = (i, list) => {
    var voicePrompt = new Audio(this.silenceUrl.URL);
    let self = this;
    playAudio(i, list);

    function playAudio(i, list) {
      if (!self.state.playing) {
        voicePrompt.src = list[i].URL;
        voicePrompt.play();
        self.setState({playing: true});
        self.setState({rowPlaying: list[i].id});

        voicePrompt.onended = function () {
          self.setState({playing: false});
          self.setState({rowPlaying: null});

          i = i + 1;

          if (i < list.length) {
            playAudio(i, list);
          } else {
            self.setState({playing: false})
          }
        }
      }
    }
  }

  buildAudioTranscript = () => {
    const {create_Table} = this.props;
    let rows = create_Table.rows;

    this.audioTranscript = [];
    rows.forEach((row) => {
      let url = row.URL;
      let deleted = row.isDeleted;
      if ((url) && (!deleted)) {
        this.audioTranscript.push({
          promptPosition: parseInt(row.promptPosition, 10),
          URL: row.URL,
          id: row._id
        });
      }
    });
    this.audioTranscript.sort((a, b) => (a.promptPosition > b.promptPosition) ? 1 : -1);
  }

  playInSequence = () => {
    this.buildAudioTranscript();
    this.autoPlay(0, this.audioTranscript)
  }

  adjustCSS = () => {
    $('.__react_component_tooltip').css('background-color', 'lightgoldenrodyellow').css('font-size', '16px');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this._isMounted = false;
    if (!localStorage.getItem('create_reset')) {
      localStorage.setObject('createTable', this.state);
    } else {
      localStorage.removeItem('create_reset'); //last component to unmount so remove
    }
  }

  render() {
    const {
      create_Table,
      history,
      setModalInfo,
      modalInfo,
      readOnly,
      user,
      instrumentType
    } = this.props;
    let rows = create_Table.rows;
    let imageType = this.state.imgURL.slice(-3);
    return (
      <div>
        <CreateHeader
          testName={create_Table.testName}
          instrumentType={instrumentType}
        />
        <ReactTooltip place="left" html={false} border={true} type="light" effect="float"
                      id="dirty-indicator"/>
        <div className="saveCreateIndicatorLocator"
             data-tip="Every change you make is automatically saved."
             data-for="dirty-indicator"
             onMouseOver={this.adjustCSS}
        >
          {this.state.documentDirty ?
            (this.state.saveStarted ?
                <div className="saveIndicatorOrange">Saving...</div>
                :
                <div className="saveIndicatorOrange"></div>
            )
            :
            (<div className="saveIndicatorGreen">All changes saved</div>)
          }
        </div>

        <div className="testElementsContainer"
             style={{
               overflowY: 'auto',
               backgroundColor: 'white',
               border: 'thin solid gray',
               marginLeft: '10px',
               position: 'relative',
               float: 'left',
               width: '98%',
               height: '500px'
             }}
        >

          <table className="testElementsTable"
                 style={{
                   borderLeft: 'solid thin none',
                   backgroundColor: 'transparent',
                   border: 'none',
                   padding: '0px'
                 }}>
            <tbody>
            {rows.map((row, index) => {
              return <CreateRow
                key={row._id}
                thisKey={row._id}
                getAssessmentOptions={this.getAssessmentOptions}
                handleCreateOptionsModal={this.handleCreateOptionsModal}
                rowPlaying={this.state.rowPlaying}
                row={create_Table.rows[index]}
                handleRowChange={this.handleRowChange}
                deleteRow={this.deleteRow}
                savePromptToBank={this.savePromptToBank}
                retrievePrompts={this.retrievePrompts}
                user={user}
                createNewRow={this.createNewRow}
                setDocumentDirty={this.setDocumentDirty}
                readOnly={readOnly}
                instrumentType={instrumentType}
                isDeployed={create_Table.status === 'deployed'}
              />
            })}
            </tbody>
          </table>
        </div>
        <div style={{
          maxHeight: '500px',
          overflowY: 'auto',
          position: 'relative',
          float: 'right',
          width: '1%'
        }}></div>
        <CreateDeleteRowModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.confirmHandler}
          cancelHandler={this.cancelHandler}
        />
        <SavePromptModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.confirmHandler}
          cancelHandler={this.cancelHandler}
        />
        <RetrievePromptModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.confirmHandler}
          cancelHandler={this.cancelHandler}
        />
        <SetGetAssessmentOptionsModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.confirmHandler}
          cancelHandler={this.cancelHandler}
        />
        <QuestionOptionsModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.confirmHandler}
          cancelHandler={this.cancelHandler}
        />
        <CreateFooter
          playInSequence={this.playInSequence}
          sortTable={this.sortTable}
          history={history}
          create_Table={create_Table}
          readOnly={readOnly}
          user={user}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    create_Table: state.create_Table,
    modalInfo: state.modalInfo,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    edit_Create_Table_Row,
    add_Create_Table_Row,
    save_Create_Table,
    setModalInfo,
    del_Create_Table_Row,
    sequence_Create_Table_Row,
    retrieve_Saved_Prompt
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTable);