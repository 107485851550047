import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';


const _defaultAnonInviteTemplate = () => {
  return (
    <div>
      <div>Dear ___________</div>
      <br/>
      <div>We are gathering the thoughts of selected individuals through an audio survey,
        and we are hoping you will participate.
      </div>
      <br/>
      <div>Participation is simple. You will hear several spoken questions, and after
        each, you will have a chance to reply.
      </div>
      <br/>
      <div>
        We chose this type of survey because it lets us listen to and consider each
        participant's viewpoints in their own words, without participants having to provide
        written responses.
      </div>
      <br/>
      <div>Thank you in advance for sharing your thoughts with us!</div>
      <br/>
      <div>______________</div>
      <br/>
    </div>

  )
}

const defaultAnonInviteTemplate = () => {
  return reactElementToJSXString(_defaultAnonInviteTemplate())
}


export default defaultAnonInviteTemplate;



