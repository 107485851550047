import stripXMLtags from  "../Common/stripXMLtags";

let textBuffer = "";
let itemNumber = 0;
let testScore = 0;
let explanation = "";
let allTestsScores = [];
let scoreArray = [];

function toScoreArray(itemNumb, maxScore, itemScore) {
  if (isNaN(parseInt(maxScore))) maxScore = "NA";
  let itemScoreObj = {
    itemNumb: itemNumb,
    maxScore: maxScore,
    itemScore: itemScore,
    explanation: JSON.parse(JSON.stringify(explanation))
  }
  scoreArray.push(itemScoreObj);
}

function toClipboard(thisText) {
  textBuffer = textBuffer + thisText + "\r\n";
}

function toExplanation(thisText) {
  explanation = explanation + thisText + "<br>";
}


/* ***************************************************************************** */
/*                                                                               */
/*  Iterate through each test item evaluating the test-taker's response to       */
/*  each item-prompt using the scoring parameters supplied by the test           */
/*  developer on the CREATE tab and the algorithm below, to generate a positive  */
/*  or negative contribution to the total score for each item. The details of    */
/*  the calculation for each item are written to the clipboard and can be pasted */
/*  into a document (e.g. notepad or Word) so that the basis for the test score  */
/*  that is produced can be reviewed step-by-step.                               */
/*                                                                               */
/* ***************************************************************************** */

function reviewDoScoring(transcriptArray) {

  for (var i = 0; i < transcriptArray.length; i++) {

    if (i === 0) {
      textBuffer = "";
      scoreArray = [];
      allTestsScores = [];
    }

    itemNumber = 0;
    testScore = 0;
    let transcript = transcriptArray[i];

    toClipboard("TEST NAME: " + transcript.dialog[0].prompt.testName);
    toClipboard("TEST IDENTIFICATION NUMBER: " + transcript.dialog[0].response.TIN);
    toClipboard("-------------------------------------------------------------------" + "\r\n");

    function arraySum(ar) { // function available to scoreItem that sums values in an array
      var sum = 0; // used later to compute average of multiple right answer points
      for (var i = 0; i < ar.length; i++) {
        sum += ar[i];
      }
      return sum;
    }

    function getScore(item) {
      let scoreParams = JSON.parse(item.prompt.scoringParameters);
      let response = "";

      if (item.response !== null){
        response = item.response.responseText;
      }

      let maxPts = parseInt(scoreParams.maxPts);
      let scorable = true;
      let wrongAnswerArray = scoreParams.wrongAnswers;
      let wrongPtsArray = [];
      let correctAnswerArray = scoreParams.correctAnswers;
      let correctPtsArray = [];
      let itemScore = 0;
      explanation = "";


      /* ********** check if scorable **************** */
      if ((isNaN(maxPts)) ||
       (correctAnswerArray[0] && (!correctAnswerArray[0].phrase || !correctAnswerArray[0] === ''))) 
      {
        scorable = false;
      }

      if (transcript.dialog[itemNumber].prompt !== undefined) {
        let noXMLprompt = stripXMLtags(transcript.dialog[itemNumber].prompt.promptText);
        toClipboard("(" + (parseInt(itemNumber) + 1) + ") PROMPT: " + noXMLprompt);
        toClipboard("RESPONDENT ANSWER: " + response);
        toExplanation("PROMPT: " + noXMLprompt);
        toExplanation("RESPONSE: " + response);
      }

      if (scorable) {
        wrongAnswerArray.forEach((element) => {

          let thisWrongAnswer = JSON.parse(JSON.stringify(item.response.responseText));
          let thisResponse = JSON.parse(JSON.stringify(element.phrase));

          if ((item.response.responseText !== undefined) && (thisWrongAnswer.toLowerCase().indexOf(thisResponse.toLowerCase()) !== -1) && (thisResponse !=="")){
            toClipboard("FOUND INCORRECT MATCH: " + element.phrase);
            toExplanation("FOUND INCORRECT MATCH: " + element.phrase);

            toClipboard("MAXPTS FOR THIS ITEM: " + maxPts);
            toExplanation("MAXPTS FOR THIS ITEM: " + maxPts);

            toClipboard("PENALTY PERCENT FOR THIS WRONG ANSWER: " + element.pct + "%");
            toExplanation("PENALTY PERCENT FOR THIS WRONG ANSWER: " + element.pct + "%");
            let penalty = parseInt(element.pct) / 100 * maxPts;
            toClipboard("POINT DEDUCTION FROM SCORE: " + element.pct + "% x " + maxPts + " = -" + penalty);
            toExplanation("POINT DEDUCTION FROM SCORE: " + element.pct + "% x " + maxPts + " = -" + penalty);
            wrongPtsArray.push(penalty);
          }
        })
      }

      if (wrongPtsArray.length > 0) { //matched 1 wrong answer in scoring parameters
        itemScore = Math.min(...wrongPtsArray) * -1; //choose smallest penalty and make it negative
        if (wrongPtsArray.length > 1) { //matched >1 wrong answer in scoring parameters
          toClipboard("\r\n");
          toClipboard("USING SMALLEST PENALTY CONTRIBUTION IN TOTAL SCORE: " + itemScore);
          toExplanation("\r\n");
          toExplanation("USING SMALLEST PENALTY CONTRIBUTION IN TOTAL SCORE: " + itemScore);
        }
      } else {

        /*  **** no wrong answrs found so look for correct answers ****  */

        let contribution = 0;
        if (scorable) {
          correctAnswerArray.forEach((element) => {

            contribution = parseInt(element.pct) / 100 * maxPts;

            let thisRightAnswer = JSON.parse(JSON.stringify(item.response.responseText));
            let thisResponse = JSON.parse(JSON.stringify(element.phrase));

            if ((item.response.responseText !== undefined) && (thisRightAnswer.toLowerCase().indexOf(thisResponse.toLowerCase()) !== -1)){
            //if ((item.response.responseText !== undefined) && (item.response.responseText.indexOf(element.phrase) !== -1)) {
              toClipboard("\r\n");
              toClipboard("FOUND CORRECT MATCH: " + element.phrase);
              toClipboard("MAXPTS FOR THIS ITEM: " + maxPts);
              toClipboard("PERCENT CONTRIBUTION FOR THIS RIGHT ANSWER: " + element.pct + "%");
              toClipboard("POINT CONTRIBUTION FOR THIS RIGHT ANSWER: " + element.pct + "% x " + maxPts + " = " + contribution);
              toExplanation("\r\n");
              toExplanation("FOUND CORRECT MATCH: " + element.phrase);
              toExplanation("MAXPTS FOR THIS ITEM: " + maxPts);
              toExplanation("PERCENT CONTRIBUTION FOR THIS RIGHT ANSWER: " + element.pct + "%");
              toExplanation("POINT CONTRIBUTION FOR THIS RIGHT ANSWER: " + element.pct + "% x " + maxPts + " = " + contribution);
              correctPtsArray.push(contribution);
            }
          })
        } else {
          toClipboard("UNSCORABLE: maxPts, correct answers, or % weighting are missing for this item.");
          toExplanation("UNSCORABLE: maxPts, correct answers, or % weighting are missing for this item.");
        }

        /* ********** set score depending on how many corrects given and their contribution values  ******** */
        if (correctPtsArray.length > 1) { // found more than 1 right answers
          let arrayTotal = arraySum(correctPtsArray); // average correct points
          itemScore = arrayTotal / correctPtsArray.length; // set item score
          toClipboard("\r\n");
          toClipboard("USING AVERAGE CONTRIBUTION IN TOTAL SCORE: " + itemScore);
          toExplanation("\r\n");
          toExplanation("USING AVERAGE CONTRIBUTION IN TOTAL SCORE: " + itemScore);
        } else if (correctPtsArray.length === 1) { //  just 1 right answer
          itemScore = correctPtsArray[0];
        } else if (correctPtsArray.length === 0) { // no right answers
          itemScore = 0;
          toClipboard("NO CORRECT OR INCORRECT ANSWERS FOUND");
          toExplanation("NO CORRECT OR INCORRECT ANSWERS FOUND");
        }
      }

      testScore = testScore + itemScore;

      toClipboard("\r\n");
      toClipboard("ITEM SCORE: " + itemScore);
      toClipboard("ACCUMULATING TEST SCORE: " + testScore);
      toClipboard("\r\n" + "-------------------------------------------------------------------" + "\r\n");

      toScoreArray(itemNumber, maxPts, itemScore, explanation);

      itemNumber++;

      /* ***************   after all items completed write total score to clipboard   **************** */
      if (itemNumber === transcript.dialog.length) {
        toClipboard("TOTAL SCORE: " + testScore);
        toClipboard("\r\n" + "\r\n" + "***** * ***** * ***** * ***** * ***** * ***** * ***** * ***** * ***** * ***** * ***** * ***** * *****" + "\r\n" + "\r\n");

        allTestsScores.push(JSON.parse(JSON.stringify(scoreArray)));
        scoreArray = [];
      }
    }

    transcript.dialog.forEach(getScore);

    navigator.clipboard.writeText("");
    navigator.clipboard.writeText(textBuffer);
  }


  return allTestsScores;
}

export default reviewDoScoring;