import React from 'react';

//adapted from: https://daveceddia.com/open-modal-in-react/



/*
Note the inlining of css directly. This is commonly done in React. Some people like it, but in general
I think it makes it harder if someday you want a designer to 'own' the look and feel of the code.
I use it here because I intend for this to a somewhat isolated stand alone component that can be easily
moved from project to project.

Unrelated, but since I'm talking about inlining styles, I also find using styles directly in a
component is helpful when all you want to do is, say, add some margin or padding to a
button and it is not something that you need to generalize to other buttons. It can avoid
the proliferation of classes that may only have a single use. It's a matter of taste and judgement.
 */

const backdropStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0,0.3)',
  zIndex: 10000
};

const modalStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  margin: '0 auto',
  color: "rgb(51, 51, 51)",
  background: 'rgb(245, 243, 238)',
  borderRadius: '2px',
  border: '3px solid #D9D2C5',
  overflow: 'hidden',
};


/*
David: Note ModalFrameHeader is what is called a 'functional' component.
Components that only render something to the screen can be written this way. This is a big religious issue in react.
They want whatever CAN be functional components to be coded as such, presumably for speed. I try to follow that
dictum but most times I find that what starts as a functional component winds up needing to change state,
have an instance method, or call a lifecyle method and that I have to recode as a class component. I just
thought I'd point that out since we never discussed it. Here I've been dealing with this basic component
for a while and it seems stable as a functional component.

Also see that I am violating another religious principle here. I have two components in one file.
I (and you) should almost never do that, but here, where we know that this 'ModalFrameHeader' will only
be used by it's parent component 'ModalFrame' it makes things a little less cluttered in the components
list of files which tends to grow rapidly in React, so I do it on occasion.
 */

function ModalFrameHeader(props) {
  const {noCloseButton, headerCloseButtonHandler, title} = props;
  return (
    <div className="modal-header">
      <div className="pull-left">
        {title}
      </div>
      {noCloseButton ? null : <div className="pull-right">
        <i className="fas fa-times"
           style={{color: '#65B0BA'}}
           onClick={headerCloseButtonHandler}
           role="button">
        </i>
      </div>}
    </div>
  )
}

/*
Note: I'm probably being a little 'naughty' here in the way I set the width of the dialog by directly
reaching into the dom and doing so. But since this component is meant to act as a frame for any needed
modal dialog we need to adjust it's width to reflect the content it gets and it won't do it on it's own
like a div would. It doesn't cause any problems since it is basically a blank canvas that sits on top of
the 'real' shadow dom it works. We should avoid doing this almost always.
 */
class ModalFrame extends React.Component {
  componentDidMount() {
    const {desktopWidth} = this.props;
    let w = desktopWidth || '60%';
    document.getElementById('modal').style.width = w;
  }
/*
Notice the use of {this.props.children} in this component. This is something relatively common to see in
React. One of the props that is automatically passed into each component is a list of any child components
it may have. You may well ask why you would need such a thing since the typical way a child is created is
 by declaring it directly in the component. ie
              <Parent
                <Clild />
                />

But in some circumstances - this being one - you want to create a component whose job is to 'surround'
whatever is put inside it. Thus the <ExampleModalController/> component is a parent of the <ModalFrame/>
component and also places the <Example/> component inside it. Thus the reference to {this.props.children}
is, in this case a reference to the <Example/> component.

This allows us to reuse the <ModalFrame/> to surround any kind of modal content we may need.
 */
  render() {
    const {title, headerCloseButtonHandler, hasHeader} = this.props;
    return (
      <div id="modal-backdrop" style={backdropStyle}>
        <div id="modal" style={modalStyle}>
          {hasHeader ?
            <ModalFrameHeader
            title={title}
            headerCloseButtonHandler={headerCloseButtonHandler}/>
            : null}

          {this.props.children}
        </div>
      </div>
    );
  }
}


export default ModalFrame;