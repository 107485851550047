import {GET_TRACK_CHART_DATA} from '../_CONSTANTS';

export const getTrackChartData = (track_Table) => {
  let batchNames = track_Table.batches;
  let cbArray = track_Table.cbArray;
  cbArray = cbArray.slice(-cbArray.length + 1)
  batchNames = batchNames.slice(-batchNames.length + 1)
  let assignments = track_Table.rows;
  let data = [];
  batchNames.forEach((batch) => {
    let obj = {
      name: batch,
      unstarted: 0,
      started: 0,
      completed: 0
    }
    data.push(obj);
  })
  assignments.forEach((assignment) => {
    let index = batchNames.findIndex((batchName) => {
      return batchName === assignment.batch;
    })
    if (cbArray[index]) {
      if (assignment.dateCompleted) {
        if (assignment.completionError) {
          data[index].started++;
        }
        else {
          data[index].completed++;
        }

      }
      else if (assignment.dateStarted) {
        data[index].started++;
      }
      else {
        data[index].unstarted++;
      }
    }
  })
  data = data.filter((d, index) => {
    return cbArray[index];
  })
  return {
    type: GET_TRACK_CHART_DATA,
    payload: {
      chartData: data,
      testName: track_Table.testName,
      key: new Date().getTime()
    }
  }
}