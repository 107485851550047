import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';


const _signupConfirmation = (email) => {
  return (
    <div>
      <p>
        <strong>To:</strong>
        {email}
      </p>
      <br/>
      <p>Thank you for signing up to TellMeThis.</p>
      <p>To complete your registration please click on this [url].</p>
      <p>You will then receive another email confirming your registration.</p>
      <p>This link will be good for 24 hours. After that you will need to create a new account.
</p>
      <br/>
      <p>If this email was sent in error or you do not want to complete your registration,
        just disregard this email.</p>

    </div>

  )
}

const signupConfirmation = (email) => {
  return reactElementToJSXString(_signupConfirmation(email))
}


export default signupConfirmation;




