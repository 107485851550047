import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toast} from 'react-toastify';
import $ from 'jquery';
import {get_Create_Table} from '../../actions/Create/get_Create_Table';
import {clear_Create_Table} from '../../actions/Create/clear_Create_Table';
import {set_Copied_Test} from '../../actions/Create/set_Copied_Test';
import {save_Create_Table} from '../../actions/Create/save_Create_Table';
import {toggle_Wants_Email} from "../../actions/Create/toggle_Wants_Email";
import {saveReportTitle} from '../../actions/Create/saveReportTitle';
import {setModalInfo} from '../../actions/setModalInfo';
import {DropdownList} from 'react-widgets'
import ReactTooltip from 'react-tooltip'
import getAllSurveys from '../Common/getAllSurveys';
import showSystemError from '../Common/showSystemError';
import showToastr from '../Common/showToastr';
import CreateOptionsModalController
  from './CreateOptionsModal/CreateOptionsModalController';
import LockedTestModalController from './LockedTestModal/LockedTestModalController';
import DeployedTestModalController from './DeployedTestModal/DeployedTestModalController';

class CreateSelector extends React.Component {
  constructor(props) {
    super(props);
    const {clear_Create_Table} = props;
    let oldState = localStorage.getObject('createSelector');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('createSelector');
    } else {
      clear_Create_Table()
      this.state = {
        testName: '',
        testSelectorName: '',
        testAlternatives: [],
        testSelected: false,
        lockedTest: '',
        instrumentType: 'TEST',
        wantsEmail: false,
        deploymentStatus: "development",
        hoverTitle: "Enables a request for respondent's email during question entry.",
        envelopeCursor: 'default',
        reportTitleDisplay: 'none',
        assessmentEmailTitle: 'Practice Interview',
        tempTitle: 'Practice Interview'
      }
    }
  }


  componentDidMount() {
    const {user} = this.props;
    getAllSurveys(user.accountId, 'create')
      .then((tests) => {
          this.setState({
            testAlternatives: tests
          });
        },
        (err) => {
          console.log(err);

        }
      )
  }


  checkAllowedCharacters = (inputString) => {
    const allowedCharacters = /^[a-zA-Z0-9 _-]+$/; // Regular expression for allowed characters

    if (inputString.match(allowedCharacters)) {
      return inputString; // All characters are in the allowed set
    } else {
      return null; // One or more characters are not in the allowed set
    }
  }

  handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let stringOK = this.checkAllowedCharacters(e.target.value);
    if (stringOK || e.target.value.length === 0) {
      this.setState({
        testName: e.target.value,
      });
    }
  }


  fileLockSurvey = (testName, accountId, email) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/test/lockSurvey',
        type: "POST",
        data: {
          testName,
          accountId,
          email
        },
        success: function (result) {
          resolve(result.status);
        },
        error: function (err) {
          console.log(err);
          reject()
        }
      })
    })
  }


  testNameInvalid = (name) => {
    if (this.state.testAlternatives.find((test) => {
      return test.testName.toLowerCase() === name.toLowerCase();
    })) {
      return 1
    }
    if (name.length < 3) {
      return 2;
    }
    return 0
  }

  openLockedTestModal(test) {
    const {setModalInfo, user} = this.props;
    setModalInfo({
      name: 'lockedTest',
      testAlternatives: this.state.testAlternatives,
      testName: test.testName,
      isLocked: test.isLocked,
      role: user.role
    })
  }

  openDeployedTestModal(test) {
    const {setModalInfo} = this.props;
    setModalInfo({
      name: 'deployedTest',
      testAlternatives: this.state.testAlternatives,
      testName: test.testName,
    })
  }

  handleLockedTest = (modalResult) => {
    const {
      get_Create_Table,
      showTable,
      setReadOnly,
      user
    } = this.props;
    if (modalResult.status === 'readOnly') {
      this.fileLockSurvey(modalResult.testName, user.accountId, user.email)
        .then((lock) => {
          if (!!lock) {
            showToastr('fa fa-exclamation-circle', this.testLockedContent(lock.email, lock.testName), {
              type: toast.TYPE.INFO,
              allowHtml: true,
              autoClose: 7500,
              pauseOnHover: true,
            })
          } else {
            setReadOnly();
            get_Create_Table({
              testName: modalResult.testName,
              isReadOnly: true
            })
              .then((create_Table) => {
                let hoverTitle = this.state.wantsEmail ? "Click to cancel email request" :
                  "Click to request respondent's email after last question";
                showTable(true);
                this.setState({
                  testSelected: true,
                  hoverTitle,
                  envelopeCursor: "default",
                  deploymentStatus: 'deployed',
                  wantsEmail: create_Table.wantsEmail,
                  assessmentEmailTitle: create_Table.assessmentEmailTitle,
                  tempTitle: create_Table.assessmentEmailTitle
                })
              })
              .catch((err) => {
                console.log(err);
                showSystemError()
              })
          }
        })
    }
  }

  testLockedContent = (email, testName) => {
    return (
      <div>
        Another user, <span
        style={{color: 'yellow', fontSize: '18px', fontFamily: 'serif'}}>{email}</span>,
        <br/>
        is currently editing <span style={{
        color: 'yellow',
        fontSize: '18px',
        fontFamily: 'serif'
      }}>"{testName}"</span>.
        <br/>
        You cannot edit it at the same time.
      </div>
    )
  }

  handleTestSelect = (value) => {
    const {get_Create_Table, showTable, user} = this.props;
    this.setState({deploymentStatus: value.status});
    if (value.isLocked || ((value.status === 'deployed' || value.status === "archived") && (user.role === 'analyst'))) {
      this.setState({lockedTest: value})
      this.openLockedTestModal(value)
    } else if (value.status === 'deployed' || value.status === 'archived') {
      this.setState({lockedTest: value})
      this.openDeployedTestModal(value);
    } else {
      this.fileLockSurvey(value.testName, user.accountId, user.email)
        .then((lock) => {
          if (!!lock) {
            showToastr('fa fa-exclamation-circle', this.testLockedContent(lock.email, value.testName), {
              type: toast.TYPE.INFO,
              allowHtml: true,
              autoClose: 7500,
              pauseOnHover: true,
            })
          } else {
            get_Create_Table({
              testName: value.testName,
            })
              .then(() => {
                let hoverTitle = value.wantsEmail ? "Click to cancel email request" :
                  "Click to request respondent's email after last question";
                showTable(true);
                this.setState({
                  testSelected: true,
                  testSelectorName: value.testName,
                  wantsEmail: value.wantsEmail,
                  assessmentEmailTitle: value.assessmentEmailTitle,
                  tempTitle: value.assessmentEmailTitle,
                  envelopeCursor: 'pointer',
                  deploymentStatus: value.status,
                  hoverTitle
                })
              }, (err) => {
                console.log(err);
                showSystemError()
              })
          }
        })
    }
  }

  handleCreateOptions = () => {
    const {user, setModalInfo, create_Table} = this.props;
    setModalInfo({
      name: 'createOptions',
      accountId: user.accountId,
      testName: create_Table.testName
    })
  }

  openOptionsModal = (accountId, testName) => {

  }

  handleClickMailIcon = (e) => {
    const {user} = this.props;
    console.log("got mail icon click");
    const {
      toggle_Wants_Email,
      save_Create_Table,
      create_Table,
      updateExistingAssignments
    } = this.props;
    toggle_Wants_Email(!this.state.wantsEmail);
    if (this.state.wantsEmail) {
      this.setState({reportTitleDisplay: 'none'})
    } else {
      this.setState({
        reportTitleDisplay: 'block',
        tempTitle: this.state.assessmentEmailTitle
      })
    }

    save_Create_Table()
      .then(() => {
        if (this.state.wantsEmail) {//if this is true, we are about to turn it false,
          // so execute 'updateExistingAssignment' now. if false, we are about to turn
          // it true, in which case 'updateExistingAssignment' when exiting
          // 'handleCancelReportTitle' or 'handleSaveReportTitle'
          updateExistingAssignments(create_Table.testName, true, user.accountId)
        }
        let wantsEmail = !this.state.wantsEmail;
        let hoverTitle = wantsEmail ? "Click to cancel email request" :
          "Click to request respondent's email after last question";
        this.setState({
          wantsEmail,
          hoverTitle,
          envelopeCursor: 'pointer'
        })
      })
  }

  handleCancelReportTitle = (e) => {
    const {create_Table, updateExistingAssignments, user} = this.props;
    updateExistingAssignments(create_Table.testName, true, user.accountId);
    this.setState({tempTitle: this.state.assessmentEmailTitle});
    this.setState({reportTitleDisplay: "none"});
  }

  handleSaveReportTitle = (e) => {
    const {
      saveReportTitle,
      save_Create_Table,
      create_Table,
      user,
      updateExistingAssignments
    } = this.props;
    this.setState({reportTitleDisplay: "none"});
    saveReportTitle(this.state.tempTitle);
    save_Create_Table()
      .then(() => {
        updateExistingAssignments(create_Table.testName, true, user.accountId)
        this.setState({
          reportTitleDisplay: "none",
          assessmentEmailTitle: this.state.tempTitle
        });
      })

  }

  handleEmailTitleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      tempTitle: e.target.value
    });
  }

  testSelector = () => {
    let dropdownListItem = ({item}) => {
      return (
        <span>
          {item.testName} &nbsp; &nbsp; &nbsp;
          {item.isLocked || item.status === 'deployed' || item.status === 'archived' ?
            <i className="fa fa-lock" style={{color: '#ccc', fontSize: '12px'}}></i> :
            <span>&nbsp;</span>}

        </span>
      )

    }

    return (
      <div style={{display: 'flex'}}>
        <div className="survey-name-prompt">
          {((this.state.testSelected) ?
              <div style={{
                float: 'left',
                paddingLeft: '60px',
                fontSize: '20px',
                cursor: this.state.envelopeCursor
              }}
                   title={this.state.hoverTitle}
                   onClick={this.handleClickMailIcon}>
                {(!this.state.wantsEmail ?
                    <i className="far fa-envelope" aria-hidden="true"
                       style={{color: "black"}}> </i>
                    :
                    <i className="fa fa-envelope" aria-hidden="true"
                       style={{color: "black"}}></i>
                )}
              </div>
              :
              <div style={{float: 'left', paddingLeft: '60px', fontSize: '20px'}}
                   title={this.state.hoverTitle}>
                <i className="far fa-envelope" aria-hidden="true"
                   style={{color: "#AAAAAA"}}></i>
              </div>
          )}
          {this.state.testSelected ?
            <div
              style={{
                float: 'left',
                paddingLeft: '28px',
                fontSize: '20px',
                cursor: 'pointer'
              }}
              title="click here to set survey options.">
              <i className='fa-solid fa-gear' style={{color: 'black'}}
                 onClick={this.handleCreateOptions}
              ></i>
            </div>
            :
            <div style={{
              float: 'left',
              paddingLeft: '28px',
              fontSize: '20px',
              cursor: 'pointer'
            }}
                 title="click here to set survey options once a test is selected.">
              <i className='fa-solid fa-gear' style={{color: '#AAAAAA'}}></i>
            </div>}
          Test Name: &nbsp;&nbsp;&nbsp;
        </div>
        <div className="survey-dropdown">
          <DropdownList
            data={this.state.testAlternatives}
            textField='testName'
            valueField="testName"
            value={this.state.testSelectorName}
            itemComponent={dropdownListItem}
            placeholder="Select a test to edit"
            disabled={this.state.testSelected}
            onChange={this.handleTestSelect}
            id="assignTestNameInput"
          />
        </div>
        <div className="reportTitleDiv" style={{display: this.state.reportTitleDisplay}}>
          <label className="reportTitleLabel">Email Report Title</label>
          <input className="reportTitleInput"
                 value={this.state.tempTitle}
                 onChange={this.handleEmailTitleChange}
          >
          </input>
          <label className="reportTitleLabel"><i>Use this title or enter your own.</i>
          </label>
          <div style={{position: "relative", float: "right", paddingTop: "15px"}}>
            <button className="blueButtonSmall"
                    type="button"
                    id="cancel-btn"
                    onClick={this.handleCancelReportTitle}>
              Cancel
            </button>
            <button className="blueButtonSmall"
                    type="submit"
                    id="save-btn"
                    onClick={this.handleSaveReportTitle}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }

  handleSubmit = () => {
    const {get_Create_Table, showTable, user} = this.props;
    let testName = this.state.testName;
    let isInvalidName = this.testNameInvalid(testName);
    if (!isInvalidName) {
      this.fileLockSurvey(testName, user.accountId, user.email)
        .then((lock) => {
          if (!!lock) {
            console.log('file is unavailable') //very unlikely to ever get here. Two
            // people would need to create the same test name at the same time.
            // Otherwise proposed name would be rejected as a duplicate.
            showToastr('fa fa-exclamation-circle', this.testLockedContent(lock.email, testName), {
              type: toast.TYPE.INFO,
              allowHtml: true,
              autoClose: 7500,
              pauseOnHover: true,
            })

          } else {
            get_Create_Table({
              testName: this.state.testName
            })
              .then(() => {
                showTable(true);
                this.setState({
                  testSelected: true,
                  hoverTitle: "Click to request respondent's email after last question"
                });

              }, (err) => {
                console.log(err);
                showSystemError()
              })
          }
        }, (err) => {
          console.log(err);
          showSystemError()
        })

    } else {
      if (isInvalidName === 1) {
        showToastr('fa fa-exclamation-circle', 'This survey name is already taken. Please try again', {type: toast.TYPE.INFO})
      } else {
        showToastr('fa fa-exclamation-circle', 'Test names must be at least three' +
          ' characters long.', {type: toast.TYPE.INFO})
      }

    }
  }

  newTestName = () => {
    return (
      <div style={{verticalAlign: 'center', marginTop: "5px"}}>
        Enter a new test name: &nbsp;&nbsp;
        <input
          className="textInputWidgetMatch"
          style={{width: '200px', padding: '5px'}}
          id="testNameInput"
          type="text"
          name="TestName"
          onChange={this.handleChange}
          disabled={this.state.testSelected}
          value={this.state.testName}/>
        <button className="blueButtonSmall"
                onClick={this.handleSubmit}
                style={{position: "relative", top: "-1px", marginLeft: "10px"}}
        >SUBMIT
        </button>
      </div>
    )
  }

  componentWillUnmount() {
    if (!localStorage.getItem('create_reset')) {
      localStorage.setObject('createSelector', this.state);
    }
  }

  developmentOnlyHTML = () => {
    return '<div class="createTooltipStyle">' +
      'This <span style="font-weight: bold;"><i>Create</i></span> tab enables the creation of new surveys, and also lists surveys already created if</br>' +
      'they are shown as "in development" under the <span style="font-weight: bold;"><i>Manage</i></span> tab.</br></br>' +
      'If a survey is shown on the <span style="font-weight: bold;"><i>Manage</i></span> tab as "deployed" or "archived", it can be made visible on</br>' +
      'this tab if its status is changed to "in development" on <span style="font-weight: bold;"><i>Manage</i></span>. But this can only be done</br>' +
      'if the survey has never been taken.  If it has been taken, and you wish to modify and redeploy it, </br>' +
      'you can "clone" a copy on the <span style="font-weight: bold;"><i>Manage</i></span> tab and then edit it here under <span style="font-weight: bold;"><i>Create</i></span>.</br>' +
      '</div>'
  }

  render() {
    const {modalInfo, setModalInfo} = this.props;
    return (
      <div className="createHeading">
        <ReactTooltip
          id="createDevOnly-tooltip"
          className="createTooltipStyle"
          place="right"
          type="light"
          border={true}
          html={true} //this is ok on Firefox
        />
        <div className="createDevOnly"
             data-tip={this.developmentOnlyHTML()}
          //data-html={true}
             data-for="createDevOnly-tooltip"
        >
          <i className="fa fa-info-circle"></i>
        </div>
        <div style={{display: 'flex'}}>
          <div className="createHeadingLeft">
            {this.testSelector()}
          </div>
          <div
            style={{width: '6%', textAlign: 'center'}}>
            <h3>or</h3>
          </div>
          <div className="createHeadingRight">
            {this.newTestName()}
          </div>
        </div>
        <LockedTestModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.handleLockedTest}

        />
        <DeployedTestModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.handleLockedTest}

        />

        <CreateOptionsModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          // confirmHandler={this.handleLockedTest}
        />

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    modalInfo: state.modalInfo,
    user: state.user,
    create_Table: state.create_Table
  };
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    get_Create_Table,
    clear_Create_Table,
    set_Copied_Test,
    save_Create_Table,
    setModalInfo,
    toggle_Wants_Email,
    saveReportTitle
  }, dispatch)
};

export const UserContext = React.createContext(null);
export default connect(mapStateToProps, mapDispatchToProps)(CreateSelector);
