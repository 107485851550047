import {
  GET_TRACK_TABLE,
  SET_TRACK_TABLE,
  SOCKET_START_ASSIGNMENT,
  SOCKET_COMPLETE_ASSIGNMENT,
  UNLOAD_TRACK,
  SOCKET_UPDATE_TRACK_TABLE,
  SOCKET_UPDATE_TRACK_TABLE_ROW,
  SOCKET_UPDATE_TRACK_TABLE_ROW_DELETE
} from '../actions/_CONSTANTS';
import moment from 'moment';
import _ from 'lodash';
import trackTableSort from '../components/Common/trackTableSort';

const track_Table = (state = {}, action) => {
  let newRows;
  let data;
  switch (action.type) {
    case GET_TRACK_TABLE:
      return action.payload;
    case SET_TRACK_TABLE:
      return action.payload;
    case SOCKET_START_ASSIGNMENT:
    case SOCKET_COMPLETE_ASSIGNMENT:
      if (!state.rows) {
        return state;
      }
      newRows = [];
      state.rows.forEach((row) => {
        if (row.TIN === action.payload.TIN) {
          if (action.payload.type === "START_ASSIGNMENT") {
            row.dateStarted = moment(new Date()).format()
          }
          else {
            row.dateCompleted = moment(new Date()).format()
          }

        }
        newRows.push(row);
      })
      return {...state, ...{rows: newRows}}
    case UNLOAD_TRACK:
      return {}
    case SOCKET_UPDATE_TRACK_TABLE:
      data = action.payload;
      console.log(data);
      if (_.isEmpty(state)) {
        return state;
      }

      if (data.testName !== state.testName) {
        return state;
      }
      let assignments = data.assignments;
      let newBatches = [...state.batches];
      let newCbArray = [...state.cbArray];

      let batchIndex = state.batches.findIndex((batch) => {
        return batch === data.batch
      });

      // batchIndex = batchIndex === -1 ? batchIndex : batchIndex - 1;

      if (batchIndex === -1) {//new batch
        newBatches.push(data.batch);
        newCbArray.push(true);
        assignments.forEach((assignment) => {
          assignment.backgroundColor = window.batchColors[newBatches.length % 10];
        })
        return {
          ...state, ...{
            rows: [...state.rows, ...assignments],
            batches: newBatches,
            cbArray: newCbArray
          }
        };
      }

      else if (!state.cbArray[batchIndex]) { // batch has been deselected
        return state;
      }
      else { //add to an existing batch
        assignments.forEach((assignment) => {
          assignment.backgroundColor = window.batchColors[(batchIndex-1) % 10];
        })
        newRows = [...state.rows, ...assignments]
        newRows = trackTableSort(newRows, state.sortCriteria);
        return {
          ...state, ...{
            rows: newRows,
            batches: newBatches,
            cbArray: newCbArray
          }
        };
      }
    case SOCKET_UPDATE_TRACK_TABLE_ROW:
    case SOCKET_UPDATE_TRACK_TABLE_ROW_DELETE:
      if (_.isEmpty(state)) {
        return state;
      }

      newRows = state.rows;
      newRows = [...newRows];
      data = action.payload;
      if (action.type === SOCKET_UPDATE_TRACK_TABLE_ROW) {
        newRows.forEach((row) => {
          if (row.TIN === data.TIN) {
            row.email = data.email;
            row.testTaker = data.participantName
          }
        })
      }
      else {
        newRows = newRows.filter((row) => {
          return row._id !== action.payload.id;
        })
      }

      return {...state, ...{rows: newRows}};
    default:
      return state;
  }
}

export default track_Table;
