import $ from 'jquery';
import passwordReset from '../../components/email_templates/passwordReset'

export const requestPasswordReset = (email) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = '/email/requestPasswordReset';
      //let url = '/testerror';
      let html = passwordReset(email);
      $.ajax({
        url,
        type: 'POST',
        data: {
          email: email,
          html: html
        },
        success: (result) => {
          if (result.error) {
            resolve(false);
          }
          resolve(true);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })

    })
  }
}

