import {
  GET_TRANSCRIPT,
  EDIT_RESPONSE_TEXT,
  CLEAR_TRANSCRIPT,
  SET_UPDATING_ASSESSMENTS
} from '../actions/_CONSTANTS';

const transcript = (state = {dialog:[]}, action) => {
  switch (action.type) {
    case GET_TRANSCRIPT:
      return action.payload;
    case EDIT_RESPONSE_TEXT:
      let dialog = [...state.dialog];
      dialog.forEach((row) => {
        if (action.payload._id === row.response._id) {
          row.responseText = action.payload.responseText;
        }
      })
      return {...state, ...{dialog: dialog}};
    case CLEAR_TRANSCRIPT:
      return action.payload;
    case SET_UPDATING_ASSESSMENTS:
      return {...state, ...{assessmentInProgress: action.payload}}
    default:
      return state
  }
}

export default transcript;