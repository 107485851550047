import {
  GET_TRACK_CHART_DATA,
  SOCKET_START_ASSIGNMENT,
  SOCKET_COMPLETE_ASSIGNMENT,
  SOCKET_UPDATE_TRACK_TABLE,
  SOCKET_UPDATE_TRACK_TABLE_ROW_DELETE
} from '../actions/_CONSTANTS';
import _ from 'lodash';

const trackChartData = (state = {}, action) => {
  let newState;
  let chartData;
  let batch;
  switch (action.type) {
    case GET_TRACK_CHART_DATA:
      return action.payload;
    case SOCKET_START_ASSIGNMENT:
    case SOCKET_COMPLETE_ASSIGNMENT:
    case SOCKET_UPDATE_TRACK_TABLE:
      if (_.isEmpty(state)) {
        return state;
      }

      if (action.payload.testName !== state.testName) {
        return state;
      }
      newState = {...state};
      chartData = [...newState.chartData];
      batch = action.payload.batch;
      let type = action.payload.type;
      if (chartData.findIndex((item) => {
          return item.name === batch
        }) === -1) {
        let newColumn = {
          name: batch,
          unstarted: 0,
          started: 0,
          completed: 0
        }
        chartData.push(newColumn);
      }

      chartData.forEach((bar) => {
        if (bar.name === batch) {
          switch (type) {
            case "START_ASSIGNMENT":
              bar.unstarted--;
              bar.started++;
              break;
            case "COMPLETE_ASSIGNMENT":
              if (!bar.completionError) {
                bar.started--;
                bar.completed++;
              }
              break;
            case "ADD_ASSIGNMENTS":
              bar.unstarted = bar.unstarted + action.payload.assignments.length;
              break;
            default:
              break;
          }
        }
      })
      return {...newState, ...{chartData: chartData, key: new Date().getTime()}}
    case SOCKET_UPDATE_TRACK_TABLE_ROW_DELETE:
      if (_.isEmpty(state) || action.payload.testName !== state.testName) {
        return state;
      }
      newState = {...state};
      chartData = [...newState.chartData];
      batch = action.payload.batch;
      chartData.forEach((bar) => {
        if (bar.name === batch) {
          bar.unstarted--;
        }
      })
      return {...newState, ...{chartData: chartData, key: new Date().getTime()}}
    default:
      return state;
  }
}


export default trackChartData;