import {EDIT_ASSIGN_TABLE_ROW} from '../_CONSTANTS';
import $ from 'jquery';

export const sendNamedInviteEmail = (html, email, subject, _id) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = 'email/sendNamedInviteEmail';
      //let url = '/testerror';
      $.ajax({
        url,
        type: "POST",
        data: {
          email,
          html,
          subject,
          _id
        },
        success: (result) => {
          if (result.authError) {
            alert('user is not authorized.')
          }
          dispatch({
            type: EDIT_ASSIGN_TABLE_ROW,
            payload:{
              _id: _id,
              inviteSent: true
            }
          })
          resolve(result);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })

    })
  }
}
