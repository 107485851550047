import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import $ from 'jquery';
//import Spinner from 'react-spinner';
import {setActiveModule} from '../../actions/setActiveModule';
import {getAnonAssignments} from '../../actions/TrackAnon/getAnonAssignments';
import {getAllAnonAssignments} from '../../actions/TrackAnon/getAllAnonAssignments';
import getAllSurveys from '../Common/getAllSurveys';
import showSystemError from '../Common/showSystemError';
import ChartCreator from './ChartCreator';
import Navbar from '../Navbar';
import {restoreEvents, suspendEvents} from '../Common/events'


/* support for downloading chart pdf */
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import base64Logo from './base64Logo';

Moment.locale('en');
momentLocalizer();
/* support for downloading chart pdf */


class ChartContainer extends React.Component {
  constructor(props) {
    super(props);
    const {chartId} = props;
    let oldState = localStorage.getObject(`chartContainer-${chartId}`);
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem(`chartContainer-${chartId}`);
    }
    else {
      this.state = {
        title: ''
      }
    }
  }


  handleTitleChange = (e) => {
    this.setState({title: e.target.value});
  }

  closeHandler = (e) => {
    const {subtractChild} = this.props;
    subtractChild(e.currentTarget.id);
  }

  /* ******************************************************* */
  /*  everything for the downloading is in this handler */
  /* ******************************************************* */
  downloadHandler = (e) => {

    let elem = $('#input-' + this.props.id);
    let parentElem = $('#container-' + this.props.id);
    let title = $(elem).val();
    let surveyName = $(parentElem).find('.rw-dropdown-list-input').text();
    if (surveyName === "Select a survey to track") {
      surveyName = "-"
    }
    let fromDate = $(parentElem).find('.from-date').find('input').val();
    let toDate = $(parentElem).find('.to-date').find('input').val();

    if (title === "") title = "title";
    const today = Moment();

    let divForPDF = $('#container-' + this.props.id).find($('.component'))[0];

    const mmPageWidth = 295; // these constants used for centering text
    const ptsToMm = 72 / 25.6;

    html2canvas(divForPDF).then(function (canvas) {
      const imgData = canvas.toDataURL('image/png');

      /* create new pdf object */
      const pdf = new jspdf({
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true,
        floatPrecision: 16
      });

      /* now set up the pdf layout */

      function getCenteringPos(thisText, thisSize) { // support for centering text
        let stringWidth = pdf.getStringUnitWidth(thisText) * thisSize / ptsToMm;
        return (mmPageWidth / 2 ) - (stringWidth / 2);
      }

      pdf.setFont("times");
      pdf.setFontType("normal");
      pdf.setFontSize(18);
      let titleLeft = getCenteringPos(title, 18);
      pdf.text(titleLeft, 27, title);

      pdf.addImage(imgData, 'PNG', 37, 47, 220, 125); //source, type, x-offset from left margin, y-offset from top margin, width, height
      pdf.setLineWidth(0.2);
      pdf.setDrawColor(44, 107, 152);

      pdf.setLineWidth(0.2);
      pdf.setDrawColor(44, 107, 152);
      pdf.setFillColor(255, 255, 255);
      pdf.rect(35, 50, 174, 25, 'F');

      pdf.setFont("times");
      pdf.setFontType("normal");
      pdf.setFontSize(15);
      pdf.text(40, 55, "Survey name:  " + surveyName);
      pdf.text(40, 62, "Data collected:  " + fromDate + " - " + toDate);

      pdf.setFontType("normal");
      pdf.setFontSize(10);
      pdf.text(20, 201, today.format('MM/DD/YYYY'));

      if (title === "title") {
        title = "TrackGraph.pdf";
      } else {
        title = title + ".pdf";
      }

      let footerLeft = getCenteringPos("filename: " + title, 10);
      pdf.text(footerLeft, 201, "filename: " + title);
      pdf.addImage(base64Logo, 'PNG', 250, 195, 31.5, 7);
      pdf.save(title);
    });
  }
  /* ******************************************************* */


  componentWillUnmount() {
    const {chartId} = this.props;
    if (!localStorage.getItem('trackAnon_reset')) {
      localStorage.setObject(`chartContainer-${chartId}`, this.state);
    }

  }

  render() {
    const {id, firstDiv, addChild, surveys, anonAssignments, getAnonAssignments, user, chartId} = this.props;
    return (
      <div className="chartDiv" style={{paddingTop: '25px'}} id={`container-${id}`}>
        <input id={`input-${id}`}
               type="text"
               className="chart-title-input"
               value={this.state.title}
               onChange={this.handleTitleChange}
               placeholder='title'/>
        <div
          className="chart-download-icon-container"
        >
          <img alt="" src="downloadIcon.png"
               className="chart-download-icon"
               id={`chart-${id}`}
               onClick={this.downloadHandler}
          />
        </div>
        <div
          className="chart-close-container">
          <i className="fas fa-times"
             role="button"
             id={`icon-${id}`}
             onClick={this.closeHandler}
          >

          </i>
        </div>

        <ChartCreator
          surveys={surveys}
          anonAssignments={anonAssignments}
          getAnonAssignments={getAnonAssignments}
          user={user}
          chartId={chartId}
        />
        {firstDiv ?
          <div className="sideDiv">
            <div className="btnDiv">
              <img className='addChartBtn'
                   src='addChartTransp.png' alt=""
                   onClick={addChild}
              />
            </div>
          </div>
          : null}
      </div>
    )
  }
}


class TrackAnon extends React.Component {

  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('trackAnon');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('trackAnon');
    }
    else {
      this.state = {
        lastId: 1,
        containers: [{id: 1, chartId: new Date().getTime().toString().slice(-6)}],
        gearVisible: true,
        surveys: []
      }
      suspendEvents();
    }
  }

  componentDidMount() {
    const {setActiveModule, getAllAnonAssignments, user} = this.props;
    setActiveModule("TrackAnon");
    window.addEventListener("resize", this.handleResize);
    localStorage.removeItem('trackAnon_reset')
    let refresh = !!localStorage.getItem('trackAnon_refresh');
    localStorage.removeItem('trackAnon_refresh');
    this.handleResize();
    getAllSurveys(user.accountId, 'trackAnon')
      .then((surveys) => {
        getAllAnonAssignments(user.accountId, refresh)
          .then(() => {
            this.setState({
              surveys,
              gearVisible: false
            });
            restoreEvents()
          }, (err) => {
            console.log(err)
            showSystemError();
          })
      }, ((err) => {
        console.log(err)
        showSystemError();
      }))

  }

  handleResize = () => {
    $('.main').height(window.innerHeight - 164).css('max-height', `$(window.innerHeight-164)px`);
  }

  subtractChild = (id) => {
    id = id.slice(-6)
    let containers = [...this.state.containers];

    _.remove(containers, (container) => {
      return container.chartId.toString() === id;
    })
    this.setState({containers}, () => {
      if (!containers.length) {
        this.addChild();
      }
    })
  }

  addChild = () => {
    let lastId = this.state.lastId + 1;
    let containers = [...[{
      id: lastId,
      chartId: new Date().getTime().toString().slice(-6)
    }], ...this.state.containers];
    this.setState({
      containers,
      lastId
    })
  }

  closeModule = () => {
    const {history} = this.props;
    localStorage.setItem('trackAnon_reset', 'reset');
    localStorage.setItem('trackAnon_refresh', 'refresh');
    history.push('/recycle/');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (!localStorage.getItem('trackAnon_reset')) {
      localStorage.setObject('trackAnon', this.state);
    }
  }

  render() {
    const {history, anonAssignments, getAnonAssignments, user} = this.props;
    if (_.isEmpty(user)) {
      history.push('/')
      return (
        null
      )
    }
    return (
      <div>
        <div>
          <div>
            <Navbar
              history={history}
            />
            <div style={{position: 'relative', width: '100%', height: '0px'}}>
              { this.state.gearVisible ?
                <div style={{
                  position: 'relative',
                  top: '230px',
                  width: '90%',
                  height: '400px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  border: 'solid thick transparent'
                }}>
                  <img src="animatedGears.gif" width='300px' alt=""
                       style={{marginTop: '0px', border: 'solid thin transparent'}}></img>
                  <div className="please-wait"
                       style={{
                         top: '-15px',
                         zIndex: '10',
                         backgroundColor: 'white',
                         width: '500px',
                         margin: 'auto'
                       }}>
                    Please wait a few moments while we organize your data.
                  </div>
                </div>
                :
                null
              }
            </div>
            <div className="main mainVisible" style={{overflowY: 'auto'}}>

              <div className="flex-container">
                {this.state.containers.map((container, index) => {
                  return (<ChartContainer
                    chartId={container.chartId}
                    id={`container-${container.chartId}`}
                    key={container.id}
                    surveys={this.state.surveys}
                    anonAssignments={anonAssignments}
                    getAnonAssignments={getAnonAssignments}
                    user={user}
                    firstDiv={index === 0}
                    subtractChild={this.subtractChild}
                    addChild={this.addChild}/>)
                })}
              </div>

            </div>
          </div>
        </div>
        <div className='trackAnonFooter'>
          <button className={'blueButton'}
                  style={{marginTop: '6px'}}
                  onClick={this.closeModule}>CLOSE
          </button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    anonAssignments: state.anonAssignments,
    allAnonAssignments: state.allAnonAssignments
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setActiveModule,
    getAnonAssignments,
    getAllAnonAssignments
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackAnon);