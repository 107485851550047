import React from 'react';
import $ from 'jquery';
import Account from './Account';
import Surveys from './Surveys/Surveys';
import Staff from './Staff/Staff';
import About from './About';
import SuperUser from './SuperUser/SuperUser';



class ManageContent extends React.Component {

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    $('.subMenuMainPanel').height(window.innerHeight - 8).width(window.innerWidth - 200)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const {subPath, user} = this.props;
    console.log('subpath: ',subPath);
    if (!subPath) {
      return null;
    }
    switch (subPath) {
      case 'account':
        return (
          <div className="subMenuMainPanel">
            <Account user={user}
            />
          </div>
        )
      case 'surveys':
        return (
          <div className="subMenuMainPanel">
            <Surveys user={user}/>
          </div>)
      case 'staff':
        return (
          <div className="subMenuMainPanel">
            <Staff user={user}
            />
          </div>)
      case 'about':
        return (
          <div className="subMenuMainPanel" style={{overflowY:'auto'}}>
            <About user={user}/>
          </div>)
      case 'superUser':
        return (
          <div className ="subMenuMainPanel">
            <SuperUser user={user}/>
          </div>
        )
      default:
        return null;
    }
  }
}

export default ManageContent;