

import {GET_GROUP_ASSIGNMENT} from '../actions/_CONSTANTS';

const groupAssignment = (state={}, action) => {
  switch(action.type){
    case GET_GROUP_ASSIGNMENT:
      return action.payload;
    default:
      return state;
  }
}

export default groupAssignment;