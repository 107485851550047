
import {SET_REVIEW_NAMES} from '../actions/_CONSTANTS';

const reviewNames = (state=[], action) => {
  switch(action.type){
    case SET_REVIEW_NAMES:
      return action.payload;
    default:
      return state;
  }
}

export default reviewNames;
