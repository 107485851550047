export const SET_ACTIVE_MODULE = "SET_ACTIVE_MODULE";
export const GET_CREATE_TABLE = "GET_CREATE_TABLE";
export const EDIT_CREATE_TABLE_ROW = "EDIT_CREATE_TABLE_ROW";
export const ADD_CREATE_TABLE_ROW = "ADD_CREATE_TABLE_ROW";
export const DEL_CREATE_TABLE_ROW = "DEL_CREATE_TABLE_ROW";
export const SEQUENCE_CREATE_TABLE_ROW = "SEQUENCE_CREATE_TABLE_ROW";
export const SET_MODAL_ID = "SET_MODAL_ID";
export const CLEAR_CREATE_TABLE = "CLEAR_CREATE_TABLE";
export const GET_ASSIGN_TABLE = "GET_ASSIGN_TABLE";
export const EDIT_ASSIGN_TABLE_ROW = "EDIT_ASSIGN_TABLE_ROW";
export const DEL_ASSIGN_TABLE_ROW = "DEL_ASSIGN_TABLE_ROW";
export const SET_ALL_INVITES = "SET_ALL_INVITES";
export const SET_MODAL_INFO = "SET_MODAL_INFO";
export const CLEAR_ASSIGN_TABLE = "CLEAR_ASSIGN_TABLE";
export const GET_NAMED_ASSIGNMENTS = "GET_NAMED_ASSIGNMENTS";
export const GET_REVIEW_TABLE = "GET_REVIEW_TABLE";
export const SET_REVIEW_DATA_LOADED = "SET_REVIEW_DATA_LOADED";
export const SET_REVIEW_EMAILS = "SET_REVIEW_EMAILS";
export const SET_REVIEW_NAMES = "SET_REVIEW_NAMES";
export const SET_REVIEW_SURVEYS = "SET_REVIEW_SURVEYS";
export const SET_REVIEW_BATCHES = "SET_REVIEW_BATCHES";
export const GET_TRANSCRIPT = "GET_TRANSCRIPT";
export const EDIT_RESPONSE_TEXT = "EDIT_RESPONSE_TEXT";
export const SET_FILTERED_TABLE = "SET_FILTERED_TABLE";
export const GET_GROUP_ASSIGNMENT = "GET_GROUP_ASSIGNMENT";
export const CLEAR_ANONASSIGN_TABLE = "CLEAR_ANONASSIGN_TABLE";
export const GET_ANONASSIGN_TABLE = "GET_ANONASSIGN_TABLE";
export const SAVE_ANONASSIGN_TABLE = "SAVE_ANONASSIGN_TABLE";
export const SET_COPIED_TEST = "SET_COPIED_TEST";
export const CLEAR_TRANSCRIPT = "CLEAR_TRANSCRIPT";
export const GET_TRACK_TABLE = "GET_TRACK_TABLE";
export const SET_TRACK_TABLE = "SET_TRACK_TABLE";
export const GET_INVITE_TEMPLATES = "GET_INVITE_TEMPLATES";
export const SAVE_INVITE_TEMPLATE = "SAVE_INVITE_TEMPLATE";
export const SIGNUP_USER = "SIGNUP_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_NAMED_INVITE_TEMPLATES = "GET_NAMED_INVITE_TEMPLATES";
export const SAVE_NAMED_INVITE_TEMPLATE = "SAVE_NAMED_INVITE_TEMPLATE";
export const EDIT_INVITE_ALL = "EDIT_INVITE_ALL";
export const INCREMENT_VISITS_HOME = "INCREMENT_VISITS_HOME";
export const ADD_STAFF_MEMBER = "ADD_STAFF_MEMBER";
export const GET_STAFF = "GET_STAFF";
export const SET_ACTIVE_SUBPATH = "SET_ACTIVE_SUBPATH"
export const EDIT_STAFF = "EDIT_STAFF";
export const DELETE_STAFF = "DELETE_STAFF";
export const GET_SURVEYS = "GET_SURVEYS";
export const EDIT_SURVEY = "EDIT_SURVEY";
export const CLONE_SURVEY = "CLONE_SURVEY";
export const GET_ANON_ASSIGNMENTS = "GET_ANON_ASSIGNMENTS";
export const GET_ALL_ANON_ASSIGNMENTS = "GET_ALL_ANON_ASSIGNMENTS";
export const GET_ALL_NAMED_ASSIGNMENTS = "GET_ALL_NAMED_ASSIGNMENTS";
export const STORE_BERTDATA = "STORE_BERTDATA";
export const CLEAR_BERTDATA = "CLEAR_BERTDATA";
export const STORE_WORDGROUPS = "STORE_WORDGROUPS";
export const CLEAR_WORDGROUPS = "CLEAR_WORDGROUPS";
export const SET_SOCKET = "SET_SOCKET";
export const SOCKET_START_ASSIGNMENT = "SOCKET_START_ASSIGNMENT";
export const SOCKET_COMPLETE_ASSIGNMENT = "SOCKET_COMPLETE_ASSIGNMENT";
export const UNLOAD_TRACK = "UNLOAD_TRACK";
export const GET_TRACK_CHART_DATA = "GET_TRACK_CHART_DATA";
export const SOCKET_UPDATE_ITEM_RESPONSE = "SOCKET_UPDATE_ITEM_RESPONSE";
export const SOCKET_ADD_ASSIGNMENTS = "SOCKET_ADD_ASSIGNMENTS"
export const SET_BATCH_COLOR_ARRAY = "SET_BATCH_COLOR_ARRAY";
export const SOCKET_UPDATE_TRACK_TABLE = "SOCKET_UPDATE_TRACK_TABLE";
export const SOCKET_UPDATE_TRACK_TABLE_ROW = "SOCKET_UPDATE_TRACK_TABLE_ROW";
export const SOCKET_UPDATE_TRACK_TABLE_ROW_DELETE = "SOCKET_UPDATE_TRACK_TABLE_ROW_DELETE";
export const SOCKET_LOCK_SURVEY = "SOCKET_LOCK_SURVEY";
export const SOCKET_TEST_SAVED = "SOCKET_TEST_SAVED";
export const SOCKET_TEST_OPENED = "SOCKET_TEST_OPENED";
export const SOCKET_TEST_CLOSED = "SOCKET_TEST_CLOSED";
export const ADD_COMPLETED_ASSIGNMENT = "ADD_COMPLETED_ASSIGNMENT";
export const SET_ENV = "SET_ENV";
export const DEFAULT_TEST_DELAY = "10";
export const DEFAULT_SURVEY_DELAY = "3";
export const TOGGLE_WANTS_EMAIL = "WANTS_EMAIL";
export const SET_WHITE_LABEL_INFO = "SET_WHITE_LABEL_INFO";
export const GET_WHITE_LABEL_INFO = "GET_WHITE_LABEL_INFO";
export const SAVE_REPORT_TITLE = "SAVE_REPORT_TITLE";
export const RETRIEVE_SAVED_PROMPT = "RETRIEVE_SAVED_PROMPT";
export const SET_UPDATING_ASSESSMENTS = "SET_UPDATING_ASSESSMENTS";
