import React from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {signUpUserOrStaff} from '../../../actions/User/signUpUserOrStaff';
import {sendStaffSignupEmail} from '../../../actions/User/sendStaffSignupEmail';
import {getStaff} from '../../../actions/Manage/getStaff';
import {editStaff} from '../../../actions/Manage/editStaff';
import {deleteStaff} from '../../../actions/Manage/deleteStaff';
import {setModalInfo} from '../../../actions/setModalInfo';
import showSystemError from '../../../components/Common/showSystemError';
import StaffTable from './StaffTable'
import DeleteStaffModalController from './ModalDeleteStaff/DeleteStaffModalController';


class Staff extends React.Component {


  componentDidMount() {
    const {getStaff, user} = this.props;
    getStaff(user)
      .then((staff) => {
      }, () => {
        showSystemError();
      })
  }

  addStaff = (employee) => {
    const {sendStaffSignupEmail} = this.props;
    sendStaffSignupEmail(employee.local.email, employee.role)
      .then(() => {
        document.getElementById('email-message').scrollIntoView(false);
      }, () => {
        showSystemError();
      })
  }

  doDeleteEmployee = (id) => {
    const {deleteStaff} = this.props;
    deleteStaff(id)
      .then(() => {
      }, (err) => {
        showSystemError();
      })
  }

  openModal = (data) => {
    const {setModalInfo} = this.props;
    setModalInfo(data);
  }

  render() {
    const {user, staff, signUpUserOrStaff, editStaff, setModalInfo, modalInfo} = this.props;
    return (
      <div id="container" className = "staffContainerStyles">
        <div className = "staffInnerContainer"></div>
        <StaffTable user={user}
                    staff={staff}
                    addStaff={this.addStaff}
                    editStaff={editStaff}
                    signUpUserOrStaff={signUpUserOrStaff}
                    openModal={this.openModal}
        />
        <DeleteStaffModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.doDeleteEmployee}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalInfo: state.modalInfo,
    staff: state.staff
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    signUpUserOrStaff,
    sendStaffSignupEmail,
    getStaff,
    editStaff,
    deleteStaff,
    setModalInfo

  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Staff);