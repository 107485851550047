import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setActiveModule} from '../../actions/setActiveModule';
import {get_Track_Table} from '../../actions/Track/get_Track_Table';
import {getTrackChartData} from '../../actions/Track/getTrackChartData';
import classnames from 'classnames';
import TrackSelector from './TrackSelector';
import TrackTable from './TrackTable';
import TrackChart from './TrackChart';
import Navbar from '../Navbar';
import showSystemError from '../Common/showSystemError';
import {restoreEvents, suspendEvents} from '../Common/events'
import _ from 'lodash'


class Track extends Component {
  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('track');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('track');
    }
    else {
      this.state = {
        visible: false,
        showBody: false,
        batchDropdownOpen: false,
        gearVisible: false
      }
    }
  }

  componentDidMount() {
    const {setActiveModule} = this.props;
    setActiveModule("Track");
    this.setState({
      visible: true
    });
    localStorage.removeItem('trackSelector');
    localStorage.removeItem('trackReentry')
  }


  showTable = (bool) => {
    this.setState({showBody: bool})
  }

  getTrackTable = (testName, cbArray, batchNames) => {
    return new Promise((resolve, reject) => {
      const {get_Track_Table, getTrackChartData, user} = this.props;
      suspendEvents();
      this.setState({
        gearVisible: true,
        showBody: false //added by DR 9/20 to make gears show up (they were there but covered by table)
      });
      get_Track_Table(testName, user.accountId, cbArray, batchNames)
      .then((track_Table) => {
        getTrackChartData(track_Table, batchNames, cbArray);
        this.showTable(true)
        this.setState({
          gearVisible: false,
          showBody: true //added by DR 9/20 to re-render table when new data is ready
        })
        restoreEvents();
        resolve()
      },(err) => {
        showSystemError()
        reject()
      })
    })
  }

  componentWillUnmount() {
    if (!localStorage.getItem('track_reset')) {
      localStorage.setObject('track', this.state);
    }
  }

  closeBatchDropdown = (e) => {
    if (e.target.classList.value !== "rw-list-option") {
      this.setState({batchDropdownOpen: false})
    }

  }

  toggleBatchDropdown = () => {
    let newState = !this.state.batchDropdownOpen;
    this.setState({
      batchDropdownOpen: newState,
      groupDropdownOpen: false
    });
  }

  render() {
    const {track_Table, history, user, trackChartData} = this.props;
    let mainClasses = classnames('main', {mainVisible: this.state.visible})
    if (_.isEmpty(user)) {
      history.push('/')
      return (
        null
      )
    }
    return (
      <div onClick={this.closeBatchDropdown}>
        <Navbar
          history={history}
        />
        <div className={mainClasses}>
          <TrackSelector
            getTrackTable={this.getTrackTable}
            user={user}
            toggleDropdown={this.toggleBatchDropdown}
            batchDropdownOpen={this.state.batchDropdownOpen}
            track_Table={track_Table}
          />
          <div style={{position: 'relative', width: '100%', height: '0px'}}>
            { this.state.gearVisible ?
              <div style={{
                position: 'relative',
                zIndex: '10',
                top: '200px',
                width: '100%',
                height: '400px',
                textAlign: 'center',
                verticalAlign: 'middle',
                border: 'solid thick transparent'
              }}>
                <img src="animatedGears.gif" alt="" width='300px'
                     style={{marginTop: '0px', border: 'solid thin transparent'}}></img>
                <div className="please-wait"
                     style={{
                       top: '-15px',
                       zIndex: '10',
                       backgroundColor: 'white',
                       width: '500px',
                       margin: 'auto'
                     }}>
                  Please wait a few moments while we organize your data.
                </div>
              </div>
              :
              null
            }
          </div>
          {this.state.showBody ?
            <div>

              <div>
                <TrackTable
                  track_Table={track_Table}
                  user={user}
                  history={history}
                  key={this.state.refreshKey}
                />
                <div className='graphOuterContainer'>
                  <div className='graphInnerContainer'>

                    <TrackChart
                      key={trackChartData.key}
                      chartData={trackChartData.chartData}
                      checkboxesStatus={this.statecheckboxesStatus}
                    />

                  </div>
                </div>
              </div>

            </div>
            : null }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    track_Table: state.track_Table,
    user: state.user,
    trackChartData: state.trackChartData
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setActiveModule,
    get_Track_Table,
    getTrackChartData
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Track);