import React from 'react';
import Clusters from '../../components/Analyze/Clusters';
import $ from 'jquery';

class ClusterDemo extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      responseData: [],
      wordGroups: [],
    }
  }

  componentDidMount() {
    $.ajax({
      url: '/admin/readLogs',
      type: "GET",
      success: (response) => {
        let bertData = response.bertData.slice(41);
        let groupData = response.groupData.slice(43);

        this.setState({
          responseData: JSON.parse(bertData),
          wordGroups: JSON.parse(groupData)
        })
      },
      error: (err) => {
        console.log(err);
      }
    })

  }

  render() {
    return (
      <div>
        {this.state.responseData.length && this.state.wordGroups.length ?
          <Clusters
            isDerivative={false}
            width={1000}
            height={650}
            numGroups={3}
            responseData={this.state.responseData}
            analysisId="123456"
            wordGroups={this.state.wordGroups}
            demo={true}
          /> : null
        }
      </div>
    )
  }
}

export default ClusterDemo;