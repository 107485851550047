import React from 'react';
// import 'react-widgets/dist/css/react-widgets.css';
import {DropdownList, DateTimePicker}  from 'react-widgets'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import Autocomplete from "../Common/Autocomplete";


Moment.locale('en')
momentLocalizer()

export class NameSelector extends React.Component {
  clearUserInput = () => {
    this.refs.name_auto.clearUserInput();
  }

  render() {
    const {suggestionsWidth, clickHandler, suggestions, disabled, userInput} = this.props;
    return (
      <div style={{marginBottom: '8px', display: 'flex'}}>
        <div className="review-filter-prompt">Name: &nbsp;&nbsp;</div>
        <div style={{width: '75%'}}>
          <Autocomplete
            classes="rc"
            firstLetterMatch={true}
            suggestionsWidth={suggestionsWidth}
            clickHandler={clickHandler}
            suggestions={suggestions}
            disabled={disabled}
            userInput={userInput}
            ref='name_auto'
          />
        </div>
      </div>
    )
  }
}


export class EmailSelector extends React.Component {
  clearUserInput = () => {
    this.refs.email_auto.clearUserInput();
  }

  render() {
    const {suggestionsWidth, clickHandler, suggestions, disabled, userInput} = this.props;
    return (
      <div style={{display: 'flex'}}>
        <div className="review-filter-prompt">Email: &nbsp;&nbsp;</div>
        <div style={{width: '75%'}}>
          <Autocomplete
            classes="rc"
            firstLetterMatch={true}
            suggestionsWidth={suggestionsWidth}
            clickHandler={clickHandler}
            suggestions={suggestions}
            disabled={disabled}
            userInput={userInput}
            ref="email_auto"
          />
        </div>
      </div>
    )
  }
}


export const SurveySelector = (props) => {
  const {data, disabled, onChange, value} = props;
  return (
    <div style={{display: 'flex', marginBottom: '8px'}}>
      <div className="review-filter-prompt" style={{width: '29%'}}>
        Survey: &nbsp;&nbsp;</div>
      <div style={{width: '71%'}}>
        <DropdownList
          data={data}
          disabled={disabled}
          onChange={onChange}
          value={value}
        /></div>
    </div>
  )
}

export const BatchSelector = (props) => {
  const {value, onChange, data, disabled} = props;
  return (
    <div style={{display: 'flex'}}>
      <div className="review-filter-prompt" style={{width: '29%'}}>
        Batch/Group: &nbsp;&nbsp;</div>
      <div style={{width: '71%'}}>
        <DropdownList
          value={value}
          onChange={onChange}
          data={data}
          disabled={disabled}
        /></div>
    </div>
  )
}

export const StartDateSelector = (props) => {
  const {onChange, value, max, disabled, min} = props;
  return (
    <div style={{display: 'flex', marginBottom: '8px'}}>
      <div className="review-filter-prompt">From: &nbsp;&nbsp;</div>
      <div style={{width: '80%'}}>
        <DateTimePicker
          id="fromDate"
          time={false}
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
          parse={str => new Date(str)}
          onChange={onChange}
          value={value}
          containerClassName="dateClass"
          min={min}
          max={max}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export const EndDateSelector = (props) => {
  const {min, disabled, onChange, value, max} = props;
  return (
    <div style={{display: 'flex'}}>
      <div className="review-filter-prompt">To: &nbsp;&nbsp;</div>
      <div style={{width: '80%'}}>
        <DateTimePicker
          id="toDate"
          time={false}
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
          parse={str => new Date(str)}
          min={min}
          containerClassName="dateClass"
          disabled={disabled}
          onChange={onChange}
          value={value}
          max={max}
        /></div>
    </div>
  )
}


