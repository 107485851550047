import {GET_REVIEW_TABLE}  from '../_CONSTANTS';
import $ from 'jquery';
import _ from 'lodash';

export const get_Review_Table = (accountId, survey) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {

      let url = `/test/processAllCompletedAssignments/?accountId=${accountId}&survey=${survey}`
      // let url = '/testerror'
      $.ajax({
        url: url,
        type: "GET",
        success: (assignments) => {
         assignments = assignments.filter((assignment)=>{
           return !assignment.completionError;
         })
          let sortedAssignments = _.sortBy(assignments, ['dateCompleted'])
          dispatch({
            type: GET_REVIEW_TABLE,
            payload: {
              survey: survey,
              assignments: sortedAssignments
            }
          })
          resolve(sortedAssignments);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}