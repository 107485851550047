import React from 'react';

import classnames from 'classnames';
import TMTContentEditable from "../../Common/TMTContentEditable";
import RoleDropdown from './RoleDropdown';
import showSystemError from '../../Common/showSystemError';

class NewStaff extends React.Component {
  constructor(props) {
    super(props);
    const {user} = this.props;
    this.state = {
      newStaffEmail: '',
      newStaffName: '',
      emailValid: false,
      newStaffRoleDropdown: user.role === 'owner' ? 'admin' : 'designer'

    };
  }

  allValid = () => {
    return this.state.newStaffName && this.state.emailValid
  }

  handleNewStaffRoleChange = (e) => {
    this.setState({
      newStaffRoleDropdown: e.target.value.replace(/&nbsp;/gi, ' ')
    })
  }


  handleNameChange = (e) => {
    let n = e.target.value.replace(/&nbsp;/gi, ' ');
    n = n.charAt(0).toUpperCase() + n.slice(1);
    this.setState({
      newStaffName: n
    })
  }

  handleEmailChange = (e) => {
    let email = e.target.value.replace(/&nbsp;/gi, ' ');
    this.setState({
      newStaffEmail: email,
      // eslint-disable-next-line
      emailValid: (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9.-]+)?$/.test(email)) || !email
    })
  }

  handleSubmit = () => {
    const {signUpUserOrStaff, user, addStaff, emailInUse} = this.props;
    emailInUse(false)
    signUpUserOrStaff(this.state.newStaffEmail, this.state.newStaffName, 'password', user.company, 'corporate', user.accountId, this.state.newStaffRoleDropdown)
      .then((employee) => {
          if (employee.error) {
            emailInUse(true)
          }
          else {
            this.setState({
              newStaffName: '',
              newStaffEmail: '',
              newStaffRoleDropdown: user.role === 'owner' ? 'admin' : 'designer'
            });
            addStaff(employee);

          }
        },
        (err) => {
          showSystemError()
        })
  }

  render() {
    const {user} = this.props;
    let emailClasses = classnames('', {badEmail: !this.state.emailValid})
    return (
      <tr style={{
        backgroundColor: 'transparent',
        width: '95%',
        marginTop: '0px',
        height: '18px'
      }}>
        <td style={{width: '25%'}} className='staff-td'>
          <TMTContentEditable
            placeholder='employee name'
            text={this.state.newStaffName}
            onChange={this.handleNameChange}
            onBlur={() => {
            }}
          />

        </td>
        <td style={{width: '20%'}} className="staff-td">
          <TMTContentEditable
            placeholder='employee email'
            text={this.state.newStaffEmail}
            onChange={this.handleEmailChange}
            classes={emailClasses}
            onBlur={() => {
            }}
          />
        </td>
        <td style={{width: '20%'}} className='staff-td'>
          <RoleDropdown
            user={user}
            value={this.state.newStaffRoleDropdown}
            onChange={this.handleNewStaffRoleChange}
          />

        </td>
        <td style={{width: '15%'}} className='staff-td'>
          <select disabled name="status" id="status" style={{width: '100%'}}>
            <option value="active">active</option>
          </select>
        </td>
        <td style={{
          width: '20%',
          border: 'solid thin transparent'
        }} className='staff-td'>
          <button
            className="blueButtonSmall"
            id="submitBtn"
            name="submitBtn"
            style={{marginLeft: '20px'}}
            disabled={!this.allValid()}
            onClick={this.handleSubmit}
          >SUBMIT
          </button>
        </td>
      </tr>
    )
  }
}

export default NewStaff;