import {GET_CREATE_TABLE, DEFAULT_SURVEY_DELAY, DEFAULT_TEST_DELAY} from '../_CONSTANTS';
import $ from 'jquery';

export const get_Create_Table = ({
                                   testName,
                                   instrumentType = "TEST",
                                   isClone = false,
                                   isReadOnly = false
                                 }) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let user = getState().user;
      let accountId = user.accountId;
      let url = `/test/getSurvey?test=${testName}&accountId=${accountId}`;
      // let url = /testerror;
      $.ajax({
        url: url,
        type: "GET",
        success: function (result) {
          let createTable;
          if (result.status !== 'ok') {
            createTable = {
              testName: testName,
              isDirty: false,
              accountId: accountId,
              status: 'development',
              instrumentType,
              delay: DEFAULT_TEST_DELAY,
              wantsEmail: false,
              assessmentEmailTitle: 'Practice Interview',
              tempTitle: 'Practice Interview',
              checkPronunciation: false,
              rows: []


            }
            dispatch({
              type: GET_CREATE_TABLE,
              payload: createTable
            })
            resolve(createTable);
          } else {
            result.user = getState().user;
            window.socket.emit('updateRoom', `room-${getState().user.accountId}`, {
              type: "TEST_OPENED",  //goes to socketDispatch for further routing
              result: result
            });
            createTable = result.test;
            if (!isReadOnly) {
              createTable.status = "development";
            }

            let rows = createTable.rows;
            let newRows = [];
            rows.forEach((row) => {
              row.isBlank = false;
              row.promptPosition = row.promptPosition.toString();
              if (row.promptPosition === "0") {
                row.promptPosition = ""
              }
              if (!row.delay) {
                row.delay = DEFAULT_SURVEY_DELAY; //handle old surveys
              }

              row.scoringParameters = JSON.parse(row.scoringParameters);


              newRows.push(row);
            })
            createTable.rows = newRows;
            dispatch({type: GET_CREATE_TABLE, payload: createTable})
            resolve(createTable);
          }
        },
        error: function (err) {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}
