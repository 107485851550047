import React from 'react';

class Checkbox extends React.Component {
  constructor(props) {
    const {initialState} = props;
    super(props);
    this.state = {checked: initialState};
  }

  handleClick = (e) => {
    const {name, id, checked, clickHandler} = this.props;
    e.stopPropagation();
    this.setState({checked: !checked});
    if (clickHandler) {
      clickHandler(id, !checked, name)
    }
  }

  render() {
    const {name, id, value, checked, centered, allOffset} = this.props;
    let align = centered ? 'center' : 'left'
    let alignAll = id === 'All'? allOffset  : '0px'
    return (
      <div tabIndex={-1} className="rw-list-option"
           style={{textAlign: align}}
           onClick={this.handleClick}
      >
        <input type="checkbox" name={name} id={id} value={value}
               onClick={this.handleClick}
               style={{marginLeft: alignAll}}
               onChange={() => {
               }} //noop. onclick executes. onChange here so react doesn't complain.
               checked={checked}/>
        <label htmlFor={value}
               style={{pointerEvents: 'none'}}
        >&nbsp;{value}</label>
      </div>
    )
  }
}

export default Checkbox;
