import {GET_ANON_ASSIGNMENTS} from '../_CONSTANTS';


export const getAnonAssignments = (accountId, survey) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let firstDate = survey.firstAnonInitiation;
      let lastDate = survey.lastAnonInitiation;
      let groupNames = [];
      let anonAssignments = getState().allAnonAssignments.filter((assignment) => {
        return assignment.testName === survey.testName;
      })
      anonAssignments.forEach((assignment, index) => {
        let found = groupNames.findIndex((group) => {
          return group === assignment.group;
        })
        if (found === -1) {
          groupNames.push(assignment.group);
        }
        //this is only required for mock data
        if (assignment.mock) {
          if (assignment.dateStarted > assignment.dateCompleted) {
            let d = assignment.dateCompleted;
            assignment.dateCompleted = assignment.dateStarted;
            assignment.dateStarted = d;
          }
        }
        ////////////////////////////////////////////
      })
      groupNames.sort();
      let groupIds = [];
      groupNames.forEach((group, index) => {
        let i = (index + 1).toString();
        let paddedIndex = i.length === 1 ? `0${i}` : i;
        let id = `G${paddedIndex}`;
        groupIds.push(id);
      })
      let anonAssignObj = {
        assignments: anonAssignments,
        firstDate: firstDate,
        lastDate: lastDate,
        groups: groupNames,
        groupIds: groupIds
      }
      dispatch({
        type: GET_ANON_ASSIGNMENTS,
        payload: anonAssignObj
      })
      resolve(anonAssignObj);
    })
  }
}
