import {
  CLEAR_ANONASSIGN_TABLE,
  GET_ANONASSIGN_TABLE,
  SAVE_ANONASSIGN_TABLE
} from "../actions/_CONSTANTS";


const anonAssign_Table = (state = {}, action) => {

  switch (action.type) {
    case CLEAR_ANONASSIGN_TABLE:
      return {}
    case GET_ANONASSIGN_TABLE:
      return action.payload;
    case SAVE_ANONASSIGN_TABLE:
      return action.payload
    default:
      return state
  }
}

export default anonAssign_Table
