import React from 'react';
import classnames from 'classnames';
import TMTContentEditable from "../Common/TMTContentEditable";
import ReactTooltip from 'react-tooltip'
import slicedTin from '../Common/slicedTin';

class AssignRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      participantName: '',
      email: '',
      badEmail: false,
      isInvalid: false,
      inviteSent: false,
      TIN: ''
    }
  }

  componentDidMount() {
    const {assign_Table_row} = this.props;
    let row = assign_Table_row;
    this.setState({
      _id: row._id,
      participantName: row.participantName,
      email: row.email,
      badEmail: row.badEmail,
      isInvalid: row.isInvalid,
      inviteSent: row.inviteSent,
      TIN: row.TIN
    })
  }

  handleParticipantNameChange = (e) => {
    const {setEditingFlag} = this.props;
    let value = e.target.value.replace(/&nbsp;/gi, ' ');
    setEditingFlag(true);
    this.setState({participantName: value});
  }


  handleParticipantNameBlur = () => {
    const {handleRowChange, assign_Table_row} = this.props;
    if (this.state.participantName !== assign_Table_row.participantName) {
      this.setState({
        inviteSent: false
      }, () => {
        handleRowChange(this.state)
      })
    }

  }


  handleParticipantEmailChange = (e) => {
    const {setEditingFlag, handleRowChange} = this.props;
    let value = e.target.value.replace(/&nbsp;/gi, ' ');
    setEditingFlag(true);
    // this.setState({email: value});

    //we need to check for valid email on each change since the user could click on
    // invite button without a blur occurring. The click on the button gets executed
    // first.

    // eslint-disable-next-line

    let validEmail = (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9.-]+)?$/.test(value)) || !value;
    this.setState({
      badEmail: !validEmail,
      email: value
    }, () => {
      handleRowChange(this.state);
    })
  }

  handleParticipantEmailBlur = () => {
    const {handleRowChange, assign_Table_row} = this.props;
    // eslint-disable-next-line
    let validEmail = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) || !this.state.email;
    if (this.state.email !== assign_Table_row.email) {
      this.setState({inviteSent: false})
    }
    this.setState({
      badEmail: !validEmail,
    }, () => {
      handleRowChange(this.state);
    })
  }

  handleDelete = () => {
    const {deleteRow, assign_Table_row} = this.props;
    if (assign_Table_row.status !== 'completed') {
      deleteRow(assign_Table_row);
    }

  }


  getIndicatorClass = (row) => {
    let completed = row.status === 'completed' || row.status === 'in progress';
    let noName = !row.participantName;
    let noNameWithEmail = row.email && !row.participantName;
    // let inviteWithoutEmail = row.sendInvite && !row.email;
    let goodData = !row.badEmail && !noNameWithEmail && !noName;
    return completed ? 'lineIndicatorGray' : goodData ? 'lineIndicatorGreen' : 'lineIndicatorOrange';
  }

  getIndicatorCursorType = (row) => {
    return this.getIndicatorClass(row) !== 'lineIndicatorGreen' ?
      'pointer' : 'default';
  }

  getIndicatorText = (row) => {
    if (row.status === 'completed') {
      return 'This assignment has been completed. It cannot be altered.';
    }
    if(row.status === 'in progress') {
      return 'This assignment has been started. It cannot be altered.'
    }
    if (row.badEmail) {
      return 'This is not a valid email address.'
    }
    if (row.email && !row.participantName) {
      return 'You still need to enter a name to complete this entry.';
    }
    if (!row.participantName) {
      return 'Remember to enter a participant name.'
    }

    return '';
  }


  handleOpenInvite = () => {
    const {openEditInvite} = this.props;
    let rows = [{
      participantName: this.state.participantName,
      email: this.state.email,
      TIN: this.state.TIN,
      _id: this.state._id
    }]
    openEditInvite(rows)
  }

  sendInviteHTML = (sentAlready) => {
    //  drubin
    if (sentAlready){
      return (
        '<div style="background-color: white; font-size: 15px; font-weight: bold;">If you need to resend an invitation to this participant, you can do so from the track module.</div>'
      )
    } else {
      return (
        '<div style="background-color: white; font-size: 15px; font-weight: bold;">Edit/send email.</div>'
      )
    }
  }

  render() {
    const {index, assign_Table_row} = this.props;
    let inviteText = this.state.inviteSent ? 'Invitation sent' : 'Send invitation'
    let row = assign_Table_row;
    let completed = row.status === 'completed' || row.status === 'in progress';
    let deleteIconClasses = classnames('deleteIcon', {hidden: completed})
    let emailClasses = classnames('assignDiv', {badEmail: row.badEmail && row.email})
    let rowClasses = classnames('', {row_invisible: row.isDeleted}, {row_inactive: completed})
    return (
      <tr className={rowClasses}>
        <td className="assignText1">
          <ReactTooltip id="row-indicator" place="right" border={true} type="light"/>
          <div className={this.getIndicatorClass(row)}
               style={{cursor: this.getIndicatorCursorType(row)}}
               data-tip={this.getIndicatorText(row)}
               data-for="row-indicator">

          </div>
          <div className="spcCodeDiv">
            {slicedTin(row.participantCode)}
            <div className={deleteIconClasses}>
                <span className="hover-control">
                <i
                  className="far fa-minus-square"
                  onClick={this.handleDelete}
                  title="delete this row">
                </i>
                 <i
                   className="fas fa-minus-square"
                   onClick={this.handleDelete}
                   title="delete this row">
                </i>
              </span>

            </div>
          </div>
        </td>
        <td className="assignText2">
          <TMTContentEditable
            text={this.state.participantName}
            classes="assignDiv"
            onChange={this.handleParticipantNameChange}
            onBlur={this.handleParticipantNameBlur}
            disabled={completed}

          />
        </td>
        <td className="assignText3">
          <TMTContentEditable
            id={`email${index}`}
            text={this.state.email}
            onChange={this.handleParticipantEmailChange}
            onBlur={this.handleParticipantEmailBlur}
            classes={emailClasses}
            disabled={completed}

          />
        </td>
        <td className="assignText4">
          <ReactTooltip id={`invite-${index}`} place="left" border={true} type="light"/>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around'
            }}>
            <div style={{marginTop: '2px'}}>
              {inviteText}
            </div>
            <div
              id={`invite-${index}`}
              data-tip={this.sendInviteHTML(row.inviteSent)}
              data-for={`invite-${index}`}
              //data-tip-disable={!row.inviteSent}
              data-html={true} //this is ok on Firefox
            >

              <button
                className="blueButtonSmall"
                disabled={row.inviteSent || !this.state.participantName || this.state.badEmail || !this.state.email}
                onClick={this.handleOpenInvite}
              >
                <i
                  className="fa fa-check"
                  style={{fontSize: '10px'}}
                ></i>
              </button>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}

export default AssignRow;