import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {logoutUser} from '../actions/User/logoutUser';
import showSystemError from './Common/showSystemError';
import hasPermission from './Common/hasPermission';

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
}


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignMenuOpen: false,
      assignSubMenuOpen: false,
      trackMenuOpen: false,
      trackSubMenuOpen: false,
    }
  }

  setActive(menuItem) {
    const {activeModule} = this.props;
    //return activeModule === menuItem ? 'active' : '';
    return activeModule.includes(menuItem) ? 'active' : '';
  }


  logout = () => {
    const {history, logoutUser, user} = this.props;
    window.socket.emit('leaveRoom', `room-${user.accountId}`);
    window.socket.disconnect();
    logoutUser(user.email)
      .then(() => {
          console.log('logged out');
          history.push('/');
        },
        (err) => {
          showSystemError()
        })
  }

  assignHandleMouseEnter = (e) => {
    this.setState({assignMenuOpen: true, assignSubMenuOpen: true});
  }

  assignHandleMouseLeave = (e) => {
    this.setState({assignMenuOpen: false, assignSubMenuOpen: false});
  }

  assignSubHandleMouseEnter = (e) => {
    this.setState({assignMenuOpen: false, assignSubMenuOpen: true});
  }

  assignSubHandleMouseLeave = (e) => {
    this.setState({assignMenuOpen: false, assignSubMenuOpen: false});
  }


  trackHandleMouseEnter = (e) => {
    this.setState({trackMenuOpen: true, trackSubMenuOpen: true});
  }

  trackHandleMouseLeave = (e) => {
    this.setState({trackMenuOpen: false, trackSubMenuOpen: false});
  }

  trackSubHandleMouseEnter = (e) => {
    this.setState({tracknMenuOpen: false, trackSubMenuOpen: true});
  }

  trackSubHandleMouseLeave = (e) => {
    this.setState({trackMenuOpen: false, trackSubMenuOpen: false});
  }

  handleNamedParticipantsClick = (e) => {
    const {history} = this.props;
    history.push('/assign');
  }

  handleAnonParticipantsClick = (e) => {
    // $('#Assign').attr("class","active");
    const {history} = this.props;
    history.push('/anonAssign');
  }

  handleTrackNamedClick = (e) => {
    const {history} = this.props;
    history.push('/track');
  }

  handleTrackAnonClick = (e) => {
    const {history} = this.props;
    history.push('/trackAnon');
  }


  render() {
    const {user} = this.props;
    let role = user.role;

    /* ***************** accomodate font differences in subMenu left offsets ***************** */

    //let navBarItemsOffset = 398.5;
    let navBarItemsOffset = 308.5;
    let navBarItemPadding = 64;
    let assignOffset = navBarItemsOffset +
      getTextWidth("MANAGE", "normal 17px Times New Roman") + navBarItemPadding + "px";
    let trackOffset = navBarItemsOffset +
      getTextWidth("MANAGE", "normal 17px Times New Roman") + navBarItemPadding +
      getTextWidth("ASSIGN", "normal 17px Times New Roman") + navBarItemPadding +
      getTextWidth("REVIEW", "normal 17px Times New Roman") + navBarItemPadding + "px";

    /* ************************************************************************************** */

    return (
      <div className="navbar gradient-fill">
        {/*      <img className="logo" src="../TellMeThisIconTrnsprnt.png" alt="" width="170"
         height="35"/> */}
        <img className="logo" src="../ColoredNavBarLogo.svg" alt="" width="180"
          /*height="35"*/ />
        <div className="navSpacer">&nbsp;</div>

        <span className={this.setActive('Manage')}>
          <Link to={'/'}
                className='menuLink'>MANAGE</Link></span>

        <span className={this.setActive('Create')}>
          <Link to={'/create'}
                className={hasPermission(role, 'create') ? 'menuLink' : 'menuLink disabled'}>CREATE</Link></span>

        <span id="Assign" className={this.setActive('Assign')}>
          <Link to={'#'}
                onMouseEnter={this.assignHandleMouseEnter}
                onMouseLeave={this.assignHandleMouseLeave}
                className={hasPermission(role, 'assign') ?
                  'menuLink' : 'menuLink disabled'}>ASSIGN</Link>

          {( this.state.assignMenuOpen || this.state.assignSubMenuOpen ) ?

            <div className='subMenuContainer'
                 style={{
                   position: 'relative',
                   height: '0px',
                   width: '0px',
                   background: 'transparent'
                 }}>
                <span className='subMenu1'
                      onMouseEnter={this.assignSubHandleMouseEnter}
                      onMouseLeave={this.assignSubHandleMouseLeave}
                      onClick={this.handleAnonParticipantsClick}
                      style={{
                        backgroundColor: '#8c8c8c',
                        color: 'white',
                        left: assignOffset
                      }}>
                    Anonymous respondents
                </span>
              <span className='subMenu2'
                    onMouseEnter={this.assignSubHandleMouseEnter}
                    onMouseLeave={this.assignSubHandleMouseLeave}
                    onClick={this.handleNamedParticipantsClick}
                    style={{
                      backgroundColor: '#8c8c8c',
                      color: 'white',
                      left: assignOffset
                    }}>
                    Named respondents
                </span>
            </div>
            : null}
        </span>

        <span className={this.setActive('Review')}>
          <Link to={'/review'}
                id="review"
                className={hasPermission(role, 'review') ?
                  'menuLink' : 'menuLink disabled'}>REVIEW</Link></span>

        <span className={this.setActive('Track')}>
          <Link to={'#'}
                onMouseEnter={this.trackHandleMouseEnter}
                onMouseLeave={this.trackHandleMouseLeave}
                className={hasPermission(role, 'track') ?
                  'menuLink' : 'menuLink disabled'}>TRACK</Link>

          {( this.state.trackMenuOpen || this.state.trackSubMenuOpen ) ?

            <div className='subMenuContainer'
                 style={{
                   position: 'relative',
                   height: '0px',
                   width: '0px',
                   background: 'transparent'
                 }}>
                <span className='subMenu3'
                      onMouseEnter={this.trackSubHandleMouseEnter}
                      onMouseLeave={this.trackSubHandleMouseLeave}
                      onClick={this.handleTrackAnonClick}
                      style={{
                        backgroundColor: '#8c8c8c',
                        color: 'white',
                        left: trackOffset
                      }}>
                    Anonymous respondents
                </span>
              <span className='subMenu4'
                    onMouseEnter={this.trackSubHandleMouseEnter}
                    onMouseLeave={this.trackSubHandleMouseLeave}
                    onClick={this.handleTrackNamedClick}
                    style={{
                      backgroundColor: '#8c8c8c',
                      color: 'white',
                      left: trackOffset
                    }}>
                    Named respondents
                </span>

            </div>
            : null}
        </span>
        <span className={this.setActive('Analyze')}>
          <Link to={'/analyze'}
                id="analyze"
                className={hasPermission(role, 'analyze') ?
                  'menuLink' : 'menuLink disabled'}>ANALYZE</Link></span>
        <span className={this.setActive('Help')}>
          <Link to={'/help'}
                id="help"
                className={hasPermission(role, 'help') ?
                  'menuLink' : 'menuLink disabled'}>HELP</Link>
        </span>


        <div className="navSpacer"></div>
        <button className="blueButton"
                style={{
                  float: 'right',

                  marginRight: '20px',
                  position: 'relative',
                  top: '10px'
                }}
                onClick={this.logout}
        >LOGOUT
        </button>
        <div style={{width: '200px', display: 'flex', float: 'right', color: '#639ea4'}}>
          <div style={{marginRight: '10px', marginTop: '11px'}}>
            <i className="fas fa-user-circle" style={{fontSize: '25px'}}></i>
          </div>
          <div style={{marginTop: '14px'}}>{user.email}</div>
        </div>
      </div>
    )
  }
}

const
  mapStateToProps = (state) => {
    return {
      activeModule: state.activeModule,
      user: state.user,
      subPath: state.subPath
    };
  };

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    logoutUser: logoutUser
  }, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
