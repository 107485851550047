import React from 'react';
import ModalFrame from '../../ModalFrame';
import NamedEmailInvite from './NamedEmailInvite';
import $ from 'jquery';

class NamedEmailInviteController extends React.Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.modalWidth = $(".example-modal").width();

  }


  hideModal() {
    const {setModalInfo} = this.props;
    setModalInfo({});
  }

  render() {
    const {modalInfo, confirmHandler, cancelHandler} = this.props;
    return (
      <div className="example-modal">
        {modalInfo.name === 'namedInviteEmail' ?

          <ModalFrame
            title="CREATE AND SAVE EMAIL INVITATIONS"
            desktopWidth="75%"
            headerCloseButtonHandler={this.hideModal}
            hasHeader={true}
            >

            <NamedEmailInvite
              modalInfo={modalInfo}
              hideModal={this.hideModal}
              confirmHandler={confirmHandler}
              cancelHandler={cancelHandler}
            />
          </ModalFrame> : null}
      </div>
    )
  }
}

export default NamedEmailInviteController;