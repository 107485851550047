import React from 'react';
import $ from 'jquery';

let colStyles0 = {
  padding: '10px',
  minWidth: '150px',
  maxWidth: '150px',
  textAlign: 'right',
  fontSize: '18px',
  backgroundColor: 'cadetblue'/* 'rgb(33, 169, 134)' */
}

let colStyles1 = {
  padding: '10px',
  minWidth: '150px',
  maxWidth: '150px',
  textAlign: 'right',
  fontSize: '20px',
  color: 'white',
  fontWeight: 'bold',
  backgroundColor: 'transparent'
}
let colStyles2 = {
  padding: '10px',
  minWidth: '150px',
  maxWidth: '150px',
  textAlign: 'center',
  fontSize: '18px',
  backgroundColor: 'white'
}

let colStyles3 = {
  padding: '10px',
  minWidth: '150px',
  maxWidth: '150px',
  textAlign: 'center',
  fontSize: '18px',
  backgroundColor: 'lightGreen'
}

let colStyles4 = {
  padding: '10px',
  minWidth: '150px',
  maxWidth: '150px',
  textAlign: 'center',
  fontSize: '18px',
  backgroundColor: 'lightBlue'
}

class SignUpMenu extends React.Component {

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    $('.no-navbar').height(window.innerHeight);
  }

  handleClick = (e) => {
    const {history} = this.props;
    localStorage.setItem('type', e.currentTarget.id);
    history.push('./signup')
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div>
        <div className='no-navbar' style={{
          overflowY: 'auto',
          //backgroundColor: 'rgb(33, 169, 134)'
          background: 'cadetblue'
        }}>
          <div>        {/* <img src="TellMeThisLogoWhiteTransparent.png"
             alt=""
             style={{height: '42px', padding: '8px', cursor: 'pointer'}}/> */}
             <img src="TMTLogoWhiteBalloonsWithTagLine.svg"
             className = "TMTLogoWhiteBallonsWithTagLine"
             alt=""
             //style={{height: '70px', paddingLeft: '8px', marginTop: '-5px', cursor: 'pointer'}}
          />
            <div className="big-menu-link"
                 style={{
                   float: 'right',
                   border: 'thin solid white',
                   borderRadius: '5px'
                 }}>
              <a href="/"
                 className="menuLink" style={{color: '#ffffff', fontSize: '20px', fontFamily: 'arial'}}>Home</a>
            </div>
          </div>

          <div style={{
            margin: '20px',
            paddingTop: '50px',
            color: 'white',
            fontSize: '40px',
            textAlign: 'center'
          }}>
            Choose your plan
          </div>
          <table style={{
            position: 'relative',
            left: '-5%',
            backgroundColor: 'transparent',
            margin: '0 auto',
            paddingTop: '0px'
          }}>
            <thead>
            <tr>
              <th style={colStyles0}>&nbsp;</th>
              <th style={colStyles2}>Trial</th>
              <th style={colStyles3}>Individual</th>
              <th style={colStyles4}>Corporate</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style={colStyles1}>Cost</td>
              <td style={colStyles2}>Free</td>
              <td style={colStyles3}>$10 a month</td>
              <td style={colStyles4}>Pay as you go</td>
            </tr>
            <tr>
              <td style={colStyles1}>Active surveys</td>
              <td style={colStyles2}>1</td>
              <td style={colStyles3}>3</td>
              <td style={colStyles4}>Unlimited</td>
            </tr>

            <tr>
              <td style={colStyles1}>Respondents</td>
              <td style={colStyles2}>50</td>
              <td style={colStyles3}>500</td>
              <td style={colStyles4}>Unlimited</td>
            </tr>

            <tr>
              <td style={colStyles1}>Multiple users</td>
              <td style={colStyles2}>No</td>
              <td style={colStyles3}>No</td>
              <td style={colStyles4}>Unlimited</td>
            </tr>
            <tr>
              <td style={colStyles1}>&nbsp;</td>
              <td style={colStyles2}>
                <button
                  className="closeBtn blackButton"
                  id="free"
                  onClick={this.handleClick}>
                  SELECT
                </button>
              </td>
              <td style={colStyles3}>
                <button
                  className="closeBtn blackButton"
                  id="individual"
                  onClick={this.handleClick}>
                  SELECT
                </button>
              </td>
              <td style={colStyles4}>
                <button
                  className="closeBtn blackButton"
                  id="corporate"
                  onClick={this.handleClick}>
                  SELECT
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}


export default SignUpMenu
