import React from 'react'
import $ from 'jquery';
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {DropdownList, DateTimePicker} from 'react-widgets'
import moment from 'moment';
import {storeBERTData} from '../../actions/Analyze/storeBERTData';
import {storeWordGroups} from '../../actions/Analyze/storeWordGroups';
import MultiSelect from '../Common/MultiSelect';
import Clusters from './Clusters';
import Sentiments from './Sentiments';
import Search from './Search';
import NamedEntities from './NamedEntities';
import {restoreEvents, suspendEvents} from '../Common/events'
import {getBERTData, setWordGroups, getKmeans} from './computeClusters';
import {getSentimentData} from './getSentiment';
import {getSearchData} from './getSearch';
import {getNamedEntities} from './getNamedEntities';
import exportData from './exportData';
import Gears from '../Common/Gears';
import showSystemError from '../Common/showSystemError';
import stripXMLtags from  "../Common/stripXMLtags";
import showToastr from '../Common/showToastr';
import {toast} from 'react-toastify';


moment.locale('en');

window.demo = false;
/*
 Turn twindow.demo to true to create demo files bertData.log and groups.log. Once
  completed return this value to false.  If bertData.log and groups.log already exist in
  client/public it is best to delete them before step 2, or the new data
  create will be appended to the existing data in these log files.

  STEPS
  1. Set window.demo to [true].
 2. Set the number of groups you want in the demo example by changing -- if necessary --
 the 'numGroups' key:value pair in the 'this.setState' array (near line 369 of this file).
 It is normally set to  '3' (numGroups: 3) but if your example requires a different number,
 it can be set to any value between from 2 to 9.  Remember to set it back to 3 when you reset
 window.demo to [false]
  2. Run the app and select the survey and question that you want to use to create the
     demo cluster analysis for the home page. This will put the log files in client/public.
  3. Copy those files into server/handler, where heroku server can find them.
  4. localhost will find the log files in client/public after the first deployment
     to Heroku.  To test before that, temporarily change the the line in the Home.js file
     that specifies the 'src' of the iframe server
       from:
         document.getElementById("clusters-iframe").setAttribute('src', `${window.MAKER_HOST}/cluster/1234`);
       to:
         document.getElementById("clusters-iframe").setAttribute('src', 'http://localhost:3000/cluster/1234');
     BUT REMEMBER TO CHANGE IT BACK TO `${window.MAKER_HOST}/cluster/1234` BEFORE DEPLOYING TO HEROKU
  5. the process of showing a demo cluster is started on the home page. It calls the
     route '/clusters/1234' (note 1234 is an arbitray analysisID so the code will work).
  6. The server routes this back to App.js where reactRouter routes it to the
     ClusterDemo component, which calls '/admin/readlogs' to fetch the data for the
     iframe on the home page.
 */


function SelectSurvey({surveys, testName, onChange, disabled}) {
  return (
    <div style={{width: '94%', marginLeft: '6px', marginBottom: '50px'}}>
      <DropdownList
        ids="surveys"
        data={surveys}
        placeholder="Select a survey"
        textField="testName"
        valueField="testName"
        value={testName}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

function SelectAnalysis({analyses, analysis, onChange, disabled}) {
  return (
    <div style={{width: '94%', marginLeft: '6px', marginBottom: '50px'}}>
      <DropdownList
        id="analysis"
        data={analyses}
        placeholder="Select a method"
        value={analysis}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}

function SelectPrompt({prompts, prompt, onChange, disabled}) {

  // first filter the prompts to remove any xml tags
  Object.entries(prompts).forEach(([key], index) => {
    prompts[index].promptText = stripXMLtags(prompts[index].promptText);
  });

  return (
    <div style={{width: '94%', marginLeft: '6px', marginBottom: '50px'}}>
      <DropdownList
        id="prompts"
        data={prompts}

        textField="promptText"
        valueField="promptText"
        placeholder="Select a question"
        value={prompt}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}

function SelectFromDate({fromDateValue, handleFromDate, disabled, min, max}) {
  return (
    <React.Fragment>
      <div style={{margin: '7px 0', width: '100%', textAlign: 'left'}}>&nbsp;&nbsp;&nbsp;
        From:
      </div>
      <div style={{width: '91%', paddingLeft: '7px'}}>
        <DateTimePicker
          id={`fromDate_${new Date().getTime()}`}
          time={false}
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
          disabled={disabled}
          parse={str => new Date(str)}
          onChange={handleFromDate}
          value={fromDateValue ? new Date(fromDateValue) : null}
          containerClassName="dateClass"
          min={new Date(min)}
          max={new Date(max)}
        />
      </div>
    </React.Fragment>
  )
}

function SelectToDate({toDateValue, handleToDate, disabled, min, max}) {
  return (
    <React.Fragment>
      <div style={{margin: '7px 0', width: '100%', textAlign: 'left'}}>&nbsp;&nbsp;&nbsp;
        To:
      </div>
      <div style={{width: '91%', paddingLeft: '7px'}}>
        <DateTimePicker
          id={`toDate_${new Date().getTime()}`}
          time={false}
          format="MM/DD/YYYY"
          parse={str => new Date(str)}
          placeholder="MM/DD/YYYY"
          disabled={disabled}
          onChange={handleToDate}
          value={toDateValue ? new Date(toDateValue) : null}
          containerClassName="dateClass"
          min={new Date(min)}
          max={new Date(max)}
        />
      </div>
    </React.Fragment>
  )
}

class SelectBatches extends React.Component {
  render() {
    let {batchNames, checkboxesStatus, setCheckboxesStatus, disabled, batchDropdownOpen, toggleBatchDropdown} = this.props;
    return (
      <div style={{padding: '0 4px', marginLeft: '4px', marginRight: '4px'}}>
        <div style={{margin: '7px 0', width: '100%', textAlign: 'left'}}>
          &nbsp;Batches:
        </div>
        <div style={{width: '100%'}}>
          <MultiSelect
            itemNames={batchNames}
            disabled={disabled || !batchNames.length}
            checkboxesStatus={checkboxesStatus}
            setCheckboxesStatus={setCheckboxesStatus}
            dropdownOpen={batchDropdownOpen}
            toggleDropdown={toggleBatchDropdown}
            centered={false}
            allOffset='0'
          />
        </div>
      </div>
    )
  }
}

class SelectGroups extends React.Component {
  render() {
    let {groups, checkboxesStatus, setCheckboxesStatus, disabled, groupDropdownOpen, toggleGroupDropdown} = this.props;
    return (
      <div style={{padding: '0 4px', marginLeft: '4px', marginRight: '4px'}}>
        <div style={{margin: '7px 0', width: '100%', textAlign: 'left'}}>
          &nbsp;Groups:
        </div>
        <div style={{width: '100%'}}>
          <MultiSelect
            itemNames={groups}
            disabled={disabled || !groups.length}
            checkboxesStatus={checkboxesStatus}
            setCheckboxesStatus={setCheckboxesStatus}
            dropdownOpen={groupDropdownOpen}
            toggleDropdown={toggleGroupDropdown}
            centered={false}
            allOffset='0'
          />
        </div>
      </div>
    )
  }
}

function AnalysisGraph({children}) {
  return (
    <div className="analysisGraph" style={{
      width: '85%',
      minHeight: '100%',
      backgroundColor: '#F7F7F7',
      borderTop: 'solid thin #BBBBBB'
    }}>
      <div>
        {children}
      </div>
    </div>
  )
}

class AnalysisCreator extends React.Component {
  constructor(props) {
    super(props);
    const {analysisId, BERTResponses, wordGroups, user} = this.props;
    let oldState = localStorage.getObject(`analysisCreator-${analysisId}`);
    if (oldState) {
      this.state = {
        ...oldState, ...{
          firstDate: oldState.firstDate ? new Date(oldState.firstDate) : null,
          lastDate: oldState.lastDate ? new Date(oldState.lastDate) : null,
          fromDateValue: oldState.fromDateValue ? new Date(oldState.fromDateValue) : null,
          toDateValue: oldState.toDateValue ? new Date(oldState.toDateValue) : null,
          fromDateMax: oldState.fromDateMax ? new Date(oldState.fromDateMax) : '',
          toDateMin: oldState.toDateMin ? new Date(oldState.toDateMin) : '',
          responseData: BERTResponses[analysisId],
          wordGroups: wordGroups[analysisId]
        }
      }
      localStorage.removeItem(`analysisCreator-${analysisId}`);
    }
    else {
      this.state = {
        analyses:
          ['Cluster Analysis', 'Named Entities', 'Sentiment Analysis', 'Search Responses', 'Export'],
        analysis: '',
        analysisCompleted: false,
        batchCheckboxesStatus: [],
        batchDropdownOpen: false,
        batchNames: [],
        firstDate: null,
        fromDateMax: null,
        fromDateValue: null,
        groupCheckboxesStatus: [],
        groupDropdownOpen: false,
        groups: [],
        lastDate: null,
        maxGroups: 9,
        numGroups: 3,
        prompt: '',
        prompts: [],
        refreshKey: 0,
        responseData: [],
        submitting: false,
        test: {},
        toDateMin: null,
        toDateValue: null,
        wordGroups: [],
        entityStr: ''


      }
    }

  }

  componentDidMount() {
    const {isDerivative, derivativeChartType} = this.props;


    if (isDerivative) {
      window.analysisState.analysis = derivativeChartType;
      let newState = _.omit(window.analysisState, ['analysisId', 'chartHasContent'])
      this.setState({...newState}, () => {

      });
      this._handleSubmit(window.analysisState);
    }
  }

  emptyChart = () => {
    const {chartHasContent, hideWizard} = this.props;
    chartHasContent(false)
    hideWizard();
  }

  getPrompts = (testName) => {
    const {user} = this.props;
    const accountId = user.accountId;
    let url = `/test/getPrompts/?testName=${testName}&accountId=${accountId}`;
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        type: "GET",
        success: (prompts) => {
          console.log("prompts: ", prompts);
          this.emptyChart();
          resolve(prompts)
        },
        err: (err) => {
          reject(err);
        }
      })
    })
  }

  getGroups = (testName) => {
    const {user} = this.props;
    const accountId = user.accountId;
    let url = `/test/getGroups/?testName=${testName}&accountId=${accountId}`;
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        type: "GET",
        success: (groups) => {
          this.emptyChart();
          resolve(groups)
        },
        err: (err) => {
          reject(err);
        }
      })
    })
  }

  handleTestChange = (test) => {
    this.emptyChart();
    let batchNames = test.batches.map((batch) => {
      return batch.batch;
    })
    batchNames = batchNames.sort();
    if (batchNames.length) {
      batchNames = ['All'].concat(batchNames)
    }
    let firstDate;
    if (!test.completedCount) {
      firstDate = new Date(test.firstAnonInitiation);
    }
    else if (!test.completedAnonCount) {
      firstDate = new Date(test.firstInitiation);
    }
    else {
      firstDate = new Date(Math.min(new Date(test.firstInitiation).getTime(), new Date(test.firstAnonInitiation).getTime()));
    }

    let lastDate = new Date(test.lastCompletionDate);
    this.getPrompts(test.testName, test.accountId)
      .then((prompts) => {
        this.getGroups(test.testName)
          .then((groups) => {
            groups = groups.sort();
            if (groups.length) {
              groups = ['All'].concat(groups);
            }
            this.setState({
              test: test,
              prompts,
              numGroups: 3, //normally this is set to 3
              firstDate: firstDate,
              lastDate: lastDate,
              fromDateValue: firstDate,
              toDateValue: lastDate,
              fromDateMax: lastDate,
              toDateMin: firstDate,
              batchNames: batchNames,
              batchCheckboxesStatus: new Array(batchNames.length).fill(true),
              groups: groups,
              groupCheckboxesStatus: new Array(groups.length).fill(true),
              prompt: '',
              analysis: '',
              submitting: false,
              analysisCompleted: false,
              refreshKey: this.state.refreshKey + 1,
            })
          })
          .catch((err) => {
            console.log(err);
            showSystemError()
          })

      })
      .catch((err) => {
        console.log(err);
        showSystemError()
      })
  }

  handlePromptChange = (prompt) => {
    this.setState({
      prompt: prompt,
      analysisCompleted: false
    })
    this.emptyChart();
  }

  handleAnalysisChange = (analysis) => {
    this.setState({
      analysis: analysis,
      analysisCompleted: false,
      prompt: ''
    })
    this.emptyChart();
  }

  setBatchesCheckboxesStatus = (cbArray) => {
    this.setState({
      batchCheckboxesStatus: [...cbArray],
      analysisCompleted: false
    })
    this.emptyChart();
  }

  setGroupsCheckboxesStatus = (cbArray) => {
    this.setState({
      groupCheckboxesStatus: cbArray,
      analysisCompleted: false
    })
    this.emptyChart();
  }


  closeDropdowns = (e) => {
    if (e.target.classList.value !== "rw-list-option") {
      this.setState({
        batchDropdownOpen: false,
        groupDropdownOpen: false
      })
    }
  }

  toggleBatchDropdown = () => {
    let newState = !this.state.batchDropdownOpen;
    this.setState({
      batchDropdownOpen: newState,
      groupDropdownOpen: false
    });
  }

  toggleGroupDropdown = () => {
    let newState = !this.state.groupDropdownOpen;
    this.setState({
      groupDropdownOpen: newState,
      batchDropdownOpen: false
    });
  }

  handleFromDate = (value) => {
    let month = value.getMonth();
    let date = value.getDate();
    let year = value.getFullYear()
    value = new Date(year, month, date, 0, 0);
    this.setState({
      fromDateValue: new Date(value),
      toDateMin: new Date(value),
      analysisCompleted: false
    })
    this.emptyChart();
  }

  handleToDate = (value) => {
    let month = value.getMonth();
    let date = value.getDate();
    let year = value.getFullYear()
    value = new Date(year, month, date, 23, 59);
    this.setState({
      toDateValue: new Date(value),
      fromDateMax: new Date(value),
      analysisCompleted: false
    })
    this.emptyChart();
  }

  _handleSubmit = ({analysis, batchCheckboxesStatus, batchNames, fromDateValue, toDateValue, groupCheckboxesStatus, groups, prompt, test}) => {
    const {title, chartHasContent, isDerivative, user} = this.props;

    let selectedBatches = batchNames.filter((batch, index) => {   //getting data for a set of selections without changing any
      return batchCheckboxesStatus[index] && batch !== "All";
    })
    let selectedGroups = groups.filter((group, index) => {
      return groupCheckboxesStatus[index] && group !== "All";
    })
    this.setState({analysisCompleted: false, submitting: true});  //added analysisCompleted: false -- so user can click submit again after
    suspendEvents()
    switch (analysis) {
      case "Export":
        exportData(fromDateValue, toDateValue, selectedBatches, selectedGroups, test, prompt, title)
          .then(() => {
            this.setState({submitting: false})
            restoreEvents()
          })
        break;
      case "Cluster Analysis":
        getBERTData(fromDateValue, toDateValue, selectedBatches, selectedGroups, test, prompt, this.state.numGroups, isDerivative)
          .then((BERTData) => {
            if (!BERTData.responseData.length) {
              restoreEvents();
              showToastr('fa fa-exclamation-circle',
                'There are no completed surveys to analyze.'
                , {type: toast.TYPE.INFO});
              this.setState({submitting: false});
            }
            else {
              var defaultGroups = this.state.numGroups;
              this.setState({
                responseData: BERTData.responseData,
                maxGroups: Math.min(BERTData.responseData.length, 9), //can't ask for more groups than there are responses
                numGroups: Math.min(Math.min(BERTData.responseData.length, 9), defaultGroups), //reset group number picker
                wordGroups: BERTData.wordGroups,
                analysisCompleted: true,
                submitting: false
              });
              if (window.demo) {
                let jsBert = JSON.stringify(BERTData.responseData);
                let jsWordGroups = JSON.stringify(BERTData.wordGroups);

                $.ajax({
                  url: '/mock/captureDemoResponseData',
                  data: {
                    jsBert,
                    jsWordGroups
                  },
                  type: "POST",
                  success: (response) => {
                    console.log(response);
                  },
                  error: (err) => {
                    console.log(err);
                  }
                })
              }
              restoreEvents()
            }
          })
        break;
      case "Named Entities":
        getNamedEntities(fromDateValue, toDateValue, selectedBatches, selectedGroups, test, prompt, isDerivative)
          .then((NamedEntitiesData) => {
            if (!NamedEntitiesData.responseData.length) {
              restoreEvents();
              showToastr('fa fa-exclamation-circle',
                'There are no completed surveys to analyze.'
                , {type: toast.TYPE.INFO});
              this.setState({submitting: false});
            }
            else {
              this.setState({
                responseData: NamedEntitiesData.responseData,
                entityStr: NamedEntitiesData.entityStr,
                analysisCompleted: true,
                submitting: false
              });
              restoreEvents()
            }
          })
        break;
      case "Sentiment Analysis":
        getSentimentData(fromDateValue, toDateValue, selectedBatches, selectedGroups, test, prompt, isDerivative)
          .then((SentimentData) => {
            this.setState({
              responseData: SentimentData.responseData,
              analysisCompleted: true,
              submitting: false
            });
            restoreEvents()
          })
        break;
      case "Search Responses":
        getSearchData(fromDateValue, toDateValue, selectedBatches, selectedGroups, test, prompt, isDerivative)
          .then((SearchData) => {
            this.setState({
              responseData: SearchData.responseData,
              analysisCompleted: true,
              submitting: false
            });
            restoreEvents()
          })
        break;
      default:
        break;
    }
    chartHasContent(true)
  }

  handleSubmit = (e) => {
    const {chartHasContent, analysisId} = this.props;
    const {analysis, batchCheckboxesStatus, batchNames, fromDateValue, toDateValue, groupCheckboxesStatus, groups, prompt, test} = this.state;


    window.analysisState = this.state;
    window.analysisState.analysisId = analysisId;
    window.analysisState.chartHasContent = chartHasContent;


    this._handleSubmit({
      analysis,
      batchCheckboxesStatus,
      batchNames,
      fromDateValue,
      toDateValue,
      groupCheckboxesStatus,
      groups,
      prompt,
      test

    });

  }

  handleGroupsChange = (e) => {
    let n = parseInt(e.target.value)
    let digit = /^\d{1}$/.test(n);
    if (digit && n !== 0) {
      let rspData = getKmeans(this.state.responseData, e.target.value);
      let wordGroups = setWordGroups(rspData, e.target.value);
      this.setState({
        numGroups: e.target.value,
        responseData: rspData,
        wordGroups: wordGroups
      });
    }
  }


  renderAnalysis = () => {
    const {analysisId, isDerivative} = this.props;
    switch (this.state.analysis) {
      case "Cluster Analysis":
        return (
          <div>
            <div
              style={{position: 'relative', width: '100%', top: '10px', left: '-5px'}}>
              <input type="number" name="quantity" min="1"
                     data-html2canvas-ignore="true"
                     max={this.state.maxGroups}
                     value={this.state.numGroups}
                     onChange={this.handleGroupsChange}/>
              <label data-html2canvas-ignore="true">&nbsp;groups</label>
            </div>
            <Clusters
              isDerivative={isDerivative}
              width={1000}
              height={650}
              numGroups={this.state.numGroups}
              responseData={this.state.responseData}
              analysisId={analysisId}
              wordGroups={this.state.wordGroups}
              demo={false}
            />
          </div>)
      case "Named Entities":
        return (
          <div>
            <NamedEntities
              isDerivative={isDerivative}
              width={1000}
              height={650}
              responseData={this.state.responseData}
              analysisId={analysisId}
              entityStr={this.state.entityStr}
            />
          </div>)
      case "Sentiment Analysis":
        return (
          <div>
            <Sentiments
              isDerivative={isDerivative}
              width={1000}
              height={650}
              responseData={this.state.responseData}
              analysisId={analysisId}
            />
          </div>)
      case "Search Responses":
        return (
          <div>
            <Search
              isDerivative={isDerivative}
              width={1000}
              height={650}
              responseData={this.state.responseData}
              analysisId={analysisId}
            />
          </div>)
      default:
        return null;
    }
  }

  changeModule = () => {
    const {storeBERTData, storeWordGroups, analysisId} = this.props;
    storeBERTData(analysisId, this.state.responseData);
    storeWordGroups(analysisId, this.state.wordGroups);
    delete this.state.responseData;
    delete this.state.wordGroups;
  }

  componentWillUnmount() {
    const {analysisId} = this.props;
    if (!localStorage.getItem('analysis_reset')) {
      this.changeModule();
      localStorage.setObject(`analysisCreator-${analysisId}`, this.state);
    }
  }

  render() {
    const {visible, surveys, isDerivative, hasChild} = this.props;
    return (
      <div onClick={this.closeDropdowns}>
        <div style={{
          width: '100%',
        }}>

          {visible ?
            <div className="AnalysisBody" style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around'
            }}>

              <div className="analysisFilter" style={{
                width: '15%', minHeight: '100%',
                borderRight: '1px dotted gray'
              }}>
                <SelectSurvey
                  isDerivative={isDerivative}
                  surveys={surveys}
                  testName={this.state.test.testName}
                  disabled={isDerivative || hasChild}
                  onChange={this.handleTestChange}/>
                <SelectAnalysis
                  analyses={this.state.analyses}
                  analysis={this.state.analysis}
                  onChange={this.handleAnalysisChange}
                  disabled={!this.state.test.testName || isDerivative || hasChild}

                />
                <SelectPrompt
                  prompts={this.state.prompts}
                  prompt={this.state.prompt}
                  onChange={this.handlePromptChange}
                  disabled={!this.state.analysis || this.state.analysis === 'Export' || isDerivative || hasChild}/>
                <div style={{
                  width: '100%',
                  height: '35px',
                  border: '1px solid transparent',
                  boxSizing: 'border-box'
                }}>
                  <p style={{textAlign: 'center'}}><u>Date Range</u></p>
                </div>
                <SelectFromDate
                  key={`fd-${this.state.refreshKey}`}
                  fromDateValue={this.state.fromDateValue}
                  handleFromDate={this.handleFromDate}
                  disabled={!this.state.analysis || isDerivative || hasChild}
                  max={this.state.fromDateMax}
                  min={this.state.firstDate}
                />
                <SelectToDate
                  key={`td-${this.state.refreshKey}`}
                  toDateValue={this.state.toDateValue}
                  handleToDate={this.handleToDate}
                  disabled={!this.state.analysis || isDerivative || hasChild}
                  min={this.state.toDateMin}
                  max={this.state.lastDate}


                />
                <div style={{
                  width: '100%',
                  height: '50px',
                  border: '1px solid transparent',
                  boxSizing: 'border-box',
                  marginTop: '7px'
                }}>
                  <br></br>
                  <p style={{textAlign: 'center'}}><u>Scope</u></p>
                </div>
                <SelectBatches
                  key={`b-${this.state.refreshKey}`}
                  refreshKey={this.state.refreshKey}
                  batchNames={this.state.batchNames}
                  checkboxesStatus={this.state.batchCheckboxesStatus}
                  disabled={!this.state.analysis || isDerivative || hasChild}
                  setCheckboxesStatus={this.setBatchesCheckboxesStatus}
                  batchDropdownOpen={this.state.batchDropdownOpen}
                  toggleBatchDropdown={this.toggleBatchDropdown}
                />

                <SelectGroups
                  key={`g-${this.state.refreshKey}`}
                  refreshKey={this.state.refreshKey}
                  groups={this.state.groups}
                  checkboxesStatus={this.state.groupCheckboxesStatus}
                  disabled={!this.state.analysis || isDerivative || hasChild}
                  setCheckboxesStatus={this.setGroupsCheckboxesStatus}
                  groupDropdownOpen={this.state.groupDropdownOpen}
                  toggleGroupDropdown={this.toggleGroupDropdown}
                />
                <div style={{width: '100%'}}>
                  <div style={{display: 'inline-block'}}>
                    <button
                      className="blueButtonSmall"
                      id="submitBtn"
                      disabled={this.state.submitting || ((!this.state.prompt && this.state.analysis !== "Export") || isDerivative || hasChild)}
                      name="submitBtn"
                      style={{margin: '20px', marginTop: '35px'}}
                      onClick={this.handleSubmit}
                    >SUBMIT
                    </button>
                  </div>
                </div>
              </div>
              <AnalysisGraph>
                {this.state.analysisCompleted ? this.renderAnalysis() :
                  <Gears submitting={this.state.submitting}/>}
              </AnalysisGraph>
            </div> : null}
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    BERTResponses: state.BERTResponses,
    wordGroups: state.wordGroups,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    storeBERTData,
    storeWordGroups
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisCreator);