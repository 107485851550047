import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';
import surveyInstructions from './surveyInstructions';
import slicedTin from '../Common/slicedTin';

const _participantReminder = (testTaker, org, hasTried) => {
  let status = hasTried ? 'completed' : 'tried';
  return (
    <div style={{fontSize:'18pt'}}>
      <p>Hello {testTaker},</p>
      <p>Not long ago you received an invitation from <span style={{fontWeight: 'bold'}}>{org}</span> to participate in a short audio survey. Our records indicate that you have not {status} the survey yet.</p>

      <p>Participation is simple. You will hear several spoken questions, and after each, you will have a chance to reply. We chose this type of survey because it lets us listen to and consider each participant's viewpoints in their own words, without participants having to provide written responses.</p>

      <p>Please take a few minutes and share your thoughts with us.</p>
      <br/>
      <p>Thank you in advance!</p>
      <br/>
       <div>______________________________________________</div>
    </div>
  )
}

const participantReminder = (testTaker, org, hasTried, TIN, testUrl) => {
  let header = reactElementToJSXString(_participantReminder(testTaker, org, hasTried));
  let footer = surveyInstructions(testUrl, slicedTin(TIN));
  return `${header}<br/>${footer}`
}

export default participantReminder;