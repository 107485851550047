// import React from 'react';
// import _ from 'lodash'
// import axios from 'axios'
//
//
// // let prompts = [
// //   {
// //     accountId: "wizmRxBFM",
// //     // _id: 0,
// //     text: 'Briefly describe the Spanish American War',
// //     category: 'School'
// //   },
// //   {
// //     accountId: "wizmRxBFM",
// //    // _id: 0,
// //     text: 'What do we mean when we speak of the correlation between two variables.',
// //     category: 'Job Interview'
// //   },
// //   {
// //     accountId: "wizmRxBFM",
// //   // _id: 0,
// //     text: 'How do you feel about the Democratic Party.',
// //     category: 'Political Preferences'
// //   },
// //   {
// //     accountId: "wizmRxBFM",
// //     // _id: 0,
// //     text: 'Briefly describe the Spanish American War',
// //     category: 'Job Interview'
// //   },
// //   {
// //     accountId: "wizmRxBFM",
// //  // _id: 0,
// //     text: 'What do we mean when we speak of the correlation between two variables.',
// //     category: 'Technical Skills'
// //   },
// //   {
// //     accountId: "wizmRxBFM",
// //    // _id: 0,
// //     text: 'Tell us about your summer vacation.',
// //     category: ''
// //   }
// // ]
//
//
// class Category extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOpen: true,
//       categorySelected: false,
//     }
//   }
//
//
//   handleClick = (e) => {
//     this.setState({isOpen: !this.state.isOpen})
//   }
//
//   deleteQuestion = (e) => {
//     const {handleDelete} = this.props;
//     const deleteIcon = e.target;
//     const questionToBeDeleted = deleteIcon.nextElementSibling;
//     console.log(questionToBeDeleted);
//     handleDelete(questionToBeDeleted.id);
//   }
//
//   render() {
//     let {category, index} = this.props;
//     if (!category.name) {
//       category.name = "Uncategorized";
//     }
//     return (
//       <div style={{marginBottom: '8px'}}>
//         <div style={{fontSize: '20px', display: 'flex'}}
//              key={index}
//              id={category.name}
//         >
//           <div onClick={this.handleClick}>
//             {this.state.isOpen ? <i className="fa-solid fa-chevron-down"></i> :
//               <i className="fa-solid fa-chevron-right"></i>}
//           </div>
//           {<div style={{marginLeft: '5px', color: 'red'}}>{category.name}</div>}
//         </div>
//         <div style={{display: this.state.isOpen ? 'block' : 'none', marginTop: '5px'}}>
//           {category.questions.map((question, index) => {
//             return (
//               <div style={{display: 'flex', marginBottom: '4px'}}
//                    key={category.name + index}>
//                 <i className="far fa-minus-square"
//                    style={{marginRight: '4px', marginLeft: '32px'}}
//                    onClick={this.deleteQuestion}> </i>
//                 <div id={question._id}>{question.text}</div>
//
//               </div>)
//           })}
//         </div>
//       </div>
//     )
//   }
// }
//
//
// class AssigningRespondents extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       categories: [],
//       prompts: []
//     }
//   }
//
//   componentDidMount() {
//     axios.get('/test/getSavedQuestions?accountId=wizmRxBFM')
//       .then((response) => {
//         this.setState({prompts: [...response.data]})
//         // let prompts = response.data;
//         // console.log(prompts);
//         let categories = [];
//         this.state.prompts.forEach((prompt) => {
//           let category = categories.find((category) => {
//             return category.name === prompt.category;
//           })
//           if (category) {
//             category.questions.push({_id: prompt._id, text: prompt.text})
//           } else {
//             categories.push({
//               name: prompt.category,
//               questions: [{_id: prompt._id, text: prompt.text}],
//             });
//           }
//         })
//
//
//         categories.sort((a, b) => {
//           if (a.name < b.name) {
//             return -1;
//           }
//           if (a.name > b.name) {
//             return 1;
//           }
//           return 0;
//         });
//
//         console.log(categories);
//
//         this.setState({categories: [...categories]}, () => {
//           console.log(this.state.categories)
//         })
//       })
//
//
//   }
//
//
//
//   handleDelete = (_id) => {
//
//     console.log(_id);
//     // const newArray = [];
//     // prompts.forEach((prompt) => {
//     //   if (prompt._id.toString() !== _id) {
//     //     newArray.push(prompt);
//     //   }
//     //   this.setState({prompts: [...newArray]})
//     // })
//
//   }
//
//   render() {
//     return (
//       <div>
//         <h1>Categories</h1>
//         <div style={{marginLeft: '12px', marginRight: '12px'}}>
//           {this.state.categories.map((category, index) => {
//             return (<Category
//                 handleDelete={this.handleDelete}
//                 category={category}
//                 index={index}/>
//             )
//           })}
//         </div>
//       </div>
//     )
//   }
// }
//
// export default AssigningRespondents;


import React from 'react'

class AssigningRespondents extends React.Component {
    render() {
    // const {user} = this.props;
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>

          <img src="../underConstruction.png" alt=""/>

      </div>
    )
  }
}

export default AssigningRespondents;
