import {GET_ANON_ASSIGNMENTS} from '../actions/_CONSTANTS';

const anonAssignments = (state={}, action) => {
  switch (action.type) {
    case GET_ANON_ASSIGNMENTS:
      return action.payload;
    default:
      return state;
  }
}

export default anonAssignments;
