import $ from 'jquery';

let responseData;
let rspData = [];
let value;
var apiInstance = new window.cloudmersiveNlpApiClient.ExtractEntitiesStringApi();

const getCloudmersiveData = (data) => {
  responseData = data;
  rspData = [];

  for (var i = 0; i < responseData.length; i++) {
    rspData[i] = "#" + responseData[i].responseText;
  }

  var defaultClient = window.cloudmersiveNlpApiClient.ApiClient.instance;
  var Apikey = defaultClient.authentications['Apikey'];
  Apikey.apiKey = 'd3e014e4-aa70-4948-8e81-79140e4556ad';

  value = rspData.join(" ");

  value = value.replace(/\d+/g, ''); //need to remove numbers from input to cloudMersive
  value = value.split("...").join(" "); //elipses are tagged as ORGANIZATIONs - remove them
}


export const getNamedEntities = (fromDateValue, toDateValue, selectedBatches, selectedGroups, test, prompt, isDerivative) => {

  return new Promise((resolve, reject) => {
    // *********************** called from CloudMersive named entity service **************** //
    const cloudMersiveCallback = (error, data) => {
      if (error) {
        resolve({
          responseData: ["Error getting named entity data"]
        });
      } else {

        resolve({
          responseData: responseData,
          entityStr: data
        });
      }
    }
    if (isDerivative) {
      let data;
      if (window.numberOfChildren) { //returning from a different module
        data = window.derivativeData[window.numberOfChildren - 1];
        window.numberOfChildren = window.numberOfChildren - 1;
      }
      else { //initial creation of a derivative chart
        data = window.derivativeData[window.derivativeData.length - 1];
      }
      data = JSON.parse(JSON.stringify(data));
      getCloudmersiveData(data)
      apiInstance.extractEntitiesStringPost(value, cloudMersiveCallback); //CALL CLOUDMERSIVE -- loaded from CDN

    } else {
      let url = '/test/getEntities';
      let data = {
        fromDateValue: fromDateValue.getTime(),
        toDateValue: toDateValue.getTime(),
        selectedBatches,
        selectedGroups: selectedGroups,
        testName: test.testName,
        accountId: test.accountId,
        promptId: prompt.promptId
      }

      $.ajax({
        url,
        type: "POST",
        data,
        success: (data) => {
          getCloudmersiveData(data)
          apiInstance.extractEntitiesStringPost(value, cloudMersiveCallback); //CALL CLOUDMERSIVE -- loaded from CDN
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    }
  })
}