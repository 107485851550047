import React from 'react';
import ModalFrame from '../../../ModalFrame';
import DeleteStaff from './DeleteStaff';

class DeleteStaffModalController extends React.Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);

  }


  hideModal() {
    const {setModalInfo} = this.props;
    setModalInfo({});
  }

  render() {
    const {modalInfo, confirmHandler} = this.props;
    return (
      <div className="example-modal">
        {modalInfo.name === 'deleteStaff' ?
          <ModalFrame
            title="DELETE A STAFF MEMBER"
            desktopWidth="60%"
            headerCloseButtonHandler={this.hideModal}
            hasHeader={true}>
            <DeleteStaff
              modalInfo={modalInfo}
              hideModal={this.hideModal}
              confirmHandler={confirmHandler}
            />
          </ModalFrame> : null}
      </div>
    )
  }
}

export default DeleteStaffModalController;
