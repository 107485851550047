import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Form, Field} from 'react-final-form';
import {resetPassword} from '../../actions/User/resetPassword';
import classnames from 'classnames'
import $ from 'jquery';
import getJsonFromUrlQueryString from '../../components/Common/getJsonFromUrlQueryString';
import showSystemError from '../Common/showSystemError';


class PasswordChange extends React.Component {
  pwValid = false;
  pwConfirm = false;
  pw = '';

  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      email: ''
    }
  }

  componentDidMount() {
    let queryString = getJsonFromUrlQueryString();
    this.setState({email: queryString.email});
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    $('.no-navbar').height(window.innerHeight);
  }

  allValid = () => {
    return this.pwValid && this.pwConfirm;
  }


  lengthLTSix = (value) => {
    if (!value) {
      return 'invalid'
    }
    this.pw = value;
    this.pwValid = value.length > 5;
    return this.pwValid ? undefined : 'invalid'
  }

  passwordsEqual = (value) => {
    this.pwConfirm = this.pw === value;
    return this.pwConfirm && this.pwValid ? undefined : 'invalid';
  }

  onSubmit = (values) => {
    const {resetPassword} = this.props;
    resetPassword(values.email, values.password)
      .then(() => {
          this.setState({formSubmitted: true});
        }, (err) => {
          showSystemError();
        }
      )
  }

  renderStyledField = (input, type, label, indicator) => {

    return (
      <div style={{marginBottom: '30px'}}>
        <div style={{marginBottom: '4px'}}>
          <label style={{
            fontSize: '14px',
            cursor: 'default'
          }}>{label}</label>
        </div>
        <div style={{width: '100%'}}>
          <div style={{display: 'inline-block', width: '95%'}}>
            <input {...input} type={type}
                   className="rc"
                   style={{
                     width: '100%',
                     cursor: input.disabled ? 'not-allowed' : 'text'
                   }}/>
          </div>
          <div style={{float: 'right', paddingTop: '8px'}}>
            <div style={{margin: '0 auto'}}
                 className={indicator}>

            </div>
          </div>
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const {history} = this.props;
    let confirmTitleVisibleClasses = classnames('confirm_title', {hidden: this.state.formSubmitted})
    return (
      <div className='no-navbar' style={{
        width: '100%',
        //background: 'rgb(33, 169, 134)',
        background: 'cadetblue'
      }}>

        <div>
                  {/* <img src="TellMeThisLogoWhiteTransparent.png"
             alt=""
             style={{height: '42px', padding: '8px', cursor: 'pointer'}}/> */}
        <img src="TMTLogoWhiteBalloonsWithTagLine.svg"
             className = "TMTLogoWhiteBallonsWithTagLine"
             alt=""
             //style={{height: '70px', paddingLeft: '8px', marginTop: '-5px', cursor: 'pointer'}}
          />

        </div>
        <h1 className={confirmTitleVisibleClasses}>Create Your Password</h1>
        <div style={{marginTop: '40px'}}>
          <Form
            onSubmit={this.onSubmit}
            validateOnBlur={false}
            initialValues={
              {
                email: this.state.email,
                password: '',
                confirmPassword: ''
              }
            }
            render={({handleSubmit, form, submitting, pristine, values}) => {
              if (this.state.formSubmitted) {
                return (
                  <div className="signup-form-panel" style={{textAlign: 'center'}}>
                    <h3>Your password has been successfully created.</h3>
                    <h3>Click the login button start using your new account.</h3>
                    <button className="blueButton"
                            style={{
                              float: 'right',
                              marginRight: '16px',
                              marginTop: '10px'
                            }}
                            onClick={() => {
                              history.replace('/login')
                            }}>
                      Log in
                    </button>
                  </div>)
              }
              return (
                <form
                  className="signup-form-panel"
                  onSubmit={handleSubmit}>
                  <Field name="email" validate={this.validEmail}>
                    {({input, meta}) => {
                      input = {...input, ...{disabled: true}}
                      let indicatorClassEmail = 'lineIndicatorGreen';
                      return (
                        this.renderStyledField(input, 'text', 'Email Address:', indicatorClassEmail)
                      )
                    }}
                  </Field>

                  <Field name="password" validate={this.lengthLTSix}>
                    {({input, meta}) => {
                      let indicatorClassPassword = '';
                      if (meta.visited) {
                        indicatorClassPassword = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen';
                      }
                      return (
                        this.renderStyledField(input, 'password',
                          'Password: (must be at least six characters)', indicatorClassPassword)
                      )
                    }}
                  </Field>
                  <Field name="confirmPassword" validate={this.passwordsEqual}>
                    {({input, meta}) => {
                      let indicatorClassConfirm = '';
                      if (meta.visited) {
                        indicatorClassConfirm = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen';
                      }
                      return (
                        this.renderStyledField(input, 'password', 'Confirm Password', indicatorClassConfirm)
                      )
                    }}
                  </Field>
                  <div>
                    <button className="blueButton"
                            style={{
                              float: 'right',
                              marginRight: '16px',
                              marginTop: '10px'
                            }}
                            type="submit"
                            disabled={submitting || !this.allValid() || this.state.formSubmitted}>
                      Submit
                    </button>
                  </div>
                </form>
              )
            }}
          ></Form>
          <div style={{
            textAlign: 'center',
            color: 'white',
            marginTop: '15px',
            lineHeight: '.6'
          }}>

          </div>
        </div>
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    resetPassword: resetPassword
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(PasswordChange);