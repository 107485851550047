import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import $ from 'jquery';
import showSystemError from '../Common/showSystemError';
import getAllSurveys from '../Common/getAllSurveys';
import {get_AnonAssign_Table} from '../../actions/AnonAssign/get_AnonAssign_Table';
import {DropdownList}  from 'react-widgets'


class AnonAssignSelector extends React.Component {
  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('anonAssignSelector');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('anonAssignSelector');
    }
    else {
      this.state = {
        testAlternatives: [],
        groups: [],
        selectedSurvey: '',
        selectedGroup: '',
        instrumentType: ''
      }
    }
  }

  componentDidMount() {
    const {user} = this.props;
    getAllSurveys(user.accountId, 'anonAssign')
      .then((tests) => {
        this.setState({
          testAlternatives: tests
        })
      }, (err) => {
        console.log(err);
        showSystemError()
      })
  }

  getAnonGroups = (test) => {
    const {user} = this.props;
    const accountId = user.accountId;
    return new Promise((resolve, reject) => {
      let url = `/test/getAnonGroups?test=${test}&accountId=${accountId}`
      // let url = '/testerror'
      $.ajax({
        url: url,
        type: 'GET',
        success: ((result) => {
          resolve(result.groups)
        }),
        error: ((err) => {
          reject(err);
        })
      })
    })
  }

  selectSurvey = (value) => {
    const {saveExistingGroups} = this.props;
    this.setState({
      selectedSurvey: value,
      instrumentType: this.state.testAlternatives.find((test)=>{
        return test.testName === value;
      }).instrumentType

    });
    this.getAnonGroups(value)
      .then((groups) => {
          this.setState({groups: groups});
          saveExistingGroups(groups);
        },
        (err) => {
          console.log(err);
          showSystemError();
        })
  }


  selectGroup = (value) => {
    const {showTable, get_AnonAssign_Table, user} = this.props;
    this.setState({
      selectedGroup: value
    }, () => {
      get_AnonAssign_Table(value, this.state.selectedSurvey, this.state.instrumentType, user.accountId)
        .then((assignment) => {
          showTable(true);
          console.log(assignment);
        }, (err) => {
          console.log(err);
          showSystemError();
        });
    });
  }

  testSelector = () => {
    let testAlernatives = this.state.testAlternatives;
    let testNames = testAlernatives.map((test) => {
      return (
        test.testName
      )
    })
    return (
      <div style={{display: 'flex'}}>
        <div className="survey-name-prompt">
          Name: &nbsp;&nbsp;&nbsp;
        </div>
        <div className="survey-dropdown"><DropdownList
          data={testNames}
          value={this.state.selectedSurvey}
          placeholder="Select a test to use"
          onChange={this.selectSurvey}
          disabled={!!this.state.selectedGroup}
        /></div>
      </div>
    )
  }

  groupSelector = () => {
    let groups = ['New'].concat(this.state.groups);
    return (
      <div style={{display: 'flex'}}>
        <div style={{
          textAlign: 'right',
          width: '15%',
          marginLeft: '20px',
          marginTop: '14px'
        }}>
          Group: &nbsp;&nbsp;&nbsp;
        </div>
        <div style={{width: '85%', marginTop: '4px', marginRight: '40px'}}><DropdownList
          data={groups}
          disabled={!this.state.selectedSurvey || !!this.state.selectedGroup}
          value={this.state.selectedGroup}
          onChange={this.selectGroup}
          placeholder="Select an existing group or create a new one."
        /></div>
      </div>
    )
  }

  topPanel = () => {
    return (
      <div className="assignHeadingTName"
           style={{fontWeight: 'normal', display: 'flex'}}>
        <div style={{width: '50%'}}>
          {this.testSelector()}
        </div>
        <div style={{width: '50%'}}>
          {this.groupSelector()}
        </div>
      </div>
    )
  }

  componentWillUnmount () {
    if(!localStorage.getItem('anonAssign_reset')) {
      localStorage.setObject('anonAssignSelector', this.state);
    }
  }

  render() {
    return (
      <div className="assignHeading">
        <div className="boldText" style={{width: '100%'}}>
          {this.topPanel()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    get_AnonAssign_Table
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonAssignSelector);


