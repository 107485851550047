import {SAVE_NAMED_INVITE_TEMPLATE} from '../_CONSTANTS';
import $ from 'jquery'

export const save_Named_Invite_Template = (template) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = '/test/saveNamedInviteTemplate';
      // let url = '/testerror';

      $.ajax({
        url: url,
        type: "PUT",
        data: {
         template
        },
        success: (template) => {
          dispatch({
            type: SAVE_NAMED_INVITE_TEMPLATE,
            payload: template
          });
          resolve(template)
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}
