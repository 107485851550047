import React from 'react';
import $ from 'jquery';

class ReviewFooterRight extends React.Component {

  audioTranscript = []
  lastClickTime = 0;
  silenceUrl = {
    URL: 'https://tmt-devstore.s3.amazonaws.com/silenceHalfSecond.wav',
    id: null
  }

  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      checkPolling: false
    }
  }

  buildAudioTranscript = () => {
    const {transcript} = this.props;
    this.setState({playing: true})

    this.audioTranscript = [];
    transcript.dialog.forEach((line) => {
      this.audioTranscript.push({
        URL: line.prompt.spokenURLEnabled ? line.prompt.spokenURL : line.prompt.URL,
        id: line.prompt._id
      })

      if (line.response.URL) {
        this.audioTranscript.push({
          URL: line.response.URL,
          id: line.response._id
        })
      }
      this.audioTranscript.push(this.silenceUrl)
    })
  }

  autoPlay = (i, list) => {
    const {setCurrentSound} = this.props;
    var voicePrompt = new Audio(this.silenceUrl.URL);
    let self = this;
    let inc = 0;
    playAudio(i, list);
    function playAudio(i, list) {
      setCurrentSound(list[i].id);
      if (list[i].id) {
        $('#transcript').animate({scrollTop: inc}, 2000);
        inc = inc + $('#' + list[i].id).height();
      }
      voicePrompt.src = list[i].URL;
      voicePrompt.play();
      voicePrompt.onended = function () {
        setCurrentSound(null)
        i = i + 1;
        if (i < list.length && self.state.playing) {
          playAudio(i, list);
        }
        else {
          self.setState({playing: false})
        }
      }
    }
  }

  playTranscript = () => {
    this.buildAudioTranscript();
    console.log(this.audioTranscript);
    this.autoPlay(0, this.audioTranscript)
  }

  handleClick = () => {
    var thisClickTime = new Date().getTime();

    if (thisClickTime - this.lastClickTime > 750) { //ignores clicks if < 1/2 elapsed since last one
      if (!this.state.playing) {
        this.setState({playing: true})
        this.playTranscript()
      }
      else {
        this.setState({playing: false})
      }
    }
    this.lastClickTime = thisClickTime;
  }

  handleTellMeThis = () => {  //click on link on footer of shared transcript viewer
    let getUrl = window.location;
    let linkToAddress = "http://localhost:3000";

    if (getUrl.hostname === "localhost") {           //when in development
      window.location.href = linkToAddress;
    } else {                                        //when running from production server
      window.location.href = 'https://' + getUrl.host;
    }
  }

  handleExport = () => {
    const {openModal, TIN, testName} = this.props;
    openModal({
      name: 'exportTranscript',
      testName: testName,
      TIN: TIN
    })
  }

  handleShowscore = () => {
    const {showScore} = this.props;
    document.body.style.cursor = 'wait';
    showScore();
  }

  handleTranscriptToClipboard = () => {
    const {toClipboard} = this.props;
    toClipboard();
  }

  isTranscriptComplete = () =>{
    const {transcript} = this.props;
    Object.values(transcript.dialog).forEach((element) => {
      if (!element.response.completed) {
        return false
      }
    });
    return true
  }

  render() {
    const {hasTranscript, readOnly, willPoll, activeRow, assessmentInProgress} = this.props;
    let playing = this.state.playing;
    return (
      <div className="reviewFooterRight">
        <div>
          { readOnly ? //just the PLAY/STOP button -- transcript is being shared or previewed
          <div>
            <button className="blueButton"
                  style={{margin: "10px"}}
                  disabled={!hasTranscript}
                  onClick={this.handleClick}>
              <div style={{display: playing ? 'none' : 'inline'}}>
                PLAY <i className="fas fa-play-circle"></i>
              </div>
              <div style={{display: playing ? 'inline' : 'none'}}>
                Stop <i className="fas fa-stop-circle"></i>
              </div>
            </button>

            <div style={{position: 'relative', width: '0px', height: '0px'}}>
              <span onClick={this.handleTellMeThis}
                  style={{
                    position: 'relative',
                    top: '-20px',
                    float: 'left',
                    marginLeft: '5px',
                    color: 'slategray',
                    textDecoration: 'underline',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    cursor: 'pointer'
                    }}>
                    <i>TellMeThis.org</i>
              </span>
            </div>
        </div>
        : // normal REVIEW tab array of buttons
        <div>
          <button id="shareButton" className="blueButton"
                  style={{margin: "10px"}}
                  disabled={!hasTranscript || willPoll || !activeRow}
                  onClick={this.handleClick}>
            <div style={{display: playing ? 'none' : 'inline'}}>
              PLAY <i className="fas fa-play-circle"></i>
            </div>
            <div style={{display: playing ? 'inline' : 'none'}}>
              Stop <i className="fas fa-stop-circle"></i>
            </div>
          </button>

          <button
            className="blueButton"
            style={{margin: "10px"}}
            disabled={!hasTranscript || willPoll || !activeRow}
            onClick={this.handleExport}
          >SHARE
          </button>

          <button
            className="blueButton"
            style={{margin: "10px"}}
            disabled={!hasTranscript || willPoll || !activeRow || assessmentInProgress}
            onClick={this.handleShowscore}
            title="For keyword-scored responses"
          >ASSESSMENTS
          </button>

          <button
            className="blueButton"
            style={{margin: "10px"}}
            disabled={!hasTranscript || willPoll || !activeRow}
            onClick={this.handleTranscriptToClipboard}
          >COPY
          </button>

        </div>
        }
      </div>
    </div>
    )
  }
}
export default ReviewFooterRight;