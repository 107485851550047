import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import EditSettings from "./EditSettings";

const testSelectorStyles = {
  display: 'inline-block',
  margin: '0',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '20px',
  borderRadius: '3px',
  border: '1px solid transparent',
  borderTop: 'none',
  borderBottom: '1px solid #DDD',
  boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF',
  width: '95.5%'
}

class SuperUser extends React.Component {

  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('superUser');

    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('superUser');
    } else {
      this.state = {
        inSync: true,
        bucketInfo: [
          {
            awsBucket: 'dev.pollyprompts',
            stage: 'dev',
            keysInBucket: '',
            keysInUse: '',
            expiredKeys: '',
            allowDelete: false,
            inSync: true,
            error: false
          },
          {
            awsBucket: 'dev.utterances',
            stage: 'dev',
            keysInBucket: '',
            keysInUse: '',
            expiredKeys: '',
            allowDelete: false,
            inSync: true,
            error: false
          },
          {
            awsBucket: 'tmt-pollyprompts',
            stage: 'prod',
            keysInBucket: '',
            keysInUse: '',
            expiredKeys: '',
            allowDelete: false,
            inSync: true,
            error: false
          },
          {
            awsBucket: 'tmt-utterances',
            stage: 'prod',
            keysInBucket: '',
            keysInUse: '',
            expiredKeys: '',
            allowDelete: false,
            inSync: true,
            error: false
          }
        ],
      }
    }
  }


  topPanel = () => {
    return (
      <div className="superUserHeader"
           style={{fontWeight: 'normal'}}>
        <center><h2>Purge Expired Bucket Objects</h2></center>
      </div>
    )
  }

  getInfo = async (bucketStats, executeDelete) => {
    let {
      stage,
      awsBucket
    } = bucketStats;

    const bucketNames = ['dev.pollyprompts', 'dev.utterances', 'tmt-pollyprompts', 'tmt-utterances']
    let index = bucketNames.indexOf(awsBucket);
    let newBucketData = [...this.state.bucketInfo];
    newBucketData[index].error = false;

    try {
      let response = await axios.post('/admin/getSuperUserData', {
        stage,
        name: awsBucket.slice(4),
        report: true,
        doDelete: executeDelete.toString()
      });
      if (response.status === 200) {
        let data = response.data.data;
        newBucketData = [...this.state.bucketInfo];
        if (executeDelete) {
          console.log('__________________deletedKeys______________________________')
          console.log(data.deletedKeys);
          console.log('__________________deletedKeys______________________________')
        }
        data.error = false;
        data.inSync = !data.abandonedKeys &&
          !data.notUniqKeysInUse &&
          !data.notUniqKeysInBucket &&
          (data.keysInBucket === data.keysInUse + data.expiredKeys);

        if (data.inSync) {
          data.allowDelete = true;
        }

        data = _.omit(data, ['deletedKeys', 'doDelete', 'notUniqKeysInUse', 'notUniqKeysInBucket', 'abandonedKeys']);
        newBucketData[index] = {...data};

        this.setState({bucketInfo: [...newBucketData]})
      } else {
        newBucketData[index].error = true;
        this.setState({bucketInfo: [...newBucketData]}, () => {
          console.log(this.state)
        })
      }

    } catch (err) {
      console.log(err)
    }
  }


  bucketPanel = (bucketStats) => {
    let {
      awsBucket,
      keysInBucket,
      keysInUse,
      expiredKeys,
      error,
      allowDelete,
      inSync
    } = bucketStats;

    return (
      <div className="bucketHeading">
        <div className="boldText" style={{paddingBottom: '3px'}}>
          <h2 style={{textAlign: 'center'}}>{awsBucket}</h2>
          <div style={{float: 'left', fontWeight: 'normal', width: '100%'}}>
            <div style={{float: 'left'}}>{`Keys in bucket: ${keysInBucket}`}</div>
            <br/>
            <div style={{float: 'left'}}>{`Keys in use: ${keysInUse}`}</div>
            <br/>
            <div style={{float: 'left'}}>{`Expired Keys: ${expiredKeys}`}</div>
            <br/>
            {error ?
              <div style={{float: 'left', color: 'red', marginTop: '5px'}}>A system error
                has occurred.
                Please Try Again.</div> : null}
            {!error && !inSync ?
              <div style={{float: 'left', color: 'red'}}>Bucket stats are inconsistent.
                Please fix before purging. See console.</div> : null}

          </div>
          <br/>
          <div style={{float: 'left', padding: '20px 0 10px'}}>
            <button className='blueButton' id={`${awsBucket}-get`}
                    onClick={() => {
                      this.getInfo(bucketStats, false)
                        .then(() => {
                          console.log(this.state);
                        })
                    }}>Get Info
            </button>
          </div>
          <div style={{float: 'right', padding: '20px 0 10px', marginRight: '10px'}}>
            <button className='blueButton' id={`${awsBucket}-purge`}
                    disabled={!allowDelete}
                    onClick={() => {
                      this.getInfo(bucketStats, true)
                        .then(() => {
                          console.log(this.state);
                        })
                    }}
            >Purge
            </button>
          </div>
        </div>


      </div>
    )
  }

  componentWillUnmount() {
    if (!localStorage.getItem('superUser_reset')) {
      localStorage.setObject('superUser', this.state);
    }
  }

  render() {
    let screenHeight = window.screen.availHeight;
    console.log(screenHeight);
    return (
      <div className="main mainVisible" style={{marginTop: '5px', height:'100%', overflowY: 'auto'}}>
        <div style={{height: {screenHeight}}}>
          <div className="assignHeading" style={{
            marginLeft: '10px',
            marginRight: '10px',
            marginTop: '10px',
            height: '1000px'

          }}>
            <div className="boldText" style={{width: '100%'}}>
              {this.topPanel()}
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
              {this.bucketPanel(this.state.bucketInfo[0])}
              {this.bucketPanel(this.state.bucketInfo[1])}
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}>
              {this.bucketPanel(this.state.bucketInfo[2])}
              {this.bucketPanel(this.state.bucketInfo[3])}
            </div>
            <div style={{fontWeight: 'normal' }}>
                <center>
                  <h2>Survey Settings</h2>
                </center>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '14px'}}>
                <div style={testSelectorStyles}>
                 <EditSettings />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default SuperUser;