import {SAVE_ANONASSIGN_TABLE} from '../_CONSTANTS';
import $ from 'jquery';

export const save_AnonAssign_Table = (groupName) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let table = getState().anonAssign_Table;
      let newAssignment = {...table.assignment, ...{group: groupName}}
      let newTable = {
        assignment: newAssignment,
        isGroupEditable: true
      }
      let url = '/test/saveAnonAssignment'
      // let url = '/testerror'
      $.ajax({
        url: url,
        type: "POST",
        data: newAssignment,
        success: (result) => { //result not used because of isGroupEditable
          dispatch({
            type: SAVE_ANONASSIGN_TABLE,
            payload: newTable
          })
          resolve(newTable);
        },
        error: (err) => {
          console.log(err);
          reject(err)

        }
      })
    })
  }
}
