import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Form, Field} from 'react-final-form';
import classnames from 'classnames';
import $ from 'jquery';
import _ from 'lodash';
import {loginUser} from '../../actions/User/loginUser';
import {setSocket} from '../../actions/setSocket';
import {socketDispatch} from '../../actions/socketDispatch';
import {get_Invite_Templates} from '../../actions/AnonAssign/get_Invite_Templates';
import {save_Invite_Template} from '../../actions/AnonAssign/save_Invite_Template';
import defaultAnonInviteTemplate from '../email_templates/defaultAnonInviteTemplate';
import {get_Named_Invite_Templates} from '../../actions/Assign/get_Named_Invite_Templates'
import {save_Named_Invite_Template} from '../../actions/Assign/save_Named_Invite_Template';
import defaultNamedInviteTemplate from '../email_templates/defaultNamedInviteTemplate';
import showSystemError from '../Common/showSystemError';
import hasPermission from '../Common/hasPermission';
import {logoutUser} from "../../actions/User/logoutUser";


class Login extends React.Component {
  emailValid = false;
  pwValid = false;

  constructor(props) {
    super(props);
    this.state = {
      badLogin: false
    }
  }

  componentDidMount() {
    const {user, logoutUser} = this.props;
    if (user.accountId) {
      window.socket.emit('leaveRoom', `room-${user.accountId}`);
      window.socket.disconnect();
      logoutUser(user.email)
        .then(() => {
            console.log('logged out');
          },
          (err) => {
            showSystemError()
          })
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    $('.no-navbar').height(window.innerHeight);
  }

  allValid = () => {
    return this.emailValid && this.pwValid;
  }

  validEmail = (value) => {
    // eslint-disable-next-line
    // this.emailValid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value));
    //NEW PATTERN GOOD FOR 4 LETTER TLD'S
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9.-]+)?$/;
    this.emailValid = emailPattern.test(value);
    return this.emailValid ? undefined : 'invalid'
  }

  lengthLTSix = (value) => {
    if (!value) {
      return 'invalid'
    }
    this.pwValid = value.length > 5;
    return this.pwValid ? undefined : 'invalid'
  }

  onSubmit = (values) => {
    const {
      loginUser,
      history,
      get_Invite_Templates,
      save_Invite_Template,
      get_Named_Invite_Templates,
      save_Named_Invite_Template,
      socket,
      setSocket,
      socketDispatch
    } = this.props;
    values.email = values.email.toLowerCase();
    this.setState({badLogin: false})
    loginUser(values)
      .then((user) => {
        if (user && _.isEmpty(socket)) {
          window.socket = window.io.connect(window.location.origin);
          console.log('socket.io connecting from login page.')
          setSocket(window.socket);
          window.socket.emit('joinRoom', `room-${user.accountId}`);//registers client as
          // belonging to room 'user-<accoundId>
          window.socket.on(user.accountId, (data) => { //server generated event
            if (!data.count) {
              if (data.type === 'COMPLETE_ASSIGNMENT') {
                window.socketEventStore.completeAssignment = data; //store separately for
                // Review module if
                // event is COMPLETE_ASSSIGNMENT
              }
              console.log('Server socket event: ', data);
              socketDispatch(data); //send to socket dispatcher to update store if needed
              window.socketEvent.info = data;
              document.dispatchEvent(window.socketEvent); //dispatch general socket
              // event for whoever needs it
            }
          })

          window.socket.on(`room-${user.accountId}`, (data) => { //client generated event
            console.log('socket event received', data);
            socketDispatch(data);
            window.socketEvent.info = data;
            document.dispatchEvent(window.socketEvent); //dispatch general socket
            // event for whoever needs it
          })


          if (hasPermission(user.role, 'assign')) {
            get_Invite_Templates(user.accountId)
              .then(() => {
                save_Invite_Template({
                  accountId: user.accountId,
                  name: 'default',
                  html: defaultAnonInviteTemplate()
                })
                  .then(() => {
                    get_Named_Invite_Templates(user.accountId)
                      .then(() => {
                        save_Named_Invite_Template({
                          accountId: user.accountId,
                          name: 'default',
                          html: defaultNamedInviteTemplate(),
                          subject: 'Invitation to participate'
                        })
                          .then(() => {
                            history.push('/');
                          }, (err) => {
                            showSystemError();
                          })
                      }, (err) => {
                        showSystemError();
                      })
                  }, (err) => {
                    showSystemError();
                  })

              }, (err) => {
                showSystemError();
              })
          } else {
            history.push('/');
          }
        } else {
          this.setState({badLogin: true})
        }
      }, (err) => {
        showSystemError();
      })
  }

  renderStyledField = (input, type, label, indicator) => {
    return (
      <div style={{marginBottom: '30px'}}>
        <div style={{marginBottom: '4px'}}>
          <label style={{
            fontSize: '14px',
            cursor: 'default'
          }}>{label}</label>
        </div>
        <div style={{width: '100%'}}>
          <div style={{display: 'inline-block', width: '95%'}}>
            <input {...input} type={type}
                   className="rc"
                   style={{
                     width: '100%'
                   }}/>
          </div>
          <div style={{float: 'right', paddingTop: '8px'}}>
            <div style={{margin: '0 auto'}}
                 className={indicator}>

            </div>
          </div>
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    let badLoginClasses = classnames("bad-login", {active: this.state.badLogin})
    return (
      <div className='no-navbar' style={{
        width: '100%',
        //background: 'rgb(33, 169, 134)',
        background: 'cadetblue'
      }}>
        <div>        {/* <img src="TellMeThisLogoWhiteTransparent.png"
             alt=""
             style={{height: '42px', padding: '8px', cursor: 'pointer'}}/> */}
          <img src="TMTLogoWhiteBalloonsWithTagLine.svg"
               className="TMTLogoWhiteBallonsWithTagLine"
               alt=""
            //style={{height: '70px', paddingLeft: '8px', marginTop: '-5px', cursor: 'pointer'}}
          />
          <div className="big-menu-link"
               style={{float: 'right', border: 'thin solid white', borderRadius: '5px'}}>
            <a href="/"
               className="menuLink"
               style={{color: 'white', fontSize: '20px', fontFamily: 'arial'}}>Home</a>
          </div>
        </div>
        <h1 style={{textAlign: 'center', color: '#EEEEEE'}}>Login</h1>
        <div style={{marginTop: '40px'}}
             onClick={() => {
               this.setState({badLogin: false})
             }}>
          <Form
            onSubmit={this.onSubmit}
            validateOnBlur={false}
            initialValues={
              {
                email: '',
                password: '',
                company: '',
                confirmPassword: ''
              }
            }
            render={({handleSubmit, form, submitting, pristine, values}) => {
              return (
                <form
                  className="login-form-panel"
                  onSubmit={handleSubmit}>
                  <Field name="email" validate={this.validEmail}>
                    {({input, meta}) => {
                      let indicatorClassEmail = '';
                      if (meta.visited) {
                        indicatorClassEmail = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen'
                      }

                      return (
                        this.renderStyledField(input, 'text', 'Email Address:', indicatorClassEmail)
                      )
                    }}
                  </Field>
                  <Field name="password" validate={this.lengthLTSix}>
                    {({input, meta}) => {
                      let indicatorClassPassword = '';
                      if (meta.visited) {
                        indicatorClassPassword = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen';
                      }
                      return (
                        this.renderStyledField(input, 'password',
                          'Password:', indicatorClassPassword)
                      )
                    }}
                  </Field>
                  <div
                    className={badLoginClasses}>
                    Incorrect email and/or password. Please try again.
                  </div>
                  <div>
                    <button className="blueButton"
                            style={{
                              float: 'right',
                              marginRight: '16px',
                              marginTop: '10px'
                            }}
                            type="submit"
                            disabled={submitting || !this.allValid()}>
                      Login
                    </button>
                  </div>
                </form>
              )
            }}
          ></Form>
          <div style={{textAlign: 'center', color: 'white', marginTop: '15px'}}>Don't have
            an account yet? &nbsp;
            <a style={{color: 'white'}} href="/signup">Signup</a></div>
          <div style={{textAlign: 'center', color: 'white', marginTop: '15px'}}>
            <a style={{color: 'white'}} href="/password-reset">Forgot password?</a>

          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    socket: state.socket,
    user: state.user

  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginUser: loginUser,
    get_Invite_Templates,
    save_Invite_Template,
    get_Named_Invite_Templates,
    save_Named_Invite_Template,
    setSocket,
    socketDispatch,
    logoutUser
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
