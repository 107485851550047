import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {set_Track_Table} from '../../actions/Track/set_Track_Table';
import {sendReminderEmail} from '../../actions/Track/sendReminderEmail';
import TrackRow from './TrackRow';
import TrackFooter from './TrackFooter';
import showSystemError from '../Common/showSystemError';
import trackTableSort from '../Common/trackTableSort';
import {toast} from 'react-toastify';
import showToastr from '../Common/showToastr';
import participantReminder from '../email_templates/participationReminder';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';


class TrackTable extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('trackTable');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('trackTable')
    }
    else {
      // this.state = {
      //   sortCriteria: null
      // }
    }

  }

  componentDidMount() {
    const {track_Table} = this.props;
    let sortCriteria = track_Table.sortCriteria || 'colBatch';
    this._isMounted = true;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (sortCriteria) {
      let artificialEvent = {
        currentTarget: {
          id: sortCriteria
        }
      }
      this.sortTrackTable(artificialEvent)
    }
  }

  handleResize = () => {
    $('.trackRowsContainer').height(window.innerHeight - $('.testElementsTable').height() - 230);
    $('.graphOuterContainer').height(window.innerHeight - 170);
    $('.graphOuterContainer').css('top', -$('#rowsContainer').height());
  }

  sortTrackTable = (e) => {
    const {track_Table, set_Track_Table} = this.props;
    let id = e.currentTarget.id;
    let rows = trackTableSort(track_Table.rows, id)
    let table = {...track_Table}
    table.rows = rows;
    table.sortCriteria = id;
    set_Track_Table(table);
  }

  tableInfoHTML = () => {
    //drubin
    return (
      '<div class="sortTrackTable">Sort this table by clicking on any of the column headings.</div>')
  }

  errorHTML = () => {
    // const {row} = this.props;
    return <div>this is an error</div>;
  }

  trackTableHeader = () => {
    return (
      <div>
        <div
          // style={{position: 'absolute', top: '174px', left: '40px', cursor: 'pointer'}}>
          style={{position: 'absolute', top: '174px', left: '22px', cursor: 'pointer'}}>
          <ReactTooltip place="right" border={true} type="light" effect="float"
                        id="sorting-info"
          />
          <i id="sorting-info"
             className="fa fa-info-circle"
             data-tip={this.tableInfoHTML()}
             data-for="sorting-info"
             data-html={true} //this is okay on Firefox
          ></i></div>
        {/*<ReactTooltip place="right" border={true} type="light" effect="float"*/}
                      {/*id="date-completed" getContent={this.errorHTML}/>*/}

        <table className="testElementsTable"
               style={{
                 width: '100%',
                 borderTop: 'solid thin gray',
                 borderTopLeftRadius: '5px',
                 borderTopRightRadius: '5px'
               }}>
          <tbody>
          <tr>
            <th className="trackColumnHeadings" onClick={this.sortTrackTable}
                id="colBatch">Batch
            </th>
            <th className="trackColumnHeadings" onClick={this.sortTrackTable}
                id="colName">Name
            </th>
            <th className="trackColumnHeadings" onClick={this.sortTrackTable}
                id="colEmail">Email
            </th>
            <th className="trackColumnHeadings" onClick={this.sortTrackTable}
                id="colSurveyNum"> Survey number
            </th>
            <th className="trackColumnHeadings" onClick={this.sortTrackTable}
                id="colAssigned">Date assigned
            </th>
            <th className="trackColumnHeadings" onClick={this.sortTrackTable}
                id="colStarted">Date started
            </th>
            <th className="trackColumnHeadings" onClick={this.sortTrackTable}
                id="colCompleted">Date completed
            </th>
            <th className="trackColumnHeadings"></th>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }

  handleSendReminder = (row) => {
    const {sendReminderEmail, user, env} = this.props;
    var mode = "CP";
    let urlQueryString = row.TIN + "&testName=" + row.testName + "&accountId=" + row.accountId + "&mode=" + mode;
    let hasTried = !!row.dateStarted && !row.dateCompleted
    //let html = participantReminder(row.testTaker, user.email, hasTried, row.TIN, `${env.TAKER_HOST}/?TIN=${urlQueryString}`)
    let html = participantReminder(row.testTaker, user.email, hasTried, row.TIN, `${window.TAKER_HOST}/?TIN=${urlQueryString}`)
    sendReminderEmail(html, row.email, 'Reminder to try our audio survey', row._id)
      .then(() => {
          console.log('email successfully sent');
          showToastr('fa fa-exclamation-circle', 'Email sent.', {type: toast.TYPE.INFO})
        },
        (err) => {
          showSystemError()
        })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this._isMounted = false;
    if (!localStorage.getItem('track_reset')) {
      localStorage.setObject('trackTable', this.state);
    }
    else {
      localStorage.removeItem('track_reset'); // last component to unmount so remove
    }
  }


  render() {
    const {track_Table, history} = this.props;
    let rows = track_Table.rows;
    return (
      <div id="rowsContainer">
        {this.trackTableHeader()}

        <div className="trackRowsContainer">
          <table className="trackTableContainer">
            <tbody>
            {rows.map((row) => {
              return <TrackRow
                row={row}
                testName={track_Table.testName}
                handleSendReminder={this.handleSendReminder}
                key={row._id}/>
            })}
            </tbody>
          </table>
          <div style={{
            maxHeight: '500px',
            overflowY: 'auto',
            position: 'relative',
            float: 'right',
            width: '1%'
          }}></div>
        </div>
        <TrackFooter
          history={history}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    track_Table: state.track_Table,
    env: state.env
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    set_Track_Table,
    sendReminderEmail
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackTable);