import React from 'react';
import $ from 'jquery';
import Overview from './Docs/Overview';
import CreateSurvey from './Docs/CreateSurvey';
import AssigningRespondents from './Docs/AssigningRespondents';
import ReviewSurveys from './Docs/ReviewSurveys';
import TrackingRespondents from './Docs/TrackingRespondents';
import Surveys from './Docs/Surveys';
import Staff from './Docs/Staff';


class HelpContent extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    // setTimeout(function(){$('.subMenuMainPanel').height(window.innerHeight - 8).width(window.innerWidth - 200);},1)
    $('.subMenuMainPanel').height(window.innerHeight - 8).width(window.innerWidth - 200);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const {subPath, user} = this.props;
    console.log('subpath: ', subPath)
    if (!subPath) {
      return null;
    }
    switch (subPath) {
      case 'overview':
        return (
          <div className="subMenuMainPanel">
            <Overview user={user}/>
          </div>)
      case 'surveys':
        return (
          <div className="subMenuMainPanel">
            <Surveys user={user}/>
          </div>)
      case 'staff':
        return (
          <div className="subMenuMainPanel">
            <Staff user={user}/>
          </div>)
      case 'create':
        return (
          <div className="subMenuMainPanel">
            <CreateSurvey user={user}/>
          </div>)
      case 'assign':
        return (
          <div className="subMenuMainPanel">
            <AssigningRespondents user={user}/>
          </div>)
      case 'review':
        return (
          <div className="subMenuMainPanel">
            <ReviewSurveys user={user}/>
          </div>)
      case 'track':
        return (
          <div className="subMenuMainPanel">
            <TrackingRespondents user={user}/>
          </div>)
      default:
        return null;
    }
  }
}

export default HelpContent;