import {CLEAR_TRANSCRIPT} from '../_CONSTANTS';

export const clearTranscript = () => {
  return {
    type: CLEAR_TRANSCRIPT,
    payload: {
      dialog: []
    }
  }
}
