import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setActiveModule} from '../../actions/setActiveModule';
import {get_Assign_Table} from '../../actions/Assign/get_Assign_Table';
import {del_Assign_Table_Row} from '../../actions/Assign/del_Assign_Table_Row';
import {save_Assign_Table} from '../../actions/Assign/save_Assign_Table';
import {clear_Assign_Table} from '../../actions/Assign/clear_Assign_Table';
import {setModalInfo} from '../../actions/setModalInfo';
import {get_Named_Assignments} from '../../actions/Assign/get_Named_Assignments';
import {sendNamedInviteEmail} from '../../actions/Assign/sendNamedInviteEmail';
import classnames from 'classnames';
import AssignSelector from './AssignSelector';
import AssignTable from './AssignTable';
import DeleteRowModalController from './ModalDeleteRow/DeleteRowModalController'
import CsvModalController from './ModalCsv/CsvModalController';
import showSystemError from '../Common/showSystemError';
import Navbar from '../Navbar';
import {save_Named_Invite_Template} from '../../actions/Assign/save_Named_Invite_Template';
import _ from 'lodash';


class Assign extends Component {
  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('assign');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('assign');
    }
    else {
      this.state = {
        visible: false,
        showBody: false,
        deleteDirty: false,
        batch: null,
        nbatches: ''
      }
    }
  }


  componentDidMount() {
    const {setActiveModule} = this.props;
    setActiveModule("Assign");
    this.setState({
      visible: true,
    });
  }

  setNumBatches = (nbatches) => {
    this.setState({nbatches})
  }

  /*
   getAssignmentRequest gets passed into AssignSelector where it is used whenever the
   REQUEST button is pressed, or a csv file is uploaded via the upload button. It's
    purpose is to retrieve NEW assignments from the TIN bank.
   It can be called with several different contexts.
   1. When the very first request is made for assignments on a given test. In this
   case the Batch selector is not visible since there are no batches to request.
   2. When there are existing batches but the user is leaving the "New" option
   selected, indicating he wants to start a new batch.
   3. When an existing assignment sheet is already in place (ie there is a batch and
    assignments already attached to that batch, but the user wants to add additional
     assignments either through the REQUEST button or by uploading a CSV file.
   */
  getAssignmentRequest = (testName, instrumentType, nrows, csvData, numberOfQuestions, wantsEmail, assessmentEmailTitle) => {
    const {get_Assign_Table, save_Assign_Table, user} = this.props;
    get_Assign_Table(
      testName,
      instrumentType,
      user.accountId,
      nrows,
      csvData,
      this.state.nbatches,
      this.state.batch,
      numberOfQuestions,
      wantsEmail,
      assessmentEmailTitle
    )
      .then((result) => {
        this.setState({batch: result.batch});
        this.setState({showBody: true})
        save_Assign_Table()
          .then((result) => {
            console.log(result)
          }, () => {
            showSystemError();
          })
      }, () => {
        showSystemError();
      })
  }

  /*
  getExistingBatch gets passed into assignSelector where it is used when the user
   enters the module, selects a test, and then selects an existing batch from the
    batch dropdown. It's purpose - unlike getAssignmentRequest - is to gather up and
     return EXISTING assignments for an EXISTING batch and populate the assignment
      sheet with them. The user can then modify existing info, send users invites
       and/or add additional new assignments to the batch.
   */

  getExistingBatch = (batch, testName) => {
    const {get_Named_Assignments, save_Assign_Table, user} = this.props;
    get_Named_Assignments(batch, testName, user.accountId)
      .then((result) => {
        this.setState({batch: result.batch});
        this.setState({showBody: true})
        save_Assign_Table()
          .then((result) => {
            console.log(result)
          }, () => {
            showSystemError();
          })
      }, () => {
        showSystemError();
      })

  }

  openModal = (data) => {
    const {setModalInfo} = this.props;
    setModalInfo(data);
  }

  cancelHandler = () => {
    const {modalInfo} = this.props;
    switch (modalInfo.name) {
      case "assignDeleteRow":
        console.log('This delete has NOT been executed.')
        break;
      default:
        console.log("cancel handler fallthrough - shouldn't get here")
    }
  }

  confirmHandler = () => {
    const {modalInfo, del_Assign_Table_Row, save_Assign_Table} = this.props;
    switch (modalInfo.name) {
      case "assignDeleteRow":
        this.setState({deleteDirty: true})
        del_Assign_Table_Row(modalInfo._id)
        save_Assign_Table()
          .then(() => {
            this.setState({deleteDirty: false})
            console.log('Assign Table Saved: ', new Date());
          }, () => {
            showSystemError();
          })
        break;
      default:
        console.log("confirm handler fallthrough - shouldn't get here")
    }
  }

  openEditInvite = (rows) => {
    const {setModalInfo, save_Named_Invite_Template, namedInviteTemplates, sendNamedInviteEmail, assign_Table, env} = this.props;
    let activeTemplate = namedInviteTemplates.find((template) => {
      return template.name === 'default';
    })
    if (!rows.length) {
      //we are doing inviteAll. We use a null array to indicate
      // because calling function does not know the number or status of rows. It just
      // knows that there is at least one available invitee.

      rows = assign_Table.rows.filter((row) => {
        return !row.inviteSent && row.email && !row.badEmail && row.participantName && (row.status !== 'completed');
      })

    }
    setModalInfo({
      name: 'namedInviteEmail',
      rows: rows,
      testName: assign_Table.testName,
      inviteTemplates: namedInviteTemplates,
      activeTemplate: activeTemplate,
      saveTemplate: save_Named_Invite_Template,
      sendNamedInviteEmail: sendNamedInviteEmail,
      TAKER_HOST: env.TAKER_HOST
    });
  }

  componentWillUnmount() {
    if (!localStorage.getItem('assign_reset')) {
      localStorage.setObject('assign', this.state);
    }
  }


  render() {
    let {history, clear_Assign_Table, user} = this.props;
    let mainClasses = classnames('main', {mainVisible: this.state.visible})
    const {setModalInfo, modalInfo, save_Named_Invite_Template, inviteTemplates} = this.props;
    if (_.isEmpty(user)) {
      history.push('/')
      return null

    }
    return (
      <span>
      <Navbar
        history={history}
      />
      <div className={mainClasses}>
        <AssignSelector
          batch={this.state.batch}
          getAssignmentRequest={this.getAssignmentRequest}
          getExistingBatch={this.getExistingBatch}
          clear_Assign_Table={clear_Assign_Table}
          openModal={this.openModal}
          setNumBatches={this.setNumBatches}
          user={user}
        />
        {this.state.showBody ?
          <div>
            <AssignTable
              setBatchName={this.setBatchName}
              openEditInvite={this.openEditInvite}
              openModal={this.openModal}
              deleteDirty={this.state.deleteDirty}
              history={history}
              setModalInfo={setModalInfo}
              save_Named_Invite_Template={save_Named_Invite_Template}
              inviteTemplates={inviteTemplates}
            />
          </div>
          : null }
        <DeleteRowModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.confirmHandler}
          cancelHandler={this.cancelHandler}
        />
        <CsvModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          cancelHandler={this.cancelHandler}
        />
      </div>
    </span>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    assign_Table: state.assign_Table,
    modalInfo: state.modalInfo,
    inviteTemplates: state.inviteTemplates,
    user: state.user,
    namedInviteTemplates: state.namedInviteTemplates,
    env: state.env
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setActiveModule,
    get_Assign_Table,
    del_Assign_Table_Row,
    save_Assign_Table,
    clear_Assign_Table,
    get_Named_Assignments,
    setModalInfo,
    save_Named_Invite_Template,
    sendNamedInviteEmail
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Assign);