import React from 'react';
import ModalFrame from '../../ModalFrame';
import DeployedTest from './DeployedTest';

class DeployedTestModalController extends React.Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    const {setModalInfo} = this.props;
    setModalInfo({});
  }

  render() {
    const {modalInfo, confirmHandler, cancelHandler} = this.props;
    return (
      <div className="example-modal">
        {modalInfo.name === 'deployedTest' ?
          <ModalFrame
            title="THIS SURVEY CANNOT BE EDITED"
            desktopWidth="60%"
            headerCloseButtonHandler={this.hideModal}
            hasHeader={true}>
            <DeployedTest
              modalInfo={modalInfo}
              hideModal={this.hideModal}
              confirmHandler={confirmHandler}
              cancelHandler={cancelHandler}
            />
          </ModalFrame> : null}
      </div>
    )
  }
}

export default DeployedTestModalController;
