import React from 'react';
import ContentEditable from 'react-contenteditable';
import ReactTooltip from 'react-tooltip';
import slicedTin from '../../Common/slicedTin';
import $ from 'jquery';
import {DropdownList}  from 'react-widgets'
import surveyInstructions from '../../email_templates/surveyInstructions';
import showSystemError from '../../Common/showSystemError';


class EmailInvite extends React.Component {
  $hiddenDiv = null;
  instructionsHTML = '';
  urlQueryString = '';

  constructor(props) {
    super(props);
    const {modalInfo} = props;
    //const TAKER_HOST = modalInfo.TAKER_HOST;
    const TAKER_HOST = window.TAKER_HOST;
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      testName : modalInfo.testName,
      templates: modalInfo.inviteTemplates,
      activeTemplate: modalInfo.activeTemplate,
      accountId: modalInfo.activeTemplate.accountId,
      html: modalInfo.activeTemplate.html,
      TIN: modalInfo.TIN,
      documentDirty: false,
      templateName: '',
      errorText: '',
      phoneResponses: false,
      computerResponses: true
    }


    $('#root').append('<div id="hiddenDiv"></div>');
    var docWidth = window.innerWidth;
    var responseMode = "CP";
    if (!this.state.computerResponses){responseMode = "P"}
    if (!this.state.phoneResponses){responseMode = "C"}
    if (!this.state.phoneResponses && !this.state.computerResponses){responseMode = ""}
    this.$hiddenDiv = $('#hiddenDiv');
    this.$hiddenDiv.css({
      position: 'relative',
      display: 'inline-block',
      color: 'black',
      left: (docWidth * 3) + 'px'
    }).html(this.state.html);

    this.urlQueryString = modalInfo.TIN +
                          "&testName="  + modalInfo.testName +
                          "&accountId=" + modalInfo.activeTemplate.accountId +
                          "&mode="      + responseMode;
    this.instructionsHTML = surveyInstructions(`${TAKER_HOST}/?TIN=${this.urlQueryString}`,
                                               `${slicedTin(modalInfo.TIN)}`, responseMode);
    console.log(this.instructionsHTML);
  }


  updateHiddenDiv = (TAKER_HOST) => { // resets message when users click phone and
                                      // computer
                             // checkboxes

    var responseMode = "CP";
    if (!this.state.computerResponses){responseMode = "P"}
    if (!this.state.phoneResponses){responseMode = "C"}
    if (!this.state.phoneResponses && !this.state.computerResponses){responseMode = ""}
    this.$hiddenDiv = $('#hiddenDiv');

    this.urlQueryString = this.state.TIN +
                          "&testName="  + this.state.testName +
                          "&accountId=" + this.state.accountId +
                          "&mode="      + responseMode;
    this.instructionsHTML = surveyInstructions(`${TAKER_HOST}/?TIN=${this.urlQueryString}`,
                                               `${slicedTin(this.state.TIN)}`, responseMode);
    console.log(this.instructionsHTML);
  }

  handleCancel(e) {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }


  copyTemplate = (e) => {
    //can't use the simpler copy which works with an input field
    //but doesn't properly select all the contenteditable contents
    /* *** select all html contents of template *** */
    e.preventDefault();
    this.$hiddenDiv.append(this.instructionsHTML);
    var id = "hiddenDiv";
    var el = document.getElementById(id);
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    /*    copy selection to the clipboard     */
    document.execCommand('copy');
    $('#copyIcon').css('color', '#555555');
    /*  ***    release the selection      *** */
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }
    this.$hiddenDiv.html($('#email-template').html());
  }


  handleSaveTemplate = () => {
    const {modalInfo} = this.props;
    let saveTemplate = modalInfo.saveTemplate;
    let name = this.state.templateName;
    let templateNames = this.state.templates.map((t) => {
      return t.name;
    })
    if (name === "default") {
      this.setState({errorText: 'You cannot change the default template.'})
    }
    else if (templateNames.indexOf(name) !== -1 && name !== this.state.activeTemplate.name) {
      this.setState({errorText: "This template name is already in use."})
    }
    else if (!name) {
      this.setState({errorText: 'You must enter a template name.'})
    }
    else {
      let newTemplate = {...this.state.activeTemplate, ...{name: name}};
      newTemplate.html = this.state.html;
      saveTemplate(newTemplate)
        .then((template) => {
            this.setState({
              activeTemplate: template,
              templateName: name,
              documentDirty: false,
              templates: [...this.state.templates, ...[template]]
            })
          },
          (err) => {
            console.log(err);
            showSystemError();
          })
    }
  }

  handleEdit = (e) => {
    this.setState({
      documentDirty: true,
      html: e.currentTarget.innerHTML
    });
    this.$hiddenDiv.html(e.currentTarget.innerHTML)
    $('#copyIcon').css('color', 'transparent');
  }

  handleTemplateChange = (value) => {
    let name = value.name;
    this.setState({
      activeTemplate: value,
      templateName: name === 'default' ? '' : name,
      documentDirty: false,
      html: value.html,
    })
    this.$hiddenDiv.html(value.html)
    $('#copyIcon').css('color', 'transparent');
  }

  handleTemplateNameChange = (e) => {
    this.setState({templateName: e.target.value})
  }

  handleCheckBoxChange = (e) => {
    if(e.target.id === "phoneCheck"){
      if (this.state.phoneResponses){this.setState({phoneResponses:false})
        } else { this.setState({phoneResponses:true})}
    } else {
      if (this.state.computerResponses){
          this.setState({computerResponses:false})
        } else { this.setState({computerResponses:true})}
    }
  }

  renderInstructions = () => {
    const {modalInfo} = this.props;
    let phoneRef = "tel:" + window.TWILIO_NUMBER;
    return (
      <div className="invite-instructions"
           id="invite-instructions"
           data-for="invite-instructions"
           data-tip="">
        <div style={{fontStyle: 'italic'}}>Instructions:</div>
        {this.state.phoneResponses ?
        <div>
          <br/>
          <div
            style={{marginLeft: '20px', marginRight: '20px', fontStyle: 'italic'}}>
            To participate with your phone, dial:&nbsp;
            <a  style={{fontSize: '16px'}} href={phoneRef}>{window.TWILIO_NUMBER}</a>
            &nbsp; and key-in this survey number when
            prompted: <strong>{slicedTin(modalInfo.TIN)}.</strong>
          </div>
          <br></br>
          <div style={{marginLeft: '20px', marginRight: '20px', fontStyle: 'italic'}}>
            If you are using a cell phone it will help to jot the survey number down so
            you will have it available for key-in when you are prompted to enter it.
          </div>
        </div>
         :  null }
        <br/>
        {this.state.computerResponses ?
        <div>
          <div style={{marginLeft: '20px', marginRight: '20px', fontStyle: 'italic'}}>To
            participate with your computer click&nbsp;<a href='#' >take the survey now.</a><br></br>
            The computer you use must have speakers and a microphone, or a headset with
            a mic.<br></br>If you use a cell phone, wear earbuds or a headset for good audio quality.
          </div>
          <br/>
        </div>
        : null }
        <br/>
      </div>
    )
  }

  editInstructionsHTML = () => {
    return (
      <div  className='emailInviteKeyboardShortcuts' >
        <strong>KEYBOARD SHORTCUTS</strong><br></br><br></br>
        <table>
          <tbody>

          <tr>
            <td><strong>Windows</strong></td>
            <td><strong>&nbsp;&nbsp;Macintosh</strong></td>
            <td></td>
          </tr>

          <tr>
            <td>Ctrl+b</td>
            <td>Cmd+b</td>
            <td><strong>bold</strong></td>
          </tr>
          <tr>
            <td>Ctrl+i</td>
            <td>Cmd+i</td>
            <td><i>italic</i></td>
          </tr>
          <tr>
            <td>Ctrl+u</td>
            <td>Cmd+u</td>
            <td><u>underline</u></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Ctrl+z</td>
            <td>Cmd+z</td>
            <td>undo</td>
          </tr>
          <tr>
            <td>Ctrl+y</td>
            <td>Cmd+y</td>
            <td>redo</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Ctrl+x</td>
            <td>Cmd+x</td>
            <td>cut</td>
          </tr>
          <tr>
            <td>Ctrl+c</td>
            <td>Cmd+c</td>
            <td>copy</td>
          </tr>
          <tr>
            <td>Ctrl+v</td>
            <td>Cmd+v</td>
            <td>paste</td>
          </tr>

          </tbody>
        </table>
      </div>
    )
  }

  includeInstructionsTooltipHTML = () => {
    return (
      <div className="emailInviteInstructions" style={{fontSize: '15px', width: '250px', fontFamily: 'sans-serif'}}>
        <p>These instructions will be automatically added to your email when you click
          the copy button and then paste what's been copied into your email
          application.</p>
        <p>They contain the specific data to allow your user to connect and take your
          survey.</p>
      </div>
    )
  }

  render() {
    const {modalInfo} = this.props;
    //const TAKER_HOST = modalInfo.TAKER_HOST;
    const TAKER_HOST = window.TAKER_HOST;
    this.updateHiddenDiv(TAKER_HOST); //needs to be updated before render
    return (
      <div>
        <div className="emailModal-body"
        style={{border: 'solid thin transparent'}}>
{/*         <table style={{fontFamily:'sans-serif',fontSize:'14px'}}><tbody><tr><td>&nbsp;Request responses by:</td>
          <td><input type="checkbox" id="compCheck"  name="compCheck" onChange= {this.handleCheckBoxChange} value="c" checked={this.state.computerResponses}></input>&nbsp;computer</td>
          <td><input type="checkbox" id="phoneCheck" name="phoneCheck" onChange= {this.handleCheckBoxChange} value="p" checked={this.state.phoneResponses}></input>&nbsp;phone</td>
        </tr></tbody></table> */}
          <div className="email-invite-subheader"
               style={{paddingBottom:'14px', border: 'solid thin transparent', width:'100%'}}>
            Modify this email template to invite survey participants.
            Then click the [copy] button, paste the contents into your preferred email
            application, and
            send it to the group you are considering for this survey.
            You can also name and save your edited version of the template for future use.
            <div className="template-dropdown">
              <DropdownList
                style={{
                  width: '200px',
                  marginLeft: '15px',
                  position: 'relative',
                  float: 'right'
                }}
                data={this.state.templates}
                valueField="name"
                textField="name"
                defaultValue={this.state.activeTemplate}
                value={this.state.activeTemplate}
                onChange={this.handleTemplateChange}
              />
              <div id="infoLocator"
                   style={{
                     position: 'relative',
                     paddingRight: '10px',
                     float: 'right',
                     width: '198px',
                     height: '100%',
                     textAlign: 'right',
                     border: 'solid thin transparent'
                   }}>
                <div style={{position: 'relative', top: '100%'}}>
                  <ReactTooltip id="edit-instructions"
                                place="left"
                                type="light"
                                border={true}
                                getContent={this.editInstructionsHTML}/>
                  <i id="edit-instructions"
                     className="fa fa-info-circle"
                     style={{
                       position: 'relative'
                     }}
                     data-for="edit-instructions"
                     data-border={false}
                     data-tip=""
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div className="editable-template-container"
               style={{marginTop: '20px', marginBottom: '10px'}}>
            <ContentEditable
              id="email-template"
              className="editable-template"
              html={this.state.html}
              onChange={this.handleEdit}
            />
            <div>
              <ReactTooltip id="invite-instructions"
                            place="top"
                            type="light"
                            border={true}
                            getContent={this.includeInstructionsTooltipHTML}
              />
              {this.renderInstructions()}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <table style={{width: '100%'}}>
            <tbody>
            <tr>
              <td style={{width: '300px'}}>
                <div className="">
                  <input
                    className="textInputWidgetMatch"
                    id="testNameInput"
                    type="text"
                    name="TemplateName"
                    onFocus={() => {
                      this.setState({errorText: ''})
                    }}
                    value={this.state.templateName}
                    onChange={this.handleTemplateNameChange}
                    // placeholder="Enter a template name"
                    style={{
                      position: 'relative',
                      float: 'left',
                      verticalAlign: 'bottom',
                      fontSize: '14px',
                      width: '200px',
                      height: '24px',
                    }}>
                  </input>
                  <button className="orangeButton"
                          onClick={this.handleSaveTemplate}
                          disabled={!this.state.documentDirty}
                          style={{
                            marginLeft: '5px',
                            position: 'relative',
                            float: 'left',
                            marginTop: '4px'
                          }}>SAVE
                  </button>
                </div>
              </td>
              <td id="error-div"
                  style={{textAlign: 'left', color: 'red'}}>{this.state.errorText}</td>
              <td>
                <div className="btn-group pull-right" style={{margin: '5px 0'}}>

                  <button className="blueButton pull-right"
                          style={{marginRight: '20px'}}
                          onClick={this.handleCancel}>
                    Cancel
                  </button>
                  <i className="far fa-copy"
                     id="copyIcon"
                     style={{
                       paddingRight: '10px',
                       fontSize: '24px',
                       color: 'transparent'
                     }}></i>
                  <button className="orangeButton pull-right"
                          onClick={this.copyTemplate}
                          disabled = { !this.state.phoneResponses && !this.state.computerResponses ? true : false }
                          style={{marginRight: '5px'}}>COPY
                  </button>

                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default EmailInvite;