import React from 'react';
import ModalFrame from '../../ModalFrame';
import EmailInvite from './EmailInvite';
import $ from 'jquery';

class EmailInviteController extends React.Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.modalWidth = $(".example-modal").width();

  }


  hideModal() {
    const {setModalInfo} = this.props;
    setModalInfo({});
  }

  render() {
    const {modalInfo, confirmHandler, cancelHandler} = this.props;
    return (
      <div className="example-modal">
        {modalInfo.name === 'anonInviteEmail' ?
          <ModalFrame
            title="CREATE AND SAVE EMAIL INVITATIONS"
            desktopWidth="60%"
            headerCloseButtonHandler={this.hideModal}
            hasHeader={true}>

            <EmailInvite
              modalInfo={modalInfo}
              hideModal={this.hideModal}
              confirmHandler={confirmHandler}
              cancelHandler={cancelHandler}
            />
          </ModalFrame> : null}
      </div>
    )
  }
}

export default EmailInviteController;