import _ from 'lodash';

const trackTableSort = (table, criteria) => {
  let rows;
  if(!criteria) {
    criteria = 'colBatch';
  }
  switch (criteria) {
    case 'colBatch':
      rows = _.sortBy(table, ['batch', 'dateCompleted', 'dateStarted', 'dateAssigned', 'TIN']);
      break;
    case 'colName':
      rows = _.sortBy(table, [(row) => {
        return row.testTaker.toLowerCase()
      }, 'dateCompleted']);
      break;
    case 'colEmail':
      rows = _.sortBy(table, [(row) => {
        return row.email.toLowerCase()
      }, 'dateCompleted']);
      break;
    case 'colSurveyNum':
      rows = _.sortBy(table, ['TIN']);
      break;
    case 'colAssigned':
      rows = _.sortBy(table, ['dateAssigned', 'testTaker']);
      break;
    case 'colStarted':
      rows = _.sortBy(table, ['dateStarted', 'testTaker']);
      break;
    case 'colCompleted':
      rows = _.sortBy(table, ['dateCompleted', 'testTaker']);
      break;
    default:
      break;
  }
  return rows;
}


export default trackTableSort;
