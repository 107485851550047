import {UNLOAD_TRACK} from './_CONSTANTS';

export const unload = (module) => {
  switch(module) {
    case 'track':
      return {
        type: UNLOAD_TRACK,
        payload: null
      }
    default:
      break;
  }
}
