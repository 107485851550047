//import React from 'react';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import autoTable from 'jspdf-autotable'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import base64Logo from './base64Logo';
import $ from 'jquery';
//import * as d3 from 'd3';

Moment.locale('en');
momentLocalizer();

function pdfDownload  (targetClicked)  {

  let idNumber = targetClicked.split('-')[2];
 // let graphCount = $('.analysisGraph').length;
  let chartType = "";
  let chartId = "";

  function getGraphId($thisGraph) {
    let thisId = $thisGraph.children().first().children().first().children().first().attr('id');
    if (thisId !== undefined) { return thisId }

    thisId = $thisGraph.children().first().children().first().children().first().next().attr('id');
    if (thisId !== undefined) { return thisId }

    thisId = $thisGraph.children().first().children().first().children().first().children().first().attr('id');
    if (thisId !== undefined) { return thisId }
  }

  $('.analysisGraph').each(function(){
      let graphId = getGraphId($(this));

      if (idNumber === graphId.split("-")[1]){
        chartType = graphId.split("-")[0];
        chartId = graphId;
      }
  });

    let $divForPDF = $('#' + chartId);
    let divForPDF = $('#' + chartId)[0];
    let $thisTitle = $('#input-container-' + idNumber);

    var showSelected = false;

    if ( $divForPDF.find($('#showButton-' + idNumber)).text() === "show selected") {
      showSelected = true
    }

    let title = $thisTitle.val();
    if (title.length === 0) {title = "title"}

    const today = Moment();
    const mmPageWidth = 279; //  these constants used for centering text
    const ptsToMm = 72 / 25.6;

    /* ************************** Search Results Chart ********************* */
    if (chartType === "search") {

        var searchTerms = $divForPDF.find($('#searchDiv-' + idNumber)).text();
        var pageCount = 0;
        const doc = new jspdf({
          orientation: 'l',
          unit: 'mm',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16
        });

        function replaceEvery(inputString, target, replacement){ //replaceAll not yet on all browsers
          let altString = "";
          altString = inputString.split(target).join(replacement);
          return altString;
        }

        function getCenteringPos(thisText, thisSize) { // support for centering text
          let stringWidth = doc.getStringUnitWidth(thisText) * thisSize / ptsToMm;
          return (mmPageWidth / 2 ) - (stringWidth / 2);
        }

        function setSearchTerms(searchTerms, thisSize) {
          doc.setFontSize(thisSize);
          let maxWidth = 50;
          let currentWidth = 0;
          let thisX = 20;
          let thisY = 50;
          if (pageCount > 0) {
            thisY = 40
          }
          let concat = "";
          let termsArray = searchTerms.split(" ");
          for (var i = 0; i < termsArray.length; i++) {
            currentWidth = doc.getStringUnitWidth(concat) * thisSize / ptsToMm;
            if (currentWidth < maxWidth) {
              concat = concat + termsArray[i] + " ";
            }
            else {
              doc.text(thisX, thisY, concat);
              concat = "";
             thisY = thisY + 4.5;
            }
          }
          doc.text(thisX, thisY, concat); //any concatenated portion not reaching maxWidth
        }

        doc.setFont("times");
        doc.setFontType("normal");
        doc.setFontSize(18);
        let titleLeft = getCenteringPos(title, 16);
        doc.text(titleLeft, 23, title);

        let drawCell = function (data) {
          var doc = data.doc;

          // if text in col 2 is highlighted, turn background of col 1 yellow
          if (data.column.index === 0) {
            if (showSelected === true) {
              if (data.row.cells[1].raw.includes('[[')) {
                doc.setFillColor(255, 255, 0);
              }
            }
          }
      };

      // create column arrays for each table from displayed chart
        var colNumb = $divForPDF.find($('#ResponseTable-' + idNumber).find('.responseRowNumb'));
        var responseText = $divForPDF.find($('#ResponseTable-' + idNumber).find('.responseColLeft'));
        var numbs = [];
        var texts = [];
        for (var i = 0; i < colNumb.length; i++) {
          numbs.push(colNumb.eq(i).text());
          texts.push(responseText.eq(i).html());
        }

        for (i = 0; i < texts.length; i++) {
          texts[i] = replaceEvery(texts[i],'<mark data-markjs="true">', '[[');
          texts[i] = replaceEvery(texts[i],'<mark data-markjs="true" style="background-color: yellow;">', '[[');
          texts[i] = replaceEvery(texts[i],'</mark>', ']]');
        }

      // combine columns data into single array of row objects as long as longest column
      // doesn't matter that there is no data for shorter columns
        var bodyArray = []
        let numberRows = colNumb.length;
        for (i = 0; i < numberRows; i++) {

        if (showSelected === true) {
          bodyArray.push({
            numb: numbs[i],
            text: texts[i],
          });
        } else {
          if (responseText.eq(i).html().toString().includes("</mark>")) {
            bodyArray.push({
              numb: numbs[i],
              text: texts[i],
            });
          }
        }
      }

      // use autoTable to create doc of chart for downloading
      console.log(typeof autoTable); //this keeps compiler from complaining that "autoTable is declared but never used."  It is used in the next line.
      doc.autoTable({
        startY: 35,
        tableWidth: 'wrap',
        margin: {top: 25, right: 16, bottom: 20, left: 100},
        columnStyles: {
          numb: {halign: 'center', cellWidth: 20},
          text: {halign: 'left', cellWidth: 150},
        },

        body: bodyArray,
        rowPageBreak: 'avoid',
        columns: [ //spaces below between \t and column name must be maintained for centering heading text!
          {header: '', dataKey: 'numb'},
          {header: '\t\t\t\t\t\t      RESPONSES', dataKey: 'text'},
        ],
        theme: 'striped',
        willDrawCell: drawCell, //drawCell function needed only to break solid color band of
                                //heading row
        didDrawPage: function (data) { //at end of each page add the footer
          var yOffset = 41;
          if (pageCount > 0) {
            yOffset = yOffset - 10
          }
          doc.setFont("Helvetica");
          doc.setFontType("normal");
          doc.setFontSize(12);
          doc.text(20, yOffset, "SEARCH TERMS");
          setSearchTerms(searchTerms, 10);
          pageCount = pageCount + 1;

          doc.setLineWidth(0.2);
          doc.setDrawColor(44, 107, 152);
          doc.setFontType("normal");
          doc.setFontSize(10);
          doc.text(20, 200, today.format('MM/DD/YYYY'));

          if (title === "title") {
            title = "Search Terms Chart.pdf"
          }
          else if (pageCount === 0) {
            title = title + ".pdf"
          }

          let footerLeft = getCenteringPos("filename: " + title, 10);
          doc.text(footerLeft, 200, "filename: " + title);
          doc.addImage(base64Logo, 'PNG', 245, 195, 31.5, 7);
        }
      });
      // now download the chart
      doc.save(title);
    }
    /* ************************************************************************** */


    /* ************************** Named Entity Chart ********************* */
    if (chartType === "entities") {

      const doc = new jspdf({
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true,
        floatPrecision: 16
      });

      function getCenteringPos(thisText, thisSize) { // support for centering text
        let stringWidth = doc.getStringUnitWidth(thisText) * thisSize / ptsToMm;
        return (mmPageWidth / 2 ) - (stringWidth / 2);
      }

      doc.setFont("times");
      doc.setFontType("normal");
      doc.setFontSize(18);
      let titleLeft = getCenteringPos(title, 16);
      doc.text(titleLeft, 23, title);

      let drawCell = function (data) {
        var doc = data.doc;
        if ((data.row.index === 0) && ((data.column.index === 2) || (data.column.index === 5))) {
          doc.setFillColor(255, 255, 255); //white spaces in header row
        }
      };

      //create column arrays for each table from displayed chart
      var personsA = $divForPDF.find($('#PersonsTable-' + idNumber)).find('.entitiesColLeft');
      var personsB = $divForPDF.find($('#PersonsTable-' + idNumber)).find('.entitiesColRight');
      var personsNames = [];
      var personsCounts = [];
      for (i = 0; i < personsA.length; i++) {
          personsNames.push(personsA.eq(i).text());
          personsCounts.push(personsB.eq(i).text());
        }

      var locationsA = $divForPDF.find($('#LocationsTable-' + idNumber)).find('.entitiesColLeft');
      var locationsB = $divForPDF.find($('#LocationsTable-' + idNumber)).find('.entitiesColRight');
      var locationsNames = [];
      var locationsCounts = [];
      for (i = 0; i < locationsA.length; i++) {
        locationsNames.push(locationsA.eq(i).text());
        locationsCounts.push(locationsB.eq(i).text());
      }

      var organizationsA = $divForPDF.find($('#OrganizationsTable-' + idNumber)).find('.entitiesColLeft');
      var organizationsB = $divForPDF.find($('#OrganizationsTable-' + idNumber)).find('.entitiesColRight');
      var organizationsNames = [];
      var organizationsCounts = [];
      for (i = 0; i < organizationsA.length; i++) {
        organizationsNames.push(organizationsA.eq(i).text());
        organizationsCounts.push(organizationsB.eq(i).text());
      }

      //combine columns data into single array of row objects as long as longest column
      //doesn't matter that there is no data for shorter columns
      var tableArray = []
      let numberRows = Math.max(personsA.length, locationsA.length, organizationsA.length);
      if (numberRows === 0) { //need placeholders to draw an empty chart
        numberRows = 1;
        personsNames[0] = "";
        personsCounts[0] = "";
        locationsNames[0] = "";
        locationsCounts[0] = "";
        organizationsNames[0] = "";
        organizationsCounts[0] = "";
      }
      for (i = 0; i < numberRows; i++) {
        tableArray.push({
          persons: personsNames[i],
          count0: personsCounts[i],
          spacer0: ' ',
          locations: locationsNames[i],
          count1: locationsCounts[i],
          spacer1: ' ',
          organizations: organizationsNames[i],
          count2: organizationsCounts[i]
        });
      }

      //use autoTable to create doc of chart for downloading
      doc.autoTable({
        startY: 35,
        tableWidth: 'wrap',
        margin: {top: 25, right: 16, bottom: 20, left: 16},
        columnStyles: {
          persons: {halign: 'left', cellWidth: 67},
          count0: {halign: 'left', cellWidth: 15},
          spacer0: {halign: 'left', cellWidth: 10, fillColor: [255, 255, 255]},
          locations: {halign: 'left', cellWidth: 67},
          count1: {halign: 'left', cellWidth: 15},
          spacer1: {halign: 'left', cellWidth: 10, fillColor: [255, 255, 255]},
          organizations: {halign: 'left', cellWidth: 67},
          count2: {halign: 'left', cellWidth: 15}
        },

        body: tableArray,

        columns: [ //spaces below between \t and column name must be maintained for centering heading text!
          {header: '\t\t\t      PERSONS', dataKey: 'persons'},
          {header: '', dataKey: 'count0'},
          {header: '', dataKey: 'spacer0'},
          {header: '\t\t\t  LOCATIONS', dataKey: 'locations'},
          {header: '', dataKey: 'count1'},
          {header: '', dataKey: 'spacer1'},
          {header: '\t\t      ORGANIZATIONS', dataKey: 'organizations'},
          {header: '', dataKey: 'count2'},
        ],
        theme: 'striped',
        willDrawCell: drawCell, //drawCell function needed only to break solid color band of
                                //heading row
        didDrawPage: function (data) { //at end of each page add the footer
          doc.setLineWidth(0.2);
          doc.setDrawColor(44, 107, 152);
          doc.setFontType("normal");
          doc.setFontSize(10);
          doc.text(20, 200, today.format('MM/DD/YYYY'));

          if (title === "title") {
            title = "Named Entities Chart.pdf"
          }

          let footerLeft = getCenteringPos("filename: " + title, 10);
          doc.text(footerLeft, 200, "filename: " + title);
          doc.addImage(base64Logo, 'PNG', 245, 195, 31.5, 7);
        }
      });
      // now download the chart
      doc.save(title);
    }
    /* ************************************************************************** */


    /* ************************** Cluster or SentimentGraph ********************* */

    else if ((chartType === 'bubbles') || (chartType === 'sentiment')){

      // ***************** save parent style  *********************** //
      var borderTop = $divForPDF.closest($('.analysisGraph')).css('border-top');
      $divForPDF.closest($('.analysisGraph')).css('border', "solid thin #CCCCCC"); //set borders just for printout
      //  ********** hide the zoom/slide controls  ******************* //

      let transform = $divForPDF.find($('svg')).first().children().last().attr('transform');//.prop("tagName");
      let tempTransform = "translate(930 574) scale(0.000001)";
      $divForPDF.find($('svg')).first().children().last().attr('transform', tempTransform);

      // ************** create image of graph area of web page ************************* //
      html2canvas(divForPDF).then(function (canvas) {
        const imgData = canvas.toDataURL('image/png');

        //  ********************** restore parent styles ******************* //
        $('.analysisGraph').css('border-bottom', 'none');
        $('.analysisGraph').css('border-left', 'none');
        $('.analysisGraph').css('border-right', 'none');
        $('.analysisGraph').css('border-top', borderTop);

        //  ********** restore the zoom/slide controls  ******************* //
        $divForPDF.find($('svg')).first().children().last().attr('transform', transform);
        // ************************************************************* //

        const pdf = new jspdf({
          orientation: 'l',
          unit: 'mm',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16
        });

        // now set up the pdf layout
        function getCenteringPos(thisText, thisSize) { // support for centering text
          let stringWidth = pdf.getStringUnitWidth(thisText) * thisSize / ptsToMm;
          return (mmPageWidth / 2 ) - (stringWidth / 2);
        }

        pdf.setFont("times");
        pdf.setFontType("normal");
        pdf.setFontSize(18);
        let titleLeft = getCenteringPos(title, 18);
        pdf.text(titleLeft, 36, title);

        pdf.addImage(imgData, 'PNG', 23, 45, 250, 140); //source, type, x-offset from left margin, y-offset from top margin, width, height
        pdf.setLineWidth(0.2);
        pdf.setDrawColor(44, 107, 152);
        pdf.setFontType("normal");
        pdf.setFontSize(10);
        pdf.text(20, 200, today.format('MM/DD/YYYY'));

        if (title === "title") {
          title = "AnalysisGraph.pdf"
        } else {
          title = title + ".pdf"
        }

        let footerLeft = getCenteringPos("filename: " + title, 10);
        pdf.text(footerLeft, 200, "filename: " + title);
        pdf.addImage(base64Logo, 'PNG', 245, 195, 31.5, 7);
        pdf.save(title);
      }).catch(function (error) {
        console.log("error: ", error)
      });
    }
}

export default pdfDownload;
