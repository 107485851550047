import {
  SOCKET_START_ASSIGNMENT,
  SOCKET_COMPLETE_ASSIGNMENT,
  SOCKET_UPDATE_ITEM_RESPONSE,
  SOCKET_ADD_ASSIGNMENTS,
  SOCKET_UPDATE_TRACK_TABLE,
  SOCKET_UPDATE_TRACK_TABLE_ROW,
  SOCKET_UPDATE_TRACK_TABLE_ROW_DELETE,
  SOCKET_LOCK_SURVEY,
  SOCKET_TEST_SAVED,
  SET_UPDATING_ASSESSMENTS
} from './_CONSTANTS';


export const socketDispatch = (data) => {
  return (dispatch) => {
    let msgType = data.type;
    switch (msgType) {
      case 'START_ASSIGNMENT':
        dispatch({
          type: SOCKET_START_ASSIGNMENT,
          payload: data
        })
        break;
      case 'COMPLETE_ASSIGNMENT':
        dispatch({
          type: SOCKET_COMPLETE_ASSIGNMENT,
          payload: data
        })
        break;
      case 'CREATE_ITEM_RESPONSE':
        break;
      case 'UPDATE_ITEM_RESPONSE':
        dispatch({
          type: SOCKET_UPDATE_ITEM_RESPONSE,
          payload: data
        })
        break;
      case "ADD_ASSIGNMENTS":
        data.result.type = "ADD_ASSIGNMENTS";
        console.log(data);
        dispatch({
          type: SOCKET_ADD_ASSIGNMENTS,
          payload: data.result.assignments
        })
        dispatch({
          type: SOCKET_UPDATE_TRACK_TABLE,
          payload: data.result
        })
        dispatch({
          type: SOCKET_LOCK_SURVEY,
          payload: data.result.testName
        })
        break;
      case "ADD_ANON_ASSIGNMENT":
        dispatch({
          type: SOCKET_LOCK_SURVEY,
          payload: data.result
        })
        break;
      case "EDIT_ASSIGNMENT":
        console.log(data);
        dispatch({
          type: SOCKET_UPDATE_TRACK_TABLE_ROW,
          payload: data.result
        })
        break;
      case "DELETE_ASSIGNMENT":
        dispatch({
          type: SOCKET_UPDATE_TRACK_TABLE_ROW_DELETE,
          payload: data.result
        });
        break;
      case "TEST_SAVED":
        dispatch({
          type: SOCKET_TEST_SAVED,
          payload: data.result
        });
        break;
      case "TEST_OPENED":
        break;
      case "TEST_CLOSED":
        break;
      case "UPDATE_ASSESSMENTS":
        dispatch({
          type: SET_UPDATING_ASSESSMENTS,
          payload: false
        })
        break;
      default:
        break;
    }
  }
}
