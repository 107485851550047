import {
  GET_SURVEYS,
  EDIT_SURVEY,
  CLONE_SURVEY,
  SOCKET_LOCK_SURVEY,
  SOCKET_TEST_SAVED
} from '../actions/_CONSTANTS';
import _ from 'lodash';

const surveys = (state = [], action) => {
  let newSurveys;
  switch (action.type) {
    case GET_SURVEYS:
      return action.payload;
    case EDIT_SURVEY:
      newSurveys = state.filter((survey) => {
        return survey._id !== action.payload._id;
      })
      newSurveys.push(action.payload);
      newSurveys = _.sortBy(newSurveys, ['createdAt']);
      _.reverse(newSurveys);
      return newSurveys;
    case CLONE_SURVEY:
      newSurveys = [...state];
      newSurveys.push(action.payload);
      return newSurveys;
    case SOCKET_LOCK_SURVEY:
      if (_.isEmpty(state)) {
        return state;
      }
      newSurveys = [...state];
      newSurveys.forEach((survey) => {
        if (survey.testName === action.payload) {
          survey.isLocked = true;
        }
      })
      return newSurveys;
    case SOCKET_TEST_SAVED:
      if (_.isEmpty(state)) {
        return state;
      }
      let isNewSurvey = true;
      state.forEach((survey)=>{
        if(survey.testName === action.payload.testName) {
          isNewSurvey = false;
        }
      })
      if(!isNewSurvey) {return state}

      newSurveys = [...state];
      newSurveys.push(action.payload);
      newSurveys = _.sortBy(newSurveys, ['createdAt']);
      _.reverse(newSurveys);
      return newSurveys;
    default:
      return state;
  }
}

export default surveys;