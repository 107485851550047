import React from 'react'
import $ from 'jquery';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

/* support for downloading chart pdf */
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import base64Logo from './base64Logo';

Moment.locale('en');
momentLocalizer();
/* support for downloading chart pdf */


class TrackChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
      visible: false,
      vertical: true,
      stacked: true,
      gt: 'gt-0',
      title: '',
      chartData: []
    }
  }

  componentDidMount() {
    this.setState({visible: true})
    this.handleResize();
  }

  handleResize = () => {
    window.addEventListener("resize", this.handleResize);
    let $container = $('.graphInnerContainer');
    this.setState({
      height: $container.height() - 125,
      width: $container.width() - 30
    })

  }

  handleTitleChange = (e) => {
    this.setState({title: e.target.value});
  }

  horizontalStackedBarChart = () => {
    const {chartData} = this.props;
    return (
      <BarChart width={this.state.width} height={ this.state.height }
                data={ chartData }
                margin={{
                  top: 0, right: 10, left: 10, bottom: 5
                }}
                layout={ 'vertical' }>
        <CartesianGrid horizontal={false} strokeDasharray="3 3"/>
        <Legend verticalAlign="bottom" height={36}/>
        <Tooltip />
        <XAxis type={ 'number' }/>
        <YAxis type={ 'category' } dataKey={ 'name' }/>
        <Bar dataKey={ 'unstarted' } stackId="a" fill={ '#8884d8' }/>
        <Bar name="abandoned" dataKey={ 'started' } stackId="a" fill={ '#82ca9d' }/>
        <Bar dataKey={ 'completed' } stackId="a" fill={ '#639ea4' }/>
      </BarChart>
    )
  }

  horizontalUnstackedBarChart = () => {
    const {chartData} = this.props;
    return (
      <BarChart width={this.state.width} height={ this.state.height }
                data={ chartData }
                margin={{
                  top: 0, right: 10, left: 10, bottom: 5
                }}
                layout={ 'vertical' }>
        <CartesianGrid horizontal={false} strokeDasharray="3 3"/>
        <Legend verticalAlign="bottom" height={36}/>
        <Tooltip />
        <XAxis type={ 'number' }/>
        <YAxis type={ 'category' } dataKey={ 'name' }/>
        <Bar dataKey={ 'unstarted' } fill={ '#8884d8' }/>
        <Bar name="abandoned"  dataKey={ 'started' } fill={ '#82ca9d' }/>
        <Bar dataKey={ 'completed' } fill={ '#639ea4' }/>
      </BarChart>
    )
  }

  verticalStackedBarChart = () => {
    const {chartData} = this.props;
    return (
      <BarChart width={ this.state.width} height={ this.state.height }
                data={ chartData } margin={{
        top: 0, right: 10, left: 10, bottom: 5
      }}>
        <CartesianGrid vertical={false} strokeDasharray="3 3"/>
        <YAxis type={ 'number' }/>
        <XAxis type={ 'category' } dataKey={ 'name' } height={60}
               tick={this.customizedAxisTick}/>
        <Legend verticalAlign="bottom" height={36}/>
        <Tooltip />
        <Bar dataKey={ 'unstarted' } stackId='a' fill={ '#8884d8' }/>
        <Bar name="abandoned"  dataKey={ 'started' } stackId='a' fill={ '#82ca9d' }/>
        <Bar dataKey={ 'completed' } stackId='a' fill={ '#639ea4' }/>
      </BarChart>
    )
  }

  verticalUnstackedBarChart = () => {
    const {chartData} = this.props;
    return (
      <BarChart width={ this.state.width} height={ this.state.height }
                data={ chartData } barCategoryGap='15%' margin={{
        top: 0, right: 10, left: 10, bottom: 5
      }}>
        <CartesianGrid vertical={false} strokeDasharray="3 3"/>
        <YAxis type={ 'number' }/>
        <XAxis type={ 'category' } dataKey={ 'name' } height={60}
               tick={this.customizedAxisTick}/>
        <Legend verticalAlign="bottom" height={36}/>
        <Tooltip />
        <Bar dataKey={ 'unstarted' } fill={ '#8884d8' }/>
        <Bar name="abandoned"  dataKey={ 'started' } fill={ '#82ca9d' }/>
        <Bar dataKey={ 'completed' } fill={ '#639ea4' }/>
      </BarChart>
    )
  }


  //from: https://jsfiddle.net/alidingling/5br7g9d6/
  customizedAxisTick = ({x, y, stroke, payload}) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fontSize="12" textAnchor="end" fill="#666"
              transform="rotate(-35)">{payload.value}</text>
      </g>
    );
  }

  /* ******************************************************* */
  /*  everything for the downloading is in this handler */
  /* ******************************************************* */
  downloadHandler = (e) => {
    let title = $('#titleInput').val();
    if (title.length === 0) {
      title = "title"
    }

    const today = Moment();
    let divForPDF = $('.recharts-wrapper')[0];
    const mmPageWidth = 208; // these constants used for centering text
    const ptsToMm = 72 / 25.6;

    html2canvas(divForPDF).then(function (canvas) {
      const imgData = canvas.toDataURL('image/png');

      /* create new pdf object */
      const pdf = new jspdf({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true,
        floatPrecision: 16
      });

      /* now set up the pdf layout */

      function getCenteringPos(thisText, thisSize) { // support for centering text
        let stringWidth = pdf.getStringUnitWidth(thisText) * thisSize / ptsToMm;
        return (mmPageWidth / 2 ) - (stringWidth / 2);
      }

      pdf.setFont("times");
      pdf.setFontType("normal");
      pdf.setFontSize(18);
      let titleLeft = getCenteringPos(title, 18);
      pdf.text(titleLeft, 45, title);

      pdf.addImage(imgData, 'PNG', 37, 80, 120, 148); //source, type, x-offset from left margin, y-offset from top margin, width, height
      pdf.setLineWidth(0.2);
      pdf.setDrawColor(44, 107, 152);
      pdf.setFontType("normal");
      pdf.setFontSize(10);
      pdf.text(20, 286, today.format('MM/DD/YYYY'));

      if (title === "title") {
        title = "TrackGraph.pdf";
      } else {
        title = title + ".pdf";
      }

      let footerLeft = getCenteringPos("filename: " + title, 10);
      pdf.text(footerLeft, 286, "filename: " + title);
      pdf.addImage(base64Logo, 'PNG', 157, 280, 31.5, 7);
      pdf.save(title);
    });
  }
  /* ******************************************************* */


  graphIconClickHandler = (event) => {
    let gt = event.target.parentElement.id;
    switch (gt) {
      case 'gt-0':
        this.setState({
          stacked: true,
          vertical: true,
          gt: gt
        });
        break;
      case 'gt-1':
        this.setState({
          stacked: true,
          vertical: false,
          gt: gt
        });
        break;
      case 'gt-2':
        this.setState({
          stacked: false,
          vertical: true,
          gt: gt
        });
        break;
      case 'gt-3':
        this.setState({
          stacked: false,
          vertical: false,
          gt: gt
        });
        break;
      default:
        break;
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    if (!this.state.visible) {
      return null
    }
    let gt = this.state.gt;
    return (
      <div>
        <div style={{
          width: '100%',
          backgroundColor: 'rgb(209, 224, 227)',
          marginBottom: '35px',
          borderBottom: 'solid thin black'
        }}>

          <div style={{position: 'relative', float: 'right', marginLeft: '10px'}}>
            <img alt="" onClick={this.downloadHandler} src="downloadIcon.png"
                 style={{width: '25px', margin: '10px', cursor: 'pointer'}}/></div>
          <div style={{position: 'relative', width: '220px', margin: 'auto'}}>

            <table style={{borderSpacing: '0px', marginLeft: '20px'}}>
              <tbody>
              <tr>
                <td>
                  <div id='gt-0'
                       className={gt === 'gt-0' ? "graph-icon active" : "graph-icon"}>
                    <img alt=""  width="35" onClick={this.graphIconClickHandler}
                         src="vertStackIcon.png"/></div>
                </td>

                <td>
                  <div id='gt-1'
                       className={gt === 'gt-1' ? "graph-icon active" : "graph-icon"}>
                    <img alt=""  width="35" onClick={this.graphIconClickHandler}
                         src="horizStackIcon.png"/></div>
                </td>

                <td>
                  <div id='gt-2'
                       className={gt === 'gt-2' ? "graph-icon active" : "graph-icon"}>
                    <img alt="" width="35" onClick={this.graphIconClickHandler}
                         src="vertUnStackIcon.png"/></div>
                </td>

                <td>
                  <div id='gt-3'
                       className={gt === 'gt-3' ? "graph-icon active" : "graph-icon"}>
                    <img alt=""  width="35" onClick={this.graphIconClickHandler}
                         src="horizUnStackIcon.png"/></div>
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style={{margin: '0 auto', width: '90%', position: 'relative', top: '-25px'}}>
          <input id="titleInput" type="text" placeholder="title"
                 value={this.state.title}
                 onChange={this.handleTitleChange}
                 style={{
                   textAlign: 'center',
                   border: '2px solid #c8d7dc',
                   width: '100%',
                   fontSize: '18px',
                 }}/>
        </div>
        <div>
          {gt === 'gt-0' ? this.verticalStackedBarChart() : null}
          {gt === 'gt-1' ? this.horizontalStackedBarChart() : null}
          {gt === 'gt-2' ? this.verticalUnstackedBarChart() : null}
          {gt === 'gt-3' ? this.horizontalUnstackedBarChart() : null}
        </div>
      </div>)


  }
}
export default TrackChart



