import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';



const _staffPasswordCreated = (email) => {
  return (
    <div>
      <p>
        <strong>To:</strong>
        {email}
      </p>
      <br/>
      <p>You have successfully created a password for your new account at TellMeThis.</p>
      {/* <p>If you did not initiate this password change yourself, contact us at support@tellmethis.com by responding to this email.</p> */}
      <p>If you did not initiate this password change yourself, contact us at support@tellmethis.org by responding to this email.</p>
    </div>

  )
}

const staffPasswordCreated = (email) => {
  return reactElementToJSXString(_staffPasswordCreated(email));
}


export default staffPasswordCreated;