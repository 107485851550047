import React from 'react';
import $ from 'jquery';
import * as d3 from 'd3';



var responseDataStash = [];

class NamedEntities extends React.PureComponent {
  margin = {top: 20, right: 30, bottom: 50, left: 40};
  wizardOn = false;
  NERobjs = [];
  PERSON = [];
  LOCATION = [];
  ORGANIZATION = [];
  containerId = '';
  tooltip = null;
  state = {
    mounted: false
  }

  componentDidMount() {
    const {isDerivative} = this.props;
    if (!isDerivative) {
      window.startWizard = this.startWizard;
      window.stopWizard = this.stopWizard;
      window.getDerivativeData = this.getDerivativeData;
    }
    this.containerId = this.refs.container.id;
    this.setState({
      mounted: true
    });
  }

  /* *************************** functions to call from external wizard  ***************************************** */

  startWizard = () => {
    const {analysisId} = this.props;
    this.wizardOn = true;
    $('#entities-' + analysisId).find($('.entitiesColLeft').css('cursor', 'pointer'));
  }

  stopWizard = () => {
    const {analysisId} = this.props;
    this.wizardOn = false;
    $('#entities-' + analysisId).find($('.entitiesColLeft').css('cursor', 'auto'));
    this.clearAll();
  }

  getDerivativeData = () => {
    window.derivativeData.push(JSON.parse(JSON.stringify(responseDataStash)));
    window.childIds.push(new Date().getTime().toString().slice(-6));

  }

  /* ************************************************************************************************************* */

  doTable = (rspData) => {
    const {width, height, analysisId} = this.props;

    if (this.state.mounted) {

      let $containerId = $('#' + this.containerId);

      $containerId.empty(); //delete existing chart if any drawn already

      $containerId.css('position', 'relative').css('overflow-y', 'auto').css('top', '-5px').css('height', height + 30).css('width', width).css('background-color', 'rgb(247,247,247)');//.css("border-color", "#EEEEEE");

      $containerId.append("<table><tr>" +
        "<td class='entitiesTable1' ></td>" +
        "<td class='entitiesTable2' >" +
        "<table id='PersonsTable-" + analysisId + "' style='width: 100%;'>" +
        "<tr class:'entitieshdng' >" +
        "<td class:'entitieshdng' colspan='2' style='padding: 7px;'><u>PERSONS</u></td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "<td class='entitiesTable1'></td>" +
        "<td class='entitiesTable2'>" +
        "<table  id='LocationsTable-" + analysisId + "' style='width: 100%;'>" +
        "<tr class:'entitieshdng'>" +
        "<td class:'entitieshdng' colspan='2'  style='padding: 7px;'><u>LOCATIONS</u></td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "<td class='entitiesTable1'></td>" +
        "<td class='entitiesTable2'>" +
        "<table  id='OrganizationsTable-" + analysisId + "' style='width: 100%;'>" +
        "<tr class:'entitieshdng' >" +
        "<td class:'entitieshdng' colspan='2'  style='padding: 7px;'><u>ORGANIZATIONS</u></td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "<td class='outerTable'></td>" +
        "</tr></table>");

      this.tooltip = d3.select(`#${this.containerId}`).append("div")
        .attr("id", "tooltip")
        .attr("class", "tooltip")
        .style("min-width", "100px")
        .style("opacity", "0.0")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("text-align", "left")
        .style("width", "175px")
        .style("height", "auto")
        .style("min-height", "30px")
        .style("padding", "4px")
        .style("font", "12px sans-serif")
        .style("background", "white")
        .style("border", "solid thin gray")
        .style("border-radius", "8px")
        .style("pointer-events", "none");

      this.prepData(rspData);
    }
  }

  addTableRow = (containerId, tableId, name, count, utteranceRefs) => {

    $('#' + containerId).find($('#' + tableId)).append("<tr class='entitiesRow'>" +
      "<td class='entitiesColLeft' id='" + Date.now() + "_" + utteranceRefs + "'>" + name + "</td>" +
      "<td class='entitiesColRight'>" + count + "</td></tr>");
  }


  prepData = (rspData) => {
    // const {responseData} = this.props;
    let self = this;
    let tmpArray = [];
    var i;
    var j;

    for (i = 0; i < rspData.length; i++) {
      var returnedData = rspData[i].split(" ");

      for (j = 0; j < returnedData.length; j++) {

        var lastSlashLoc = returnedData[j].lastIndexOf("/");
        var partA = returnedData[j].substring(0, lastSlashLoc);
        var partB = returnedData[j].substring(lastSlashLoc + 1, returnedData[j].length);

        /* *************** correct data as needed   ********************** */
        partA = partA.replace("/ORGANIZATION", "/oRGANIZATION"); //to avoid problems with the next lines
        partA = partA.replace("/O.", "");
        partA = partA.replace("/O", "");
        partA = partA.replace("/O'", "'");
        partA = partA.replace(",", "");

        if (partA.includes("PERSON")) {
          partA = partA.replace("/PERSON.", "");
          partA = partA.replace("/PERSON", "");
          partB = "PERSON";
        } else if (partA.includes("LOCATION")) {
          partA = partA.replace("/LOCATION.", "");
          partA = partA.replace("/LOCATION", "");
          partB = "LOCATION";
        } else if (partA.includes("oRGANIZATION")) {
          partA = partA.replace("/oRGANIZATION.", "");
          partA = partA.replace("/oRGANIZATION", "");
          partB = "ORGANIZATION";
        }

        /* ***************** combine entity names in consecutive positions ********************** */

        if (partB === "PERSON") {
          if (tmpArray[1] === "PERSON") {
            partA = tmpArray[0] + " " + partA;
            tmpArray[1] = "transferred";
          }
        } else if (partB === "LOCATION") {
          if (tmpArray[1] === "LOCATION") {
            partA = tmpArray[0] + " " + partA;
            tmpArray[1] = "transferred";
          }
        } else if (partB === "ORGANIZATION") {
          if (tmpArray[1] === "ORGANIZATION") {
            partA = tmpArray[0] + " " + partA;
            tmpArray[1] = "transferred";
          }
        }
        partA = partA.replace(". ", ".");

        /* ************************************************************************************** */

        tmpArray = [];
        tmpArray.push(partA);
        tmpArray.push(partB);
        tmpArray.push(i);// index of utterance array
        self.NERobjs.push(tmpArray);
      }
    }

    /* ****************************** handle initials with periods ***************************** */
    for (i = 0; i < self.NERobjs.length; i++) { // abbreviations in PERSON's or ORGANIZATION initials
      if (self.NERobjs[i][0].split('.').length > 3) { // more than two letters most likely an ORG
        self.NERobjs[i][1] = "ORGANIZATION";
      }
    }
    /* ***************************************************************************************** */
    self.buildTableData();
  }


  sortByAlpha = (a, b) => { //to group all same names consecutively
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  sortByCount = (a, b) => { //to relist from highest counts to lowest
    if (a.count < b.count) {
      return 1;
    }
    if (a.count > b.count) {
      return -1;
    }
    return 0;
  }

  tallyDups = (array) => { //collapses duplicate list entries to one with incremented counts
    let tmpArray = [];
    let thisUtterance = "";
    for (var i = 0; i < array.length; i++) {
      let dups = false;
      var newCount = 1;

      if (i + 1 < array.length) { //this looks ahead one name to see if there are duplicates
        while (array[i + 1].name === array[i].name) {
          dups = true;
          newCount = newCount + 1; //dups are tallied and will be saved in the count parameter for each entry
          thisUtterance = thisUtterance + array[i].utterance.toString() + "-";
          i = i + 1;
          if (i === array.length - 1) {
            break;
          }
        }
        if (!dups) {
          tmpArray.push({
            name: array[i].name,
            count: 1,
            utterance: array[i].utterance.toString()
          });
          thisUtterance = "";
        } else { //there are dups
          dups = false;
          thisUtterance = thisUtterance + array[i].utterance.toString();
          tmpArray.push({name: array[i].name, count: newCount, utterance: thisUtterance});
          thisUtterance = "";
        }
      }
      else if (i < array.length) { //if last in list is not a dup need to get it this way
        tmpArray.push({
          name: array[array.length - 1].name,
          count: 1,
          utterance: array[i].utterance.toString()
        });
        thisUtterance = "";
      }
    }
    tmpArray = tmpArray.sort(this.sortByCount); //move most frequently mentioned to top of list
    return tmpArray;
  }

  setSelected = (idClicked) => {
    if (this.wizardOn) {
      $('.entitiesColLeft').each(function (index) {
          let color = $(this).css('background-color').replace(/ /g, '');// in case other browsers don't place spaces in rgbs
          if (color === "rgb(196,251,142)") { //green
            let responseTextSelected = $('#' + idClicked).text();
            for (var i = 0; i < responseDataStash.length; i++) {
              if (responseDataStash[i].responseText.includes(responseTextSelected)) {
                responseDataStash[i].selected = true;
              } else {
                responseDataStash[i].selected = false;
              }
            }
          }
        }
      )
    }
  }

  clearAll = () => {
    //const {responseData} = this.props;
    for (var i = 0; i < responseDataStash.length; i++) { //add key:value for selected
      responseDataStash[i].selected = false;
    }
    $('.entitiesColLeft').css('background-color', '#EEEEEE');
  }

  clearSelected = (textSelected) => {
    // const {responseData} = this.props;
    for (var i = 0; i < responseDataStash.length - 1; i++) {
      if (responseDataStash[i].responseText.includes(textSelected)) {
        responseDataStash[i].selected = false;
      }
    }
  }

  setClickHandler = () => {
    let self = this;
    $('.entitiesColLeft').on('click', function () {
      if (self.wizardOn) {
        if ($(this).css('background-color') === 'rgb(196, 251, 142)') {
          $(this).css('background-color', '#EEEEEE');
          self.clearSelected($(this).text());
        } else {
          $(this).css('background-color', '#c4fb8e'); //green
          self.setSelected($(this).attr('id'));
        }
      }

      let foundSelected = false;
      $('.entitiesColLeft').each(function (index) {
        if ($(this).css('background-color') === 'rgb(196, 251, 142)') {
          foundSelected = true;
        }
        if (self.wizardOn) {
          if (foundSelected) {
            window.areAnySelected(true);
          } else {
            window.areAnySelected(false);
          }
        }
      });
    });
  }

  buildTableData = () => {
    const {analysisId} = this.props;
    let self = this;
    var i;

    for (i = 0; i < self.NERobjs.length; i++) {
      if (self.NERobjs[i][1] === "PERSON") {
        self.PERSON.push({
          name: self.NERobjs[i][0],
          count: 1,
          utterance: self.NERobjs[i][2]
        });
      } else if (self.NERobjs[i][1] === "LOCATION") {
        self.LOCATION.push({
          name: self.NERobjs[i][0],
          count: 1,
          utterance: self.NERobjs[i][2]
        });
      } else if (self.NERobjs[i][1] === "ORGANIZATION") {
        self.ORGANIZATION.push({
          name: self.NERobjs[i][0],
          count: 1,
          utterance: self.NERobjs[i][2]
        });
      }
    }

    self.PERSON = self.PERSON.sort(this.sortByAlpha);
    self.LOCATION = self.LOCATION.sort(this.sortByAlpha);
    self.ORGANIZATION = self.ORGANIZATION.sort(this.sortByAlpha);

    self.PERSON = this.tallyDups(self.PERSON);
    self.LOCATION = this.tallyDups(self.LOCATION);
    self.ORGANIZATION = this.tallyDups(self.ORGANIZATION);

    for (i = 0; i < self.PERSON.length; i++) { //populate PERSON column
      self.addTableRow(this.containerId, "PersonsTable-" + analysisId, self.PERSON[i].name, self.PERSON[i].count, self.PERSON[i].utterance)
    }

    for (i = 0; i < self.LOCATION.length; i++) { //populate LOCATION column
      self.addTableRow(this.containerId, "LocationsTable-" + analysisId, self.LOCATION[i].name, self.LOCATION[i].count, self.LOCATION[i].utterance)
    }

    for (i = 0; i < self.ORGANIZATION.length; i++) { //populate ORGANIZATION column
      self.addTableRow(this.containerId, "OrganizationsTable-" + analysisId, self.ORGANIZATION[i].name, self.ORGANIZATION[i].count, self.ORGANIZATION[i].utterance)
    }

    this.setClickHandler();
  };


  render() {
    const {responseData, analysisId, entityStr} = this.props;
    var i;
    if (responseData.length !== 0) {
      responseDataStash = JSON.parse(JSON.stringify(responseData));
    }

    /* *********** NEM data from cloudMersive returned with "#" in single string indicating new utterance ******* */
    /* *********** we need to split into separate utterances and remove # token plus cloudmersive chars   ******* */
    let rspData = entityStr.split('#');
    rspData.shift(); //top element is empty


    let filteredIndexes = [];

    if (responseDataStash[0].selected !== undefined) {     // It's a derivative chart
      responseDataStash = responseDataStash.filter((item, index) => {

        if (item.selected) filteredIndexes.push(index); // identify the selected data indexes, save in "filteredSet"
        return item.selected;  // return only the "selected" responseData items since it's a child chart
      })

      // now also filter out the unselected NEM-coded strings from the cloudMersive array using filteredIndexes
      let selectedSet = [];
      for (i = 0; i < rspData.length; i++) {
        if (filteredIndexes.includes(i)) {
          selectedSet.push(rspData[i])
        }
      }
      rspData = [];
      rspData = JSON.parse(JSON.stringify(selectedSet)); // recreate rspData from the "selected" array
    }


    for (i = 1; i < rspData.length; i++) {  //some quick cleaning of NEM coding to simplify for prepData function
      if (rspData[i].substring(0, 2) === "/O") {
        rspData[i] = rspData[i].substring(2, rspData[i].length)
        if (rspData[i].substring(0, 1) === " ") {
          rspData[i] = rspData[i].substring(1, rspData[i].length)
        }
      }
    }


    /* ********************************************************************************************************** */

    return (
      <div id={`entities-${analysisId}`}
           ref="container"
           style={{
             position: 'relative',
             margin: 'auto',
             marginTop: '20px',
             display: 'block',
             border: '1px solid transparent'
           }}>
        {this.doTable(rspData)}
      </div>
    )
  }
}

export default NamedEntities;