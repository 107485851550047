import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import zeroPaddedNumber from '../Common/zeroPaddedNumber';
import $ from 'jquery';
import _ from 'lodash';
import {restoreEvents, suspendEvents} from '../Common/events'
import showSystemError from '../Common/showSystemError';
import getAllSurveys from '../Common/getAllSurveys';
import {get_Review_Table} from '../../actions/Review/get_Review_Table';
import {setReviewNames} from '../../actions/Review/setReviewNames';
import {setReviewEmails} from '../../actions/Review/setReviewEmails';
import {setReviewBatches} from '../../actions/Review/setReviewBatches';
import {addCompletedAssignment} from '../../actions/Review/addCompletedAssignment';
import {
  EmailSelector,
  NameSelector,
  SurveySelector,
  BatchSelector,
  StartDateSelector,
  EndDateSelector
} from './ReviewFilters';

class ReviewSelector extends React.Component {

  constructor(props) {
    super(props);
    const {review_Table} = props;
    let oldState = localStorage.getObject('reviewSelector');
    if (oldState) {
      this.state = oldState;
    } else {
      let firstSurveyDate = review_Table.length ? new Date(review_Table[0].dateCompleted) : new Date();
      this.state = {
        surveys: [],
        surveysStatus: {},
        selectedName: '',
        selectedEmail: '',
        selectedSurvey: '',
        selectedBatch: 'All',
        selectedBatchList: [],
        firstAssignmentDate: firstSurveyDate,
        fromDate: firstSurveyDate,
        toDate: new Date(),
        toDateMin: firstSurveyDate,
        fromDateMax: new Date(),
        dateIsAltered: false
      }
    }
  }

  setupSelector = (filter, surveys) => {
    let surveyNames = [];
    let surveysStatus = [];
    surveyNames = surveys.map((survey) => {
      return survey.testName;
    });
    surveys.forEach((survey) => {
      surveysStatus[survey.testName] =
        {
          status: survey.status,
          lastEdit: survey.updatedAt
        }

    })

    this.setState({
      surveys: surveyNames,
      surveysStatus: surveysStatus
    });

    filter({
      survey: this.state.selectedSurvey,
      batch: this.state.selectedBatch,
      name: this.state.selectedName,
      email: this.state.selectedEmail,
      fromDate: new Date(this.state.fromDate),
      toDate: new Date(this.state.toDate)
    })
  }

  componentDidMount() {
    const {filter, user} = this.props;

    document.addEventListener('socket', this.handleSocketorReEntry, false);
    if (localStorage.getObject('reviewSelector')) { //reentering module from another module
      localStorage.removeItem('reviewSelector');
      getAllSurveys(user.accountId, 'review')
        .then((surveys) => {
          this.setupSelector(filter, surveys)
          if (!_.isEmpty(window.socketEventStore.completeAssignment)) {
            this.handleSocketorReEntry(null, true);
          }
        }, (err) => {
          console.log(err);
          showSystemError()
        })

    } else {
      if (localStorage.getItem('review_reset')) { //coming from 'reset filters
        localStorage.removeItem('review_reset');
        this.setState({name_email_div_width: $('#name-email-div').width() * .78})
      }
      if (!this.state.surveys.length) {
        getAllSurveys(user.accountId, 'review')
          .then((surveys) => {
            this.setupSelector(filter, surveys)

          }, (err) => {
            console.log(err);
            showSystemError()
          })
      }
    }
  }

  handleSocketorReEntry = (e, reEnter) => {
    const {
      filter,
      user,
      get_Review_Table,
      activeRow,
      setActiveRow,
      handleRowClick,
      sortFilteredTable,
      sortCriteria
    } = this.props;

    if (reEnter || e.info.type === 'COMPLETE_ASSIGNMENT') {
      let data = reEnter ? window.socketEventStore.completeAssignment : window.socketEvent.info;
      window.socketEventStore.completeAssignment = {};
      console.log('completeAssignment data: ', data);
      if (data.testName === this.state.selectedSurvey) {
        let currentScrollPosition;
        let difference;
        if (activeRow) {
          let $activeRow = $('#' + activeRow);
          difference = $activeRow.offset().top - $('#review-info-inner').offset().top;
          currentScrollPosition = $activeRow.position().top;
        }
        get_Review_Table(user.accountId, data.testName)
          .then((assignments) => {
              this.structureReviewTable(assignments);
              filter({
                survey: data.testName,
                selectedName: '',
                selectedEmail: '',
                selectedSurvey: '',
                selectedBatch: 'All',
                selectedBatchList: [],
                toDate: this.state.dateIsAltered ? this.state.toDate : new Date()
              });
              if ((data.batch === this.state.selectedBatch || this.state.selectedBatch === "All") &&
                (!this.state.selectedName || data.testTaker === this.state.selectedName) &&
                (!this.state.selectedEmail || data.email === this.state.selectedEmail) &&
                (!this.state.dateIsAltered)
              ) {
                let artificialEvent = {
                  currentTarget: {
                    id: sortCriteria
                  }
                }
                sortFilteredTable(artificialEvent);
                let currentRow = activeRow ? activeRow : data._id;
                setActiveRow(currentRow);
                handleRowClick(currentRow)
                if (activeRow) {
                  let insertedRowScrollPosition = $('#' + data._id).position().top;
                  let delta = currentScrollPosition > insertedRowScrollPosition ? 24 : 0;
                  $('#review-info-inner').animate({scrollTop: currentScrollPosition - difference + delta}, 600);
                } else {
                  this.scrollRowIntoView(data._id);
                }
              }


            }, (err) => {
              console.log(err);
              showSystemError();
            }
          )
      }
    }
  }

  scrollRowIntoView = (id) => { // row id
    let rowHeight = ($('td.reviewTd:first').css('border-width').split('px')[0] * 1) +
      ($('td.reviewTd:first').css('padding').split('px')[0] * 2) +
      ($('table.reviewTableBody').css('border-spacing').split('px')[0] * 1) +
      ($('td.reviewTd:first').height());
    let maxRowsInContainer = Math.floor($('#review-info-inner').height() / rowHeight); //round down to move a fractional row up
    let numberTableRows = $('tr.reviewRow').length;
    if (maxRowsInContainer < numberTableRows) {
      var rowpos = $('#' + id).position();
      $('#review-info-inner').animate({scrollTop: rowpos.top}, 600);
    }
  }

  structureReviewTable = (assignments) => {
    const {setReviewNames, setReviewEmails, setReviewBatches} = this.props;

    let trialSuffix = 1;
    let anonSuffix = 1
    assignments.forEach((assignment) => {
      if (assignment.group) {
        assignment.batch = assignment.group;
        if (assignment.group === 'testing') {
          assignment.testTaker = `Trial ${zeroPaddedNumber(trialSuffix, 4)}`
          trialSuffix++;
        } else {
          assignment.testTaker = `Anon ${zeroPaddedNumber(anonSuffix, 4)}`
          anonSuffix++;
        }
      }
    });

    //respondent names to redux store
    let sortedAssignments = _.sortBy(assignments, ['testTaker']);
    let names = sortedAssignments.map((assignment) => {
      return assignment.testTaker;
    })
    setReviewNames(names);

    //email array to redux store
    sortedAssignments = _.sortBy(assignments, ['email']);
    let emails = sortedAssignments.map((assignment) => {
      return assignment.email;
    })
    setReviewEmails(emails);

    //batches list to redux store

    let batchList = [];
    sortedAssignments.forEach((assignment) => {
      batchList.push(assignment.batch);
    });
    batchList = _.uniq(batchList);
    batchList.sort()
    setReviewBatches(batchList);


    let firstSurveyDate = assignments.length ? new Date(assignments[0].dateCompleted) : new Date();
    this.setState({
      reviewReady: true,
      firstAssignmentDate: firstSurveyDate,
      fromDate: firstSurveyDate,
      toDate: this.state.dateIsAltered ? this.state.toDate : new Date(),
      toDateMin: firstSurveyDate,
      fromDateMax: new Date(),
      name_email_div_width: $('#name-email-div').width() * .78

    })
  }

  onNameClick = (name) => {
    const {filter} = this.props;
    this.setState({selectedName: name});
    filter({name: name})
  }

  onEmailClick = (email) => {
    const {filter} = this.props;
    this.setState({selectedEmail: email});
    filter({email: email})
  }

  onChangeFromDate = (date) => {
    const {filter} = this.props;
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let newDate = new Date(year, month, day, 0, 0);
    this.setState({
      fromDate: newDate,
      toDateMin: newDate,
    })
    filter({fromDate: newDate})
  }

  onChangeToDate = (date) => {
    const {filter} = this.props;
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let newDate = new Date(year, month, day, 23, 59);
    this.setState({
      toDate: newDate,
      fromDateMax: newDate,
      dateIsAltered: true
    })
    filter({toDate: newDate})
  }

  onSurveyChange = (value) => {
    const {filter, user, get_Review_Table, appReady, resetFilters} = this.props;
    appReady(false)
    suspendEvents();

    get_Review_Table(user.accountId, value)
      .then((assignments) => {
        let status = this.state.surveysStatus[value].status
        let lastEdit = this.state.surveysStatus[value].lastEdit;
        if (status === 'development') {
          assignments = assignments.slice(-1);
          if (assignments.length && (assignments[0].completionError || assignments[0].dateCompleted < lastEdit)) {
            assignments = [];
          }
        } else {
          assignments = assignments.filter((assignment) => {
            return !assignment.completionError && !assignment.isTestTIN;
          })
        }


        let firstSurveyDate = assignments.length ? new Date(assignments[0].dateCompleted) : new Date();
        this.structureReviewTable(assignments);
        this.setState({
          selectedSurvey: value,
          //TOTALLY WEIRD!! 'this.props.reviewBatches' works but destructured
          // 'reviewBatchs' fails. ??
          selectedBatchList: ['All', ...this.props.reviewBatches],
          selectedBatch: 'All',
          selectedEmail: '',
          selectedName: '',
          firstAssignmentDate: firstSurveyDate,
          fromDate: firstSurveyDate,
          toDateMin: firstSurveyDate,
          toDate: new Date(),
          fromDateMax: new Date(),
          dateIsAltered: false
        });
        this.refs.name_selector.clearUserInput();
        this.refs.email_selector.clearUserInput();
        resetFilters();
        filter({
          survey: value,
          fromDate: firstSurveyDate
        });

        restoreEvents();
        appReady(true);
      }, (err) => {
        console.log(err);
        showSystemError();
      })
  }

  onBatchChange = (value) => {
    const {filter} = this.props;
    this.setState({selectedBatch: value})
    filter({batch: value});
  }


  componentWillUnmount() {
    if (!localStorage.getItem('review_reset')) {
      localStorage.setObject('reviewSelector', this.state);
    }
    document.removeEventListener('socket', this.handleSocketorReEntry, false);
  }


  render() {
    const {reviewNames, reviewEmails} = this.props;
    return (
      <div className="assignHeading">
        <div className="assignHeadingTName"
             style={{fontWeight: 'bold', textAlign: 'center'}}>
          Select the survey to review then use any or all of the filters below.
        </div>
        <div className="review-selector">
          <section id="survey-batch" className="inset review-selector-section">
            <SurveySelector
              data={this.state.surveys}
              // disabled={!this.state.reviewReady}
              onChange={this.onSurveyChange}
              value={this.state.selectedSurvey}

            />
            <BatchSelector
              value={this.state.selectedBatch}
              onChange={this.onBatchChange}
              data={this.state.selectedBatchList}
              disabled={!this.state.selectedSurvey}
            />
          </section>

          <section id="name-email-div"
                   className="inset review-selector-section">
            <NameSelector
              suggestionsWidth={this.state.name_email_div_width}
              suggestions={reviewNames}
              disabled={!this.state.selectedSurvey}
              clickHandler={this.onNameClick}
              userInput={this.state.selectedName}
              ref='name_selector'>
            </NameSelector>
            <EmailSelector
              suggestionsWidth={this.state.name_email_div_width}
              suggestions={reviewEmails}
              disabled={!this.state.selectedSurvey}
              clickHandler={this.onEmailClick}
              userInput={this.state.selectedEmail}
              ref="email_selector"
            />
          </section>
          <section id="dates" className="inset review-selector-section">
            <StartDateSelector
              onChange={this.onChangeFromDate}
              value={new Date(this.state.fromDate)}
              max={new Date(this.state.fromDateMax)}
              disabled={!this.state.selectedSurvey}
              min={new Date(this.state.firstAssignmentDate)}
            />

            <EndDateSelector
              min={new Date(this.state.toDateMin)}
              disabled={!this.state.selectedSurvey}
              onChange={this.onChangeToDate}
              value={new Date(this.state.toDate)}
              max={new Date()}
            />
          </section>
        </div>
      </div>
    )

  }

}


const mapStateToProps = (state) => {
  return {
    review_Table: state.review_Table,
    reviewEmails: state.reviewEmails,
    reviewNames: state.reviewNames,
    reviewBatches: state.reviewBatches,
    user: state.user

  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    get_Review_Table,
    setReviewNames,
    setReviewEmails,
    setReviewBatches,
    addCompletedAssignment
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSelector);
