import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Form, Field} from 'react-final-form';
import classnames from 'classnames';
import $ from 'jquery';
import {signUpUserOrStaff} from '../../actions/User/signUpUserOrStaff';
import {sendSignupEmail} from '../../actions/User/sendSignupEmail';
import showSystemError from '../Common/showSystemError';


class SignUp extends Component {
  emailValid = false;
  pwValid = false;
  pwConfirm = false;
  pw = '';
  companyIsValid = true;
  nameIsValid = true;


  constructor(props) {
    super(props);
    this.state = {
      emailInUse: false,
      formSubmitted: false,
      signupEmail: '',
      accountType: ''
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      accountType: localStorage.getItem('type')
    });
    localStorage.removeItem('type');
    this.handleResize();
  }

  handleResize = () => {
    $('.no-navbar').height(window.innerHeight);
  }

  allValid = () => {
    return this.emailValid && this.pwValid && this.pwConfirm && this.companyIsValid && this.nameIsValid;
  }

  validEmail = (value) => {
    // eslint-disable-next-line
    // this.emailValid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value));
    //NEW PATTERN GOOD FOR 4 LETTER TLD'S
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9.-]+)?$/;
    this.emailValid = emailPattern.test(value);

    return this.emailValid ? undefined : 'invalid'
  }

  lengthLTSix = (value) => {
    if (!value) {
      return 'invalid'
    }
    this.pw = value;
    this.pwValid = value.length > 5;
    return this.pwValid ? undefined : 'invalid'
  }

  passwordsEqual = (value) => {
    this.pwConfirm = this.pw === value;
    return this.pwConfirm && this.pwValid ? undefined : 'invalid';
  }


  companyValid = (value) => {
    if (this.state.accountType !== 'corporate') {
      return undefined;
    } else {
      if (!value || !value.length) {
        this.companyIsValid = false;
        return 'invalid'

      } else {
        this.companyIsValid = true;
        return undefined
      }
    }
  }

  nameValid = (value) => {
    if (this.state.accountType !== 'corporate') {
      return undefined;
    } else {
      if (!value || !value.length) {
        this.nameIsValid = false;
        return 'invalid'

      } else {
        this.nameIsValid = true;
        return undefined
      }
    }
  }

  onSubmit = (values) => {
    const {signUpUserOrStaff, sendSignupEmail} = this.props;
    this.setState({emailInUse: false})
    signUpUserOrStaff(values.email, values.name, values.password, values.company, this.state.accountType)
      .then((user) => {
        if (user.error) {
          this.setState({emailInUse: true});
        } else {
          sendSignupEmail(user.local.email)
            .then(() => {
              this.setState({
                formSubmitted: true,
                signupEmail: user.local.email
              })
            })

        }
      }, (err) => {
        showSystemError()
      })

  }

  renderStyledField = (input, type, label, indicator, disabled) => {

    return (
      <div style={{marginBottom: '20px'}}>
        <div style={{marginBottom: '4px'}}>
          <label style={{
            fontSize: '14px',
            cursor: 'default'
          }}>{label}</label>
        </div>
        <div style={{width: '100%'}}>
          <div style={{display: 'inline-block', width: '95%'}}>
            <input {...input} disabled={disabled} type={type}
                   className="rc"
              // onFocus={this.clearEmailInUse}
                   style={{
                     width: '100%'
                   }}/>
          </div>
          <div style={{float: 'right', paddingTop: '8px'}}>
            <div style={{margin: '0 auto'}}
                 className={indicator}>

            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let emailInUseClasses = classnames("email-in-use", {active: this.state.emailInUse})
    return (
      <div id='xxx' className='no-navbar' style={{
        width: '100%',
        // background: 'rgb(33, 169, 134)',
        background: 'cadetblue'
      }}>
        <div>        {/* <img src="TellMeThisLogoWhiteTransparent.png"
             alt=""
             style={{height: '42px', padding: '8px', cursor: 'pointer'}}/> */}
          <img src="TMTLogoWhiteBalloonsWithTagLine.svg"
               className="TMTLogoWhiteBallonsWithTagLine"
               alt=""
            //style={{height: '70px', paddingLeft: '8px', marginTop: '-5px', cursor: 'pointer'}}
          />
          <div className="big-menu-link"
               style={{float: 'right', border: 'thin solid white', borderRadius: '5px'}}>
            <a href="/"
               className="menuLink">Home</a>
          </div>
        </div>
        <h1 style={{textAlign: 'center', color: 'white'}}>Sign Up</h1>
        <div style={{marginTop: '40px'}}
             onClick={() => {
               this.setState({emailInUse: false})
             }}>
          <Form
            onSubmit={this.onSubmit}
            validateOnBlur={false}
            initialValues={
              {
                email: '',
                password: '',
                company: '',
                confirmPassword: ''
              }
            }
            render={({handleSubmit, form, submitting, pristine, values}) => {
              return (
                <form
                  className="signup-form-panel"
                  onSubmit={handleSubmit}>
                  <Field name="email" validate={this.validEmail}>
                    {({input, meta}) => {
                      let indicatorClassEmail = '';
                      if (meta.visited) {
                        indicatorClassEmail = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen'
                      }

                      return (
                        this.renderStyledField(input, 'text', 'Email Address:', indicatorClassEmail)
                      )
                    }}
                  </Field>
                  {this.state.accountType === 'corporate' ?
                    <Field name="name" validate={this.nameValid}>
                      {({input, meta}) => {

                        let indicatorClassName = '';
                        if (meta.visited) {
                          indicatorClassName = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen';
                        }

                        return (
                          this.renderStyledField(input, 'text',
                            'Name', indicatorClassName)
                        )
                      }}
                    </Field> : null}


                  <Field name="company" validate={this.companyValid}>
                    {({input, meta}) => {

                      let indicatorClassCompany = '';
                      if (meta.visited) {
                        indicatorClassCompany = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen';
                      }
                      const companyName = this.state.accountType === 'corporate' ?
                        "Company Name:" : "Company Name: (Optional)";
                      return (
                        this.renderStyledField(input, 'text',
                          companyName, indicatorClassCompany)
                      )
                    }}
                  </Field>
                  <Field name="password" validate={this.lengthLTSix}>
                    {({input, meta}) => {
                      let indicatorClassPassword = '';
                      if (meta.visited) {
                        indicatorClassPassword = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen';
                      }
                      return (
                        this.renderStyledField(input, 'password',
                          'Password: (must be at least six characters)', indicatorClassPassword)
                      )
                    }}
                  </Field>
                  <Field name="confirmPassword" validate={this.passwordsEqual}>
                    {({input, meta}) => {
                      let indicatorClassConfirm = '';
                      if (meta.visited) {
                        indicatorClassConfirm = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen';
                      }
                      return (
                        this.renderStyledField(input, 'password', 'Confirm Password', indicatorClassConfirm)
                      )
                    }}
                  </Field>
                  <div
                    className={emailInUseClasses}>
                    This email address is already in use.
                  </div>
                  <div>
                    <button className="blueButton"
                            style={{
                              float: 'right',
                              marginRight: '16px',
                              marginTop: '10px'
                            }}
                            type="submit"
                            disabled={submitting || !this.allValid() || this.state.formSubmitted}>
                      Sign Up
                    </button>
                  </div>
                </form>
              )
            }}
          ></Form>
          <div style={{
            textAlign: 'center',
            color: 'white',
            marginTop: '15px',
            lineHeight: '.6'
          }}>
            {this.state.formSubmitted ?
              <div>
                <p style={{fontSize: '30px'}}>Thank you for signing up for TellMeThis.</p>
                <p style={{fontSize: '24px'}}>A verification email has been sent
                  to <span style={{color: 'yellow'}}>{`${this.state.signupEmail}`}.</span>
                </p>
                <p style={{fontSize: '24px', color: 'white'}}>Click the <u>link</u> in
                  that email to confirm your
                  registration.</p>
              </div>
              :
              <div>
                <p>Already a member? &nbsp;
                  <a style={{color: 'white'}} href="/login"> Login here.</a></p>
              </div>
            }
          </div>


        </div>
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    signUpUserOrStaff: signUpUserOrStaff,
    sendSignupEmail: sendSignupEmail
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(SignUp);