import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setActiveModule} from '../../actions/setActiveModule';
import HelpSubMenu from './HelpSubMenu';
import HelpContent from './HelpContent';
import Navbar from '../Navbar';
import _ from 'lodash';

class Help extends React.Component {


  componentDidMount() {
    const {setActiveModule} = this.props;
    setActiveModule('Help');

  }


  render() {
    const {user, history, match} = this.props;
     if (_.isEmpty(user)) {
      history.push('/')
      return (
        null
      )
    }
    let subPath = match.url.split('/')[2];
    if(!subPath) {history.replace('/help/overview')}
    return (
      <span>
        <Navbar history={history}/>
        <HelpSubMenu user={user} history={history} subPath={subPath}/>
        <HelpContent subPath={subPath || 'overview'} user={user}/>

      </span>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setActiveModule
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
