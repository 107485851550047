import {EDIT_RESPONSE_TEXT} from '../_CONSTANTS';
import $ from 'jquery'


export const editResponseText =(row) => {
  return function (dispatch, getState) {
    return new Promise((resolve,reject)=>{
     let {TIN, testItemId, response, URL} = row
      let url=`/test/editResponseText`;
      // let url = '/testerror'
      $.ajax({
        url:url,
        type: "PUT",
        data: {
          TIN,
          testItemId,
          response,
          wavURL: URL
        },
        success:(itemResponse)=>{
          dispatch({
            type:EDIT_RESPONSE_TEXT,
            payload: itemResponse
          })
          resolve(itemResponse);
        },
        error: (err)=>{
           console.log(err);
          reject(err);
        }
      })
    })
  }
}