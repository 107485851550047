import React from 'react';
import ModalFrame from '../../ModalFrame';
import DeleteRow from './DeleteRow';

class DeleteRowModalController extends React.Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);

  }


  hideModal() {
    const {setModalInfo} = this.props;
    setModalInfo({});
  }

  render() {
    const {modalInfo, confirmHandler, cancelHandler} = this.props;
    return (
      <div className="example-modal">
        {modalInfo.name === 'assignDeleteRow' ?
          <ModalFrame
            title="DELETE A PARTICIPANT CODE ENTRY"
            desktopWidth="60%"
            headerCloseButtonHandler={this.hideModal}
            hasHeader={true}>
            <DeleteRow
              modalInfo={modalInfo}
              hideModal={this.hideModal}
              confirmHandler={confirmHandler}
              cancelHandler={cancelHandler}
            />
          </ModalFrame> : null}
      </div>
    )
  }
}

export default DeleteRowModalController;
