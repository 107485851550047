import React from 'react';
import classnames from 'classnames';
import Checkbox from './Checkbox';

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownPlaceholder: 'All',
    }
  }

  componentDidMount() {
    const {checkboxesStatus} = this.props;
    if (checkboxesStatus[0]) {
      this.setState({dropdownPlaceholder: 'All'});
    }
    else {
      this.setDropdownPlaceholder(checkboxesStatus);
    }

  }

  toggleDropdownOpen = (e) => {
    const {disabled, toggleDropdown} = this.props;
    if (!disabled) {
      e.stopPropagation();
      toggleDropdown();
    }
  }

  setDropdownPlaceholder = (cbArray) => {
    const {itemNames} = this.props;
    let arr = cbArray.slice(-cbArray.length + 1)
    arr = arr.filter((cb) => {
      return cb === true;
    })
    let l = arr.length;

    if (!l) {
      this.setState({
        dropdownPlaceholder: 'None'
      })
    }
    else if (l === 1) {
      let index = cbArray.findIndex((cb) => {
        return cb === true;
      })
      this.setState({
        dropdownPlaceholder: itemNames[index]
      })
    }
    if (l > 1) {
      this.setState({
        dropdownPlaceholder: 'Various'
      })
    }
  }

  handleCheckboxSelect = (id, status, name) => {
    const {setCheckboxesStatus, checkboxesStatus} = this.props;
    let checkBoxValue = status;
    let cbArray;
    if (id === 'All') {
      if (checkBoxValue) {
        let l = checkboxesStatus.length;
        cbArray = new Array(l).fill(true);
        this.setState({
          dropdownPlaceholder: 'All'
        })
        setCheckboxesStatus(cbArray)
      }
      else {
        cbArray = [...checkboxesStatus];
        cbArray[0] = false;
        this.setDropdownPlaceholder(cbArray);
        setCheckboxesStatus(cbArray)
      }
    }
    else {
      let cbi = parseInt(name);
      cbArray = [...checkboxesStatus];
      cbArray[cbi] = checkBoxValue;
      if (!checkBoxValue) {
        cbArray[0] = false;
      }
      this.setDropdownPlaceholder(cbArray);
      setCheckboxesStatus(cbArray)
    }
  }

  render() {
    const {disabled, itemNames, checkboxesStatus, dropdownOpen, centered, allOffset} = this.props;
    let popupClasses = classnames('rw-popup-container', {'rw-popup-transition-exited': !dropdownOpen})
    let popupContainerClasses = classnames('rw-dropdown-list rw-widget', {'rw-state-focus rw-open': dropdownOpen})
    let cursor = disabled ? 'no-drop' : 'pointer';
    return (
      <div className={popupContainerClasses}
           tabIndex={0}>
        <div className="rw-widget-input rw-widget-picker rw-widget-container"
             style={{cursor: cursor}}>
          <div className="rw-input rw-dropdown-list-input"
               onClick={this.toggleDropdownOpen}
          >
            <input tabIndex={-1}
                   placeholder="All"
                   className="rw=dropdown-list autofill rw-detect-autofill rw-sr"/>
            <span id='bd-placeholder-span'
                  className="rw-placeholder">{this.state.dropdownPlaceholder}</span>
          </div>
          <span className="rw-select">
              <button tabIndex={-1} type="button"
                      style={{cursor: cursor}}
                      className="rw-btn rw-btn-select">
                <span className="rw-i rw-i-caret-down"
                      onClick={this.toggleDropdownOpen}
                ></span>
              </button>
            </span>
        </div>

        <div className={popupClasses} tabIndex={-1}
        >
          <div className="rw-popup-transition">
            <div className="rw-popup">
              <div className="rw-list">
                {itemNames.map((item, index) => {
                  return (
                    <Checkbox
                      name={index}
                      id={item}
                      value={item}
                      key={index}
                      centered={centered}
                      allOffset={allOffset}
                      checked={checkboxesStatus[index]}
                      initialState={checkboxesStatus[index]}
                      clickHandler={this.handleCheckboxSelect}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MultiSelect;
