import $ from 'jquery';


export const getSearchData = (fromDateValue, toDateValue, selectedBatches, selectedGroups, test, prompt, isDerivative) => {

  return new Promise((resolve, reject) => {
    if (isDerivative) {
      let data;
      if (window.numberOfChildren) { //returning from a different module
        data = window.derivativeData[window.numberOfChildren - 1];
        window.numberOfChildren = window.numberOfChildren - 1;
      }
      else { //initial creation of a derivative chart
        data = window.derivativeData[window.derivativeData.length-1];
      }
      data = JSON.parse(JSON.stringify(data));

      resolve({
        responseData: data
      })
    }
    else {
      let url = '/test/getSearch';
      let data = {
        fromDateValue: fromDateValue.getTime(),
        toDateValue: toDateValue.getTime(),
        selectedBatches,
        selectedGroups: selectedGroups,
        testName: test.testName,
        accountId: test.accountId,
        promptId: prompt.promptId
      }
      $.ajax({
        url,
        type: "POST",
        data,
        success: (data) => {
          resolve({
            responseData: data
          })
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    }
  })

}