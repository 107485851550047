import {GET_ASSIGN_TABLE} from '../_CONSTANTS';
import $ from 'jquery';

export const get_Assign_Table = (testName, instrumentType, accountId, rowsNeeded, csvData, nbatches, currentBatchName, numberOfQuestions, wantsEmail, assessmentEmailTitle) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let isExistingBatch = !!currentBatchName;
      let batchSuffix;
      if (!currentBatchName) {
        if (nbatches < 9) {
          batchSuffix = "00" + (nbatches + 1).toString();
        } else {
          if (nbatches < 99) {
            batchSuffix = "0" + (nbatches + 1).toString();
          } else {
            batchSuffix = (nbatches + 1).toString();
          }
        }
        currentBatchName = `${testName.slice(0, 3)}${batchSuffix}`;
      }

      let assignTable = getState().assign_Table;
      let hasCsvData = !!csvData;

      let url =
        `/admin/getTins?count=${rowsNeeded}&accountId=${accountId}&test=${testName}&type=${instrumentType}&batch=${currentBatchName}&existing=${isExistingBatch}&numberOfQuestions=${numberOfQuestions}&wantsEmail=${wantsEmail}&assessmentEmailTitle=${assessmentEmailTitle}`;
      // let url = '/testerror'
      assignTable = {
        ...assignTable, ...{
          accountId: accountId,
          testName: testName
        }
      }

      $.ajax({
        url: url,
        type: "GET",
        success: (result) => {
          result.testName = testName;
          window.socket.emit('updateRoom', `room-${getState().user.accountId}`, {
            type: "ADD_ASSIGNMENTS",  //goes to socketDispatch for further routing
            result: result
          });
          let rows = assignTable.rows;
          let newRows = [];
          result.assignments.forEach((row, index) => {
            let badEmail = true;
            if (hasCsvData) {
              // eslint-disable-next-line
              badEmail = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(csvData[index].email)) || !csvData[index].email;
            }
            let obj = {
              ...row, ...{
                participantCode: row.TIN,
                participantName: hasCsvData ? csvData[index].participantName : '',
                email: hasCsvData ? csvData[index].email : '',
                externalId: hasCsvData ? csvData[index].externalId : '',
                isDeleted: false,
                badEmail: badEmail,
                error: 0,
                hasScoring: false,
                cumulativeScore: ''
              }
            }
            newRows.push(obj);
          })
          rows = [...rows, ...newRows];
          assignTable.rows = rows;
          assignTable.batch = result.batch;

          dispatch({
            type: GET_ASSIGN_TABLE,
            payload: assignTable
          })
          resolve(assignTable);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}