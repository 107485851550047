import {toast} from 'react-toastify';
import showToastr from './showToastr';


function noClickEvents(e) {
   //hack required to exclude click event created by exporting csv file which
  // generates a click event for some reason at clientX =0 and clientY=0
  if(e.clientX && e.clientY) {
      e.stopPropagation();
  e.preventDefault();
  console.log('noClickEvents')
  showToastr('fa fa-exclamation-circle',
    'Please wait. We are preparing your data for you'
    , {type: toast.TYPE.INFO})
}
  }


export const restoreEvents = () => {
  document.removeEventListener('click', noClickEvents, true)
  document.onkeydown = function () {
    return true;
  }
}
export const suspendEvents = () => {
  document.addEventListener('click', noClickEvents, true)
  document.onkeydown = function () {
    return false;
  }
}

