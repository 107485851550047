import React from 'react'
import $ from 'jquery';
import StaffRow from './StaffRow';
import NewStaff from './NewStaff';


const headerStyles = {
  backgroundColor: '#d1e0e3',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  margin: '0 auto',
  width: '100%',
  paddingTop: '20px',
  paddingBottom: '4px',
  borderBottom: 'solid thin transparent',
  boxSizing: 'border-box'
}

class StaffHeader extends React.Component {
  render() {
    return (
      <div>
        <table id="header"
               style={headerStyles}
               className = "staffHeaderStyles">
          <tbody>
          <tr style={{backgroundColor: 'transparent', width: '100%'}}>
            <th style={{width: '25%'}}>Name</th>
            <th style={{width: '20%'}}>Email</th>
            <th style={{width: '20%'}}>Role</th>
            <th style={{width: '15%'}}>Status</th>
            <th style={{width: '20%'}}>Last Login</th>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

class StaffTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {emailInUse: false};
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    let $header = $('#header');
    let $bodyContainer = $('.body-container');
    let constantSpacer = 70;
    let topSpace = $('.subMenuMainPanel').position().top + $header.height();
    $bodyContainer.height(window.innerHeight - topSpace - constantSpacer);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  emailInUse = (bool) => {
    this.setState({emailInUse: bool})
  }

  deleteEmployee = (id) => {
    const {openModal, staff} = this.props;
    let employee = staff.find((employee)=>{
      return employee._id === id;
    })
    openModal({
      name: 'deleteStaff',
      userName: employee.name,
      _id: id
    })

  }
  render() {
    const {staff, user, addStaff, signUpUserOrStaff, editStaff} = this.props;
    let emailMessage = this.state.emailInUse ? 'This email address is already in use.' : '';
    return (
      <div>
        <StaffHeader />
        <div className="outer-container staffOuterContainerStyles"
             onClick={() => {
               this.setState({emailInUse: false})
             }}>
          <div className="body-container staffTableContainerStyles" >
            <table  className="staffTableStyle" >
              <tbody>
              {staff.map((employee, index) => {
                return <StaffRow user={user}
                                 employee={employee}
                                 key={index}
                                 editStaff={editStaff}
                                 deleteEmployee={this.deleteEmployee}
                />
              })}
              <tr>
                <td colSpan={5}>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan={5} style={{
                  fontWeight: 'bold'
                }}>Add new staff member
                </td>
              </tr>
              <NewStaff user={user} addStaff={addStaff}
                        signUpUserOrStaff={signUpUserOrStaff}
                        emailInUse={this.emailInUse}
              />
              <tr>
                <td colSpan={5}>&nbsp;</td>
              </tr>
              <tr id="email-message">
                <td colSpan={4} style={{textAlign: 'center', color: 'red'}}>
                  {emailMessage}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default StaffTable;