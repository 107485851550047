import {SET_REVIEW_NAMES} from '../_CONSTANTS';

export const setReviewNames = (names) => {
  return {
    type: SET_REVIEW_NAMES,
    payload: names
  }
}


