import React from 'react';

class CloneSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClone = this.handleClone.bind(this);
    this.state = {
      cloneName: '',
      errorMessage: ''
    };
  }

  handleCancel(e) {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  testNameInvalid = (name) => {
    const {modalInfo} = this.props;
    if (modalInfo.surveys.find((test) => {
      return test.testName.toLowerCase() === name.toLowerCase();
    })) {
      return 'This survey name is already taken. Please try again'
    }
    if (name.length < 3) {
      return 'Test names must be at least three characters long.'
    }
    return ''
  }

  handleClone(e) {
    const {hideModal, confirmHandler, modalInfo} = this.props;
    e.preventDefault();
    let invalidMessage = this.testNameInvalid(this.state.cloneName);
    if (!invalidMessage) {
      confirmHandler(modalInfo.survey, this.state.cloneName);
      hideModal();
    } else {
      this.setState({errorMessage: invalidMessage});
    }
  }

  handleChange = (e) => {
    this.setState({
      cloneName: e.target.value
    })
  }

  clearErrorMsg = () => {
    this.setState({errorMessage: ''})
  }

  render() {
    const {modalInfo} = this.props;
    return (
      <div>
        <div className="modal-body"><br></br>
          <div>Create a copy of the survey named&nbsp;
            <span style={{
              fontWeight: 'bold',
              color: 'darkblue'
            }}><i>{modalInfo.survey.testName}</i> </span></div>
          <div>
            <p>The copy you create will be added to this Surveys Page so it can be managed
              like any new survey.
              The copy can be modified in whatever way you wish under the "Create"
              tab.<br></br><br></br>
              This feature can be particularly useful in situations such as:
              <ul>
                <li><span style={{fontWeight: 'bold', color: 'darkblue'}}>Seasonal administrations
                     </span> - where you want identical surveys to compare responses
                  across seasons or years
                </li>
                <li><span style={{fontWeight: 'bold', color: 'darkblue'}}>Individualized variants
                     </span> - such as spoken personal references, where just the name of
                  an applicant or the position
                  to be filled must be survey-specific
                </li>
                <li><span style={{fontWeight: 'bold', color: 'darkblue'}}>Website embedings
                     </span> - where you want to do A/B testing of the effectiveness of
                  alternate versions
                </li>
              </ul>
            </p>
          </div>
          {/* <div>survey id: {modalInfo.survey._id}</div> */}
          <div><br></br>Enter the name to be given to the copy&nbsp;
            <input type="text"
                   style={{width: '240px'}}
                   value={this.state.cloneName}
                   onChange={this.handleChange}
                   onFocus={this.clearErrorMsg}/>
            &nbsp;and click "Clone".
          </div>

        </div>
        <div style={{
          textAlign: 'center',
          color: 'red',
          minHeight: '28px',
          paddingTop: '5px',
          fontSize: '18px'
        }}>{this.state.errorMessage}</div>

        <div className="modal-footer">
          <div className="btn-group pull-right" style={{margin: '5px 0'}}>

            <button className="blueButton pull-right"
                    onClick={this.handleClone}
                    style={{marginLeft: '5px'}}>CLONE
            </button>
            <button className="blueButton pull-right"
                    onClick={this.handleCancel}>
              Cancel
            </button>

          </div>
        </div>
      </div>
    )
  }
}

export default CloneSurvey;