import React from 'react';
import classnames from 'classnames';
import AnalysisCreator from './AnalysisCreator';
import pdfDownload from './pdfDownload';
import DerivativeWizard from './DerivativeWizard';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';

class AnalysisContainer extends React.Component {
  initialSelectionState = null;

  constructor(props) {
    super(props);
    const {analysisId} = props;
    let oldState = localStorage.getObject(`analysisContainer-${analysisId}`);
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem(`analysisContainer-${analysisId}`);
    }
    else {
      this.state = {
        title: '',
        hasContent: false,
        displayWizard: false,
        parentAnalysisId: null,
        childAnalysisId: null,
        chartLink: ''
      }
    }
  }

  componentDidMount() {
    const {parentAnalysisId} = this.props;
    this.setState({
      parentAnalysisId: parentAnalysisId
    });
  }

  chartHasContent = (bool) => {
    this.setState({
      hasContent: bool
    })
  }

  createDerivative = (type) => {
    const {addDerivativeChild, analysisId} = this.props;
    let length = window.childIds.length;
    let childAnalysisId = window.childIds[length-1];
    this.setState({childAnalysisId: childAnalysisId});
    addDerivativeChild(type, childAnalysisId, analysisId);
  }

  handleTitleChange = (e) => {
    this.setState({title: e.target.value});
    const {adjustLinkedTitle} = this.props;
    adjustLinkedTitle(e.currentTarget.id, e.target.value);
  }

  closeHandler = (e) => {
    const {subtractChild} = this.props;
    subtractChild(e.currentTarget.id);
  }

  handleHideShow = (e) => {
    const {hideShowContainer} = this.props;
    let id = e.target.id;
    let hide = id.slice(0, 4) !== 'hide';
    hideShowContainer(id.slice(-6), hide);
  }


  showWizard = () => {
    this.setState({
      displayWizard: true
    })
    if (window.startWizard) {
      this.initialSelectionState = window.startWizard();
    }
  }

  hideWizard = () => {
    this.setState({
      displayWizard: false
    })
    if (window.stopWizard) {
      window.stopWizard();
    }
  }

  componentWillUnmount() {
    const {analysisId} = this.props;
    if (!localStorage.getItem('analyze_reset')) {
      localStorage.setObject(`analysisContainer-${analysisId}`, this.state);
    }
  }

  handleParentChildLinkClick = (e) => {
    let idVal = e.target.getAttribute("scrollto");
    if (idVal) {
      let $targetElement = $("#" + idVal);
      let chromeOffset = 55;
      let yVal = $targetElement.offset().top + $('.main').scrollTop() - chromeOffset;

      $('.main').animate({scrollTop: yVal}, 800);
    }
  }

  adjustCSS = () => {
    $('.__react_component_tooltip').css('background-color', 'lightgoldenrodyellow').css('font-size', '16px');
  }

  getLinkString = () => {  // provide links between parent and child if there's a derivative chart
    const {id, isChartDeleted} = this.props;
    let link = '';
    let scrollto = '';
    let linkStyles = {};
    // THREE CASES TO HANDLE //

    /* **************** CASE 1: container not part of a linked pair  ***************************** */
    if (!this.state.parentAnalysisId && !this.state.childAnalysisId) {
      linkStyles = {pointerEvents: 'none', color: 'transparent', textDecoration: 'none'};
      scrollto = '';
    }
    /*  ***************************************************************************************** */
    /* **************** CASE 2: container is part of linked pair -- this is the child *********** */
    else if (this.state.parentAnalysisId) {
      if (isChartDeleted(this.state.parentAnalysisId)) {
        link = "Parent chart was deleted";
        linkStyles = {pointerEvents: 'none', color: '#555555', textDecoration: 'none'};
        scrollto = '';
      }
      else {
        let parentTitle = $("#input-container-" + this.state.parentAnalysisId).val() || this.state.parentAnalysisId;
        link = `Child of chart: ${parentTitle}`;
        linkStyles = {cursor: 'pointer', color: 'blue', textDecoration: 'underline'};
        scrollto = this.state.parentAnalysisId;
      }
    }
    /*  ***************************************************************************************** */
    /*  **************** CASE 3: container part of linked pair -- this is the parent  *********** */
    else if (this.state.childAnalysisId) {
      if (isChartDeleted(this.state.childAnalysisId)) {
        link = "Chld chart was deleted";
        linkStyles = {pointerEvents: 'none', color: '#555555', textDecoration: 'none'};
        scrollto = '';
      }
      else {
        let childTitle = $("#input-container-" + this.state.childAnalysisId).val() || this.state.childAnalysisId;
        link = `Parent of chart: ${childTitle}`;
        linkStyles = {cursor: 'pointer', color: 'blue', textDecoration: 'underline'};
        scrollto = this.state.childAnalysisId;
      }
    }
    /*  ***************************************************************************************** */
    return (
      <a id={`parentChildLink-${id}`}
         style={linkStyles}
         onClick={this.handleParentChildLinkClick}
         scrollto={scrollto}
      >{link}</a>)
  }


  render() {
    const {id, firstDiv, addChild, user, analysisId, isDerivative, surveys, visible, derivativeChartType, childAnalysisId} = this.props;
    let sideDivClasses = classnames('analysisSideDiv', {collapsed: !visible})
    return (
      <div className="analysis-container" id={analysisId}
           style={{
             minWidth: '1187px',
             paddingTop: '25px',
             border: 'dotted thin gray',
             textAlign: 'center',
             marginTop: '20px',
             marginBottom: '25px',
             backgroundColor: isDerivative ? 'lightblue' : '#e3eaed'
           }}>
        {this.state.displayWizard ?
          <DerivativeWizard
            createDerivative={this.createDerivative}
            hideWizard={this.hideWizard}
            initialSelectionState={this.initialSelectionState}
          />
          : null}

        <div className="analyze-header">
          <table style={{width: '100%', position: 'relative', top: '-8px'}}>
            <tbody>
            <tr>
              <td> {/* added this td because react doesn't like tooltip as child of table row */}
                <ReactTooltip html={false}
                              place="top"
                              type="light"
                              border={true}
                              effect="float"
                              id='parentChildLinkCell'/>
              </td>
              <td>
                <span id='parentChildLinkCell'
                      style={{width: '25%'}}
                      data-tip='Scroll to linked chart'
                      data-for='parentChildLinkCell'
                      data-offset="{'top': 0, 'left': 0}"
                      data-delay-show='200'
                      data-delay-hide='200'
                      onMouseOver={this.adjustCSS}
                >
                 {this.getLinkString()}
                </span>
              </td>
              <td style={{width: '60%', verticalAlign: 'top'}}>
                <input id={`input-${id}`}
                       type="text"
                       className="analyze-chart-title-input"
                       value={this.state.title}
                       onChange={this.handleTitleChange}
                       placeholder='title'/>
              </td>
              <td style={{width: '25%'}}>
                <div className="analysis-close-container">
                  <i className="fas fa-times"
                     role="button"
                     id={`close-${id}`}
                     onClick={this.closeHandler}
                  ></i>
                </div>
                <div className="analyze-hide-container">
                  {visible ?
                    <i className="fas fa-minus"
                       role="button"
                       id={`hide-${id}`}
                       onClick={this.handleHideShow}>
                    </i> :
                    <i className="fas fa-plus"
                       role="button"
                       id={`show-${id}`}
                       onClick={this.handleHideShow}>
                    </i>}
                </div>
                <div >
                  <img alt="" src="downloadIcon.png"
                       className="analysis-download-icon"
                       id={`chart-${id}`}
                    //onClick={pdfDownload}
                       onClick={e => pdfDownload(e.target.id)}
                  />
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <AnalysisCreator
          user={user}
          analysisId={analysisId}
          title={this.state.title}
          surveys={surveys}
          visible={visible}
          chartHasContent={this.chartHasContent}
          isDerivative={isDerivative}
          derivativeChartType={derivativeChartType}
          hasChild={!!childAnalysisId}
          hideWizard={this.hideWizard}
        />

        {firstDiv ?
          <div className={sideDivClasses}>

            <div className="btnDiv">
              <ReactTooltip html={false}
                            place="left"
                            type="light"
                            border={true}
                            effect="float"
                            id="add-a-chart"/>
              <img id="add-a-chart"
                   className='addChartBtn'
                   data-tip='Add another chart'
                   data-for='add-a-chart'
                   data-offset="{'top': 0, 'left': 20}"
                   data-delay-show='200'
                   data-delay-hide='200'
                   style={{marginBottom: '30px'}}
                   src='addChartTransp.png' alt=""
                   onMouseOver={this.adjustCSS}
                   onClick={addChild}
              />
            </div>
            {this.state.hasContent && !isDerivative ?
              <div className="btnDiv" style={{marginLeft: '5px'}}
                   onClick={this.showWizard}
              >
                <ReactTooltip html={false}
                              place="left"
                              type="light"
                              border={true}
                              effect="float"
                              id="add-child-chart"/>
                <img id="add-child-chart"
                     className='addChartBtn'
                     data-tip='Select elements from this chart to use in another.'
                     data-for='add-child-chart'
                     data-offset="{'top': 0, 'left': 20}"
                     data-delay-show='200'
                     data-delay-hide='200'
                     style={{marginBottom: '30px'}}
                     src='derivativeGraph.png' alt=""
                     onMouseOver={this.adjustCSS}
                />
              </div> : null}
          </div> : null}
      </div>
    )
  }
}

export default AnalysisContainer;