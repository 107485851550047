import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import { editResponseText } from '../../actions/Review/editResponseText'
import { setModalInfo } from '../../actions/setModalInfo'
import { getTranscript } from '../../actions/Review/getTranscript'
import { setUpdatingAssessments } from '../../actions/Review/setUpdatingAssessments'
import ReviewTranscriptRow from './ReviewTranscriptRow'
import ReviewFooterRight from './ReviewFooterRight'
import reviewDoScoring from './reviewDoScoring'
import showAutoSaveError from '../Common/showAutoSaveError'
import showSystemError from '../Common/showSystemError'
import ReactTooltip from 'react-tooltip'
import axios from 'axios'
import showToastr from '../Common/showToastr'
import { toast } from 'react-toastify'

let readOnlyPCframeStyles = {
  position: 'relative',
  top: '0px',
  width: '350px',
  height: '470px',
  margin: 'auto',
  marginTop: '20px'
}

let cellFrameStyles = {
  position: 'relative',
  top: '10px',
  width: '97%',
  height: '97%x',
  marginTop: '0px',
  margin: 'auto'
}

let fullAppFrameStyles = {
  marginLeft: '3px'
}

function handleTouchMove (event) {
  const [backdrop] = document.getElementsByClassName('transcriptFrame')
  const isScrollingBackdrop = backdrop === event.target
  isScrollingBackdrop ? event.preventDefault() : event.stopPropagation()
}

// var outerDivWidth = 0;
var outerDivHeight = 0

var readOnlyTranscriptStyles = {
  overflowY: 'scroll',
  border: 'solid thin gray',
  height: '100%', //99.55%
  width: 'auto',
  backgroundColor: 'white'
}

let fullTranscriptStyles = {
  overflowY: 'scroll',
  border: 'solid thin gray',
  backgroundColor: 'white',
  //width: "99.47%"
  width: 'auto'
}

let cellExplanationHeader = {
  position: 'relative',
  float: 'right',
  color: '#888888',
  fontSize: '24px'
}

let PCExplanationHeader = {
  position: 'relative',
  float: 'right',
  color: '#888888',
  visibility: 'hidden'
}

class ReviewTranscript extends React.Component {
  _isMounted = false

  constructor (props) {
    super(props)
    this.state = {
      editing: false,
      documentDirty: false,
      editorFocused: false,
      playlist: [],
      currentSound: null,
      isCellPhone: false,
      readOnly: false,
      showMessage: true
    }
  }

  componentDidMount () {
    if (
      /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.setState({ isCellPhone: true }) //isCellPhone = true;
      // outerDivWidth = $(window).width();
      outerDivHeight = $(window).height()
      readOnlyTranscriptStyles.height = outerDivHeight * 0.75
      // = "width: " + $(window).width() + " height: " + $(window).height();
      window.addEventListener('touchmove', handleTouchMove, { passive: false })
      window.addEventListener(
        'orientationchange',
        this.handleorientationchange,
        { passive: false }
      )

      /* ***** keep mobile screen from enlarging on screen rotation ***** */
      var viewportmeta = document.querySelector('meta[name="viewport"]')
      if (viewportmeta) {
        viewportmeta.content =
          'width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0'
        document.body.addEventListener(
          'gesturestart',
          function () {
            viewportmeta.content =
              'width=device-width, minimum-scale=0.25, maximum-scale=1.6'
          },
          false
        )
      }
      /* **************************************************************** */
    } else {
      // this.setState({isCellPhone: true}) //uncomment to see cellphone features on PC during development
    }

    let props = this.props
    if (props.match) {
      //sharing a transcript
      const {
        getTranscript,
        match: {
          params: { TIN, testName, showMessage }
        }
      } = this.props
      console.log('===========================================================')
      console.log('showMessage: ', showMessage)
      console.log('===========================================================')
      this.setState({ readOnly: true, showMessage: showMessage === '1' })
      getTranscript(TIN, testName).then(
        transcript => {
          console.log(transcript)
        },
        err => {
          showSystemError()
        }
      )
    } else {
      //normal in-app processing
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
      this._isMounted = true
    }
  }

  handleorientationchange = event => {
    let scrnSpecs = []
    scrnSpecs.push(window.screen.width * window.devicePixelRatio)
    scrnSpecs.push(window.screen.height * window.devicePixelRatio)
    scrnSpecs.sort(function (a, b) {
      return a - b
    }) //lowest to highest

    /* ********* width and height change with rotation on android, not on iphone    *** */
    /* ********* so we just get the values and sort so width is always [0] in array *** */

    if (window.orientation === 0) {
      //in portrait mode

      /* ******* these scaling factors are experimentally determined     **** */
      /* ******* on old androids and iphones. We need to check on others **** */

      if (/iPhone/i.test(navigator.userAgent)) {
        $('#transcript').height(scrnSpecs[1] * 0.3)
        $('#explainDiv').width(scrnSpecs[0] * 0.42)
      } else {
        //other phones
        $('#transcript').height(scrnSpecs[1] * 0.4)
        $('#explainDiv').width(scrnSpecs[0] * 0.56)
      }
    } else {
      //in landscape mode
      if (/iPhone/i.test(navigator.userAgent)) {
        $('#transcript').height(scrnSpecs[0] * 0.27)
        $('#explainDiv').width(scrnSpecs[1] * 0.44)
      } else {
        //other phones
        $('#transcript').height(scrnSpecs[0] * 0.3)
        $('#explainDiv').width(scrnSpecs[1] * 0.54)
      }
    }
  }

  showScore = async () => {
    const { getTranscript, TIN, testName, filteredTable } = this.props
    try {
      getTranscript(TIN, testName).then(transcript => {
        let transcriptArray = [transcript]

        let allScores = reviewDoScoring(transcriptArray)

        this.openReportModal({
          name: 'reportTranscript',
          allScores: allScores,
          transcriptArray: transcriptArray,
          filteredTable: filteredTable
        })
      })
    } catch (err) {
      console.log(err)
    }
  }

  toClipboard = () => {
    const { transcript } = this.props
    let textBuffer = ''

    function addPromptToBuffer (thisText) {
      textBuffer = textBuffer + thisText + '\r\n'
    }

    function addResponseToBuffer (thisText) {
      textBuffer =
        textBuffer +
        thisText +
        '\r\n' +
        '----------------------------------------------------------------------------------------------------------------------' +
        '\r\n'
    }

    function getPromptAndResponse (item) {
      addPromptToBuffer('PROMPT:  ' + item.prompt.promptText)
      addResponseToBuffer('RESPONSE:  ' + item.response.responseText)
    }

    transcript.dialog.forEach(getPromptAndResponse)
    navigator.clipboard.writeText(textBuffer)
  }

  getStyles () {
    if (this.state.isCellPhone) {
      return cellFrameStyles
    } else if (this.state.readOnly) {
      return readOnlyPCframeStyles
    } else return fullAppFrameStyles
  }

  getExplanationHeader () {
    if (this.state.isCellPhone) {
      return cellExplanationHeader
    } else {
      return PCExplanationHeader
    }
  }

  setCurrentSound = id => {
    this.setState({ currentSound: id })
  }

  handleResize = () => {
    $('#transcript').height(window.innerHeight - 328)
  }

  handleResponseBlur = row => {
    const { editResponseText, setUpdatingAssessments, getTranscript } =
      this.props
    this.setDocumentDirty(true)
    this.setEditingFlag(false)
    setUpdatingAssessments(true)
    console.log('props: ', this.props)
    console.log('state: ', this.state)
    editResponseText(row).then(
      () => {
        if (this._isMounted) {
          this.setDocumentDirty(false)
          this.setEditingFlag(false)
        }
      },
      () => {
        showAutoSaveError()
      }
    )
  }

  setEditingFlag = bool => {
    this.setState({ editing: bool })
  }

  setFocusFlag = bool => {
    this.setState({ editorFocused: bool })
  }

  openModal = data => {
    const { setModalInfo } = this.props
    setModalInfo(data)
  }

  openReportModal = data => {
    const { setModalInfo } = this.props
    setModalInfo(data)
  }

  setDocumentDirty = bool => {
    this.setState({ documentDirty: bool })
  }

  documentStatus = () => {
    if (this.state.editorFocused) {
      if (this.state.editing) {
        return <div className='saveIndicatorGreen'></div>
      } else {
        return this.state.documentDirty ? (
          <div className='saveIndicatorOrange'>Saving...</div>
        ) : (
          <div className='saveIndicatorGreen'>All changes saved</div>
        )
      }
    }
  }

  transcriptionErrorsHTML = () => {
    return '<div class="reviewTranscriptEdits">Survey responses can be edited to correct transcription errors.</div>'
  }

  adjustCSS = () => {
    $('.__react_component_tooltip')
      .css('background-color', 'lightgoldenrodyellow')
      .css('font-size', '16px')
  }

  renderTranscriptHeader = () => {
    const { transcript } = this.props
    return (
      <table className='reviewTableHeader'>
        <tbody>
          <tr>
            <th
              style={{
                width: '90%',
                height: '38px',
                fontWeight: 'normal',
                paddingBottom: '2px'
              }}
            >
              Transcript
            </th>
            {this.state.readOnly || !transcript.dialog.length ? null : (
              <th style={{ width: '10%' }}>
                <ReactTooltip
                  place='left'
                  border={true}
                  type='light'
                  effect='float'
                  id='transcript-info'
                />
                <i
                  id='transcript-info'
                  className='fa fa-info-circle'
                  data-tip={this.transcriptionErrorsHTML()}
                  data-for='transcript-info'
                  data-html={true} //this is okay on Firefox
                ></i>
              </th>
            )}
          </tr>
        </tbody>
      </table>
    )
  }

  renderTranscriptBody = () => {
    const { transcript, checkElipseCount, inScoreAll, activeRow } = this.props
    return (
      <div
        id='transcript'
        style={
          this.state.readOnly ? readOnlyTranscriptStyles : fullTranscriptStyles
        }
      >
        <div
          style={{
            top: '-1000px',
            left: '-1000px',
            width: '0px',
            height: '0px'
          }}
        ></div>
        {
        transcript.dialog.map(row => {
          if (row.response) {
            return (
              <ReviewTranscriptRow
                setEditingFlag={this.setEditingFlag}
                setFocusFlag={this.setFocusFlag}
                row={row}
                key={row.id}
                handleResponseBlur={this.handleResponseBlur}
                currentSound={this.state.currentSound}
                disabled={false}
                readOnly={this.state.readOnly}
                dateCompleted={transcript.dateCompleted}
                checkElipseCount={checkElipseCount}
              />
            )
          } else {
            return null
          }
        })
        }
      </div>
    )
  }

  renderReviewIndicator = () => {
    return (
      <span>
        <ReactTooltip
          place='left'
          type='light'
          html={false}
          border={true}
          effect='float'
          id='dirty-indicator'
        />
        <div
          className='saveReviewIndicatorLocator'
          data-tip='Every change you make is automatically saved.'
          data-for='dirty-indicator'
          onMouseOver={this.adjustCSS}
        >
          {this.documentStatus()}
        </div>
      </span>
    )
  }

  handleExplanationClose = () => {
    $('#explainDiv').hide()
  }

  renderTranscriptExplanation = () => {
    const { user } = this.props
    if (this.state.readOnly) {
      /* these values changed depending on screenWidth of device being used */
      /* ****************************************************************** */
      let setTop = '-450px'
      let setLeft = '-290px'
      let setWidth = '270px'
      let textColor = 'black'
      let setBorder = 'none'
      let setBackground = '#FFFFFF'
      let setOpacity = '0.95'
      let setPosition = 'relative'
      let setShadow = 'none'
      let shareButtonMarginLeft = '120px'
      $('#shareButton').css('margin-left', shareButtonMarginLeft) //slightly adjusts the play
      //button positioning when
      //explanatory text affects
      //its positioning
      /* ****************************************************************** */
      if (this.state.isCellPhone) {
        //...MinWidth set on line 23
        setTop = '50px'
        setLeft = '15px'
        setWidth = $('#transcript').width() - 30 //'270px';
        textColor = 'black'
        setBorder = 'solid thin gray'
        setBackground = '#ffffff'
        setOpacity = '0.92'
        setPosition = 'fixed'
        shareButtonMarginLeft = '30px'
        setShadow = '5px 5px #888888'
        $('#shareButton').css('margin-left', shareButtonMarginLeft) //restores normal positioning
      }
      /* ****************************************************************** */
      return (
        <div
          id='explainDiv'
          style={{
            width: setWidth,
            //maxWidth: '300px',
            padding: '7px',
            height: 'auto',
            border: setBorder,
            boxShadow: setShadow,
            position: setPosition,
            top: setTop,
            left: setLeft,
            color: textColor,
            backgroundColor: setBackground,
            opacity: setOpacity,
            borderRadius: '5px',
            zIndex: 100
          }}
        >
          <span style={this.getExplanationHeader()}>
            <i
              onClick={this.handleExplanationClose}
              className='fas fa-window-close'
            ></i>
          </span>
          <br></br>
          This audio-transcript has been shared with you by <b>{user.email}</b>,
          who felt you would be interested in hearing the prompts and responses.
        </div>
      )
    }
  }

  componentWillUnmount () {
    if (!this.state.readOnly) {
      window.removeEventListener('resize', this.handleResize)
      window.removeEventListener('touchmove', this.handleTouchMove)
      this._isMounted = false
    }
  }

  render () {
    const { transcript, TIN, testName, willPoll, activeRow } = this.props

    return (
      //medium border subtract 6 pixels
      <div
        className='transcriptFrame'
        style={{
          marginTop: '0px',
          border: 'none',
          height: '97%',
          marginLeft: '0px',
          width: 'auto'
        }}
      >
        <div style={this.getStyles()}>
          {this.renderReviewIndicator()}
          {this.renderTranscriptHeader()}
          {this.renderTranscriptBody()}
          {this.state.showMessage ? (
            <div style={{ position: 'relative', height: '0px', width: '0px' }}>
              {this.renderTranscriptExplanation()}
            </div>
          ) : null}

          <ReviewFooterRight
            showScore={this.showScore}
            toClipboard={this.toClipboard}
            setCurrentSound={this.setCurrentSound}
            hasTranscript={transcript.dialog.length}
            transcript={transcript}
            TIN={TIN}
            testName={testName}
            openModal={this.openModal}
            readOnly={this.state.readOnly}
            willPoll={willPoll}
            activeRow={activeRow}
            assessmentInProgress={this.props.transcript.assessmentInProgress}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    transcript: state.transcript,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      editResponseText,
      setModalInfo,
      getTranscript,
      setUpdatingAssessments
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewTranscript)
