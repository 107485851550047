import {GET_NAMED_ASSIGNMENTS} from '../_CONSTANTS';
import $ from 'jquery';
import _ from 'lodash';

export const get_Named_Assignments = (batch, testName, accountId) => {
  return function (dispatch, getstate) {
    return new Promise((resolve, reject) => {
      let url = `/test/getNamedAssignments?batch=${batch}&testName=${testName}&accountId=${accountId}`
      // let url = '/testerror'
      let newTable = getstate().assign_Table;
      newTable = {...newTable, ...{
        accountId: accountId,
        testName: testName,
        batch: batch
      }}

      $.ajax({
        url: url,
        type: "GET",
        success: (result) => {
          let rows = [];
          result.assignments.forEach((assignment) => {
            // eslint-disable-next-line
            let validEmail = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(assignment.email)) || !assignment.email;
            let obj = {};
            for (let key in assignment) {
              if(assignment.hasOwnProperty(key)){
                obj[key] = assignment[key];
              }
            }
            obj['participantCode'] = assignment.TIN;
            obj['participantName'] = assignment.testTaker;

            // let obj = {
            //   TIN: assignment.TIN,
            //   assessmentEmailTitle: assignment.assessmentEmailTitle,
            //   badEmail: !validEmail,
            //   dateAssigned: assignment.dateAssigned,
            //   chatAssessments: assignment.chatAssessments,
            //   completedAssessments: assignment.completedAssessments,
            //   completionError: assignment.completionError,
            //   email: assignment.email,
            //   error: assignment.error,
            //   isDeleted: false,
            //   accountId: assignment.accountId,
            //   participantCode: assignment.TIN,
            //   participantName: assignment.testTaker,
            //   inviteSent: assignment.inviteSent,
            //   status: assignment.status,
            //   _id: assignment._id
            // }
            rows.push(obj);
          })
          newTable.rows = rows;

          dispatch({
            type: GET_NAMED_ASSIGNMENTS,
            payload: newTable
          })
          resolve(newTable)
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}
