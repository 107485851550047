import {GET_REVIEW_TABLE, ADD_COMPLETED_ASSIGNMENT} from '../actions/_CONSTANTS';
//use of immer from: https://www.pluralsight.com/guides/deeply-nested-objectives-redux

const review_Table = (state = {}, action) => {
  switch (action.type) {
    case GET_REVIEW_TABLE:
      return action.payload;
    case ADD_COMPLETED_ASSIGNMENT:
    return {
      survey: state.survey,
      assignments: action.payload
    }
    default:
      return state;
  }
}

export default review_Table;

