import {GET_CREATE_TABLE} from "../actions/_CONSTANTS";
import {EDIT_CREATE_TABLE_ROW} from "../actions/_CONSTANTS";
import {ADD_CREATE_TABLE_ROW} from "../actions/_CONSTANTS";
import {DEL_CREATE_TABLE_ROW} from "../actions/_CONSTANTS";
import {SEQUENCE_CREATE_TABLE_ROW} from "../actions/_CONSTANTS";
import {CLEAR_CREATE_TABLE} from '../actions/_CONSTANTS';
import {SET_COPIED_TEST} from '../actions/_CONSTANTS';
import {DEFAULT_TEST_DELAY} from '../actions/_CONSTANTS';
import {DEFAULT_SURVEY_DELAY} from '../actions/_CONSTANTS';
import {TOGGLE_WANTS_EMAIL} from '../actions/_CONSTANTS';
import {SAVE_REPORT_TITLE} from '../actions/_CONSTANTS';
import {RETRIEVE_SAVED_PROMPT} from '../actions/_CONSTANTS';

import _ from 'lodash';

const ObjectID = require("bson-objectid");


const create_Table = (state = {}, action) => {
  let rows;
  let newRows;

  let _id = ObjectID().str;
  let blankRow = {
    _id: _id,
    promptPosition: '',
    promptText: '',
    promptPositionRevert: '',
    promptId: '',
    awaitResponse: true,
    imageURL: '', //note repurposed. used to hold both jpg and mp4 urls
    toggleLabel: "image or MP4",//old field to be removed
    displayText: "",//old field to be removed
    delay: '10',//old field to be removed
    soundFileURL: '',
    isBlank: true,
    isDeleted: false,
    eggTimer: '0',
    //new fields
    audioOnly: true,
    mediaOption: 'image',
    videoURL: '',
    htmlText: '',
    repeatArrow: true,
    nextArrow: true,
    eggTimerEnabled: false,
    spokenURLEnabled: false,
    spokenURL: '',
    replayOnSilence: true,
    version: 2,
    questionDelay: '10',
    instructionDelay: '2',
    //end new fields


    scoringParameters: {
      checkbox: false,
      maxPts: '',
      correctAnswers: [],
      wrongAnswers: []
    },
    chatGPTPrompt: {
      checkbox: false,
      gptPrompt: ''
    },
    checkGrammar: false,
    checkPronunciation: false,
    checkSentiment: {
      checkbox: false,
      displayInEmail: true
    }
  }
  switch (action.type) {

    case GET_CREATE_TABLE:
      let newTable = {...action.payload}
      blankRow.delay = action.payload.instrumentType === "TEST" ? DEFAULT_TEST_DELAY : DEFAULT_SURVEY_DELAY
      blankRow.testName = action.payload.testName;
      blankRow.accountId = action.payload.accountId;
      newTable.rows.push(blankRow);
      return newTable;

    case EDIT_CREATE_TABLE_ROW:
      rows = state.rows;
      newRows = [];
      rows.forEach((row) => {
        if (row._id === action.payload._id) {
          newRows.push(action.payload);
        } else {
          newRows.push(row);
        }
      });
      return {...state, ...{rows: newRows}}


    case SEQUENCE_CREATE_TABLE_ROW:
      rows = {...state.rows};
      let sortedRows = _.sortBy(rows, [function (row) {
        return parseInt(row.promptPosition)
      }]);
      return {...state, ...{rows: sortedRows}};


    case DEL_CREATE_TABLE_ROW:
      rows = [...state.rows];
      rows.forEach((row) => {
        if (row._id === action.payload) {
          row.isDeleted = true;
        }
      })
      return {...state, ...{rows: rows}};

    case ADD_CREATE_TABLE_ROW:
      rows = [...state.rows];
      blankRow.delay = action.payload.instrumentType === "TEST" ? DEFAULT_TEST_DELAY : DEFAULT_SURVEY_DELAY
      blankRow.testName = action.payload.testName;
      blankRow.accountId = action.payload.accountId;
      rows.push(blankRow);
      return {...state, ...{rows: rows}}

    case CLEAR_CREATE_TABLE:
      return {
        isDirty: false,
        accountId: '',
        rows: [],
      }
    case SET_COPIED_TEST:
      return action.payload;
    case TOGGLE_WANTS_EMAIL:
    case SAVE_REPORT_TITLE:
      return {...state, ...action.payload}
    case RETRIEVE_SAVED_PROMPT:
      rows = [...state.rows];
      let index = rows.findIndex((row) => {
        return row._id === action.payload._id;
      })
      rows[index] = action.payload;
      return {...state, ...{rows: rows}}


    default:
      return {...state};
  }
}

export default create_Table