import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setActiveModule} from '../../actions/setActiveModule';
import {incrementVisitsHome} from '../../actions/incrementVisitsHome';
import ManageSubMenu from './ManageSubMenu';
import ManageContent from './ManageContent';
import Navbar from '../Navbar';
import _ from 'lodash';

export const UserContext = React.createContext(null);

class Manage extends React.Component {


  componentDidMount() {
    const {setActiveModule, incrementVisitsHome, user} = this.props;
    setActiveModule('Manage');
    if (!_.isEmpty(user)) {
      incrementVisitsHome(user);
    }
  }


  render() {
    const {user, history, match} = this.props;
    if (_.isEmpty(user)) {
      history.push('/')
      return (
        null
      )
    }
    let subPath = match.url.split('/')[2];
    return (
      <UserContext.Provider value={{email: user.email, accountId: user.accountId}}>

        <Navbar history={history}/>
        <ManageSubMenu user={user} history={history} subPath={subPath}/>
        <ManageContent subPath={subPath} user={user}/>

      </UserContext.Provider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    socket: state.socket
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setActiveModule,
    incrementVisitsHome
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Manage);