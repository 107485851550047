import React from 'react';
import ContentEditable from 'react-contenteditable';
import ReactTooltip from 'react-tooltip';
import slicedTin from '../../Common/slicedTin';
import $ from 'jquery';
import {DropdownList}  from 'react-widgets'
import surveyInstructions from '../../email_templates/surveyInstructions';
import salutation from '../../email_templates/salutation';
import showSystemError from '../../Common/showSystemError';


class NamedEmailInvite extends React.Component {
  $hiddenDiv = null;

  constructor(props) {
    super(props);
    const {modalInfo} = props;
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      templates: modalInfo.inviteTemplates,
      activeTemplate: modalInfo.activeTemplate,
      html: modalInfo.activeTemplate.html,
      documentDirty: false,
      templateName: '',
      errorText: '',
      subject: modalInfo.activeTemplate.subject,
      phoneResponses: false,
      computerResponses: true
    }


    $('#root').append('<div id="hiddenDiv"></div>');
    var docWidth = window.innerWidth;
    this.$hiddenDiv = $('#hiddenDiv');
    this.$hiddenDiv.css({
      position: 'relative',
      display: 'inline-block',
      color: 'black',
      left: (docWidth * 3) + 'px'
    }).html(this.state.html);

  }

  handleCancel(e) {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  constructAndSendEmails = () => {
    const {modalInfo, hideModal, confirmHandler} = this.props;
    console.log("modalInfo: ", modalInfo);
    var responseMode = "CP";
    if (!this.state.computerResponses){responseMode = "P"}
    if (!this.state.phoneResponses){responseMode = "C"}
    if (!this.state.phoneResponses && !this.state.computerResponses){responseMode = ""}
    modalInfo.rows.forEach((row) => {
      let urlQueryString = row.TIN +
                          "&testName="  + modalInfo.testName +
                          "&accountId=" + modalInfo.activeTemplate.accountId +
                          "&mode="      + responseMode;

      let instructionsHTML = surveyInstructions(`${modalInfo.TAKER_HOST}/?TIN=${urlQueryString}`,
                                               `${slicedTin(row.TIN)}`, responseMode);

      let salutationHTML = salutation(row.participantName)
      let $html = this.$hiddenDiv.clone();
      $html = $html.prepend(salutationHTML).append(instructionsHTML);
      modalInfo.sendNamedInviteEmail($html.html(), row.email, this.state.subject, row._id)
        .then(confirmHandler)
    })
    hideModal();
  }

  handleSaveTemplate = () => {
    const {modalInfo} = this.props;
    let saveTemplate = modalInfo.saveTemplate;
    let name = this.state.templateName;
    let templateNames = this.state.templates.map((t) => {
      return t.name;
    })
    if (name === "default") {
      this.setState({errorText: 'You cannot change the default template.'})
    }
    else if (templateNames.indexOf(name) !== -1 && name !== this.state.activeTemplate.name) {
      this.setState({errorText: "This template name is already in use."})
    }
    else if (!name) {
      this.setState({errorText: 'You must enter a template name.'})
    }
    else {
      let newTemplate = {
        ...this.state.activeTemplate, ...{
          name: name,
          html: this.state.html,
          subject: this.state.subject
        }
      };
      saveTemplate(newTemplate)
        .then((template) => {
            this.setState({
              activeTemplate: template,
              templateName: name,
              documentDirty: false,
              templates: [...this.state.templates, ...[template]]
            })
          },
          (err) => {
            console.log(err);
            showSystemError();
          })
    }
  }

  handleEdit = (e) => {
    this.setState({
      documentDirty: true,
      html: e.currentTarget.innerHTML
    });
    this.$hiddenDiv.html(e.currentTarget.innerHTML)
    $('#copyIcon').css('color', 'transparent');
  }

  handleTemplateChange = (value) => {

    this.setState({
      activeTemplate: value,
      templateName: '',
      documentDirty: false,
      html: value.html,
      subject: value.subject
    })
    this.$hiddenDiv.html(value.html)
    $('#copyIcon').css('color', 'transparent');
  }

  handleTemplateNameChange = (e) => {
    this.setState({templateName: e.target.value})
  }

  handleSubjectChange = (e) => {
    this.setState({
      documentDirty: true,
      subject: e.target.value,
    });
  }

  handleCheckBoxChange = (e) => {
    if(e.target.id === "phoneCheck"){
      if (this.state.phoneResponses){this.setState({phoneResponses:false})
        } else { this.setState({phoneResponses:true})}
    } else {
      if (this.state.computerResponses){
          this.setState({computerResponses:false})
        } else { this.setState({computerResponses:true})}
    }
  }

  renderInstructions = () => {
    let phoneRef = "tel:" + window.TWILIO_NUMBER;
    return (
      <div className="invite-instructions"
           id="invite-instructions"
           data-for="invite-instructions"
           data-tip="">
        <div style={{fontStyle: 'italic'}}>Instructions:</div>

        {this.state.phoneResponses ?
<div>
<br/>
        <div
          style={{marginLeft: '20px', marginRight: '20px', fontStyle: 'italic'}}>
          To participate with your phone, dial:&nbsp;
          <a style={{fontSize: '16px'}} href={phoneRef}>{window.TWILIO_NUMBER}</a>
          &nbsp; and key-in this  number when
          prompted: <strong>[ticket number].</strong>
        </div>
        <br></br>
        <div style={{marginLeft: '20px', marginRight: '20px', fontStyle: 'italic'}}>
          If you are using a cell phone it will help to jot this number down so
          you will have it available for key-in when you are prompted to enter it.
        </div>
        </div>
          : null}
  {this.state.computerResponses ?
  <div>
        <br/>
        <div style={{marginLeft: '20px', marginRight: '20px', fontStyle: 'italic'}}>
          To participate with your computer click&nbsp;
          <a href='#' >try it now.</a>
          <br></br>
          The computer you use must have speakers and a microphone, or a headset with a mic.
          <br></br>If you use a cell phone, wear earbuds or a headset for good audio quality.
        </div>
        <br/>
        <br/>
   </div>
   : null}
      </div>
    )
  }

  ////////////////////////////////////  tooltips ////////////////////

  editInstructionsTooltip = () => {
    return (
      <div className='emailInviteKeyboardShortcuts'>
        <strong>KEYBOARD SHORTCUTS</strong><br></br><br></br>
        <table>
          <tbody>

          <tr>
            <td><strong>Windows</strong></td>
            <td><strong>&nbsp;&nbsp;Macintosh</strong></td>
            <td></td>
          </tr>

          <tr>
            <td>Ctrl+b</td>
            <td>Cmd+b</td>
            <td><strong>bold</strong></td>
          </tr>
          <tr>
            <td>Ctrl+i</td>
            <td>Cmd+i</td>
            <td><i>italic</i></td>
          </tr>
          <tr>
            <td>Ctrl+u</td>
            <td>Cmd+u</td>
            <td><u>underline</u></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Ctrl+z</td>
            <td>Cmd+z</td>
            <td>undo</td>
          </tr>
          <tr>
            <td>Ctrl+y</td>
            <td>Cmd+y</td>
            <td>redo</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Ctrl+x</td>
            <td>Cmd+x</td>
            <td>cut</td>
          </tr>
          <tr>
            <td>Ctrl+c</td>
            <td>Cmd+c</td>
            <td>copy</td>
          </tr>
          <tr>
            <td>Ctrl+v</td>
            <td>Cmd+v</td>
            <td>paste</td>
          </tr>

          </tbody>
        </table>
      </div>
    )
  }

  instructionsTooltip = () => {
    return (
      <div className="emailInviteInstructions" style={{fontSize: '15px', width: '250px', fontFamily: 'sans-serif'}}>
        <p>These instructions will be automatically added to your email when you click
          the send button.</p>
        <p>They contain the specific data to allow your user to connect and take your
          survey.</p>
      </div>
    )
  }

  salutationTooltip = () => {
    return (
      <div style={{fontSize: '15px', width: '250px', fontFamily: 'sans-serif'}}>
        <p>This salutation along with the participant's name will automatically be added
          to your email when you click the send button.</p>
      </div>
    )
  }

  sendEmailTooltip = () => {
    if (this.state.documentDirty) {
      return (
        <div>
          <div>You have modified this template.</div>
          <div>You need to name and save it before sending.</div>
        </div>
      )
    }
    else return null
  }

  //////////////////////////////////////////////////////////////////

  render() {
    return (
      <div>
        <div className="emailModal-body" style={{border: 'solid thin transparent'}}>
        <table style={{fontFamily:'sans-serif',fontSize:'14px'}}><tbody><tr><td>&nbsp;Request responses by:</td>
          <td><input type="checkbox" id="compCheck"  name="compCheck" onChange= {this.handleCheckBoxChange} value="c" checked={this.state.computerResponses}></input>&nbsp;computer</td>
          <td><input type="checkbox" id="phoneCheck" name="phoneCheck" onChange= {this.handleCheckBoxChange} value="p" checked={this.state.phoneResponses}></input>&nbsp;phone</td>
        </tr></tbody></table>
          <div className="email-invite-subheader"
               style={{border: 'solid thin transparent', width: '100%'}}>
            Modify this email template as needed to invite participants.
            You can name and save your edited version of the template for future use.
            <div className="template-dropdown">
              <DropdownList
                style={{
                  width: '200px',
                  marginLeft: '15px',
                  position: 'relative',
                  float: 'right'
                }}
                data={this.state.templates}
                valueField="name"
                textField="name"
                defaultValue={this.state.activeTemplate}
                value={this.state.activeTemplate}
                onChange={this.handleTemplateChange}
              />
              <div id="infoLocator"
                   style={{
                     paddingRight: '25px',
                     float: 'right',
                     width: '100%',
                     height: '230%',
                     textAlign: 'right',
                   }}>
                <div style={{position: 'relative', top: '100%'}}>
                  <ReactTooltip id="edit-instructions"
                                place="left"
                                type="light"
                                border={true}
                                getContent={this.editInstructionsTooltip}/>
                  <i id="edit-instructions"
                     className="fa fa-info-circle"
                     style={{
                       position: 'relative',
                       left: '10px'
                     }}
                     data-for="edit-instructions"
                     data-border="false"
                     data-tip=""
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="email-invite-subheader">
            <div style={{marginTop: '5px'}}>
              Email subject:
              <input className="textInputWidgetMatch" id="subject" name="subject"
                     style={{width: '350px', padding: '5px', marginLeft: '20px'}}
                     type="text" value={this.state.subject}
                     onChange={this.handleSubjectChange}/>
            </div>
          </div>
          <div className="editable-template-container"
               style={{marginTop: '20px', marginBottom: '10px'}}>
            <ReactTooltip
              id="pName"
              place="top"
              type="light"
              border={true}
              getContent={this.salutationTooltip}

            />
            <div id="pName" className="invite-instructions"
                 data-for="pName"
                 data-tip="">
              Dear [participant name],
            </div>
            <ContentEditable
              id="email-template"
              className="editable-template"
              html={this.state.html}
              onChange={this.handleEdit}
            />
            <div>
              <ReactTooltip id="invite-instructions"
                            place="top"
                            type="light"
                            border={true}
                            getContent={this.instructionsTooltip}
              />
              {this.renderInstructions()}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <table style={{width: '100%'}}>
            <tbody>
            <tr>
              <td style={{width: '300px'}}>
                <div className="">
                  <input
                    className="textInputWidgetMatch"
                    id="testNameInput"
                    type="text"
                    name="TemplateName"
                    onFocus={() => {
                      this.setState({errorText: ''})
                    }}
                    value={this.state.templateName}
                    onChange={this.handleTemplateNameChange}
                    placeholder="Template name"
                    style={{
                      position: 'relative',
                      float: 'left',
                      verticalAlign: 'bottom',
                      fontSize: '14px',
                      width: '200px',
                      height: '24px',
                    }}>
                  </input>
                  <button className="orangeButton"
                          onClick={this.handleSaveTemplate}
                          disabled={!this.state.documentDirty}
                          style={{
                            marginLeft: '5px',
                            position: 'relative',
                            float: 'left',
                            marginTop: '4px'
                          }}>SAVE
                  </button>
                </div>
              </td>
              <td id="error-div"
                  style={{textAlign: 'left', color: 'red'}}>{this.state.errorText}</td>
              <td>
                <div className="btn-group pull-right" style={{margin: '5px 0'}}>

                  <button className="blueButton pull-right"
                          style={{marginRight: '20px'}}
                          onClick={this.handleCancel}>
                    Cancel
                  </button>
                  <i className="far fa-copy"
                     id="copyIcon"
                     style={{
                       paddingRight: '10px',
                       fontSize: '24px',
                       color: 'transparent'
                     }}></i>
                  <ReactTooltip id="sendButton"
                                place="top"
                                type="dark"
                                getContent={this.sendEmailTooltip}
                  />
                  <span id="sendButton"
                        data-tip=""
                        data-for="sendButton">
                    <button className="orangeButton pull-right"
                            onClick={this.constructAndSendEmails}
                            disabled={(this.state.documentDirty)||(!this.state.computerResponses && !this.state.phoneResponses)}
                            style={{marginRight: '5px'}}>SEND
                    </button>
                  </span>


                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default NamedEmailInvite;