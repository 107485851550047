import React from 'react'
import { CsvBuilder } from 'filefy'
import ReactTooltip from 'react-tooltip'
import $ from 'jquery'

class SubstitutionList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedFile: null,
      allDisabled: false,
      dropHover: false
    }
  }

  subListErrors = file_contents => {
    let error = [0, 0]  //elem 1 = error type, elem 2 equals row of table it occurred in, [0,0] = no errors found
    console.log('file_contents: ', file_contents)

    /* no comma found in the row */
    for (var i = 1; i < file_contents.length - 1; i++) {
      if (!file_contents[i].includes(',')) {
        error = [4, i]
        return error
      }
    }

    /* empty first cell in row */
    for (var i = 1; i < file_contents.length - 1; i++) {
      if (file_contents[i].split('')[1] === ',') {
        error = [5, i]
        return error
      }
    }

    /* quotation mark in row */
    for (var i = 0; i < file_contents.length - 1; i++) {
      if (file_contents[i].includes('"')) {
        error = [6, i]
        return error
      }
    }

    if (!file_contents[0].includes(',')) {
      //missing comma in header row
      error = [1, 0]
      return error
    } else if (file_contents[0].split(',')[0] !== 'ORIGINAL') {
      error = [2, 0]
      return error
    } else if (file_contents[0].split(',')[1] !== 'SUBSTITUTE') {
      error = [3, 0]
      return error
    }

    return error //returns [0,0] here, file is okay to upload
  }

  resetDragDropBox = () => {
    console.log("resetting dragDropBox")
    $('.dragDropBox').css('background-color', 'white');
    $('#upload-csv').css('border', 'solid 1px #ffffff');
    $('#modalInfoPopup').remove();
    $('#fileBrowse').val('');
  }

  onFileSelect = e => {
    $('#modalInfoPopup').remove(); //in case it is open
    let This = this
    this.setState({
      selectedFile: e.target.files[0]
    })

    let subTable = "<table id='subTable'>" + "<tbody id='tbody'></tbody>" + '</table>'
    $('#csvContainer').append(
      '<div id="modalInfoPopup">' +
        '<div id="SubListInfo">SUBSTITUTION LIST' +
        '<span id="subListClose">X</span></div>' +
        '<div id="clickToSaveMsg">Click the <span style="font-weight:bold;">UPLOAD</span> button to save this list</div><br>' +
        '<div id="messageBody" class="subListStyle3">"' +
        e.target.files[0].name +
        '"</div></div>'
    )
    $('#subListClose').on('click', function () {
      This.resetDragDropBox();
      This.setState({
        selectedFile: null,
        allDisabled: false
      })
    })

    $('#modalBodyContainer').animate(
      {
        scrollTop: $('#modalInfoPopup')[0].scrollHeight + 300
      },
      'slow'
    )
    var file = e.target.files[0]
    var filename = e.target.files[0].name
    var reader = new FileReader()
    var errorNumb = [0, 0]
    reader.onload = function () {
      let contents = reader.result;
      console.log("contents: ", contents);
      var charArray = contents.split('');

      // Check if the last two characters are not "0D0A" (parsing for errors requires each line to be terminated this way).
      let correctEndArray = ['\r', '\n']
      let matchedCharsArray = [false, false]
      const lastTwoChars = charArray.slice(-2) // Assuming the file ends with two characters and a newline character.
      console.log('lastTwoChars: ', correctEndArray)

      if (correctEndArray[0] === lastTwoChars[0]) matchedCharsArray[0] = true
      if (correctEndArray[1] === lastTwoChars[1]) matchedCharsArray[1] = true

      if (matchedCharsArray[0] && matchedCharsArray[1])
        console.log('Ends correctly')
      if (!matchedCharsArray[0] && !matchedCharsArray[1]) {
        console.log('Needs both ending chars')
        contents = contents + '\r\n'
      }
     /* ***************************************************************************************************************** */
      let file_contents = contents.toString().split('\r')
      console.log(file_contents[0], file_contents[1])
       file_contents[0] = file_contents[0].replace(/ /g, '_');

      errorNumb = This.subListErrors(file_contents)
      console.log('errorArray: ', errorNumb)
      if (!errorNumb[0]) {
        // file is okay to upload
        $('#modalInfoPopup').append(subTable)
        $('#upload-csv').css('border', 'solid thick red')
        for (var i = 0; i < file_contents.length - 1; i++) {
          let col2str = file_contents[i].split(',')[1]
          if (col2str === '') {
            col2str =
              "<span style='color: red;'>This cell is blank - occurances of the text in the left column will be erased.</span>"
          } else {
            col2str = "<span style='color: black;'>" + col2str + '</span>'
          }
          $('#tbody').append(
            '<tr><td class="subListTableCell">' +
              file_contents[i].split(',')[0] +
              '</td>' +
              '<td class="subListTableCell">' +
              col2str +
              '</td></tr>'
          )
        }
      } else {
        let message =
          'There is a problem with the contents of <span style="font-weight: bold;">"' +
          filename +
          '"</span>'
        $('#clickToSaveMsg').html(message)
        $('#fileBrowse').val('')
        This.setState({ allDisabled: true })
        $('#modalInfoPopup').append(subTable)
        for (var i = 0; i < file_contents.length - 1; i++) {
          $('#tbody').append(
            '<tr><td class="subListTableCell">' +
              file_contents[i].split(',')[0] +
              '</td><td class="subListTableCell">' +
              file_contents[i].split(',')[1] +
              '</td></tr>'
          )
        }

        if (errorNumb[0] === 1) {
          $('#messageBody')
            .html(
              'The first cell in the first row of the .csv file must have the heading: ORIGINAL' +
                ' and the second cell in the first row must have the heading: SUBSTITUTE. ' +
                'The words ORIGINAL,SUBSTITUTE must be separated only by a comma.'
            )
            .css('text-align', 'left')
          $('.subListTableCell:eq(0)').css('background-color', 'yellow')
          $('.subListTableCell:eq(1)').css('background-color', 'yellow')
        } else if (errorNumb[0] === 2) {
          $('#messageBody')
            .html(
              'The first cell in the first row of the .csv file must contain the heading: ORIGINAL'
            )
            .css('text-align', 'left')
          $('.subListTableCell:eq(0)').css('background-color', 'yellow')
        } else if (errorNumb[0] === 3) {
          $('#messageBody')
            .html(
              'The second cell in the first row of the .csv file must contain the heading: SUBSTITUTE'
            )
            .css('text-align', 'left')
          $('.subListTableCell:eq(1)').css('background-color', 'yellow')
        } else if (errorNumb[0] === 4) {
          $('#messageBody')
            .html(
              'The highlighted row is missing a comma after the first word or phrase.'
            )
            .css('text-align', 'left')
          $('.subListTableCell:eq(' + errorNumb[1] * 2 + ')').css(
            'background-color',
            'yellow'
          )
          $('.subListTableCell:eq(' + (errorNumb[1] * 2 + 1) + ')').css(
            'background-color',
            'yellow'
          )
        } else if (errorNumb[0] === 5) {
          $('#messageBody').html(
            'The hightlighted table cell should not be empty'
          )
          $('.subListTableCell:eq(' + errorNumb[1] * 2 + ')').css(
            'background-color',
            'yellow'
          )
        }else if (errorNumb[0] === 6) {
          $('#messageBody')
            .html(
              'There should be no quotation characters in the file you uploaded.<br>' +
                'Please use Excel or Google Sheets to modify the file and then<br> save ' +
                'it as a .csv (comma separated value) file to ensure proper formatting.'
            )
          $('.subListTableCell:eq(' + errorNumb[1] * 2 + ')').css(
              'background-color',
              'yellow'
            )
          $('.subListTableCell:eq(' + (errorNumb[1] * 2 + 1) + ')').css(
              'background-color',
              'yellow'
            )
        }
      }
    }
    reader.readAsText(file);
  }

  downloadSubstitutions = async () => {
    const { accountId, testName } = this.props
    $.ajax({
      type: 'GET',
      url: `/test/getSubstitutions?accountId=${accountId}&testName=${testName}`,
      success: data => {
        data.sort(function (a, b) {
          return parseInt(a.id) - parseInt(b.id)
        })
        console.log('substitutions: ', data)
        let results = data.map(item => {
          return [item.original, item.substitute]
        })

        let headings = ['ORIGINAL', 'SUBSTITUTE']
        let csvTitle = `substitution list for ${testName}`
        new CsvBuilder(csvTitle)
          .setColumns(headings)
          .addRows(results)
          .exportFile()
      },
      error: err => {
        console.log(err)
      }
    })
  }

  uploadFile = data => {
    console.log('in uploadFile')
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/upload',
        type: 'POST',
        data: data,
        processData: false,
        contentType: false,
        success: result => {
          resolve(result.csv)
          this.dismissSubstList()
        },
        error: err => {
          console.log(err)
          reject(err)
        }
      })
    })
  }

  dismissSubstList = () => {
    console.log('dismissing substitution list')
    $('.dragDropBox').css('background-color', 'white')
    $('#modalInfoPopup').remove()
    $('#fileBrowse').val('')
    this.setState({
      selectedFile: null
    })
  }

  handleUpload = () => {
    const { accountId, testName } = this.props
    const data = new FormData()
    data.append('file', this.state.selectedFile)
    this.uploadFile(data).then(data => {
      let substitutes = []
      data.forEach((item, index) => {
        substitutes.push({
          id: index,
          accountId,
          testName,
          original: data[index].ORIGINAL,
          substitute: data[index].SUBSTITUTE
        })
      })
      console.log('substitution array: ', substitutes)
      $.ajax({
        type: 'POST',
        url: '/test/updateSubstitutionCollection',
        data: {
          accountId,
          testName,
          substitutes: substitutes
        },
        success: () => {
          $('#upload-csv').css('border', 'solid 1px #ffffff')
          console.log('substitution collection successfully updated.')
        },
        error: err => {
          console.log(err)
        }
      })
    })
  }

  adjustCSS = () => {}

  openCsvModal = () => {
    let explainModelOpen = $('#i-clickedDiv').length;  //if div exists set to true, else to false
    this.resetDragDropBox(); //in case it is open when someone clicks the (i) icon, need to reset the drop box before showing this explanation
    let thisHTML = '<div id="i-clickedDiv">The purpose of substitution lists is to provide a way to correct predictable but unwanted transcriptions ' +
    'of respondent\'s speech which you would like to revise before these responses are evaluated.&nbsp;&nbsp;' +
    'For example, in a hospital setting, a doctor may say "ICU", referring to the intensive care unit, ' +
    'but the transcription may be "I see you."<br><br> A substitution list allows you to request that any occurances of&nbsp;&nbsp;' +
    '"I see you" be replaced by "ICU" before assessments and reporting occur.' +
    '<div style="text-align: center; padding: 12px;">' +
      '<img src="formatCSV.png" width="250" style="border: solid thin #1f3e7b;">' +
    '</div>' +
    '<div style="text-align: left;">Use a spreadsheet to create a 2-column list of target words or phrases (denoted as ORIGINAL) and words or phrases you ' +
    'want the targets to be replaced with (denoted as SUBSTITUTE).  Then save the list as a .csv file, and upload the file using the ' +
    'Import a CSV File controls above this explanatory note.<br><br>' +
    'You can have as many rows of substitutions in this list as you wish. Each time you upload a list it will replace the prior ' +
    'uploaded list. In creating these lists remember that the first row must contain "ORIGINAL" in cell "A1" and "SUBSTITUTE" in cell "B1" with no punctuation ' +
    'and no extra space characters. Each survey or test script you create can use the same substitution list that you have used for other scripts, ' +
    'or each script can have a list unique to just that script, but for every script that requires a list you must separately ' +
    'upload the list for that script using this feature.</div>';

    if (explainModelOpen) {
        $('#modalInfoPopup').remove() //toggle it closed
    } else { //toggle it open
      $('#csvContainer').append(
        '<div id="modalInfoPopup">' + thisHTML  +
        '</div>'
      )
      $('#modalBodyContainer').animate(
        {
          scrollTop: $('#modalInfoPopup')[0].scrollHeight + 300
        },
        'slow'
      )
    }
  }

  dragDropOver = () => {
    console.log('dragDropOver')
    $('.dragDropBox').css('background-color', '#FFFFBB')
  }

  dragDropLeave = () => {
    console.log('dragDropLeave')
    $('.dragDropBox').css('background-color', 'white')
  }

  handleUploadMouseEnter = () => {
    console.log('MouseEnter')
  }

  handleUploadMouseLeave = () => {
    console.log('MouseLeave')
  }

  render () {
    return (
      <div
        id='csvContainer'
        style={{ width: '100%', padding: '2px', marginTop: '17px' }}
      >
{/*         <div style={{ paddingBottom: '3px' }}>
          Substitution List (use to replace words or phrases in transcribed
          responses)
        </div> */}
        <div className='SubstitutionContainer' >
          <div className='subListHdng'>
          Substitution List (replace words or phrases in transcribed responses)
          <hr />
          </div>

          <div className='HeadingImportCsv' style={{ float: 'left' }}>
            <ReactTooltip
              id='csv'
              place='right'
              type='light'
              html={false}
              border={true}
            />
            <div
              className='csvSubInfo'
              style={{cursor:'pointer'}}
              data-tip='Click here to learn how to format csv files for uploading.'
              data-for='csv'
              onMouseOver={this.adjustCSS}
              onClick={this.openCsvModal}
            >
              <i className='fa fa-info-circle' aria-hidden='true'></i>
            </div>

            <div
              /* className='boldText' */
              style={{ marginLeft: '150px', paddingBottom: '0px' }}
            >
              Import a CSV File
            </div>
            <div style={{ textAlign: 'center', marginBottom: '10px' }}></div>
            <ReactTooltip id='csv-drop' place='right' type='dark' />
            <div style={{ display: 'inline-block' }}>
              <div
                className='dragDropBox'
                style={{
                  backgroundColor: this.state.dropHover
                    ? 'rgba(0, 0, 255, 0.1)'
                    : 'white',
                  marginLeft: '20px'
                }}
                data-for='csv-drop'
                data-tip=''
                onDragOver={this.dragDropOver}
                onDragLeave={this.dragDropLeave}
                onMouseOver={this.handleUploadMouseEnter}
                onMouseLeave={this.handleUploadMouseLeave}
              >
                <form method='post' action='#' id='#'>
                  <input
                    id='fileBrowse'
                    type='file'
                    accept='.csv'
                    className='csvInput'
                    multiple=''
                    disabled={this.state.allDisabled}
                    name='file'
                    onChange={this.onFileSelect}
                  />
                  {this.state.uploadBtnHover ? (
                    <div className='uploadBackgroundHover'>
                      <div id='uploadDiv' className='uploadDiv'></div>
                    </div>
                  ) : this.state.allDisabled ? (
                    <div className='uploadBackgroundDisabled'>
                      <div id='uploadDiv' className='uploadDiv'></div>
                    </div>
                  ) : (
                    <div className='uploadBackgroundNormal'>
                      <div id='uploadDiv' className='uploadDiv'></div>
                    </div>
                  )}
                </form>
              </div>
              <div style={{ display: 'inline-block' }}>
                <span id='chooseCsvMessage'>
                  <i>
                    Choose a csv file or drag and drop it here. Then click
                    upload.
                  </i>
                </span>
                <button
                  disabled={!this.state.selectedFile || this.state.allDisabled}
                  style={{}}
                  id='upload-csv'
                  className='uploadButtonSmall'
                  onClick={this.handleUpload}
                >
                  UPLOAD
                </button>
              </div>
            </div>
          </div>
          <div
            className='HeadingCurrentCsv'
            style={{ float: 'right', height: '104px', marginRight: '70px' }}
          >
            <div /* className='boldText' */>Current CSV File</div>
            <div style={{ marginTop: '15px', marginLeft: '15px' }}>
              <button
                className='blueButton'
                onClick={this.downloadSubstitutions}
              >
                DOWNLOAD
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SubstitutionList
