import React from 'react'
import $ from 'jquery';
import showSystemError from '../Common/showSystemError';

class CreateFooter extends React.Component {

  handleReSequence = () => {
    const {sortTable} = this.props;
    sortTable();
  }

  handleClose = () => {
    const {history, user, create_Table} = this.props;
    window.socket.emit('updateRoom', `room-${user.accountId}`, {
      type: "TEST_CLOSED",  //goes to socketDispatch for further routing
      result: {
        testName: create_Table.testName,
        email: user.email
      }
    });
    $.ajax({
      url: `/test/releaseSurveyLock?testName=${create_Table.testName}&accountId=${user.accountId}`,
      type: "DELETE",
      success: function () {
        localStorage.setItem('create_reset', 'reset');
        history.push('/recycle/')
      },
      error: function (err) {
        showSystemError();
      }
    })


  }

  handleTryIt = () => {
    const {create_Table, user} = this.props;
    $.ajax({
      url: 'admin/getTestTIN',
      //  url: '/testerror
      type: "PUT",
      data: {
        accountId: user.accountId,
        email: user.email,
        instrumentType: create_Table.instrumentType,
        testName: create_Table.testName,
        numberOfQuestions: create_Table.numberOfQuestions,
        wantsEmail: create_Table.wantsEmail,
        assessmentEmailTitle: create_Table.assessmentEmailTitle,
        testTaker: user.name,
        assessmentEmail: ''
      },
      success: (assignment) => {
        console.log(assignment);
        let URL =
          `${window.TAKER_HOST}/?TIN=${assignment.TIN}&testName=${create_Table.testName}&accountId=${user.accountId}&mode=C`;
        // `http://localhost:5000/?TIN=${assignment.TIN}&testName=${create_Table.testName}&accountId=${user.accountId}&mode=C`;

        window.open(URL, '_blank');

      },
      error: () => {
      }
    })
  }

  render() {
    const {playInSequence, create_Table, readOnly} = this.props;
    return (
      <div>
        <div className="testCheckAndSaver" style={{}}>

            <span id="createButtonGroup" style={{position: 'relative'}}>
              <button id="save" className="closeBtn blueButton"
                // style={{position: 'relative', left: '-25%'}}
                      style={{margin: 'auto'}}
                      onClick={this.handleClose}
              >CLOSE</button>
              <button id="reorderPrompts" className="blueButton"
                      style={{position: 'relative', marginLeft: '30px'}}
                      onClick={this.handleReSequence}
              >LIST IN SEQUENCE</button>
               <button id="trySurvey" className="blueButton"
                       style={{position: 'relative', marginLeft: '20px'}}
                       onClick={playInSequence}
               >PLAY IN SEQUENCE</button>
              <button id="tryIt" className="blueButton"
                      style={{position: 'relative', marginLeft: '20px'}}
                      onClick={this.handleTryIt}
                // disabled={readOnly}
              >TRY IT</button>
            </span>
        </div>
      </div>
    )
  }
}

export default CreateFooter;