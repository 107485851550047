import {SIGNUP_USER,
  LOGIN_USER,
  INCREMENT_VISITS_HOME,
  SET_WHITE_LABEL_INFO,
  GET_WHITE_LABEL_INFO,
} from '../actions/_CONSTANTS';

const user = (state = {}, action) => {

  switch (action.type) {
    case SIGNUP_USER:
      return action.payload;
    case LOGIN_USER:
      return action.payload;
    case INCREMENT_VISITS_HOME:
      return action.payload;
    case SET_WHITE_LABEL_INFO:
      return {...state, ...action.payload}
    case GET_WHITE_LABEL_INFO:
      return {...state, ...action.payload}

    default:
      return state;
  }
}

export default user;