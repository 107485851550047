import React, {Component} from 'react';


class Page404 extends Component {


  render () {
    return (
       <span>

      <h1 style ={{marginTop: '200px', marginLeft:'400px'}}>404</h1>
    </span>
    )
  }
}



export default Page404
