import React from 'react';

class ManageSubMenu extends React.Component {

  setActive(subMenuItem) {
    const {subPath} = this.props;
    return subPath === subMenuItem ? 'submenu-btn active' : 'submenu-btn';
  }

  setActiveSubPath = (e) => {
    const {history} = this.props;
    history.push(`/manage/${e.currentTarget.id}`)
  }

  render() {
    const {user} = this.props;
    return (
      <div className="sidenav">
        <p>&nbsp;</p>
        <div className={this.setActive('account')}
             id="account"
             onClick={this.setActiveSubPath}
        >Manage Account
        </div>
        {user.role === 'owner' || user.role === 'admin' ?
          <div className={this.setActive('surveys')}
               id="surveys"
               onClick={this.setActiveSubPath}
          >Manage Surveys
          </div> : null}

        {(user.role === 'owner' || user.role === 'admin') && user.accountType === 'corporate' ?
          <div className={this.setActive('staff')}
               id="staff"
               onClick={this.setActiveSubPath}
          >Manage Staff
          </div> : null}

        <div className={this.setActive('about')}
             id="about"
             onClick={this.setActiveSubPath}
        >About
        </div>
        {user.role === 'owner' && (user.email === 'harry@hwilker.com' || user.email ==='rubindav@gmail.com') ?
        <div className={this.setActive('superUser')}
               id="superUser"
               onClick={this.setActiveSubPath}
          >SuperUser Admin
          </div> : null}

      </div>

    )
  }
}

export default ManageSubMenu;

