import React from 'react';
import ModalFrame from '../../ModalFrame';
import GetAssessmentOption from './GetAssessmentOption';

class SetGetAssessmentOptionsModalController extends React.Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    const {setModalInfo} = this.props;
    setModalInfo({});
  }

  render() {
    const {modalInfo, confirmHandler, cancelHandler} = this.props;
    return (
      <div className="example-modal">
        {modalInfo.name === 'getAssessmentOptions' ?
          <ModalFrame
            title="SET ASSESSMENT OPTIONS FOR THIS QUESTION"
            desktopWidth="80%"
            hasHeader={true}>
            <GetAssessmentOption
              modalInfo={modalInfo}
              hideModal={this.hideModal}
              confirmHandler={confirmHandler}
              cancelHandler={cancelHandler}
            />
          </ModalFrame> : null}
      </div>
    )
  }
}

export default SetGetAssessmentOptionsModalController;