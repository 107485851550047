import React from 'react';
import classnames from 'classnames';

class HelpSubMenu extends React.Component {
  constructor(props) {
    super(props);
    const {subPath} = props;
    this.state = {
      adminDropdown: subPath === 'projects' || subPath === 'staff',
    };
  }

  toggleAdminDropdown = () => {
    let ad = !this.state.adminDropdown;
    this.setState({adminDropdown: ad});
  }


  setActive(subMenuItem) {
    const {subPath} = this.props;
    return subPath === subMenuItem ? 'submenu-btn active' : 'submenu-btn';
  }

  setActiveSubPath = (e) => {
    const {history} = this.props;
    history.push(`/help/${e.currentTarget.id}`)
  }

  render() {
    const {user} = this.props;
    let adminDropdownClasses = classnames('dropdown-container', {active: this.state.adminDropdown});
    return (
      <div className="sidenav">
        <p>&nbsp;</p>
        <div className={this.setActive('overview')}
             id="overview"
             onClick={this.setActiveSubPath}
        >Overview
        </div>
        {(user.role === 'owner' || user.role === 'admin') && user.accountType === 'corporate' ?
        <div>
          <button className="submenu-btn"
                  onClick={this.toggleAdminDropdown}
          >Administration
            <i className="fa fa-caret-down"></i>

          </button>
          <div className={adminDropdownClasses} id="admin-dropdown">
            <div className={this.setActive('surveys')}
                 id="surveys"
                 onClick={this.setActiveSubPath}
            >Manage Surveys
            </div>
            <div className={this.setActive('staff')}
                 id="staff"
                 onClick={this.setActiveSubPath}
            >Manage Staffing
            </div>
          </div>
        </div> : null }
        <div className={this.setActive('create')}
             id="create"
             onClick={this.setActiveSubPath}
        >Creating Surveys
        </div>

        <div className={this.setActive('assign')}
             id="assign"
             onClick={this.setActiveSubPath}
        >Assigning Respondents
        </div>
        <div className={this.setActive('review')}
             id="review"
             onClick={this.setActiveSubPath}
        >Reviewing Completed Surveys
        </div>
        <div className={this.setActive('track')}
             id="track"
             onClick={this.setActiveSubPath}
        >Tracking Survey Results
        </div>
      </div>

    )
  }
}

export default HelpSubMenu;

