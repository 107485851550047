import {GET_NAMED_INVITE_TEMPLATES, SAVE_NAMED_INVITE_TEMPLATE} from '../actions/_CONSTANTS';


const namedInviteTemplates = (state = [], action) => {
  let newState;
  switch (action.type) {
    case GET_NAMED_INVITE_TEMPLATES:
      return action.payload;
    case SAVE_NAMED_INVITE_TEMPLATE:
      let templateNames = state.map((t) => {
        return t.name;
      })
      if (templateNames.indexOf(action.payload.name) === -1) {
        newState = [...state];
      }
      else {
        newState = state.filter((template) => {
          return template.name !== action.payload.name;
        })
      }
      newState.push(action.payload);
      return newState;
    default:
      return state;
  }
}
export default namedInviteTemplates;