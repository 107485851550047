import {DEL_ASSIGN_TABLE_ROW} from '../_CONSTANTS';


export const del_Assign_Table_Row = (id) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let testName = getState().assign_Table.testName;
      let batch = getState().assign_Table.batch;
      window.socket.emit('updateRoom', `room-${getState().user.accountId}`, {
        type: "DELETE_ASSIGNMENT", //goes to socketDispatch for further routing
        result: {
          id: id,
          testName: testName,
          batch: batch
        }
      })
      dispatch({
        type: DEL_ASSIGN_TABLE_ROW,
        payload: id
      })
      resolve()
    })
  }
}