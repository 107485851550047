import {GET_TRANSCRIPT}  from '../_CONSTANTS';
import $ from 'jquery';
const ObjectID = require("bson-objectid");

// this should be simplified. itemResponses are now already available in ReviewTable
// as part of assignment document. All that is needed is the list of prompts which is
// available via the '/test/getPrompts' route.
export const getTranscript = (TIN, testName) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let accountId = getState().user.accountId;
      let url = `/test/getCompletedSurvey/?TIN=${TIN}&name=${testName}&accountId=${accountId}`;
      // let url = '/testerror'

      $.ajax({
        url: url,
        type: "GET",
        success: (survey) => {
          let transcript = {};
          transcript.dialog = [];
          let dummyResponse = {
            TIN: survey.TIN,
            _id: ObjectID().str,
            testItemId: null,
            responseText: ''
          }
          let dummyAssessment = {
            TIN: survey.TIN,
            _id: ObjectID().str,
            testItemId: null,
            assessments: {}
          }
          let nextResponse;
          let nextPrompt;
          let nextAssessment;
          let j = 0;
          let incrementJ = true;
          for (let i = 0; i < survey.prompts.length; i++) {
            nextPrompt = survey.prompts[i];
            if (nextPrompt.awaitResponse) {
              nextResponse = survey.responses[j];
              nextAssessment = survey.assessments[j];
              incrementJ = true
            }
            else {
              nextResponse = dummyResponse;
              nextAssessment = dummyAssessment;
              incrementJ = false;
            }

            let obj = {
              prompt: nextPrompt,
              response: nextResponse,
              assessments: nextAssessment,
              id: parseInt(Math.random()*10000000)
            }
            transcript.dialog.push(obj);
            if(incrementJ) {j++}
          }
          transcript.dateCompleted = survey.dateCompleted;
          transcript.TIN = TIN;
          transcript.assessmentInProgress = false
          transcript.testName = testName;
          dispatch({
            type: GET_TRANSCRIPT,
            payload: transcript
          })

          resolve(transcript)
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}
