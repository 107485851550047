import React from 'react';

class AssignFooter extends React.Component {

  handleClose = () => {
    const {history} = this.props;
    localStorage.setItem('assign_reset', 'reset');
    history.push('/recycle/')
  }

  render() {
    return (
      <div className="assignSubmitFooter">
        <div style={{textAlign: 'center'}}>
          <button
            className="blueButton"
            style={{margin: "10px"}}
            onClick={this.handleClose}
          >CLOSE
          </button>
        </div>
      </div>
    )
  }
}

export default AssignFooter;

