
import {SET_FILTERED_TABLE, ADD_COMPLETED_ASSIGNMENT} from "../actions/_CONSTANTS";
const filteredTable = (state=[], action) => {
  switch(action.type) {
    case SET_FILTERED_TABLE:
    case ADD_COMPLETED_ASSIGNMENT:
      console.log(action);
      return action.payload;
    default:
      return state;
  }
}

export default filteredTable;