import { combineReducers } from 'redux';
import activeModule from './activeModule';
import create_Table from './create_Table';
import assign_Table from './assign_Table';
import modalId from './modalId';
import modalInfo from './modalInfo';
import review_Table from './review_Table';
// import reviewDataLoaded from './reviewDataLoaded';
import reviewEmails from './reviewEmails';
import reviewNames from './reviewNames';
import reviewBatches from './reviewBatches';
import transcript from './transcript';
import filteredTable from './filteredTable';
import env from './env';
import groupAssignment from './groupAssignment';
import anonAssign_Table from './anonAssign_Table';
import track_Table from './track_Table';
import inviteTemplates from './inviteTemplates';
import user from './user';
import namedInviteTemplates from './namedInviteTemplates';
import staff from './staff';
import surveys from './surveys';
import anonAssignments from './anonAssignments';
import allAnonAssignments from './allAnonAssignments';
import allNamedAssignments from './allNamedAssignments';
import BERTResponses from './BERTResponses';
import wordGroups from './wordGoups';
import socket from './socket';
import trackChartData from './trackChartData';
import batchColorArray from './batchColorArray';



export default combineReducers ({
  activeModule,
  create_Table,
  assign_Table,
  review_Table,
  // reviewDataLoaded,
  reviewEmails,
  reviewNames,
  reviewBatches,
  transcript,
  filteredTable,
  modalId,
  modalInfo,
  env,
  groupAssignment,
  anonAssign_Table,
  track_Table,
  inviteTemplates,
  user,
  namedInviteTemplates,
  staff,
  surveys,
  anonAssignments,
  allAnonAssignments,
  allNamedAssignments,
  BERTResponses,
  wordGroups,
  socket,
  trackChartData,
  batchColorArray,

});
