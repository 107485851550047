import React from 'react';
import 'react-widgets/dist/css/react-widgets.css';
import {DropdownList}  from 'react-widgets'
import MultiSelect from '../Common/MultiSelect';
import getAllSurveys from '../Common/getAllSurveys';
import showSystemError from '../Common/showSystemError';

class SelectBatches extends React.Component {
  render() {
    const {batchNames, checkboxesStatus, setCheckboxesStatus, disabled, batchDropdownOpen, toggleBatchDropdown} = this.props;
    return (
      <div style={{display: 'flex'}}>
        <div className="survey-name-prompt">
          Batch Name: &nbsp;&nbsp;&nbsp;
        </div>
        <div className="survey-dropdown">
          <MultiSelect
            itemNames={batchNames || []}
            disabled={disabled}
            checkboxesStatus={checkboxesStatus || []}
            setCheckboxesStatus={setCheckboxesStatus}
            dropdownOpen={batchDropdownOpen}
            toggleDropdown={toggleBatchDropdown}
            centered={true}
            allOffset="-28px"
          />
        </div>
      </div>
    )
  }
}

class TrackSelector extends React.Component {
  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('trackSelector');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('trackSelector')
    }
    else {
      this.state = {
        selectedTestName: '',
        tests: [],
        selectedBatchName: 'All',
        batchDropdownPlaceholder: 'All',
        refreshKey: 0
      }
    }
  }

  componentDidMount() {
    const {user} = this.props;
    getAllSurveys(user.accountId, 'track')
      .then((tests) => {
        // tests = tests.filter((test) => {
        //   return test.batches.length || test.mock; //test.mock is temporary
        // })
        this.setState({tests: tests});
      }, (err) => {
        console.log(err);
        showSystemError()
      })
  }

  handleTestSelect = (value) => {
    const {getTrackTable} = this.props;
    let testName = value;
    let selectedTest = this.state.tests.find((test) => {
      return test.testName === testName;
    })
    let batchNames = selectedTest.batches.map((batch) => {
      return batch.batch;
    })
    let cbArray = new Array(batchNames.length + 1).fill(true);
    this.setState({
      selectedTestName: testName,
      selectedBatchName: 'All',
      batchDropdownPlaceholder: 'All',
      refreshKey: this.state.refreshKey + 1 //needed to reset default on batches menu
      // when changing tests

    })
    getTrackTable(testName, cbArray, ['All'].concat(batchNames))
      .then(() => {
        this.setState({
          refreshKey: this.state.refreshKey + 1
        })
      })
  }

  testSelector = () => {
    let tests = this.state.tests;
    let testNames = tests.map((test) => {
      return test.testName;
    })
    return (
      <div style={{display: 'flex'}}>
        <div className="survey-name-prompt">
          Survey Name: &nbsp;&nbsp;&nbsp;
        </div>
        <div className="survey-dropdown">
          <DropdownList
            data={testNames}
            placeholder="Select a survey to track"
            onChange={this.handleTestSelect}
            value={this.state.selectedTestName}
          />
        </div>
      </div>
    )
  }

  setBatchesCheckboxesStatus = (cbArray) => {
    const {getTrackTable, track_Table} = this.props;
    getTrackTable(this.state.selectedTestName, cbArray, track_Table.batches)
  }

  componentWillUnmount() {
    if (!localStorage.getItem('track_reset')) {
      localStorage.setObject('trackSelector', this.state);
    }
  }

  render() {
    const {batchDropdownOpen, toggleDropdown, track_Table} = this.props;
    return (
      <div className="trackHeading">
        <div style={{display: 'flex'}}>
          <div className="trackHeadingLeft">
            {this.testSelector()}
          </div>
          <div style={{width: '6%', textAlign: 'center'}}>
            <h3>then</h3>
          </div>

          <div className="trackHeadingRight">
            <SelectBatches
              key={this.state.refreshKey}
              batchNames={track_Table.batches}
              checkboxesStatus={track_Table.cbArray}
              disabled={false}
              setCheckboxesStatus={this.setBatchesCheckboxesStatus}
              batchDropdownOpen={batchDropdownOpen}
              toggleBatchDropdown={toggleDropdown}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default TrackSelector;

