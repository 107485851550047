import $ from 'jquery';
import signupConfirmation from '../../components/email_templates/signupConfirmation'

export const sendSignupEmail = (email) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = 'email/signupemail';
      //let url = '/testerror';
      let html = signupConfirmation(email);
      $.ajax({
        url,
        type: 'POST',
        data: {
          email: email,
          html: html
        },
        success: (result) => {
          if (result.authError) {
            alert('user is not authorized.')
          }
          resolve(result);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })

    })
  }
}
