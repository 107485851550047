import React  from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Form, Field} from 'react-final-form';
import classnames from 'classnames';
import $ from 'jquery';
import showSystemError from '../../components/Common/showSystemError';
import {requestPasswordReset} from '../../actions/User/requestPasswordReset';


class ResetPassword extends React.Component {
  emailValid = false;

  constructor(props) {
    super(props);
    this.state = {
      badEmail: false,
      formSubmitted: false,
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    $('.no-navbar').height(window.innerHeight);
  }

  allValid = () => {
    return this.emailValid;
  }

  validEmail = (value) => {
    // eslint-disable-next-line
    // this.emailValid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value));
     this.emailValid = (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9.-]+)?$/.test(value));
    return this.emailValid ? undefined : 'invalid'
  }


  onSubmit = (values) => {
    const {requestPasswordReset} = this.props;
    this.setState({badEmail: false})
    requestPasswordReset(values.email.toLowerCase())
      .then((goodEmail) => {
        if (goodEmail) {
          this.setState({formSubmitted: true});
        }
        else {
          this.setState({badEmail: true})
        }

      }, (err) => {
        showSystemError();
      })
  }

  renderStyledField = (input, type, label, indicator) => {
    return (
      <div style={{marginBottom: '30px'}}>
        <div style={{marginBottom: '4px'}}>
          <label style={{
            fontSize: '14px',
            cursor: 'default'
          }}>{label}</label>
        </div>
        <div style={{width: '100%'}}>
          <div style={{display: 'inline-block', width: '95%'}}>
            <input {...input} type={type}
                   className="rc"
                   style={{
                     width: '100%'
                   }}/>
          </div>
          <div style={{float: 'right', paddingTop: '8px'}}>
            <div style={{margin: '0 auto'}}
                 className={indicator}>

            </div>
          </div>
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    let badEmailClasses = classnames("bad-login", {active: this.state.badEmail})
    return (
      <div className='no-navbar' style={{
        width: '100%',
       // background: 'rgb(33, 169, 134)',
        background: 'cadetblue'
      }}>
        <div>        {/* <img src="TellMeThisLogoWhiteTransparent.png"
             alt=""
             style={{height: '42px', padding: '8px', cursor: 'pointer'}}/> */}
             <img src="TMTLogoWhiteBalloonsWithTagLine.svg"
             className = "TMTLogoWhiteBallonsWithTagLine"
             alt=""
             //style={{height: '70px', paddingLeft: '8px', marginTop: '-5px', cursor: 'pointer'}}
          />
          <div className="big-menu-link"
               style={{float: 'right', border: 'thin solid white', borderRadius: '5px'}}>
            <a href="/"
               className="menuLink">Home</a>
          </div>
        </div>
        <h1 style={{textAlign: 'center'}}>Request a Password Reset</h1>
        <div style={{marginTop: '40px'}}
             onClick={() => {
               this.setState({badEmail: false})
             }}>
          <Form
            onSubmit={this.onSubmit}
            validateOnBlur={false}
            initialValues={
              {
                email: ''
              }
            }
            render={({handleSubmit, form, submitting}) => {
              return (
                <form
                  className="reset-password-form-panel"
                  onSubmit={handleSubmit}>
                  <Field name="email" validate={this.validEmail}>
                    {({input, meta}) => {
                      let indicatorClassEmail = '';
                      if (meta.visited) {
                        indicatorClassEmail = meta.error ? "lineIndicatorOrange" : 'lineIndicatorGreen'
                      }

                      return (
                        this.renderStyledField(input, 'text', 'Email Address:', indicatorClassEmail)
                      )
                    }}
                  </Field>

                  <div
                    className={badEmailClasses}>
                    There is no user with that email address. Please try again.
                  </div>
                  <div>
                    <button className="blueButton"
                            style={{
                              float: 'right',
                              marginRight: '16px',
                              marginTop: '10px'
                            }}
                            type="submit"
                            disabled={submitting || !this.allValid() || this.state.formSubmitted}>
                      Reset Password
                    </button>
                  </div>
                </form>
              )
            }}
          ></Form>
          <div style={{
            textAlign: 'center',
            color: 'white',
            marginTop: '15px',
            lineHeight: '.6'
          }}>
            {this.state.formSubmitted ?
              <div>
                <p>An email has been sent to the address shown above.</p>
                <p>It contains instructions regarding how to reset your password.</p>
              </div>

              : null}

          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    requestPasswordReset: requestPasswordReset
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(ResetPassword);

