import {LOGOUT_USER}  from '../_CONSTANTS';
import $ from 'jquery';

export const logoutUser = (email) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/test/deleteLocks?email=' + email,
        type: "GET",
        success: () => {
          console.log('file locks deleted');
          $.ajax({
            url: '/auth/logout',
            type: "GET",
            success: () => {
              dispatch({
                type: LOGOUT_USER,
                payload: undefined
              })
              localStorage.clear();
              resolve({})
            },
            error(err){
              console.log(err);
              reject(err)
            }
          })
        },
        error(err){
          console.log(err);
          reject(err)
        }
      })


    })
  }
}