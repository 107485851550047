import {SAVE_REPORT_TITLE} from '../_CONSTANTS';

export const saveReportTitle = (assessmentEmailTitle) => {


    return ({
        type: SAVE_REPORT_TITLE,
        payload: {assessmentEmailTitle: assessmentEmailTitle}
    })
}
