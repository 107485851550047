import {EDIT_STAFF} from '../_CONSTANTS';
import $ from 'jquery';

export const editStaff = (email, changedData) => {
  return function(dispatch, getState) {
    return new Promise((resolve, reject)=> {
      let url = '/admin/editStaff';
      // let url = '/testerror';
      $.ajax({
        url: url,
        type: "PUT",
        data: {
          email,
          changedData
        },
        success: (employee)=>{
          dispatch({
            type: EDIT_STAFF,
            payload: employee
          })
          resolve(employee)
        },
        error:(err)=>{
          console.log(err);
          reject(err);
        }
      })
    })
  }
}
