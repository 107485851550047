import React from 'react';

const Gears = (props) => {
  const {submitting, styles, imageWidth} = props;
  let imgWidth = imageWidth ? imageWidth : '225px';
  return (
    <div style={{
      position: 'relative',
      top: styles? styles.top : '35px',
      width: styles ?styles.width : '1000px',
      height: styles ? styles.height : '650px',
      margin: 'auto',
      backgroundColor: styles ? styles.backgroundColor : 'white',
      minWidth: styles ?styles.width : '1000px'
    }}>
      {submitting ? <img src="animatedGears.gif" alt="" width={imgWidth} style={{marginTop: '18%'}}></img>
      :
      <img src="singleFrameGears.png" alt="" width={imgWidth} style={{marginTop: '18%'}}></img>}
    </div>
  )
}

export default Gears;