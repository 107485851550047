import {SET_BATCH_COLOR_ARRAY} from '../actions/_CONSTANTS';

const batchColorArray = (state=[], action) => {
 switch(action.type) {
   case SET_BATCH_COLOR_ARRAY:
     return action.payload;
   default:
     return state;
 }
}

export default batchColorArray;