const hasPermission = (role, task) => {
  switch (task) {
    case 'create':
      return role !== 'analyst';
    case 'assign':
      return role !== 'analyst';
    case 'review':
      // return role !== 'designer';
      return true;
    case 'track':
      return role !== 'designer';
    case 'analyze':
      return role !== 'designer'
    default:
      return true;
  }
}

export default hasPermission;
