// import {NOT_BRAND_NEW} from '../_CONSTANTS';
import _ from 'lodash';
import $ from 'jquery';

export const save_Create_Table = () => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let data = getState().create_Table;
      let rows = _.dropRight(data.rows);

      // let newRow = {};
      let newRows = [];
      rows.forEach((row) => {
        // let scoringParameters = JSON.stringify(row.scoringParameters);
        let newRow = _.omit(row, ['soundFileURL']);
        // newRow.scoringParameters = scoringParameters;
        newRow.scoringParameters = JSON.stringify(row.scoringParameters)
        newRows.push(newRow);
      })
      let testData = _.omit(data, ['rows', 'isDirty']);
      testData.rows = newRows;

      $.ajax({
        url: '/test/saveSurvey',
        // url:'/testerror',
        type: "PUT",
        data: testData,
        success: function (data) {
          window.socket.emit('updateRoom', `room-${getState().user.accountId}`, {
            type: "TEST_SAVED",  //goes to socketDispatch for further routing
            result: data
          });
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}