import {CLONE_SURVEY} from '../_CONSTANTS';
import $ from 'jquery';

export const cloneSurvey = (survey, clonedSurveyName) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = '/admin/cloneSurvey';
      // let url = '/testerror';
      $.ajax({
        url: url,
        type: "POST",
        data: {
          accountId: survey.accountId,
          originalName: survey.testName,
          cloneName: clonedSurveyName,
          instrumentType: survey.instrumentType,
          wantsEmail: survey.wantsEmail,
          assessmentEmailTitle: survey.assessmentEmailTitle
        },
        success: (survey) => {
          dispatch({
            type: CLONE_SURVEY,
            payload: survey
          })
          resolve(survey);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })


    })
  }
}
