import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {unload} from '../actions/unload';


class Recycle extends React.Component {
  componentDidMount() {
    const {activeModule, history} = this.props;
    let am = activeModule.toLowerCase();
    history.push(`/${am}`);
  }

  render() {
    return (
      null
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activeModule: state.activeModule
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    unload
  }, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(Recycle);
