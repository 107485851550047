import {GET_WHITE_LABEL_INFO} from '../_CONSTANTS';
import _  from 'lodash';
import $ from 'jquery';


export const getWhiteLabelInfo = (id) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
       let url = `/admin/getWhiteLabelInfo?accountId=${id}`;
     
      $.ajax({
        url: url,
        type:"GET",
        success: (whiteLabelInfo)=>{


          dispatch({
            type: GET_WHITE_LABEL_INFO,
            payload: whiteLabelInfo
          })
          resolve(whiteLabelInfo);
        },
        error: (err)=> {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}