import React from 'react';

function RoleDropdown(props) {
  const {user, employee, value, onChange} = props;
  if (employee && employee._id === user._id) {
    return (
      <select name="role"
                disabled
                id="role"
                value={value}
                onChange={onChange}
                style={{width: '100%'}}>
          <option value={user.role}>{user.role}</option>
        </select>
    )
  }

  //we only get here if the staff member is not the user.

  return (
    <select name="role"
            id="role"
            value={value}
            onChange={onChange}
            style={{width: '100%'}}>
      {user.role === 'owner' ? <option value="admin">admin</option> : null}
      <option value="designer">designer</option>
      <option value="analyst">analyst</option>
    </select>
  )
}

export default RoleDropdown;