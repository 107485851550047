import React from 'react';
import axios from 'axios';
import AutoSuggest from '../../Common/AutoSuggest';
import $ from 'jquery';
import { relative } from 'path';


class RetrievePrompt extends React.Component {
  constructor(props) {
    super(props)
    const {modalInfo} = this.props;
    this.state = {
      prompts: modalInfo.retrievedPrompts,
      selectedPrompts: modalInfo.retrievedPrompts,
      textInput: '',
      tagInput: '',
      clearTagText: false,
    }
  }

  componentDidMount() {
    $('#suggestions-input, #searchByText').css('position','relative')
                                          .css('width','93%').css('border','solid thin lightgray')
                                          .css('background-color','light-yellow')
                                          .css('top','2px')
                                          .css('left','25px')
                                          .css('top','-21px');
  }
  //needed?
  resetClearTagText = () => {
    this.setState({clearTagText: false});
  }

  handleCancel = (e) => {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  handleDelete = async (e) => {
    const response = await axios.put('test/deleteSavedPrompt', {id: e.target.parentElement.parentElement.id});
    const id = response.data.id;
    let newArray = this.state.prompts.filter((prompt) => {
      return prompt._id !== id;
    })
    this.setState({
      prompts: newArray,
      selectedPrompts: newArray
    });
  }

  handleDownload = (e) => {
    const {hideModal, confirmHandler, modalInfo} = this.props;
    e.preventDefault();
    console.log(modalInfo);
    modalInfo.retrievedPrompt = this.state.prompts.find((prompt) => {
      return prompt._id === e.target.parentElement.parentElement.id;
    })
    if (confirmHandler) {
      console.log(e.target.parentElement.parentElement.id);
      confirmHandler(modalInfo)
    }
    hideModal();
  }

  //needed?
  setTagAsInput = (tag) => {
    this.setState({tagInput: tag})
  }

  renderPrompt = (prompt) => {
    let tags = '';
    prompt.tags.forEach((tag) => {
      tags = tags.concat(`${tag}, `)
    })
    tags = tags.slice(0, tags.length - 2)
    return (
      <tr
        title={'Tags: ' + tags}
        key={prompt._id}
        id={prompt._id}
        className='retrieved-prompt' style={{width: '100%'}}>
        <td style={{border: 'solid thin lightgray', width: '5%', maxWidth: '25px'}}>
          <i className="far fa-minus-square"
             onClick={this.handleDelete}
             style={{marginRight: '14px', cursor: 'pointer'}}
          ></i></td>
        <td style={{border: 'solid thin lightgray', width: '5%', maxWidth: '25px'}}>
          <i className="fa-solid fa-download"
             onClick={this.handleDownload}
             style={{marginRight: '14px', cursor: 'pointer'}}
          ></i></td>
        <td style={{border: 'solid thin lightgray', width: '60%'}}>{prompt.text}</td>
        <td style={{border: 'solid thin lightgray', width: '30%'}}> {prompt.testName}</td>
      </tr>
    )
  }

  handleTextSearchChange = (e) => {
    let searchTerm = e.target.value;
    this.setState({textInput: searchTerm})
    this.setState({
      selectedPrompts: this.state.prompts.filter((prompt) => {
        return prompt.prompt.promptText.toLowerCase().includes(searchTerm.toLowerCase())
      })
    })
  }



  handleTagSearchChange = (tag) => {
    let selectedTag = tag;
    let selectedPrompts = [];
    this.state.prompts.forEach((prompt) => {
      const tags = prompt.tags;
      let index = tags.find((tag) => {
        return tag.toLowerCase() === selectedTag.toLowerCase();
      })
      if (index) {
        selectedPrompts.push(prompt);
      }
    })
    this.setState({selectedPrompts: selectedPrompts});
    document.getElementById('retrieve-or-inner-container').focus()
  }

  handleTextBlur = (e) => {
    this.setState({textInput: '', selectedPrompts: this.state.prompts})
  }

  handleTextFocus = (e) => {
    this.setState({selectedPrompts: this.state.prompts});
  }

  tagsOnFocus = () => {
    this.setState({selectedPrompts: this.state.prompts});
  }



  render() {
    const {modalInfo} = this.props;
    return (
      <div>
        <div className="modal-body" style={{backgroundColor: 'white'}}>
          <div style={{marginTop: '10px', marginBottom:'5px'}}>Search for prompts by:</div>
          <div className="retrieve-search-selector">
            <div id='searchByTextContainer' className='retrieve-text-select' style={{paddingTop:'20px', width:'47%'}}>
              <label>Text </label>
              <input id='searchByText'
                     type="text"
                     placeholder='Enter Search text here'
                     value={this.state.textInput}
                     autoComplete='off'
                     style={{
                       position: 'relative',
                       marginBottom: '12px',
                       marginLeft: '5px',
                       width: '84%',
                       fontSize: '14px',
                       padding: '4px'
                     }}
                     onChange={this.handleTextSearchChange}
                     onBlur={this.handleTextBlur}
                     onFocus={this.handleTextFocus}
              />
            </div>
            <div className="retrieve-or-container">
              <div id='retrieve-or-inner-container' className="retrieve-or-inner-container" tabIndex='0'>
                or
              </div>
            </div>
            <div id='searchByTagContainer' className='retrieve-tag-select' style={{paddingTop:'20px', width:'47%'}}>
              <div id='searchByTagInnerContainer'>
                <label style={{marginTop: '5px'}}>Tag</label>
                <div style={{marginLeft: '5px', position: 'relative', width: '100%'}}>
                  <AutoSuggest
                    existingSuggestions={modalInfo.existingSuggestions}
                    width='300px'
                    maxHeight='160px'
                    placeholder='Select a tag or enter text'
                    suggestionField='suggestion'
                    handleTagSelected={this.handleTagSearchChange}
                    autoFocus={false}
                    suggestionsAlwaysOpen={false}
                    matchAnywhere={false}
                    parentOnFocus={this.tagsOnFocus}
                    parentOnBlur={this.tagsOnBlur}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="retrieved-prompts-outer"
               style={{height: '400px'}}>
            <table style={{width: '100%', marginTop: '15px'}}>
              <tbody>
              <tr style={{width: '100%'}}>
                <td style={{
                  border: 'solid thin transparent',
                  width: '5%',
                  maxWidth: '25px'
                }}>
                  <i className="far fa-minus-square"
                     style={{marginRight: '14px', color: 'transparent'}}
                  ></i></td>
                <td style={{
                  border: 'solid thin transparent',
                  marginBottom: '3px',
                  width: '5%',
                  maxWidth: '25px'
                }}>
                  <i className="fa-solid fa-download"
                     style={{marginRight: '14px', color: 'transparent'}}
                  ></i></td>
                <td style={{
                  border: 'solid thin transparent',
                  textAlign: 'left',
                  width: '60%'
                }}>PROMPT TEXT
                </td>
                <td style={{
                  border: 'solid thin transparent',
                  textAlign: 'left',
                  width: '30%'
                }}>SCRIPT NAME
                </td>
              </tr>
              {this.state.selectedPrompts.map((prompt, index) => {
                return this.renderPrompt(prompt, index);
              })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <div className="btn-group pull-right" style={{margin: '5px 0'}}>
            <button className="blueButton pull-right"
                    style={{marginRight: '20px'}}
                    onClick={this.handleCancel}>
              Close
            </button>

          </div>
        </div>
      </div>
    )
  }
}

export default RetrievePrompt;