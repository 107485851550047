import React from 'react';
import ImageUploading from 'react-images-uploading';

export const DropImage = () => {


  let [images, setImages] = React.useState([]);


  let maxNumber = 1; //max number of images

  let onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  maxNumber = 1;

  return (
    <div className="DropImage">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={isDragging ? {color: 'red', fontSize: '20px', height: '180px'} :
                {
                  fontSize: '20px',
                  height: '180px',
                  color: 'gray',
                  borderColor: 'transparent',
                  backgroundColor: 'transparent'
                }}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click to browse, or Drop image here
            </button>
            <br/>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="80"/>
                <div className="image-item__btn-wrapper">
                  {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                  <br/>
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}


export default DropImage;