import React from 'react'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import $ from 'jquery'
import { toast } from 'react-toastify'
import showSystemError from '../../Common/showSystemError'
import showToastr from '../../Common/showToastr'

class SurveyRow extends React.Component {
  constructor (props) {
    super(props)
    const { survey } = props
    this.state = {
      selectedButton: survey.status,
      isFileLocked: false,
      byWhom: ''
    }
  }

  componentDidMount () {
    const { fileLocks, survey } = this.props
    document.addEventListener('socket', this.handleSocket, false)

    let fileLock = fileLocks.find(item => {
      return survey.testName === item.testName
    })
    fileLock = fileLock !== undefined

    this.setState({ isFileLocked: fileLock })
  }

  statusChangeToastContent = (email, testName, status) => {
    return (
      <div>
        Another user,{' '}
        <span
          style={{ color: 'yellow', fontSize: '18px', fontFamily: 'serif' }}
        >
          {email}
        </span>
        ,
        <br />
        has changed the status of{' '}
        <span
          style={{
            color: 'yellow',
            fontSize: '18px',
            fontFamily: 'serif'
          }}
        >
          "{testName}"
        </span>{' '}
        to
        <span
          style={{
            color: 'yellow',
            fontSize: '18px',
            fontFamily: 'serif'
          }}
        >
          &nbsp;"{status}"
        </span>
        .
      </div>
    )
  }

  testOpenedToastContent = (email, testName) => {
    return (
      <div>
        Another user,{' '}
        <span
          style={{ color: 'yellow', fontSize: '18px', fontFamily: 'serif' }}
        >
          {email}
        </span>
        ,
        <br />
        is now editing{' '}
        <span
          style={{
            color: 'yellow',
            fontSize: '18px',
            fontFamily: 'serif'
          }}
        >
          "{testName}"
        </span>
        .
        <br />
        Its status cannot be changed while it is being edited.
      </div>
    )
  }

  testClosedToastContent = (email, testName) => {
    return (
      <div>
        <span
          style={{ color: 'yellow', fontSize: '18px', fontFamily: 'serif' }}
        >
          {email}
        </span>
        ,
        <br />
        has finished editing{' '}
        <span
          style={{
            color: 'yellow',
            fontSize: '18px',
            fontFamily: 'serif'
          }}
        >
          "{testName}"
        </span>
        .
        <br />
        If needed, the status of this survey can now be changed.
      </div>
    )
  }

  handleSocket = e => {
    let result = e.info.result
    const { survey, user } = this.props
    if (e.info.type === 'TEST_CLOSED' && result.testName === survey.testName) {
      this.setState({
        isFileLocked: false,
        byWhom: ''
      })
      showToastr(
        'fa fa-exclamation-circle',
        this.testClosedToastContent(result.email, survey.testName),
        {
          type: toast.TYPE.INFO,
          allowHtml: true,
          autoClose: 7500,
          pauseOnHover: true
        }
      )
    }
    if (
      e.info.type === 'TEST_OPENED' &&
      result.test.testName === survey.testName
    ) {
      this.setState({
        isFileLocked: true,
        byWhom: result.email
      })
      showToastr(
        'fa fa-exclamation-circle',
        this.testOpenedToastContent(result.user.email, survey.testName),
        {
          type: toast.TYPE.INFO,
          allowHtml: true,
          autoClose: 7500,
          pauseOnHover: true
        }
      )
    }
    if (
      e.info.type === 'SURVEY_STATUS_CHANGE' &&
      result.testName === survey.testName &&
      user.email !== result.user.email
    ) {
      this.setState({ selectedButton: result.buttonValue })
      console.log(result)
      let buttonValue =
        result.buttonValue === 'development'
          ? 'in development'
          : result.buttonValue
      showToastr(
        'fa fa-exclamation-circle',
        this.statusChangeToastContent(
          result.user.email,
          survey.testName,
          buttonValue
        ),
        {
          type: toast.TYPE.INFO,
          allowHtml: true,
          autoClose: 7500,
          pauseOnHover: true
        }
      )
    }
  }

  dateFromTimeStamp = timestamp => {
    if (!timestamp) {
      return '---'
    }
    return moment(timestamp).format('MMM Do, YYYY')
  }

  handleChange = e => {
    const { survey, handleEditSurvey, user } = this.props
    let value = e.target.value
    window.socket.emit('updateRoom', `room-${user.accountId}`, {
      type: 'SURVEY_STATUS_CHANGE', //goes to socketDispatch for further routing
      result: {
        user: user,
        testName: survey.testName,
        buttonValue: value
      }
    })
    this.setState({ selectedButton: value })
    handleEditSurvey(survey._id, survey.testName, value)
  }

  openCloneModal = e => {
    const { openModal, survey, user } = this.props
    openModal({
      name: 'cloneSurvey',
      survey: survey,
      accountId: user.accountId
    })
  }

  isFileLockedHTML = () => {
    return (
      '<div class="surveyTooltipStyle">' +
      '<div style="margin-left: 15px; margin-right: 15px;">' +
      `This survey is currently being edited.</br>` +
      "Its' status cannot be changed until this user has finished editing this" +
      ' survey and closed it.</br></br>' +
      'To modify a survey like this, create a copy by clicking the "Clone" button to the right of this</br>' +
      'row.&nbsp;&nbsp;Then, under the <span style="font-weight: bold;"><i>Assign</i></span> tab, you can assign respondents to the modified version.</br>' +
      '</div>' +
      '</div>'
    )
  }

  surveyAssignedHTML = () => {
    //this tooltip text applies to surveys that have been
    // deployed and have had assignments created for it
    const { survey } = this.props

    return survey.isLocked
      ? '<div class="surveyTooltipStyle">' +
          '<div style="margin-left: 15px; margin-right: 15px;">' +
          'Once a survey has been assigned to a respondent, it can no longer' +
          ' be edited.</br></br>' +
          'To modify a survey like this, create a copy by clicking the "Clone" button to the right of this</br>' +
          'row.&nbsp;&nbsp;Then, under the <span style="font-weight: bold;"><i>Assign</i></span> tab, you can assign respondents to the modified version.</br>' +
          '</div>' +
          '</div>'
      : null
  }

  componentWillUnmount () {
    document.removeEventListener('socket', this.handleSocket)
  }

  render () {
    const { survey, showArchived } = this.props
    if (!showArchived && survey.status === 'archived') {
      return null
    } else {
      return (
        <tr>
          <td className='surveyRowTDStyle' style={{ width: '18%' }}>
            {survey.testName}
          </td>
          <td className='surveyRowTDStyle' style={{ width: '10%' }}>
            {this.dateFromTimeStamp(survey.createdAt)}
          </td>
          <td
            className='surveyRowTDStyle'
            style={{ minWidth: '332px', width: '30%' }}
          >
            <ReactTooltip
              id='development-tooltip'
              className='surveyTooltipStyle'
              place='right'
              type='light'
              border={true}
            />
            <div
              style={{ display: 'inline' }}
              data-tip={this.surveyAssignedHTML()}
              data-html={true} //okay on Firefox
              data-for='development-tooltip'
            >
              <label>
                <input
                  type='radio'
                  style={{ cursor: 'pointer' }}
                  onChange={this.handleChange}
                  name={survey._id}
                  disabled={survey.isLocked}
                  checked={this.state.selectedButton === 'development'}
                  value='development'
                />
                in development &nbsp; &nbsp;
              </label>
            </div>

            {this.state.isFileLocked ? ( //isLocked refers to survey in use by another user
              <span>
                <ReactTooltip
                  id='deployed-tooltip'
                  className='surveyTooltipStyle'
                  place='right'
                  type='light'
                  border={true}
                />
                <div
                  style={{ display: 'inline' }}
                  data-tip={this.isFileLockedHTML()}
                  data-html={true} //okay on Firefox
                  data-for='deployed-tooltip'
                >
                  <label>
                    <input
                      type='radio'
                      onChange={this.handleChange}
                      style={{ cursor: 'help' }}
                      name={survey._id}
                      disabled={this.state.isFileLocked}
                      checked={this.state.selectedButton === 'deployed'}
                      value='deployed'
                    />
                    deployed &nbsp; &nbsp;
                  </label>
                </div>
              </span>
            ) : (
              <div style={{ display: 'inline' }}>
                <label>
                  <input
                    type='radio'
                    style={{ cursor: 'pointer' }}
                    onChange={this.handleChange}
                    name={survey._id}
                    disabled={this.state.isFileLocked}
                    checked={this.state.selectedButton === 'deployed'}
                    value='deployed'
                  />
                  deployed &nbsp; &nbsp;
                </label>
              </div>
            )}
            {this.state.isFileLocked ? ( //isLocked refers to survey in use by another user
              <span>
                <ReactTooltip
                  id='archived-tooltip'
                  className='surveyTooltipStyle'
                  place='right'
                  type='light'
                  border={true}
                />
                <div
                  style={{ display: 'inline' }}
                  data-tip={this.isFileLockedHTML()}
                  data-html={true} //okay on Firefox
                  data-for='archived-tooltip'
                >
                  <label>
                    <input
                      type='radio'
                      onChange={this.handleChange}
                      name={survey._id}
                      disabled={this.state.isFileLocked}
                      checked={this.state.selectedButton === 'deployed'}
                      value='archived'
                    />
                    archived &nbsp; &nbsp;
                  </label>
                </div>
              </span>
            ) : (
              <div style={{ display: 'inline' }}>
                <label>
                  <input
                    type='radio'
                    style={{ cursor: 'pointer' }}
                    onChange={this.handleChange}
                    name={survey._id}
                    disabled={this.state.isFileLocked}
                    checked={this.state.selectedButton === 'archived'}
                    value='archived'
                  />
                  archived
                </label>
              </div>
            )}
          </td>
          <td className='surveyRowTDStyle' style={{ width: '15%' }}>
            {survey.completedAnonCount}
          </td>
          <td className='surveyRowTDStyle' style={{ width: '12%' }}>
            {survey.completedCount}
          </td>
          <td className='surveyRowTDStyle' style={{ width: '10%' }}>
            {this.dateFromTimeStamp(survey.lastCompletionDate)}
          </td>
          <td
            style={{
              textAlign: 'left',
              border: 'solid thin transparent',
              backgroundColor: 'invisible',
              width: '8%'
            }}
          >
            <button
              className='blueButtonSmall'
              id={'cloneBtn' + survey._id}
              style={{ marginLeft: '0px' }}
              onClick={this.openCloneModal}
            >
              Clone
            </button>
          </td>
        </tr>
      )
    }
  }
}

export default SurveyRow
