import {SET_REVIEW_BATCHES} from '../_CONSTANTS';

export const setReviewBatches = (batchesObj) => {
  return {
    type: SET_REVIEW_BATCHES,
    payload: batchesObj
  }
}


