import React from 'react'
import Draggable from 'react-draggable';
import * as d3 from 'd3';
import $ from 'jquery';

$.fn.d3Click = function () {           // this simulates a mouse click on an SVG element
  this.each(function (i, e) {          // used to move selected arrow to firs one if user
    var evt = new MouseEvent("click"); // de-selects all selected regions
    e.dispatchEvent(evt);
  });
};

class DerivativeWizard extends React.Component {
  methodArray = ['Cluster Analysis','Named Entities','Sentiment Analysis','Search Responses'];
  methodSelector = 0;
  chartType = 'Cluster Analysis';

  state = {
    chartType: "Cluster Analysis",
    anySelected: false
  }

  componentDidMount() {
    const {initialSelectionState} = this.props;
    if(initialSelectionState) {
      this.areAnySelected(true);
    }
    let self = this;
    window.areAnySelected = this.areAnySelected;

     d3.select('svg').selectAll('.svgArrow').each(function(d,i) {
        d3.select(this).on(
          "click", function(d) {
            let elemClicked = d3.select(this);
            self.arrowClickHandler(elemClicked);
          })
          .on(
            "mouseover", function(d) {
              d3.select(this).style("cursor", "pointer");
              let elem = d3.select(this);
              self.colorSubmitLabel(elem);
            })
          .on(
            "mouseout", function(d) {
              let elem = d3.select(this);
              self.restoreSubmitLabel(elem);
            })
    });
  }

  colorSubmitLabel = (elem) => {
    if ((elem._groups[0][0].id === "svgArrow3") &&
        ( d3.select('svg').select('#svgArrow2').style("fill").replace(/ /g,'') === 'rgb(196,251,142)' ) &&
        (this.state.anySelected)){
          $('#arrow3').css('color','blue').css('font-weight','bold');
        }
  }

  restoreSubmitLabel = (elem) => {
    if (elem._groups[0][0].id === "svgArrow3"){
      $('#arrow3').css('color','black').css('font-weight','normal');
    }
  }

  cycleMethodsText = () => {
     let self = this;
     if (self.methodSelector < 3){
      self.methodSelector++
     } else {
      self.methodSelector = 0;
     }
     $('#methodText').text(self.methodArray[self.methodSelector]);
     self.chartType = self.methodArray[self.methodSelector];

  }

  /* ********************* Manage the arrow states in wizard **************************** */
  arrowClickHandler = (elemClicked) => {
    let self = this;
    let currentArrow = 1;
    let thisElemId = elemClicked._groups[0][0].id;
    let clickedArrow = thisElemId.substring(8,9) * 1;
    let targetColor = "rgb(196,251,142)"; //same as #c4fb8e
    d3.select('svg').selectAll('.svgArrow').each(function(d,i) {
      if (d3.select(this).style("fill").replace(/ /g,'') === targetColor){
        currentArrow = i +1;
      }
    });

    $('#correctText').text(""); //erase any prior messages

    if ((currentArrow === 1) && (clickedArrow === 1)) { // click twice on arrow 1
      if (!this.state.anySelected) { // nothing selected
        $('#correctText').text("Select data subset by clicking graphical chart elements.");
        $('#wizardSelectorDiv').css('display','block');
      }
    }
    else if ((currentArrow === 1) && (clickedArrow === 2)) {// on 1 going to 2
          if (!this.state.anySelected) { // nothing selected

              $('#correctText').text('Select data subset by clicking graphical chart elements.');
              $('#wizardSelectorDiv').css('display','block');
          } else { // something selected
              //need to pick a method, switch to method display
              d3.selectAll('.svgArrow').style('fill','#eeeeee');
              elemClicked.style('fill','#c4fb8e');

              $('#wizardSelectorDiv').css('display','block');
              $('#arrow2').css('color','transparent');
              $('#methodText').css('color','black');
              $('#methodPicker').css('color','black');
          }
    }
    else if ((currentArrow === 1) && (clickedArrow === 3)) {// on 1 going to 3
        if (!this.state.anySelected) {// nothing selected
              //need to pick a data subset
              $('#correctText').text("Select data subset by clicking graphical chart elements.");
              $('#wizardSelectorDiv').css('display','block');
         } else { // something selected
              //need to pick a method
              $('#correctText').text("Choose type of analysis before clicking submit.");
              $('#wizardSelectorDiv').css('display','block');
         }
    }
    else if ((currentArrow === 2) && (clickedArrow === 2)) {// cycle methods
      this.cycleMethodsText();
      self.setState({chartType: self.methodArray[self.methodSelector] });
    }
    else if ((currentArrow === 2) && (clickedArrow === 1)) {// on 2 going to 1
        //remove table, set 2 to gray and 1 to green

          d3.selectAll('.svgArrow').style('fill','#eeeeee');
          elemClicked.style('fill','#c4fb8e');

          $('#wizardSelectorDiv').css('display','none');
          $('#arrow2').css('color','black');
          $('#methodText').css('color','transparent');
          $('#methodPicker').css('color','transparent');
    }
    else if ((currentArrow === 2) && (clickedArrow === 3)) {// on 2 going to 3
        if (!this.state.anySelected) { // nothing selected
          //need to pick a data subset, leave all colors as they are with method
          // selection as is")
        } else { // something selected
          d3.selectAll('.svgArrow').style('fill','#eeeeee');
          elemClicked.style('fill','#c4fb8e');
          //leave method choice visible on 2, turn 3 green, execute submit (maybe
          // short delay)")
          self.handleDerivative();
        }
    }
  }
/* ******************************************************************************** */


  areAnySelected = (bool) => {
    this.setState({anySelected: bool
    })

    if(!bool){ // false when user clicks on last selected element to de-select it
      $('#svgArrow1').d3Click(); // returns highlighted arrow to first one
    }
  }

  setChartType = (e) => {
    this.setState({chartType: e.currentTarget.value})
  }

  handleDerivative = () => {
    const {createDerivative, hideWizard} = this.props;
    window.getDerivativeData();
    createDerivative(this.state.chartType); // need to create derivative chart before stopWizard
    window.stopWizard(); //because stopWizard clears selections in parent chart and createDerivative needs
    hideWizard();        //selection info
  }

  closeDraggable = () => {
    const {hideWizard} = this.props;
    window.stopWizard();
    hideWizard();
  }

  getWizardX = () => { return $('.addChartBtn').offset().left -450 }
  getWizardY = () => { return $('.addChartBtn').offset().top  -100 }

  render() {
    $('#correctText').text("");
    return (
      <Draggable
        //defaultPosition={{x: 780, y: 48}}
        defaultPosition={{x: this.getWizardX(), y: this.getWizardY()}}
        position={null}
        scale={1}
      >
        <div id="draggableDiv" >
          <div style={{width: '100%', backgroundColor: 'lightgrey', height: '25px', paddingTop: '5px', cursor:'move'}}>
          Create a new chart from a subset of this data
            <i className="fas fa-times"
               style={{float: 'right', paddingRight: '8px', paddingTop: '0px'}}
               role="button"
               onClick={this.closeDraggable}
            ></i></div>

          <div style={{width: '100%', verticalAlign: 'bottom', height: '100px', marginTop: '20px', marginBottom: '0px'}}>
              <div style={{width: '200px', textAlign: 'left'}}>
                <div id='wizardSelectorDiv'>
                  <table id='selectorTable'><tbody><tr>
                    <td id ='methodText'>cluster analysis</td>
                    <td id='methodPicker'><i className='fas fa-chevron-right'></i></td></tr></tbody>
                  </table>
                  <div  id='correctText' style={{ position:'relative', width: '400px', textAlign:'center', top:'20px', color: 'brown'}}>
                      &nbsp;
                   </div>
                </div>
                <svg className='wizardSVG'>
                  <path className='svgArrow' id='svgArrow1' d='m 8 17 l 94 0 l 30 30 l -30 30 l -94 0 l 30 -30 l -30 -30 z'
                      markerEnd ='url(#pos)' fill='#c4fb8e' stroke='#908484' strokeWidth='1' fillRule='evenodd'>
                  </path>
                  <path className='svgArrow' id='svgArrow2' d='m 118 17 l 125 0 l 30 30 l -30 30 l -125 0 l 30 -30 l -30 -30 z'
                      markerEnd ='url(#pos)' fill='#eeeeee' stroke='#908484' strokeWidth='1' fillRule='evenodd'>
                  </path>
                  <path className='svgArrow' id='svgArrow3' d='m 258 17 l 94 0 l 30 30 l -30 30 l -94 0 l 30 -30 l -30 -30 z'
                      markerEnd ='url(#pos)' fill='#eeeeee' stroke='#908484' strokeWidth='1' fillRule='evenodd'>
                  </path>
                </svg>
                <div id='arrow1' className='arrowLabels'>select data</div>
                <div id='arrow2' className='arrowLabels'>choose type</div>
                <div id='arrow3' className='arrowLabels'><u>submit</u></div>
              </div>
          </div>
        </div>
      </Draggable>
    )
  }
}

export default DerivativeWizard;