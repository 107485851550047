import _ from 'lodash';
import moment from 'moment';
import zeroPaddedNumber from '../Common/zeroPaddedNumber';

moment.locale('en');

function getBarChartData(anonAssignments, options) {
  let fromDate = new Date(options.fromDate);
  let toDate = new Date (options.toDate);
  let excludedGroups = options.excludedGroups;
  let excludedGroupIds = options.excludedGroupIds;
  let assignments = anonAssignments.assignments;
  let chartType = options.chartType;
  let timeFrame = options.timeFrame;
  let info = [];
  let groupNames = [...anonAssignments.groups];
  groupNames = _.difference(groupNames, excludedGroups);

  if (chartType === 'byGroup') {
    let groupIds = [...anonAssignments.groupIds];
    groupIds = _.difference(groupIds, excludedGroupIds);
    groupNames.forEach((group, index) => {
      let obj = {
        tooltipName: group,
        legend: groupIds[index],
        completed: 0,
        abandoned: 0
      }
      info.push(obj)
    })
    assignments.forEach((assignment) => {
      let i = groupNames.findIndex((groupName) => {
        return groupName === assignment.group;
      })
      let toDate2359 = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59);
      if (i !== -1 && (fromDate <= new Date(assignment.dateStarted).getTime()) && (toDate2359 >= new Date(assignment.dateStarted).getTime())) {
        if (assignment.status === 'completed' && !assignment.completionError) {
          info[i].completed++;
        }
        else {
          info[i].abandoned++;
        }
      }
    });
  }
  else {
    const dayInMs = 1000 * 60 * 60 * 24;
    const weekInMs = dayInMs * 7;
    let firstDate = new Date(fromDate);
    let lastDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59);


    switch (timeFrame) {
      case 'day': {
        let daily = null;
        let nDays = Math.ceil((lastDate.getTime() - firstDate.getTime()) / dayInMs);
        for (var i = 0; i < nDays; i++) {
          if (i === 0) {
            daily = firstDate;
          }
          else {
            daily = new Date(firstDate.setDate(firstDate.getDate() + 1))
          }
          info[i] = {
            legend: moment(daily).format('MM/DD/YY'),
            completed: 0,
            abandoned: 0,
            tooltipName: moment(daily).format('MMMM Do, YYYY')
          };
        }

        assignments.forEach((assignment) => {
          let d = moment(new Date(assignment.dateStarted)).format('MM/DD/YY');
          let dateIndex = info.findIndex((obj) => {
            return obj.legend === d;
          })
          let groupIndex = groupNames.findIndex((groupName) => {
            return groupName === assignment.group;
          })
          if (dateIndex !== -1 && groupIndex !== -1) {
            // console.log('****', dateIndex, d, info[dateIndex].legend)
            assignment.status === 'completed'  && !assignment.completionError ? info[dateIndex].completed++ : info[dateIndex].abandoned++;
          }
        })
      }
        break;

      case 'week': {
        let dateTime = new Date(firstDate).getTime();
        let last = lastDate.getTime();
        let weekNumber = 0;
        while (dateTime < last) {
          info[weekNumber] = {
            legend: `Week${zeroPaddedNumber(weekNumber + 1, 3)}`,
            completed: 0,
            abandoned: 0,
            tooltipName: `Week starting ${moment(new Date(dateTime)).format('MM/DD/YY')}`,
            start: dateTime,
            end: dateTime + weekInMs
          }
          weekNumber++;
          dateTime = dateTime + weekInMs;
        }

        assignments.forEach((assignment) => {
          let groupIndex = groupNames.findIndex((groupName) => {
            return groupName === assignment.group;
          })
          let dateTime = new Date(assignment.dateStarted).getTime();
          if (groupIndex !== -1 && dateTime >= new Date(firstDate).getTime()) {
            for (var i = 0; i < info.length; i++) {
              if (dateTime > info[i].end) {
                continue
              }
              assignment.status === 'completed'  && !assignment.completionError ? info[i].completed++ : info[i].abandoned++;
              break;
            }
          }
        })
        break;
      }
      case 'month': {
        let date = new Date(firstDate)
        let index = 0;
        do {
          // console.log(`${date.getFullYear()}${zeroPaddedNumber(date.getMonth(), 2)}`)
          let monthIndex = date.getMonth();
          let year = date.getFullYear();
          info[index] = {
            legend: moment(date).format('MMM YY'),
            completed: 0,
            abandoned: 0,
            tooltipName: `Month of ${moment(date).format('MMM YYYY')}`,
            monthYear: `${date.getFullYear()}${zeroPaddedNumber(date.getMonth(), 2)}`
          }
          monthIndex++;
          if (monthIndex > 11) {
            monthIndex = 0;
            year++
          }
          date = new Date(year, monthIndex,1,0,0)
          index++;
        }
        while (date < new Date(lastDate))

        assignments.forEach((assignment) => {
          let groupIndex = groupNames.findIndex((groupName) => {
            return groupName === assignment.group;
          })
          let toDate2359 = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59);
          if (groupIndex !== -1 && new Date(assignment.dateStarted) >= fromDate && new Date(assignment.dateStarted) <= toDate2359) {
            let d = new Date(assignment.dateStarted)
            let monthYear = `${d.getFullYear()}${zeroPaddedNumber(d.getMonth(), 2)}`
            let infoIndex = info.findIndex((item) => {
              return (monthYear === item.monthYear);
            })
            assignment.status === 'completed'  && !assignment.completionError ? info[infoIndex].completed++ : info[infoIndex].abandoned++;
          }
        })
        break;
      }
      default: {
        break;
      }
    }
  }
  return info;
}

export default getBarChartData;