import React from 'react';
import AutoSuggest from '../../Common/AutoSuggest';
import axios from 'axios';


class SavePrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
    }
  }

  componentDidMount() {
    const {modalInfo} = this.props;
    this.setState({
      tags: [...modalInfo.tags.map((tag) => {
        return {isNew: false, value: tag};
      })]
    })
  }

  handleDeleteClick = async (e) => {
    const {modalInfo} = this.props;
    const {row} = modalInfo;
    const testName = row.testName;
    var firstChild = e.target.parentNode.firstChild
    let remainingTags = this.state.tags.filter((tag) => {
        return tag.value !== firstChild.textContent
      }
    )
    let remainingTagNames = remainingTags.map((tag) => {
      return tag.value;
    })
    this.setState({tags: remainingTags});
    let obj = {
      testName,
      row,
      tags: remainingTagNames
    }
    try {
      await axios.post('/test/savePromptToBank', obj);
      console.log(`Deleted: ${firstChild.textContent}`)
    } catch (err) {
      console.log(err)
    }
  }

  handleCancel = (e) => {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  handleSave = (e) => {
    const {hideModal, confirmHandler, modalInfo} = this.props;
    e.preventDefault();
    modalInfo.tags = this.state.tags;
    confirmHandler()
    hideModal();
  }


  insertNewTag = (value, isNew) => {
    let dup = this.state.tags.find((tag) => {
      return tag.value === value;
    })
    if (!dup) {
      this.setState({tags: [...this.state.tags, ...[{value, isNew}]]})
    }

  }
  renderTagsPanel = () => {
    const {modalInfo} = this.props;
    const {existingSuggestions} = modalInfo;
    return (
      <div className='tags-panel'>
        <AutoSuggest
          handleTagSelected={this.insertNewTag}
          existingSuggestions={existingSuggestions}
          width='267'
          maxHeight='163px'
          placeholder='Optional: enter/select tags here'
          suggestionField='suggestion'
          autoFocus={true}
          displaySelectedTag={false}
          suggestionsAlwayOpen={true}
          matchAnywhere={false}
        />

        <div id='tags_container' className='tags-container'
             style={{backgroundImage: 'url("/tags_container_watermark.png")',
                     backgroundSize: 'contain',
                     backgroundPosition: 'center',
                     backgroundRepeat: 'no-repeat'
             }}>
          {!this.state.tags ? null :
            <div>
              {this.state.tags.map((tag, index) => (
                <div className='tag-template' key={`tag${index}`}>
                  <div>
                    {tag.value}
                    <span className='tag-close-button'
                          onClick={this.handleDeleteClick}>
                X
              </span>
                  </div>
                </div>
              ))}
            </div>}

        </div>
      </div>
    )
  }

  render() {
    const {modalInfo} = this.props;
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <br/>
        <div className="modal-body">
          <div className="prompt-info-container" >
            <p style={{
              textAlign: 'left',
              fontWeight: '400',
              color: 'black'
            }}> PROMPT:&nbsp;&nbsp;<span
              style={{color: 'blue'}}>{modalInfo.row.promptText}</span></p>
            <p style={{
              textAlign: 'left',
              fontWeight: '400',
              color: 'black'
            }}> SCRIPT:&nbsp;&nbsp;<span
              style={{color: 'blue'}}>{modalInfo.row.testName}</span></p>
            <div style={{textAlign: 'left', fontWeight: '400'}}>{modalInfo.inUse ?
              <div>NOTE: <span style={{color: 'blue'}}>
Clicking SAVE will replace a prompt already saved with this text and this script name.
You may want to do this if you have changed the "Options" or "Assessment" settings for this prompt.</span>
              </div> : null}</div>
          </div>
          <div className='tags-panel-instruction'> {/* <p style={{position: 'relative', top: '-10px' }}> */}
            To simplify future prompt retrieval, click and assign a pre-defined tag to this prompt. Or type a new tag and press 'Enter'.
          </div>
          <div style={{paddingTop: '3px'}}
          >{this.renderTagsPanel()}</div>
        </div>

        <div className="modal-footer">
          <div className="btn-group pull-right" style={{margin: '5px 0'}}>

            <button className="blueButton pull-right"
                    onClick={this.handleSave}
                    style={{marginLeft: '5px'}}>SAVE
            </button>
            <button className="blueButton pull-right"
                    onClick={this.handleCancel}>
              Cancel
            </button>

          </div>
        </div>
      </div>
    )
  }
}

export default SavePrompt;
