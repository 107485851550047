import {SIGNUP_USER, ADD_STAFF_MEMBER} from '../_CONSTANTS';
import $ from 'jquery';

export const signUpUserOrStaff = (email, name, password, company, accountType, accountId, role) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = '/auth/signup';
      // let url = '/testerror';
      $.ajax({
        url: url,
        type: "POST",
        data: {
          email: email.toLowerCase(),
          password,
          company,
          accountId,
          accountType,
          role,
          name
        },
        success: (user) => {
          if (user.error) {
            resolve(user)
          }
          else {
            if (user.role === 'owner') {
              dispatch({
                type: SIGNUP_USER,
                payload: user
              })
              resolve(user);
            }
            else {
              dispatch({
                type:ADD_STAFF_MEMBER,
                payload: user
              })
              resolve(user);
            }

          }
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}