import React from 'react'

class TrackFooter extends React.Component {




  handleClose = () => {
    const {history} = this.props;
    localStorage.setItem('track_reset', 'reset')
    history.push('/recycle/')
  }

  render() {
    return (
      <div >
        <div className="footerContainer"
             style={{
               border: 'none',
               backgroundColor: '#d1e0e3',
               borderLeft: 'solid thin gray',
               borderRight: 'solid thin gray',
               borderBottom: 'solid thin gray',
               borderBottomRightRadius: '5px',
               borderBottomLeftRadius: '5px'
             }}>
          <div className="trackFooter"
               style={{
                 width: '98%',
                 textAlign: 'center',
                 margin: '10px',
                 border: 'none',
                 display: 'inline-block'
               }}>

            <span id="trackButtonGroup" style={{position: 'relative'}}>
              <button id="save" className="closeBtn blueButton"
                      style={{margin: 'auto'}}
                      onClick={this.handleClose}
              >CLOSE</button>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default TrackFooter;
