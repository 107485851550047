import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import _ from 'lodash';
import {UserContext} from "../Manage";


const textAreaStyles = {
  boxSizing: 'border-box',
  resize: 'none',
  overflowY: 'scroll',
  margin: '0',
  display: 'block',
  width: '100%',
  padding: '6px',
  border: '1px solid',
  borderRadius: '4px'
}

const inputStyles = {
  boxSizing: 'border-box',
  display: 'block',
  width: '100%',
  padding: '6px',
  border: '1px solid',
  borderRadius: '4px',
  background: 'white',
  fontSize: 'inherit',
  lineHeight: 'inherit'
}

const cardStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  margin: '0',
  marginTop: '15px',
  marginBottom: '10px',
  padding: '5px',
  border: 'solid thin grey',
  borderRadius: '5px',
  background: 'linear-gradient(to bottom #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%)',
  height: 'auto',
  boxShadow: '0 0 8px rgba(0,0,0,0.125)',
  boxSizing: 'border-box'
}

function CreateOptionsModal(props) {
  const emptyForm = {
    preamble: "",
    useSpokenPrompts: "",
    pushURL: "",
    privacy: "",
    testName: ""
  }
  const [state, setState] = useState(emptyForm)
  const [testNameEntered, setTestNameEntered] = useState(false)
  const [error, setError] = useState(false);
  const accountId = useContext(UserContext).accountId;

  async function fetchData() {
    setError(false);
    let response =
      await axios.get(`/admin/getSettings/?testName=${state.testName}&accountId=${accountId}`);
    console.log('response data: ', response.data);
    if (!_.isEmpty(response.data)) {
      if (!response.data.testName) {
        setError(true)
      } else {
        setState({...response.data});
        setTestNameEntered(true);
      }
    }
  }


  function handleModalSubmit() {
    const {onSubmit} = props;
    onSubmit(state)
      .then((response) => {
        console.log(response.data);
        setState(emptyForm);
        setTestNameEntered(false);
      })
  }

  function handleModalClose() {
    const {onClose} = props;
    onClose()
  }

  function handleClear() {
    setTestNameEntered(false);
    setState({
      preamble: "",
      useSpokenPrompts: "",
      pushURL: "",
      privacy: "",
      testName: ""
    })
  }

  const handleChange = (event) => {
    const {name, value, type, checked} = event.target;
    const inputValue = type === 'checkbox' ? checked : value;
    const newState = {...state, [name]: inputValue};
    if (name === 'testName') {
      setError(false);
    }
    setState(newState);
  }

  //---------------------------------------------------------------------
  //these can be changed but for consistency usually should not
  const headerSize = 30;
  const footerSize = 44;
  //---------------------------------------------------------------------

  const {hasHeader, hasFooter, modalHeight} = props;

  let modalBodyHeight = modalHeight;
  if (hasHeader) {
    modalBodyHeight = modalBodyHeight - headerSize
  }
  if (hasFooter) {
    modalBodyHeight = modalBodyHeight - footerSize
  }

  return (
    <div id='modalBodyOuterContainer'>
      {hasHeader &&
      <div id='modalHeader' className='modalHeader'>
        <div style={{float: 'left'}}>SURVEY OPTIONS</div>
      </div>}
      <br/>

      <div id='modalBodyContainer' className='modalBodyContainer'
           style={{height: `${modalBodyHeight}px`}}>
        <div id='modalBody' className='modalBody'>
          <div style={cardStyles} id='modalBodyContent'>

            <div id='testNameContainer'
                 style={{width: '100%', padding: '2px', marginTop: '17px'}}>
              <label>Test Name</label>
              <div id="testNameSpacer"
                   style={{display: 'flex', justifyContent: 'flex-start'}}>
                <div id="testNameInnerContainer"
                     style={{width: '60%', marginRight: '20px'}}>
                  <input
                    style={{
                      ...inputStyles,
                      ...{cursor: testNameEntered ? 'not-allowed' : 'text'}
                    }}
                    name='testName' value={state.testName}
                    placeholder="Enter test name here"
                    disabled={testNameEntered}
                    onChange={handleChange}></input>
                </div>
                <button className='blueButton' id='testNameSubmit'
                        disabled={!state.testName || testNameEntered}
                        onClick={fetchData}>Submit
                </button>
                <button className='blueButton' id='testNameClear'
                        style={{marginLeft: '8px'}}
                        disabled={!state.testName || !testNameEntered}
                        onClick={handleClear}>Clear
                </button>
              </div>
              <div style={{
                color: 'red',
                margin: '12px 0 0 25px',
                visibility: error ? 'visible' : 'hidden'
              }}>This test does not
                exist.
              </div>
            </div>
            <div id='pushURLContainer'
                 style={{width: '100%', padding: '2px', marginTop: '17px'}}>
              <label>Push URL</label>
              <input
                style={{
                  ...inputStyles, ...{
                    background: testNameEntered ? 'white' : '#eee'
                  }
                }}
                name='pushURL' value={state.pushURL}
                onChange={handleChange} disabled={!testNameEntered}></input>
            </div>
            <div id='preambleContainer' style={{
              width: '100%',
              marginTop: '17px'
            }}>
              <label>Preamble </label>
              <textarea
                style={{...textAreaStyles, ...{background: testNameEntered ? 'white' : '#eee'}}}
                name='preamble'
                value={state.preamble} rows={4}
                disabled={!testNameEntered}
                onChange={handleChange}></textarea>
            </div>
            <div id='privacyContainer' style={{
              width: '100%',
              padding: '2px',
              marginTop: '17px'
            }}>
              <label>Privacy Notice</label>
              <textarea
                style={{...textAreaStyles, ...{background: testNameEntered ? 'white' : '#eee'}}}
                name='privacy'
                value={state.privacy}
                disabled={!testNameEntered}
                rows={4}
                onChange={handleChange}></textarea>
            </div>

            <div id='spokenPromptsContainer' style={{
              width: '40%',
              marginTop: '17px',
              display: 'block',
            }}>
              <div id='spokenPromptsInnerContainer'>
                <label style={{display: 'flex'}}>Use Spoken Prompts
                  <div style={{marginLeft: '8px'}}>
                    <input type='checkbox' name='useSpokenPrompts'
                           style={{cursor: testNameEntered ? "pointer" : "default"}}
                           checked={state.useSpokenPrompts}
                           disabled={!testNameEntered}
                           onChange={handleChange}></input>
                  </div>
                </label>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div id='modalFooter' className="modalFooter" style={{boxSizing: 'border-box'}}>
        <button className='blueButton' id='submitButton'
                style={{float: 'right', marginLeft: '8px'}}
                disabled={!testNameEntered}
                onClick={handleModalSubmit}>Save
        </button>
        <button className='blueButton' style={{float: 'right'}}
                onClick={handleModalClose}>Close
        </button>
      </div>
    </div>
  )
}

export default CreateOptionsModal;