import React from 'react';

class AnonAssignFooter extends React.Component {

  handleClose = () => {
    const {history} = this.props;
    localStorage.setItem('anonAssign_reset', 'reset');
    history.push('/recycle/')
  }

  render() {
    return (
      <div className="anonAssignSubmitFooter">
        <table style={{width: '100%'}}>
          <tbody>
          <tr style={{borderBottom: 'solid thin gray'}}>
            <td style={{width: '100%', textAlign: 'center'}}>
              <button
                className="blueButton"
                onClick={this.handleClose}
              >CLOSE
              </button>

            </td>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default AnonAssignFooter;


