import {GET_STAFF} from '../_CONSTANTS';
import $ from 'jquery'
import _ from 'lodash';

export const getStaff = (user) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = `/admin/getStaff?role=${user.role}&accountId=${user.accountId}&userId=${user._id}`;
      // let url = '/testerror';
      $.ajax({
        url: url,
        type: "GET",
        success: (staff) => {
          staff = _.sortBy(staff, ['name']);
          dispatch({
            type: GET_STAFF,
            payload: staff
          })
          resolve(staff);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}
