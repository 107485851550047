import {SET_WHITE_LABEL_INFO} from '../_CONSTANTS';
import _ from 'lodash';
import $ from 'jquery';

export const setWhiteLabelInfo = (accountId,
                                  whiteLabelInfo
                                  ) => {
 return function(dispatch) {
  return new Promise((resolve, reject)=> {
      let url  = '/admin/setWhiteLabelInfo';
      let data = {
        accountId,
        whiteLabelInfo
      }
       $.ajax({
        url: url,
        type: "PUT",
        async : true,
        data,
        dataType: 'json',
        success: function (whiteLabelInfo) {
          console.log(whiteLabelInfo);
         dispatch({
            type: SET_WHITE_LABEL_INFO,
            payload: {whiteLabelInfo}
          })
           resolve(whiteLabelInfo)
        },
        error: function (err) {
          console.log("err ", err)      
          reject(err)
        }
      }) 
    })
  }
}