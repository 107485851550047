import $ from 'jquery';
import {EDIT_INVITE_ALL} from '../_CONSTANTS';


export const save_Assign_Table = () => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = '/test/saveAssignments';
      // let url = '/testerror'
      let table = getState().assign_Table;
      $.ajax({
        url: url,
        type: "PUT",
        data: table,
        success: (result) => {
          let anyInvites = table.rows.findIndex((row) => {
            // eslint-disable-next-line
            let validEmail = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(row.email)) || !row.email;
            return !row.inviteSent && row.email && validEmail && !row.error && row.participantName && !row.isDeleted && row.status !== 'completed'
          })
          console.log('-------------------------   ', anyInvites, '   ------------------------')
          dispatch({
            type: EDIT_INVITE_ALL,
            payload: anyInvites !== -1
          })
          resolve(table);
        },
        error(err) {
          console.log(err);
          reject(err);
        }
      })
    })
  }

}