
import {SET_MODAL_ID} from '../actions/_CONSTANTS';

const modalId = (state={}, action) => {
  switch(action.type){
    case SET_MODAL_ID:
      return action.payload;
    default:
      return state;
  }
}

export default modalId;