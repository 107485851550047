import $ from 'jquery';
import staffSignup from '../../components/email_templates/staffSignup'

export const sendStaffSignupEmail = (email, role) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
       let url = '/email/sendStaffSignupEmail';
      //let url = '/testerror';
      let html = staffSignup(email, role);
      $.ajax({
        url,
        type: 'POST',
        data: {
          email: email,
          html: html
        },
        success: (result) => {
          if (result.authError) {
            alert('user is not authorized.')
          }
          resolve(true);
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })

    })
  }
}
