
import {GET_INVITE_TEMPLATES} from '../_CONSTANTS';
import $ from 'jquery'

export const get_Invite_Templates = (accountId) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject)=>{
      let url = `/test/getInviteTemplates?accountId=${accountId}`;
      // let url = '/testerror';

      $.ajax({
        url: url,
        type: "GET",
        success: (inviteTemplates) => {
          dispatch({
            type:GET_INVITE_TEMPLATES,
            payload: inviteTemplates
          });
          resolve(inviteTemplates)
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}