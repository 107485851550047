import {
  GET_TRACK_TABLE,
  GET_ALL_NAMED_ASSIGNMENTS,
  SET_BATCH_COLOR_ARRAY
}  from '../_CONSTANTS';
import $ from 'jquery';
import _ from 'lodash';

let batchColorArray = [];

function parseTable(testName, accountId, assignments, batchNames, cbArray) {
  let rows = [];
  let currentBatch = '';
  let colorIndex = -1;
  batchColorArray = [];
  let batchColors = window.batchColors;
  assignments = _.sortBy(assignments, ['batch']);
  let batchColorObj = {};
  assignments.forEach((assignment) => {
    let index = batchNames.findIndex((name) => {
      return name === assignment.batch;
    })

    // eslint-disable-next-line
    let validEmail = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(assignment.email)) || !assignment.email;
    if (currentBatch !== assignment.batch) {
      colorIndex++;
      if (colorIndex === 10) {
        colorIndex = 0;
      }
      currentBatch = assignment.batch;

      batchColorObj = {};
      batchColorObj[batchNames[index]] = batchColors[colorIndex];
      batchColorArray.push(batchColorObj);

      currentBatch = assignment.batch
    }
    assignment.backgroundColor = batchColors[colorIndex];

    if (cbArray[index]) {
      let obj = {
        batch: assignment.batch,
        backgroundColor: assignment.backgroundColor,
        group: assignment.group,
        testTaker: assignment.testTaker,
        email: assignment.email,
        TIN: assignment.TIN,
        dateAssigned: assignment.dateAssigned,
        dateStarted: assignment.dateStarted,
        dateCompleted: assignment.dateCompleted,
        badEmail: !validEmail,
        _id: assignment._id,
        testName: testName,
        accountId: accountId,
        isAnon: assignment.isAnon,
        completionError: assignment.completionError
      }
      rows.push(obj);
    }
  })
  return rows;

}

export const get_Track_Table = (testName, accountId, cbArray, batchNames) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let track_Table = {
        testName: testName,
        batches: batchNames,
        cbArray: cbArray,
        sortCriteria: null
      }
      cbArray = cbArray.slice(-cbArray.length + 1)
      batchNames = batchNames.slice(-batchNames.length + 1)
      let url = `/test/getNamedAssignments?batch=All&testName=${testName}&accountId=${accountId}`
      // let url = '/testerror';

      let allNamedAssignments = getState().allNamedAssignments;
      if (!_.isEmpty(allNamedAssignments) && getState().track_Table.testName === testName) {
        track_Table.rows = parseTable(testName, accountId, allNamedAssignments, batchNames, cbArray);
        dispatch({
          type: GET_TRACK_TABLE,
          payload: track_Table
        })

        dispatch({
          type: SET_BATCH_COLOR_ARRAY,
          payload: batchColorArray
        })

        resolve(track_Table)
      }

      $.ajax({
        url: url,
        type: "GET",
        success: (result) => {
          track_Table.rows = parseTable(testName, accountId, result.assignments, batchNames, cbArray)

          dispatch({
            type: GET_ALL_NAMED_ASSIGNMENTS,
            payload: result.assignments
          })
          dispatch({
            type: SET_BATCH_COLOR_ARRAY,
            payload: batchColorArray
          })
          dispatch({
            type: GET_TRACK_TABLE,
            payload: track_Table
          })
          resolve(track_Table)
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}