import $ from 'jquery';
import skmeans from './kmeans/main';
import pos from 'pos';

export const setWordGroups = (responseData, numGroups) => {

  /* objective of this method is to construct groups of words for the word-cloud, to be displayed
   /* aligned with the dot clusters, where the groups are passed to the clusters component in an
   /* array, with groups ordered in the same sequence as the dot clusters are ordered.
   /*
   /* It is based on first grouping response texts using the kMeans-assigned group numbers,
   /* and second on removing unwanted words from the response texts by their "parts of speech (pos)"
   /* using the "NPM pos" library.  https://www.npmjs.com/package/pos
   /* Here are the tags produced by that library, and the tags used to filter the texts at this time:

   /* ************* word tags ************ */
  /* use these ###
   CC    Coord Conjuncn           and,but,or
   CD    Cardinal number          one,two
   DT    Determiner               the,some
   EX    Existential there        there
   FW    Foreign Word             mon dieu
   IN    Preposition              of,in,by
   JJ    Adjective                big                ###
   JJR   Adj., comparative        bigger             ###
   JJS   Adj., superlative        biggest            ###
   LS    List item marker         1,One
   MD    Modal                    can,should
   NN    Noun, sing. or mass      dog                ###
   NNP   Proper noun, sing.       Edinburgh          ###
   NNPS  Proper noun, plural      Smiths             ###
   NNS   Noun, plural             dogs               ###
   POS   Possessive ending        �s
   PDT   Predeterminer            all, both
   PP$   Possessive pronoun       my,one�s
   PRP   Personal pronoun         I,you,she
   RB    Adverb                   quickly            ###
   RBR   Adverb, comparative      faster             ###
   RBS   Adverb, superlative      fastest            ###
   RP    Particle                 up,off
   SYM   Symbol                   +,%,&
   TO    �to�                    to
   UH    Interjection              oh, oops
   VB    verb, base form           eat               ###
   VBD   verb, past tense          ate               ###
   VBG   verb, gerund              eating            ###
   VBN   verb, past part           eaten             ###
   VBP   Verb, present             eat               ###
   VBZ   Verb, present             eats              ###
   WDT   Wh-determiner             which,that
   WP    Wh pronoun                who,what
   WP$   Possessive-Wh             whose
   WRB   Wh-adverb                 how,where
   ,     Comma                     ,
   .     Sent-final punct          . ! ?
   :     Mid-sent punct.           : ; �
   $     Dollar sign               $
   #     Pound sign                #
   "     quote                     "
   (     Left paren                (
   )     Right paren               )

   /* ************************************  */

  const keeperTags = ['JJ', 'JJR', 'JJS', 'NN', 'NNP', 'NNPS', 'NNS', 'RB', 'RBR', 'RBS', 'UH', 'VB', 'VBD', 'VBG', 'VBN', 'VBP', 'VBZ'];


  var nestedTextArrays = [] //create array with the right number of sub-arrays for text groups
  var nestedWordCloudArrays = [] //create right number of sub-arrays for cloud-word lists
  for (let i = 0; i < numGroups; i++) {
    nestedTextArrays.push([]);
    nestedWordCloudArrays.push([]);
  }

  responseData.forEach((item) => {
    nestedTextArrays[item.kMeansGroup].push({
      'kMeansGroup': item.kMeansGroup,
      'text': item.responseText
    });
  })

  for (let i = 0; i < numGroups; i++) { // use pos to select keeper words and place them
    for (let j = 0; j < nestedTextArrays[i].length; j++) {
      var words = new pos.Lexer().lex(nestedTextArrays[i][j].text);
      var tagger = new pos.Tagger();
      var taggedWords = tagger.tag(words);

      for (let k in taggedWords) {
        var taggedWord = taggedWords[k];
        var word = taggedWord[0];
        var thisTag = taggedWord[1];
        if (keeperTags.includes(thisTag) && word.length > 2) {
          nestedWordCloudArrays[i].push(word);
        }
      }
    }
  }
  return nestedWordCloudArrays;
}

export const getKmeans = (responseData, numGroups) => {
  let vectors = [];
  let rspData = [];
  responseData.forEach((item) => {
    vectors.push(item.BERTData);
  })
  let kMeanResults = skmeans(vectors, numGroups); // "kmrand", "kmpp"
  responseData.forEach((item, index) => {
    rspData[index] = {...responseData[index], ...{kMeansGroup: kMeanResults.idxs[index]}}
  })
  return [...rspData];
}

export const getBERTData = (fromDateValue, toDateValue, selectedBatches, selectedGroups, test, prompt, numGroups, isDerivative) => {
  return new Promise((resolve, reject) => {
    if (isDerivative) {
      // let parentData = JSON.parse(JSON.stringify(window.derivativeData));
      let parentData
      if (window.numberOfChildren) { //returning from a different module
        parentData = window.derivativeData[window.numberOfChildren - 1];
        window.numberOfChildren = window.numberOfChildren - 1;
      }
      else { //initial creation of a derivative chart
        parentData = window.derivativeData[window.derivativeData.length - 1];
      }

      if (parentData[0].BERTData === undefined) { //if parent was any chart type except cluster
        //let url = '/test/getBERT';
        let url = '/test/getGPTembeddings';
        let data = {
          fromDateValue: fromDateValue.getTime(),
          toDateValue: toDateValue.getTime(),
          selectedBatches,
          selectedGroups: selectedGroups,
          testName: test.testName,
          accountId: test.accountId,
          promptId: prompt.promptId
        }
        $.ajax({
          url,
          type: "POST",
          data,
          success: (data) => {

            let selectedCount = 0;
            for (var i = 0; i < data.length; i++) {
              if (parentData[i].selected === true) {
                data[i].selected = true;
                selectedCount++;
              } else {
                data[i].selected = false;
              }
            }
            data = data.filter((item) => {
              return item.selected
            });
            var maxGroups = Math.min(selectedCount, 9);
            var groupsVal = Math.min(maxGroups, numGroups)
            data = getKmeans(data, groupsVal)
            let wordGroups = setWordGroups(data, groupsVal);

            resolve({
              responseData: data,
              wordGroups
            })
          },
          error: (err) => {
            console.log(err);
            reject(err);
          }
        })
      }
      else { //if parent was also a cluster chart
        parentData = parentData.filter((item) => {
          return item.selected;
        })
        var maxGroups = Math.min(parentData.length, 9);
        var groupsVal = Math.min(maxGroups, numGroups)
        parentData = getKmeans(parentData, groupsVal)
        let wordGroups = setWordGroups(parentData, groupsVal);
        resolve({
          responseData: parentData,
          wordGroups
        })
      }
    } else { //not a derivative chart
    //let url = '/test/getBERT';
      let url = '/test/getGPTembeddings';
      let data = {
        fromDateValue: fromDateValue.getTime(),
        toDateValue: toDateValue.getTime(),
        selectedBatches,
        selectedGroups: selectedGroups,
        testName: test.testName,
        accountId: test.accountId,
        promptId: prompt.promptId
      }
      $.ajax({
        url,
        type: "POST",
        data,
        success: (data) => {
          if (!data.length) {
            resolve({
              responseData: []
            })
          }
          else {
            var maxGroups = Math.min(data.length, 9);
            var groupsVal = Math.min(maxGroups, numGroups)
            data = getKmeans(data, groupsVal)
            let wordGroups = setWordGroups(data, groupsVal);
            resolve({
              responseData: data,
              wordGroups
            })
          }
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    }
  })
}