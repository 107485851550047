import React from 'react';
import $ from 'jquery';
import * as d3 from 'd3';
import * as d3layoutcloud from './wordCloud/d3.layout.cloud';
import svgPanZoom from 'svg-pan-zoom';

/* ******************************************************************* */
// array of arrays x,y % offsets for dots
// from 0,0 where centroids of circle groupings should be centered
var g1x = [0.5]
var g1y = [0.5]
var g2x = [0.333, 0.667]
var g2y = [0.5, 0.5]
var g3x = [0.5, 0.154, 0.846]
var g3y = [0.22, 0.72, 0.72]
var g4x = [0.783, 0.217, 0.217, 0.783]
var g4y = [0.217, 0.217, 0.783, 0.783]
var g5x = [0.5, 0.12, 0.265, 0.735, 0.88]
var g5y = [0.1, 0.376, 0.824, 0.824, 0.376]
var g6x = [0.7, 0.3, 0.1, 0.3, 0.7, 0.9]
var g6y = [0.154, 0.154, 0.5, 0.846, 0.846, 0.5]
var g7x = [0.5, 0.187, 0.11, 0.326, 0.674, 0.89, 0.813]
var g7y = [0.1, 0.251, 0.589, 0.86, 0.86, 0.589, 0.251]
var g8x = [0.653, 0.347, 0.13, 0.13, 0.347, 0.653, 0.87, 0.87]
var g8y = [0.13, 0.13, 0.347, 0.653, 0.87, 0.87, 0.653, 0.347]
var g9x = [0.5, 0.243, 0.106, 0.154, 0.363, 0.637, 0.846, 0.894, 0.757]
var g9y = [0.1, 0.194, 0.431, 0.7, 0.876, 0.876, 0.7, 0.431, 0.194]

var xLocPercents = [g1x, g2x, g3x, g4x, g5x, g6x, g7x, g8x, g9x]
var yLocPercents = [g1y, g2y, g3y, g4y, g5y, g6y, g7y, g8y, g9y]

/* ******************************************************************* */
// array of arrays x,y % offsets for clouds
var scaleFactors = [0.8, 0.7, 0.7, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6]
var scale1x = [0.304]
var scale1y = [0.27]
var scale2x = [0.08, 0.55]
var scale2y = [0.32, 0.32]
var scale3x = [0.316, 0.08, 0.6]
var scale3y = [0.028, 0.537, 0.537]
var scale4x = [0.6, 0.08, 0.08, 0.6]
var scale4y = [0.08, 0.08, 0.6, 0.6]
var scale5x = [0.35, 0.06, 0.08, 0.6, 0.63]
var scale5y = [0.028, 0.246, 0.6, 0.6, 0.246]
var scale6x = [0.57, 0.1, 0.06, 0.1, 0.57, 0.63]
var scale6y = [0.028, 0.028, 0.3, 0.65, 0.65, 0.3]
var scale7x = [0.316, 0.07, 0.02, 0.1, 0.5, 0.63, 0.58]
var scale7y = [0.02, 0.06, 0.4, 0.7, 0.7, 0.4, 0.06]
var scale8x = [0.53, 0.15, 0.01, 0.02, 0.22, 0.53, 0.7, 0.7]
var scale8y = [0.02, 0.02, 0.22, 0.51, 0.7, 0.7, 0.51, 0.22]
var scale9x = [0.316, 0.02, 0.02, 0.01, 0.20, 0.50, 0.63, 0.7, 0.6]
var scale9y = [0.02, 0.026, 0.3, 0.55, 0.75, 0.75, 0.55, 0.26, 0.028]

var scalexOffsets = [scale1x, scale2x, scale3x, scale4x, scale5x, scale6x, scale7x, scale8x, scale9x]
var scaleyOffsets = [scale1y, scale2y, scale3y, scale4y, scale5y, scale6y, scale7y, scale8y, scale9y]

function inIframe() { //is this code running in an iframe?
  try {
    return window.self !== window.top;
  }
  catch (e) {
    return true;
  }
}

var responseDataStash = [];

class Clusters extends React.PureComponent {
  currentMousePos = {x: -1, y: -1}; // continuously updated mouse positions available to tooltip
  mostCited = 0;
  noSingletonThreshold = 50;
  currentWordGroup = 0;
  wizardOn = false;
  wordGroups = [];
  wordMaps = [];
  rspData = [];
  bboxArray = [];
  colorScale = ['#7293CB', '#E1974C', '#84BA5B', '#D35E60', '#808585', '#9067A7', '#AB6857', '#CCC210', '00FFCC'];
  containerId = '';
  tooltip = null;
  xoffset = 0;   //this will let us slide the svg region
  yoffset = 30;  //this too
  scale = 1.0; //so we can zoom in or out
  xadj = 20;  //to separate the tooltip from the cursor to the right in screen (not svg) coordinates
  yadj = -30; //position a bit higher
  drawCloud = true;
  textVisible = true;
  dotsVisible = true;
  macroScaleFactor = 0.65; //sets scale used when clusters produces chart for iframe (typically on home page)
                           //home page div enclosing iframe needs independent adjustment that depends on this setting
  state = {
    mounted: false
  }

  componentDidMount() {
    const {isDerivative, demo} = this.props;
    if (!isDerivative && !demo) {
      window.startWizard = this.startWizard;
      window.stopWizard = this.stopWizard;
      window.getDerivativeData = this.getDerivativeData;
    }

    this.containerId = this.refs.container.id;
    this.setState({
      mounted: true
    });

  }

  /* *************************** functions to call from external wizard  ***************************************** */

  startWizard = () => {
    this.wizardOn = true;
    let textCloudElem = d3.select(`#${this.containerId}`).select('.cloud');
    let dotClusterElem = d3.select(`#${this.containerId}`).select('.dots');
    let showTextTransform = "translate(0,0)";
    let showDotsTransform = "translate(0, 0), scale(1.0)";
    textCloudElem.attr('transform', showTextTransform);
    dotClusterElem.attr('transform', showDotsTransform);
  }

  stopWizard = () => {
    this.wizardOn = false;
    let textCloudElem = d3.select(`#${this.containerId}`).select('.cloud');
    let dotClusterElem = d3.select(`#${this.containerId}`).select('.dots');
    let hideTextTransform = "translate(-10000,-10000)";
    let hideDotsTransform = "translate(-10000,-10000), scale(1.0)";

    if (!this.textVisible) {
      textCloudElem.attr('transform', hideTextTransform);
    }

    if (!this.dotsVisible) {
      dotClusterElem.attr('transform', hideDotsTransform);
    }

    $('.bbox').remove();
    this.restoreAllDots();
  }

  getDerivativeData = () => {
    this.getSelections();
    window.derivativeData.push(JSON.parse(JSON.stringify(responseDataStash)));
    window.childIds.push(new Date().getTime().toString().slice(-6));
  }

  /* ********************************************************************************* */

  setSelections = () => {
    if ($('.bbox').length > 0) {
      if (this.wizardOn) {
        window.areAnySelected(true)
      }
    } else {
      if (this.wizardOn) {
        window.areAnySelected(false)
      }
    }
  }

  getSelections = () => {
    let clusters = [];
    let selectedTins = [];
    var i;
    $('.bbox').each(function () {
      clusters.push("cluster-" + $(this).attr('id').split("-")[1]);
    });
    for (i = 0; i < clusters.length; i++) {
      $('.' + clusters[i]).each(function () {
        selectedTins.push($(this).attr('id'));
      });
    }

    for (i = 0; i < responseDataStash.length; i++) {

      responseDataStash[i].selected = false; //first reset each to false
      if (selectedTins.includes(responseDataStash[i].TIN)) {
        responseDataStash[i].selected = true;
      }
    }
    return responseDataStash;
  }


  setDotsGreen = (bboxId) => {
    const {analysisId} = this.props;
    let thisContainer = "bubbles-" + analysisId;
    let clusterClass = "cluster-" + bboxId.split("-")[1];
    $("#" + thisContainer).find($('.' + clusterClass)).each(function () {
      $(this).css("fill", "rgb(196, 251, 142)");
    });
  }

  restoreDotCluster = (bboxId) => {
    const {analysisId} = this.props;
    let thisContainer = "bubbles-" + analysisId;
    let clusterClass = "cluster-" + bboxId.split("-")[1];
    $("#" + thisContainer).find($('.' + clusterClass)).each(function () {
      let color = $(this).attr('color');
      $(this).css("fill", color);
    });
  }

  restoreAllDots = () => {
    const {analysisId} = this.props;
    let thisContainer = "bubbles-" + analysisId;
    $("#" + thisContainer).find($('circle')).each(function () {
      let color = $(this).attr('color');
      $(this).css("fill", color);
    });
  }


  drawWordCloud = (data, thisScale, xOffpercent, yOffpercent, cloudNumb) => {
    let self = this;

    function value_limit(val, min, max) {
      return val < min ? min : (val > max ? max : val);
    }

    var width = 1000;
    var height = 650;

    if (inIframe()) {
      width = 652;
      height = 425;
      thisScale = thisScale * this.macroScaleFactor;
    }

    const aspectRatio = 1.1;

    if (this.state.mounted) {
      let xoff = xOffpercent * width;
      let yoff = yOffpercent * height;
      let minFontSize = 18;
      let maxFontSize = 40;
      if (inIframe) minFontSize = 21; //make smallest fonts bigger
      const g = d3.select(`#${this.containerId}`).select('svg').select('.cloud')
        .append("g")
        .attr("class", "innerCloud")
        .attr("transform", "translate(" + xoff + "," + yoff + ")scale(" + thisScale + ")");

      const color = d3.scaleOrdinal(d3.schemeCategory10);

      d3.keys(d3.nest().key(d => d.occurances)
        .map(data));

      let rangeArray = [40, 80];

      const fontSize = d3.scalePow()
        .exponent(5)
        .domain([0, 1])
        .range(rangeArray);

      const draw = words => {
        let fontScale = 1.0;
        if (inIframe()) fontScale = 1.44;
        cloud.selectAll("text")
          .data(words)
          .enter().append("text")
          .attr('class', 'word')
          .on("mouseover", function (d) {  //set #3346FF blue  set wordset to single color and strokes around circles the same
            let groupNode = d3.select(this.parentNode);

            if (self.wizardOn) {
              let g_transform = groupNode.node().getAttribute("transform");
              g_transform = g_transform.replace("translate(", "");
              g_transform = g_transform.replace(")", "");
              let xOffset = g_transform.split(",")[0] * 1;
              let yOffset = g_transform.split(",")[1] * 1;
              let bbox = groupNode.node().getBBox();
              let bboxId = groupNode.node().getAttribute("id") + "-bbox";

              // On text-cloud hover, if the 'selected' box doesn't already exist, create it
              if ($('#' + bboxId).length === 0) {
                self.setDotsGreen(bboxId);
                g.append("rect")
                  .attr("id", bboxId)
                  .attr("class", "bbox")
                  .attr("x", bbox.x + xOffset)
                  .attr("y", bbox.y + yOffset)
                  .attr("width", bbox.width)
                  .attr("height", bbox.height)
                  .attr("removable", "true")
                  .style("fill", "#c4fb8e")
                  .style("fill-opacity", ".3")
                  .style("stroke", "#DDDDDD")
                  .style("stroke-width", "1.5px")
                  .style("cursor", "pointer")
                  .on("click", function (d) {
                    if ($('#' + bboxId).attr("removable") === "true") {
                      $('#' + bboxId).attr("removable", "false");

                      self.setDotsGreen(bboxId);
                    } else {
                      $('#' + bboxId).remove();
                      self.restoreDotCluster(bboxId);
                    }
                    self.setSelections();
                  })
                  .on("mouseout", function (d) {
                    if ($('#' + bboxId).attr("removable") !== "false") {
                      $('#' + bboxId).remove();
                      self.restoreDotCluster(bboxId);
                    }
                  });
              }
            }

            let groupID = groupNode.attr("id").split("-")[1];
            let cluster = ".cluster-" + groupID;
            $(cluster).css("opacity", 0.3);

            if (self.wizardOn) {
              self.cloudHilite(groupID, "#c4fb8e");
            } else {
              self.cloudHilite(groupID, "#3346FF");
            }


          })
          .on("mouseout", function (d) {  //restore text colors and stroke colors around circles
            let groupNode = d3.select(this.parentNode);
            let groupID = groupNode.attr("id").split("-")[1];
            let cluster = ".cluster-" + groupID;
            $(cluster).css("stroke", "black");
            $(cluster).css("opacity", 1.0);

            self.cloudUnlite(groupID);
            self.setSelections();

          })
          .style("fill", (d, i) => color(i))
          .attr('color', (d, i) => color(i))
          .style("font-size", d => value_limit((d.occurances + 1), fontScale * minFontSize, maxFontSize) + "px")
          .style("font-family", "serif")
          .style("font-weight", "bold")
          .attr("text-anchor", "middle")
          .attr("transform", d => "translate(" + [d.x / aspectRatio - width / 4, d.y / aspectRatio - height / 4] + ")scale(" + 1.0 + ")rotate(" + d.rotate + ")")
          .text(d => d.text);
      };

      d3layoutcloud().size([width, height])
        .timeInterval(20)
        .words(data)
        .fontSize((d, i) => fontSize(d.occurances / (this.mostCited + 1))) //a number between 0 and 1
        .rotate(0)
        .fontWeight(["bold"])
        .text(d => d.text)
        .spiral("rectangular") // "archimedean" or "rectangular"
        .on("end", draw)
        .start();

      const cloud = g.append("g")
        .attr('class', 'cloud')
        .attr('id', cloudNumb)
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    }
  }

  cloudHilite = (groupID, fillColor) => {
    d3.select(`#${this.containerId}`).select('svg').select('.cloud')
      .select('#cloud-' + groupID).selectAll('text').style("fill", fillColor);
  }

  cloudUnlite = (groupID) => {
    d3.select(`#${this.containerId}`).select('svg').select('.cloud')
      .select('#cloud-' + groupID).selectAll('text').each(function () {
      let thisColor = d3.select(this).style("color")
      d3.select(this).style("fill", thisColor).attr("cursor", "move");
    });
  }

  checkBoxClickHandler = (whichClicked) => {
    if (!this.wizardOn) {
      let polyPoints = "5 11 7 15 16 6";
      var thisCheckMark = 0;

      let textCloudElem = d3.select(`#${this.containerId}`).select('.cloud');
      let showTextTransform = "translate(0,0)";
      let hideTextTransform = "translate(-10000,-10000)";

      let dotClusterElem = d3.select(`#${this.containerId}`).select('.dots');
      let showDotsTransform = "translate(0, 0), scale(1.0)";
      let hideDotsTransform = "translate(-10000,-10000), scale(1.0)";


      if (whichClicked === 1) { // select the right polyline (checkmark) to show or hide
        thisCheckMark = d3.select(`#${this.containerId}`).select('#gcheckMark1').select('polyline');
      } else {
        thisCheckMark = d3.select(`#${this.containerId}`).select('#gcheckMark2').select('polyline');
      }

      if (thisCheckMark.attr("points") === polyPoints) {
        thisCheckMark.attr("points", "") //remove checkmark
        if (whichClicked === 1) {
          textCloudElem.attr('transform', hideTextTransform);
          this.textVisible = false;
        } else {
          dotClusterElem.attr('transform', hideDotsTransform);
          this.dotsVisible = false;
        }
      } else {
        thisCheckMark.attr("points", polyPoints) //show checkmark
        if (whichClicked === 1) {
          textCloudElem.attr('transform', showTextTransform);
          this.textVisible = true;
        } else {
          dotClusterElem.attr('transform', showDotsTransform);
          this.dotsVisible = true
        }
      }
    }
  }

  addCheckBoxes = () => {
    let self = this;
    const marginleft = -500;
    const margintop = -50;

    d3.select(`#${this.containerId}`).select('#svg-pan-zoom-controls') //checkbox1 body
      .append("g")
      .attr("transform", "translate(" + marginleft + "," + margintop + ")")
      .attr("id", "checkBoxGroup1")
      .attr("transform", "translate(-292 67) scale(1.37)")
      .attr("class", "svg-pan-zoom-control")
      .append('path')
      .attr('d', 'M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z')
      .attr('class', 'svg-pan-zoom-control-element')
      .attr('id', 'checkbox1')
      .on("click", function () {
        self.checkBoxClickHandler(1)
      });

    d3.select(`#${this.containerId}`).select('#svg-pan-zoom-controls') //checkbox1 label
      .append("g")
      .attr("transform", "translate(" + marginleft + "," + margintop + ")")
      .attr("id", "checkBoxTextGroup1")
      .attr("transform", "translate(-263 92) scale(1.25)")
      .attr("class", "svg-pan-zoom-control")
      .append('text')
      .attr("font-family", "sans-serif")
      .attr("font-size", "20px")
      .attr("fill", "black")
      .attr('class', 'svg-pan-zoom-control-element')
      .attr('id', 'labelText1')
      .text('text')
      .on("click", function () {
        self.checkBoxClickHandler(1)
      });

    d3.select(`#${this.containerId}`).select('#svg-pan-zoom-controls')//checkbox1 checkmark
      .append("g")
      .attr("transform", "translate(" + marginleft + "," + margintop + ")")
      .attr("id", "gcheckMark1")
      .attr("transform", "translate(-291 66) scale(1.25)")
      .attr("class", "svg-pan-zoom-control")
      .append('polyline')
      .attr('points', '5 11 7 15 16 6')
      .attr('stroke', 'white')
      .attr('stroke-width', '3')
      .attr('fill', 'white')
      .attr('class', 'svg-pan-zoom-control-element')
      .attr('id', 'checkmark1')
      .on("click", function () {
        self.checkBoxClickHandler(1)
      });

    d3.select(`#${this.containerId}`).select('#svg-pan-zoom-controls')//checkbox2 body
      .append("g")
      .attr("transform", "translate(" + marginleft + "," + margintop + ")")
      .attr("id", "checkBoxGroup2")
      .attr("transform", "translate(-205 67) scale(1.37)")
      .attr("class", "svg-pan-zoom-control")
      .append('path')
      .attr('d', 'M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z')
      .attr('class', 'svg-pan-zoom-control-element')
      .attr('id', 'checkbox2')
      .on("click", function () {
        self.checkBoxClickHandler(2)
      });

    d3.select(`#${this.containerId}`).select('#svg-pan-zoom-controls')//checkbox2 label
      .append("g")
      .attr("transform", "translate(" + marginleft + "," + margintop + ")")
      .attr("id", "checkBoxTextGroup2")
      .attr("transform", "translate(-177 92) scale(1.25)")
      .attr("class", "svg-pan-zoom-control")
      .append('text')
      .attr("font-family", "sans-serif")
      .attr("font-size", "20px")
      .attr("fill", "black")
      .attr('class', 'svg-pan-zoom-control-element')
      .attr('id', 'labelText2')
      .text('clusters')
      .on("click", function () {
        self.checkBoxClickHandler(2)
      });

    d3.select(`#${this.containerId}`).select('#svg-pan-zoom-controls')//checkbox2 checkmark
      .append("g")
      .attr("transform", "translate(" + marginleft + "," + margintop + ")")
      .attr("id", "gcheckMark2")
      .attr("transform", "translate(-204 66) scale(1.25)")
      .attr("class", "svg-pan-zoom-control")
      .append('polyline')
      .attr('points', '5 11 7 15 16 6')
      .attr('stroke', 'white')
      .attr('fill', 'white')
      .attr('stroke-width', '3')
      .attr('class', 'svg-pan-zoom-control-element')
      .attr('id', 'checkmark2')
      .on("click", function () {
        self.checkBoxClickHandler(2)
      });

    this.cloudHilite(1);
  }

  selectorControlMessage = () => { //if displayed groups don't match group selector number
    //an explanatory message is appended to the selector control
    let emptyGroupsCount = 0;
    for (var i = 0; i < this.wordGroups.length; i++) {
      if (this.wordGroups[i].length === 0) emptyGroupsCount++;
    }

    if (emptyGroupsCount === 1) {
      $('#groupCountSelector').parent().append("<span class='selectorComment'  style='color:red;'> -- the clustering algorithm generated 1 empty group</span>");
    }
    else if (emptyGroupsCount > 1) {
      $('#groupCountSelector').parent().append("<span class='selectorComment' style='color:red;'> -- the clustering algorithm generated " + emptyGroupsCount + " empty groups</span>");
    }
    else {
      $('.selectorComment').remove();
    } //if there is no prior comment to remove this is a noop
  }

  createWordMaps = (wordArray) => {
    // create map for word counts
    var i;
    for (i = 0; i < wordArray.length; i++) {
      wordArray[i] = wordArray[i].toLowerCase();
    }

    var wordCounter = {};

    for (i = 0; i < wordArray.length; i++) {
      if (wordCounter[wordArray[i]]) {
        wordCounter[wordArray[i]] += 1;
      } else {
        wordCounter[wordArray[i]] = 1;
      }
    }

    return wordCounter;
  }

  sortByCount = (wordsMap) => {
    // sort by count in descending order
    var finalWordsArray = [];

    finalWordsArray = Object.keys(wordsMap).map(function (key) {
      return {
        text: key,
        occurances: wordsMap[key].toString() //needed for mapping in wordCloud?
      };
    });

    finalWordsArray.sort(function (a, b) {
      return b.occurances - a.occurances;
    });

    return finalWordsArray;
  }


  extractTransAttr = () => {
    var regExp = /\(([^)]+)\)/; //extract the contents of ( )
    let $containerId = $('#' + this.containerId);
    var transform = $containerId.children().children().attr("transform");
    var style = $containerId.children().children().attr("style");

    if (style === undefined) { //undefined only after chart render and before 'slide' or 'zoom' occurs;
      var tArray = transform.split("scale");
      var xyPart = regExp.exec(tArray[0]);
      var xyArray = xyPart[1].split(",");
      var scalePart = regExp.exec(tArray[1]);
      this.xoffset = xyArray[0] * 1; //this will let us slide the svg region
      this.yoffset = xyArray[1] * 1; //this too
      this.scale = scalePart[1] * 1; //so we can zoom in or out
    }
    else // used after slide/zoom introduces new <g> with different transform attributes
    {
      var xyscaleparts = regExp.exec(style);
      var transArray = xyscaleparts[1].split(",");
      this.xoffset = transArray[4] * 1; //this will let us slide the svg region
      this.yoffset = transArray[5] * 1; //this too
      this.scale = transArray[0] * 1; //so we can zoom in or out
    }
  }


  ticked = () => {
    let dotScale = 1.0;
    if (inIframe()) dotScale = this.macroScaleFactor;
    let self = this;

    var u = d3.select(`#${this.containerId}`).select('svg').select('.dots')
      .selectAll('circle')
      .data(this.rspData);

    u.enter()
      .append('circle')
      .attr('class', function (d) {
        return "cluster-" + d.kMeansGroup;
      })
      .attr('r', function (d) {
        return 5;
      })
      .attr('id', function (d) {
        return d.TIN;
      })
      .attr('TIN', function (d) {
        return d.TIN;
      })
      .attr('dateCompleted', function (d) {
        return d.dateCompleted;
      })
      .attr('color', function (d) {
        return self.colorScale[d.kMeansGroup];
      })
      .style('fill', function (d) {
        return self.colorScale[d.kMeansGroup];
      })
      .style('stroke-width', function (d) {
        return 1;
      })
      .attr("cursor", "pointer")
      .merge(u)
      .attr('cx', function (d) {
        return dotScale * d.x;
      })
      .attr('cy', function (d) {
        return dotScale * d.y;
      })
      .attr('stroke', function (d) {
        return "black";
      })
      .on('click', function (d) {
        self.playSound(d.url);
      })
      .on("mouseover", function (d) {

        let p = $('#' + self.containerId).find('svg:first');
        let position = p.position();
        let thisX = self.currentMousePos.x - position.left + self.xadj;
        let thisY = self.currentMousePos.y - position.top + self.yadj;


        d3.select(this).style('stroke-width', 4); //highlight hovered circle on mouseover

        self.tooltip.transition()
          .duration(200)
          .style("opacity", .9);

        if (thisX < 400) {  //transcription text to left or right depending on xy location of cursor
          self.tooltip.html(d.responseText)
            .style("left", thisX + "px")
            .style("top", thisY + "px")
        } else {
          self.tooltip.html(d.responseText)
            .style("left", thisX - 230 + "px")
            .style("top", thisY + "px")
        }
      })
      .on("mouseout", function (d) {
        d3.select(this).style('stroke-width', 1);
        self.tooltip.transition()
          .duration(500)
          .style("opacity", 0)
      })
    u.exit().remove();
  }

  playSound = (url) => {
    var response = new Audio();
    response.src = url;
    response.play();
  }

  addSVGandToolTipDiv = () => {
    var {width, height} = this.props;

    if (inIframe()) { //if in iframe on home page
      width = width * this.macroScaleFactor;
      height = height * this.macroScaleFactor;
    }
    $('.bubbleChart').width(width);
    $('.bubbleChart').height(height);


    if (this.state.mounted) {
      let $containerId = $('#' + this.containerId);

      $containerId.empty(); //delete existing chart if any drawn already

      d3.select(`#${this.containerId}`)
        .append("svg").attr("width", width).attr("height", height).attr("class", "bubbleChart").attr("cursor", "move");

      d3.select(`#${this.containerId}`).select('svg')
        .append('g').attr('class', 'cloud').attr('id', 'wordMaps').attr("transform", "translate(0, 0), scale(1.0)");

      d3.select(`#${this.containerId}`).select('svg')
        .append('g').attr('class', 'dots').attr('id', 'clusters').attr("transform", "translate(0, 0), scale(1.0)");

      $containerId.css('position', 'relative').css('top', '-5px').css('max-width', width).css('background-color', 'white').css("border-color", "#EEEEEE");

      this.extractTransAttr();

      this.tooltip = d3.select(`#${this.containerId}`).append("div")
        .attr("class", "tooltip")
        .style("min-width", "100px")
        .style("opacity", "0.0")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("text-align", "left")
        .style("width", "175px")
        .style("height", "auto")
        .style("padding", "4px")
        .style("font", "12px sans-serif")
        .style("background", "white")
        .style("border", "solid thin gray")
        .style("border-radius", "8px")
        .style("pointer-events", "none");

      if (inIframe()) {
        $('.bubbleChart').css('border', 'solid thin gray');
      }
    }
  }

  startSimulation = () => {

    const {numGroups, width, height, wordGroups} = this.props;
    let numClusters = 0;
    let collisionRadius = 5;

    if (inIframe()) {
      numClusters = wordGroups.length - 1; //numGroups always starts at 3, so for home page base count on number word groups
      collisionRadius = 8;
    } else {
      numClusters = numGroups - 1;
    }

    if (this.state.mounted) {
      let self = this;
      /* ****************  set up pan and zoom   *************************** */
      var elem = document.getElementById(this.containerId).firstChild;

      svgPanZoom(elem, {
        zoomEnabled: true,
        controlIconsEnabled: true,
        fit: false,
        center: false,
        minZoom: 0.5,
        maxZoom: 3.0,
        onUpdatedCTM: function () {
          self.extractTransAttr();
        }
      });

      $(document).mousemove(function (event) {
        self.currentMousePos.x = event.pageX;
        self.currentMousePos.y = event.pageY;
      });

      self.addCheckBoxes();

      d3.forceSimulation(self.rspData)
        .force('charge', d3.forceManyBody().strength(20))
        .force('x', d3.forceX().x(function (d) {
          return xLocPercents[numClusters][d.kMeansGroup] * width;
        })) //scale to width of div
        .force('y', d3.forceY().y(function (d) {
          return yLocPercents[numClusters][d.kMeansGroup] * height;
        })) //scale to height of div
        .force('collision', d3.forceCollide().radius(function (d) {
          return collisionRadius;
        }))
        .on('tick', this.ticked) //call svg and animation engine in d3
    }
  }


  doTheCloud = () => {
    var i;
    if (this.drawCloud) {
      //separate the groups into an array of separate lists by kmeans group number
      this.wordMaps = [];
      for (i = 0; i < this.wordGroups.length; i++) {
        var list = {};
        list = this.createWordMaps(this.wordGroups[i]);
        this.wordMaps.push(list);
      }

      //sort each of the lists by word frequency in descending order
      for (i = 0; i < this.wordMaps.length; i++) {
        var tmpArray = [];
        tmpArray = this.sortByCount(this.wordMaps[i]);
        this.wordMaps[i] = tmpArray;
      }

      //now strip out singletons where appropriate
      let sparseWordMaps = [];
      for (i = 0; i < this.wordMaps.length; i++) {
        let tempMap = [];
        if (this.wordMaps[i].length > this.noSingletonThreshold) {
          for (var j = 0; j < this.wordMaps[i].length; j++) {
            if (this.wordMaps[i][j].occurances > 1) {
              tempMap.push(this.wordMaps[i][j]);
            }
          }
          sparseWordMaps.push(tempMap);
        } else {
          sparseWordMaps.push(this.wordMaps[i]);
        }
      }

      //stash group sizes in groupCounts array used in formatting wordClouds where we need to know the largest groupCount across lists
      var frequencyArray = [];
      for (i = 0; i < sparseWordMaps.length; i++) {
        if (sparseWordMaps[i][0] !== undefined) {
          frequencyArray.push(sparseWordMaps[i][0].occurances * 1);
        }
      }
      this.mostCited = Math.max(...frequencyArray);

      //now draw as many clouds to the screen as there are sparseWordMaps
      // pass the drawing method each map with values for: scaling, xoffsetting, and yoffsetting
      var scaleCoords = 1.0

      if (inIframe()) scaleCoords = 1.0;

      for (i = 0; i < sparseWordMaps.length; i++) {
        if (sparseWordMaps[i].length !== 0) {  //don't try to draw any wordmaps from
          // empty word arrays
          this.drawWordCloud(sparseWordMaps[i],
            scaleFactors[sparseWordMaps.length - 1],
            scaleCoords * scalexOffsets[sparseWordMaps.length - 1][i],
            scaleCoords * scaleyOffsets[sparseWordMaps.length - 1][i],
            "cloud-" + i.toString()); //is used to create id for grouping
        }
      }
    }
  }

  render() {
    const {responseData, analysisId, wordGroups, demo} = this.props;
    console.log("demo: ", demo);

    this.wordGroups = wordGroups;

    this.selectorControlMessage();

    if (responseData.length !== 0) {
      responseDataStash = JSON.parse(JSON.stringify(responseData));
    }

    if ((responseDataStash[0].selected !== undefined) && (! demo)) {     // if a derivative chart
      responseDataStash = responseDataStash.filter((item) => {  // filter out the unselected data
        return item.selected;
      })
    }


    if (this.rspData.length === 0) { //render gets called twice when submit is clicked
      this.drawCloud = false //first time through loads the data
    } else {
      this.drawCloud = true  //second time does the drawing
    }

    for (var i = 0; i < responseDataStash.length; i++) {
      this.rspData[i] = {...responseDataStash[i]};
      delete this.rspData[i].BERTData
    }


    return (
      <div id={`bubbles-${analysisId}`}
           ref="container"
           style={{
             position: 'relative',
             margin: 'auto',
             marginTop: '20px',
             display: 'block',
             border: '1px solid transparent'
           }}>
        {this.addSVGandToolTipDiv()}
        {this.doTheCloud()}
        {this.startSimulation()}
      </div>
    )
  }
}

export default Clusters;