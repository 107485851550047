import React from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from "react-router-dom";
import $ from 'jquery';
import * as d3 from 'd3';
import {setActiveModule} from '../../actions/setActiveModule';
import {setModalId} from '../../actions/setModalId';


/*
 Notes on csv support:

 javascript promises: https://stackoverflow.com/questions/47326087/how-to-use-javascript-promises-es6-instead-of-jquery-ajax-call

 https://programmingwithmosh.com/javascript/react-file-upload-proper-server-side-nodejs-easy/

 https://stackabuse.com/reading-and-writing-csv-files-with-node-js/

 https://codeburst.io/handling-file-uploads-on-node-server-using-multer-part-a-backend-a789e0003e04

 important: how to format ajax calls with formdata as data:
 https://stackoverflow.com/questions/12755945/jquery-and-html-formdata-returns-uncaught-typeerror-illegal-invocation


 */

/* *************************** cloverLeaf support functions ************************************************* */
var step = 0;
var timerID = 0;
var fadeDuration = 700;

//frames         0     1     2     3     4     5     6     7     8     9    10    11    12    13    14    15
var waitArray = [500, 500, 500, 500, 500, 1000, 1000, 500, 500, 1000, 500, 1000, 1000, 500, 1, 1];

function cloverLeaf() {
  clearTimeout(timerID);
  if ($('.analyzeBubble').length > 0) {
    fadeArrows();
    step = 0;
    window.setTimeout(invokeAnim, waitArray[step] / 2);
  } else {
    d3.xml("./TellMeThisProcessCloverLeaf.svg")
      //d3.xml("https://tmt-devstore.s3.us-east-1.amazonaws.com/TellMeThisProcessCloverLeaf.svg")
      .then(data => {
        if (d3.select("#animated-div").node() !== null) { //this code is accessed for login and signup but the div isn't present
          d3.select("#animated-div").node().append(data.documentElement);
          window.setTimeout(invokeAnim, waitArray[step] / 2);
        }
      });
  }

  function invokeAnim() {
    //console.log("in invoke Anim step: ", step);
    let time = waitArray[step] / 2;

    if (step < 16) {
      clearTimeout(timerID);
      timerID = window.setTimeout(invokeAnim, time);
      animate(step);
      step++;
    } else {
      clearTimeout(timerID);
      step = 0;
      return;
    }
  }

  function animate(step) {
    switch (step) {
      case 0:
        $('.createArrow').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        $('.createArrowText').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 1:
        $('.assignArrow').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        $('.assignArrowText').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 2:
        $('.reviewArrow').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        $('.reviewArrowText').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 3:
        $('.analyzeArrow').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        $('.analyzeArrowText').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 4:
        $('.createBubble').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 5:
        $('.createText1').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 6:
        $('.createText2').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 7:
        $('.assignBubble').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 8:
        $('.assignText1').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 9:
        $('.assignText2').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 10:
        $('.reviewBubble').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 11:
        $('.reviewText1').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 12:
        $('.reviewText2').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 13:
        $('.analyzeBubble').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 14:
        $('.analyzeText2').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      case 15:
        $('.analyzeText1').css('opacity', 0.0).css('visibility', 'visible').fadeTo(fadeDuration, 1);
        break;
      default:
        clearInterval(timerID);
        break;
    }
  }
}

function fadeAnim() {
  clearTimeout(timerID);
  $('.createBubble, .createText1, .createText2, .assignBubble, .assignText1, .assignText2,' +
    '.reviewBubble, .reviewText1, .reviewText2, .analyzeBubble, .analyzeText1, .analyzeText2').fadeTo(fadeDuration, 0);
}

function fadeArrows() {
  clearTimeout(timerID);
  $('.createArrow,.createArrowText, .assignArrow, .assignArrowText, .reviewArrow,' +
    '.reviewArrow, .reviewArrowText, .analyzeArrow, .analyzeArrowText').fadeTo(fadeDuration, 0);
}

/* ******************************* end cloverleaf support ******************************* */

/* ******************************* top panel support    ******************************** */
let nextBullet = 0;
let duration = 700;
let bulletTimer = 0;

function invokeBullets() {
  if (nextBullet < $('.bullets').length + 1) {
    clearTimeout(bulletTimer);
    bulletTimer = window.setTimeout(invokeBullets, duration);
    runBullets(nextBullet);
    nextBullet++;
  } else {
    clearTimeout(bulletTimer);
    nextBullet = 0;
    return;
  }
}

function runBullets(bulletNumb) {
  $('#a' + bulletNumb).fadeTo(duration, 1);
}

function endBullets() {
  $('#a1, #a2, #a3, #a4, #a5').fadeTo(duration, 0);
}

/* ******************************* end top panel support ******************************* */

/* ******************************* when to consider support  ******************************** */
let nextWhenBullet = 0;
let whenDuration = 700;
let whenBulletTimer = 0;

function invokeWhenBullets() {
  if (nextWhenBullet < $('.whenBullets').length + 1) {
    clearTimeout(whenBulletTimer);
    whenBulletTimer = window.setTimeout(invokeWhenBullets, whenDuration);
    runWhenBullets(nextWhenBullet);
    nextWhenBullet++;
  } else {
    clearTimeout(whenBulletTimer);
    nextWhenBullet = 0;
    return;
  }
}

function runWhenBullets(bulletNumb) {
  $('#b' + bulletNumb).fadeTo(whenDuration, 1);
}

function endWhenBullets() {
  $('#b1, #b2, #b3, #b4, #b5').fadeTo(whenDuration, 0, function () {});
}
/* ******************************* end when to consider support ******************************* */

function locateCardArrow() {
  var x = $("#transcriptRightCell").offset().left - 160;
  var y = $("#embed").offset().top + $("#container").scrollTop() + $("#arrowContainer").height() + 20;
  $('#CardArrow').css('left', x).css('top', y).css('padding','5px');
}

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        cloverVisible: false,
        cloverFaded: false
      }
    }

    componentDidMount() {
      let self = this;
      const {
        setActiveModule
      } = this.props;
      setActiveModule("Home");
      document.getElementById("clusters-iframe").setAttribute('src', `${window.MAKER_HOST}/cluster/1234`);
      // document.getElementById("clusters-iframe").setAttribute('src', 'http://localhost:3000/cluster/1234');
      // document.getElementById("homePageTranscript").setAttribute('src', `https://tmt-maker.herokuapp.com/transcript/638685670/ClimateChange-02/ro`);
      document.getElementById("homePageTranscript").setAttribute('src', `https://tmt-maker.herokuapp.com/transcript/480637526/Say2/ro`);

      document.getElementById("embed").setAttribute('src',
          "https://tmt-taker.herokuapp.com/?TIN=000085224&testName=Say2&accountId=Ue9E1omk5&mode=CP&clr=black&t0=008000&t1=90EE90&msg=Click%2520to%2520share%2520your%2520thoughts%2520about...");


      let containerObj = document.getElementById("container");
      containerObj.addEventListener("scroll", this.handleScroll);

      window.addEventListener("resize", this.handleResize);
      self.handleResize();
      self.panelHandler('#bulletFeatures');
    }

    panelHandler = (targetDiv) => { //triggers panel animation when it scrolls into view
      let offset = 0;

      function isInview(offset) {
        let viewPortTop = $('#container').offset().top + offset;
        let viewPortBottom = $('#container').outerHeight() - offset;
        let targetTop = $(targetDiv).offset().top;
        let targetBottom = targetTop + $(targetDiv).height();
        if ((targetBottom > viewPortTop) && (targetTop < viewPortBottom)) {
          return true
        } else {
          return false
        }
      }

      switch (targetDiv) {
        case '#bulletFeatures':
          offset = 0;
          if (isInview(offset)) {
            if (nextBullet === 0) {
              endBullets();
              invokeBullets();
            }
          }
          break;
        case '#whenBulletFeatures':
          offset = 400;
          if (isInview(offset)) {
            if (nextWhenBullet === 0) {
              endWhenBullets();
              invokeWhenBullets();
            }
          }
          break;
        case '#animated-div':
          offset = 200;
          if (isInview(offset)) {
            if (!this.state.cloverVisible) {
              cloverLeaf();
              this.setState({
                cloverVisible: true,
                cloverFaded: false
              });
            }
          } else {
            if (!this.state.cloverFaded) {
              fadeAnim();
              this.setState({
                cloverVisible: false,
                cloverFaded: true
              });
            }
          }
          break;
        default:
      }
    }

    handleScroll = () => {
      this.panelHandler('#bulletFeatures');
      this.panelHandler('#whenBulletFeatures');
      this.panelHandler('#animated-div');
    }

    handleResize = () => {
      $('#container').height(window.innerHeight);
      locateCardArrow();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
      let containerObj = document.getElementById("container");
      containerObj.removeEventListener('scroll', this.handleScroll);
    }

  render() {
    return (
      <div id='staticUnderlayer' >
        <div style={{position: 'relative', top: '100px', width: '100%', height: '0px'}}>
          <table style={{position: 'relative', width: '98%'}}>
            <tbody>
              <tr>
                <td>
                  <img width="320" src="womanLaptopBlue.png" alt=""></img>
                </td>
              </tr>
              <tr>
                <td style={{textAlign: 'right', verticalAlign: 'top', paddingRight: '0px'}}>
                  <img width="300" style={{position: 'relative', top: '-40px'}} src="manCellPhoneBlue.png" alt=""></img>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="container" style={{zIndex: '5'}}>

          <div id="CardArrow" style={{position: 'relative', width: '0px', height:'0px'}}>
               <div id="arrowContainer" style={{position: 'relative',  textwidth: '350px',height: '170px', backgroundColor: 'transparent', opacity:'0.0', zIndex: '10'}}>
                 <img src='CcardArrow.png' alt=""></img>
               </div>
          </div>

          <div id="panel-top" style={{position: 'relative', zIndex: '5', background: 'transparent'}}>
            <div className='topBar'>
              <img src="TMTLogoWhiteBalloonsWithTagLine.svg" className="TMTLogoWhiteBallonsWithTagLine" alt="" />
              <div id="SignUpButton" className="big-menu-link" style={{
                     position: 'relative',
                     top: '-6px',
                     float: 'right',
                     border: 'solid thin white',
                     borderRadius: '5px',
                   }}>
                <Link to={'./signUpMenu'} className='menuLink'
                  style={{color: '#ffffff', fontSize: '20px', fontFamily: 'arial'}}>Signup</Link>
              </div>
              <span id="LoginSpan" className="big-menu-link" style={{
                      float: 'right',
                      marginTop: '15px'
                    }}>
                <Link to={'/login'} className='menuLink' style={{
            color: '#ffffff',
            fontSize: '20px',
            fontFamily: 'arial'
          }}>Log in</Link>
              </span>
            </div>

            <div>
              <table style={{margin: 'auto', marginTop: '50px', paddingBottom: '50px'}}>
                <tbody>
                  <tr>
                    <td rowSpan='3' style={{
                    border: 'solid thin transparent',
                    minWidth: '320px',
                    maxWidth: '320px'
                  }}>&nbsp;
                    </td>
                    <td style={{border: 'solid thin transparent'}}>
                      <div id="Hdng-0">
                        Your online tool for spoken response surveys
                      </div>
                    </td>
                    <td rowSpan='3' style={{
                    border: 'solid thin transparent',
                    minWidth: '300px',
                    maxWidth: '300px'
                  }}>&nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: 'solid thin transparent'}}>
                      <div id="tagLine" style={{
                      fontSize: '30px',
                      marginLeft: '0px',
                      textAlign: 'center'
                    }}>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: 'solid thin transparent', paddingLeft: '20px'}}>
                      <ul id="bulletFeatures">
                        <li id="a1" className="bullets">Create new surveys in minutes</li>
                        <li id="a2" className="bullets">Reach participants on their PCs or
                          phones
                        </li>
                        <li id="a3" className="bullets">Share responses in participant's own
                          voices
                        </li>
                        <li id="a4" className="bullets">Analyze results with state of the
                          art AI
                        </li>
                        <li id="a5" className="bullets">Adopt as a single user or engage a
                          team of any size
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div id="panel-whenToConsider">
            <div id="whySpoken">
              <div id="Hdng-1">
                When to consider a spoken response survey
              </div>
              <ul id="whenBulletFeatures">
                <li id="b1" className="whenBullets">Respondents prefer to share
                  their thoughts verbally
                </li>
                <li id="b2" className="whenBullets">Spoken responses would capture
                  important non-verbal information (e.g. emotion, confidence)
                </li>
                <li id="b3" className="whenBullets">Phones would be the most
                  convenient devices for participants
                </li>
                <li id="b4" className="whenBullets">Literacy levels could influence
                  response validity
                </li>
                <li id="b5" className="whenBullets">Survey designs indicate
                  appropriatenss of "open" responses
                </li>
              </ul>
            </div>
          </div>

          <div id="panel-cloverLeaf">
            <div className='anim-spacer'></div>
            <table>
              <tbody>
                <tr>
                  <td  id='cloverLeafLeft' >&nbsp;</td>
                  <td  id='cloverLeafMidLeft'>
                    <div id='Hdng-2'>
                      <i id='tellmethis'>TellMeThis&nbsp;</i> supports the full
                      design-to-analysis process
                    </div>
                    <ul className='homePageBulletPts.no-bullets' id='cloverLefBullets'>
                      <li className="no-bullets"><span className='homePageAllCaps'>CREATE</span> <span
                          className='homePageNoCaps'>online or phone surveys in minutes</span></li>
                      <li className="no-bullets"><span className='homePageAllCaps'>ASSIGN</span> <span
                          className='homePageNoCaps'>surveys to named individuals or allow anonymous
                          participation</span></li>
                      <li className="no-bullets"><span className='homePageAllCaps'>REVIEW</span> <span
                          className='homePageNoCaps'>responses using online audio transcripts</span></li>
                      <li className="no-bullets"><span className='homePageAllCaps'>ANALYZE</span> <span
                          className='homePageNoCaps'>responses for sentiment, content and other attributes</span></li>
                    </ul>
                  </td>
                  <td   id='cloverLeafMidRight' >
                    <div id='animated-div' >
                    </div>
                  </td>
                  <td  id='cloverLeafMidRight' >&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id='panel-creditCard'>
            <div className='creditCardHdng'>Embed a survey on your website</div>
            <table id='creditCardTable'>
              <tbody>
                <tr>
                  <td id='creditCardTableLeft'>&nbsp;</td>
                  <td  id='creditCardTableMidLeft' >
                    <iframe id="embed" width="300" height="180" frameBorder="0" allow="microphone" title="credit_card"></iframe>
                  </td>
                  <td  id='creditCardMidRight'>
                    <ul className='homePageBulletPts.no-bullets' id="creditCardUL">
                      <li className="card-bullets"><span id="interestedInTheViews">Interested in the views of...
                        </span></li>
                      <li className="card-bullets"><span
                          className='homePageGrayCaps'><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>- customers visiting
                          your site?</span></li>
                      <li className="card-bullets"><span
                          className='homePageGrayCaps'><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>- followers reading
                          your blog?</span></li>
                      <li className="card-bullets"><span
                          className='homePageGrayCaps'><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>- members of your
                          organization?</span></li>
                      <li className="card-bullets" id="cardBulletsLastItem">&nbsp;</li>
                      <li className="card-bullets"><span id="collectOpinions" >Click the card and try it!</span></li>
                      <li className="card-bullets"><div id="collectOpinions2" style={{left:'20px', top: '-15px'}}>
                           Try this survey on your PC now and review your transcript below.
                           </div>
                           </li>
                    </ul>
                  </td>
                  <td  id='creditCardTableRight' >&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="panel-transcript">
            <table>
              <tbody>
                <tr>
                  <td id='transcriptLeftMarginCell'>&nbsp;</td>
                  <td id="transcriptLeftCell" >
                    <div id="transcriptText">
                      Review any survey transcript
                      <ul className="transcriptBulletsUL">
                        <li className="transcript-bullets"><span className='homePageWhiteCaps'>Scroll through prompt-response
                            conversations</span></li>
                        <li className="transcript-bullets"><span className='homePageWhiteCaps'>Listen to respondents in
                            their own voices</span></li>
                        <li className="transcript-bullets">
                          <span className='homePageWhiteCaps'>Share conversation links with
                            anyone</span></li>
                      </ul>
                    </div>
                  </td>
                  <td id="transcriptRightCell" >
                    <p align="left" id="transcriptIframePara">
                      <iframe id='homePageTranscript' width="350" height="650" frameBorder="0" title="transcript">
                      </iframe>
                    </p>
                  </td>
                  <td  id='transcriptRightMarginCell'>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="panel-clusters">
            <div  className='homePageHeading' id='clustersHdng' >
                      Use natural language processing to explore your data
            </div>
            <table>
              <tbody>
                <tr>
                  <td  id='clusterTableTopLeft'>&nbsp;</td>
                  <td id="iFrameCell">
                    <div id="clusterContainerDiv">
                      <iframe id="clusters-iframe" width="652" height="425" frameBorder="0" title="clusters"></iframe>
                    </div>
                     <div style={{pointerEvents: 'none', position: 'relative', top: '-25px', paddingLeft: '20px', paddingRight: '20px', fontSize: '16px'}}
                        >In this example, 8 survey takers responded to the question,
                        "Are there any ways that climate change has already affected your day-to-day life?"
                    </div>
                  </td>
                  <td id='clusterBottomRightTD' >
                    <div id='clusterRightText'>
                      <div id='clusterLeftColText'>
                        <span className='clusterNoCaps'>Access and play spoken responses with mouse-clicks and mouse-overs on graphical elements
                          in analysis charts</span>
                      </div>
                      <div id="clustersSortText">
                        <span className='clusterNoCaps'>Sort responses into groups using built-in AI</span>
                        <ul className='clusterBulletsUL'>
                          <li className='clusterBulletsLI'><span className='clusterGrayCaps'>by meaning</span></li>
                          <li className='clusterBulletsLI'><span className='clusterGrayCaps'>by sentiment
                            (positivity/negativity)</span></li>
                          <li className='clusterBulletsLI'>
                          <span className='clusterGrayCaps'>by “named entities” (known names and places)</span></li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td  id='clusterTableTopRight' >&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id='homePageFooter'>
            <table className='homePageFooterTable'>
              <tbody>
                <tr>
                  <td className='homePageFooterCols'>
                    <a href="#" className='homePageFooterLinks'>About TellMeThis!</a>
                  </td>
                  <td id='footerMidCol' className='homePageFooterCols'>
                    &copy;2021
                  </td>
                  <td id='footerRightCol' className='homePageFooterCols' >
                    <a href='./signUpMenu' className='homePageFooterLinks'>Signup</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modalId: state.modalId
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setActiveModule,
    setModalId
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);