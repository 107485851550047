import React from 'react';
import ModalFrame from '../../ModalFrame';
import RetrievePrompt from './RetrievePrompt';

class RetrievePromptModalController extends React.Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    const {setModalInfo} = this.props;
    setModalInfo({});
  }

  render() {
    const {modalInfo, confirmHandler, cancelHandler} = this.props;
    return (
      <div className="example-modal">
        {modalInfo.name === 'retrievePrompts' ?
          <ModalFrame
            title="RETRIEVE A SAVED PROMPT"
            desktopWidth="75%"
            headerCloseButtonHandler={this.hideModal}
            hasHeader={true}>
            <RetrievePrompt
              modalInfo={modalInfo}
              hideModal={this.hideModal}
              confirmHandler={confirmHandler}
              cancelHandler={cancelHandler}
            />
          </ModalFrame> : null}
      </div>
    )
  }
}

export default RetrievePromptModalController;
