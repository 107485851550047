/*
 see: http://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript
 */

const getJsonFromUrlQueryString = () => {
  let query = window.location.search.substr(1);
  if (!query) {
    return "";
  }
  var result = {};
  query.split("&").forEach(function (part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export default getJsonFromUrlQueryString;