
import {SET_REVIEW_BATCHES} from '../actions/_CONSTANTS';

const reviewBatches = (state=[], action) => {
  switch(action.type){
    case SET_REVIEW_BATCHES:
      return action.payload;
    default:
      return state;
  }
}

export default reviewBatches;
