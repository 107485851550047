import $ from 'jquery';
import _ from 'lodash';

function getAllSurveys(accountId, module) {
  return new Promise((resolve, reject) => {
    let url = `/test/getSurveys?accountId=${accountId}`
    // let url = '/testerror';
    $.ajax({
      url: url,
      type: "GET",
      success: (result) => {
        let tests = result.tests;
        tests = tests.filter((test) => {
          return test.status !== 'archived';
        })
        if (module) {
          _.sortBy(tests, ['createdAt']);
          _.reverse(tests);
          switch (module) {
            case 'assign':
              tests = tests.filter((test) => {
                return test.status === 'deployed';
              })
              break;
            case 'anonAssign':
              tests = tests.filter((test) => {
                return test.status === 'deployed';
              })
              break;
            case 'create':
              break;
            case 'review':
              break;
            case 'track':
              tests = tests.filter((test) => {
                return test.status !== 'development';
              });
              break;
            case 'trackAnon':
              tests = tests.filter((test) => {
                return test.status !== 'development' && test.completedAnonCount > 0;
              });
              break;
            case 'analyze':
              tests = tests.filter((test) => {
                return test.status !== 'development' && (test.completedAnonCount > 0 || test.completedCount > 0);
              });
              break;
            default:
              break;
          }
        }
        resolve(tests)
      },
      error: (err) => {
        console.log(err)
        reject(err);
      }
    })
  })
}

export default getAllSurveys;