import React from 'react';
import slicedTin from '../Common/slicedTin';
import moment from 'moment';



class TableRow extends React.Component {

  trackDate(date, completionError) {
    if (completionError) {
     /*  return 'error' */
      return 'technical problem'
    }
    if (date) {
      return moment(date).format('MM/DD/YYYY');
    }
    else {
      return '---'
    }
  }

  sendReminder = () => {
    const {row, handleSendReminder} = this.props;
    console.log("clicked send")
    handleSendReminder(row);
  }

errorTooltip = () => {
    const {row} = this.props;
    let tooltip = "";
    if(row.completionError) {
      if(row.isAnon) {
        tooltip = "This survey did not complete properly";
      }
      else {
        tooltip = row.email ? `${row.email} was not able to complete this survey` : 'An error occurred completing this survey'
      }
      return tooltip;
    }
}

  render() {
    const {row} = this.props;
    let sendReminderDisabled = !row.testTaker || row.badEmail || !row.email || row.dateCompleted;
    let sendReminderVisible = sendReminderDisabled ? 'hidden' : 'visible';
    let dateCompletedColor = row.completionError ? 'red' : 'black';
    let cursorType = row.completionError ? 'pointer' : 'text';

    return (
      <tr style={{backgroundColor: row.backgroundColor}}>
        <td className="trackTableCells" style={{paddingLeft: '3px'}}>{row.batch}</td>
        <td className="trackTableCells" style={{paddingLeft: '3px'}}>{row.testTaker}</td>
        <td className="trackTableCells" style={{paddingLeft: '3px'}}>{row.email}</td>
        <td className="trackTableCells"
            style={{textAlign: 'center'}}>{slicedTin(row.TIN)}</td>
        <td className="trackTableCells"
            style={{textAlign: 'center'}}>{this.trackDate(row.dateAssigned)}</td>
        <td className="trackTableCells"
            style={{textAlign: 'center'}}>{this.trackDate(row.dateStarted)}</td>
        <td className="trackTableCells"
            style={{
              textAlign: 'center',
              color: dateCompletedColor,
              cursor: cursorType  //pointer if error so user knows to hover for tooltip
            }}
            title={this.errorTooltip()}
        >{this.trackDate(row.dateCompleted, row.completionError)}</td>
        <td className="trackTableEmail">
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <div style={{marginTop: '2px', visibility: sendReminderVisible}}>Send
              reminder
            </div>
            <div>

              <button className="blueButtonSmall"
                      disabled={ sendReminderDisabled }
                      onClick={this.sendReminder}>
                <i className="fa fa-check" style={{fontSize: '10px'}}></i>
              </button>
            </div>
          </div>
        </td>
      </tr>
    )

  }

}

export default TableRow;