import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import $ from 'jquery';
import {setActiveModule} from '../../actions/setActiveModule';
import {clearBERTData} from '../../actions/Analyze/clearBERTData';
import {clearWordGroups} from '../../actions/Analyze/clearWordGroups';
import getAllSurveys from '../Common/getAllSurveys';
import showSystemError from '../Common/showSystemError';
import Navbar from '../Navbar';
import AnalysisContainer from './AnalysisContainer';


import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';

Moment.locale('en');
momentLocalizer();
/* support for downloading chart pdf */


class Analyze extends React.Component {

  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('analyze');
    if (oldState) {
      window.numberOfChildren = 0;
      oldState.containers.forEach((container) => {
        if (container.isDerivative) {
          window.numberOfChildren = window.numberOfChildren + 1;
        }
      })
      this.state = oldState;
      localStorage.removeItem('analyze');


    }
    else {
      this.state = {
        lastId: 1,
        containers: [{
          id: 1,
          analysisId: new Date().getTime().toString().slice(-6),
          visible: true,
          isDerivative: false,
          type: null,
          childAnalysisId: null
        }],
        surveys: []
      }
    }
  }

  componentDidMount() {
    const {setActiveModule, user, clearBERTData, clearWordGroups} = this.props;
    setActiveModule("Analyze");
    window.addEventListener("resize", this.handleResize);
    localStorage.removeItem('analyze_reset');

    this.handleResize();
    clearBERTData();
    clearWordGroups();
    getAllSurveys(user.accountId, 'analyze')
      .then((surveys) => {
        surveys.filter((survey) => {
          return survey.completedAnonCount || survey.completedCount
        })
        this.setState({
          surveys,
        });
      }, (err) => {
        console.log(err)
        showSystemError();
      })
  }

  handleResize = () => {
    $('.main').height(window.innerHeight - 92);
  }


  hideShowContainer = (analysisId, bool) => {
    let containers = [...this.state.containers];
    containers.forEach((container) => {
      if (container.analysisId === analysisId) {
        container.visible = bool;
      }
    })
    this.setState({containers});
  }

  subtractChild = (id) => {
    id = id.slice(-6)
    let containers = [...this.state.containers];

    let containerToBeRemoved = containers.find((container) => {
      return id === container.analysisId;
    })

    if (containerToBeRemoved.isDerivative) {
      if (containerToBeRemoved.parentAnalysisId) {
        let parentContainer = containers.find((container) => {
          return container.analysisId === containerToBeRemoved.parentAnalysisId;
        })
        parentContainer.childAnalysisId = null;
      }
      let index = window.childIds.findIndex((childId)=>{
        return childId === id;
      })

    //  https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array

      window.derivativeData.splice(index, 1);

    }
    else {
      if(containerToBeRemoved.childAnalysisId) {
        let childContainer = containers.find((container) => {
          return container.analysisId === containerToBeRemoved.childAnalysisId;
        })
        childContainer.parentAnalysisId = null;
      }

    }


    _.remove(containers, (container) => {
      return container.analysisId.toString() === id;
    })

    this.setState({containers}, () => {
      if (!containers.length ||
        containers.findIndex((container) => {
          return container.visible
        }) === -1) {
        this.addChild();
      }
      else {

      }
    })
  }

  isChartDeleted = (analysisId) => {
    if (!analysisId) {
      return false
    }
    return (this.state.containers.findIndex((container) => {
      return container.analysisId === analysisId
    }) === -1)
  }


  _addChild = (obj) => {

    let containers = [...[obj], ...this.state.containers];
    this.setState({
      containers,
      lastId: this.state.lastId + 1
    })
    $('.main').scrollTop(0)
  }

  addDerivativeChild = (type, childAnalysisId, analysisId) => {
    let nextId = this.state.lastId + 1;
    let obj = {
      id: nextId,
      analysisId: childAnalysisId,
      visible: true,
      isDerivative: true,
      type: type,
      parentAnalysisId: analysisId
    }
    let containers = [...this.state.containers];
    containers.forEach((container) => {
      if (container.analysisId === analysisId) {
        container.childAnalysisId = childAnalysisId;
      }
    })
    this._addChild(obj);
  }

  addChild = (e) => {
    let nextId = this.state.lastId + 1;
    let obj = {
      id: nextId,
      analysisId: new Date().getTime().toString().slice(-6),
      visible: true,
      isDerivative: false,
      type: null,
      childAnalysisId: null
    }
    this._addChild(obj)

  }

  adjustLinkedTitle = (titleElemChanged, newText) => {  //change link text when titles are edited
    let id = titleElemChanged.split('-')[2];
    let $thisLink = $('#' + $('#' + id).find('a').attr('id'));
    let $linkedChart = $('#' + $thisLink.attr("scrollTo")).find('a');

    if ($linkedChart.text().indexOf('Parent') !== -1) {
      $linkedChart.text("Parent of chart: " + newText);
    } else if ($linkedChart.text().indexOf('Child') !== -1) {
      $linkedChart.text("Child of chart: " + newText);
    }
  }

  closeModule = () => {
    const {history} = this.props;
    localStorage.setItem('analyze_reset', 'reset');
    localStorage.setItem('analyze_refresh', 'refresh');
    history.push('/recycle/');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (!localStorage.getItem('analyze_reset')) {
      localStorage.setObject('analyze', this.state);
    }
  }

  render() {
    const {history, user} = this.props;
    if (_.isEmpty(user)) {
      history.push('/')
      return (
        null
      )
    }
    return (
      <div>
        <div>
          <div>
            <Navbar
              history={history}
            />
            <div className="main mainVisible"
                 style={{marginBottom: '0px', marginTop: '20px', overflowY: 'auto'}}>

              <div className="analysis-outer-container"
                   style={{width: '96%', minWidth: '96%', marginTop: '12px'}}>
                {this.state.containers.map((container, index) => {
                  return (<AnalysisContainer
                    analysisId={container.analysisId}
                    parentAnalysisId={container.parentAnalysisId}
                    childAnalysisId={container.childAnalysisId}
                    id={`container-${container.analysisId}`}
                    key={container.id}
                    isDerivative={container.isDerivative}
                    derivativeChartType={container.type}
                    surveys={this.state.surveys}
                    user={user}
                    firstDiv={index === 0}
                    visible={container.visible}
                    hideShowContainer={this.hideShowContainer}
                    subtractChild={this.subtractChild}
                    addChild={this.addChild}
                    isChartDeleted={this.isChartDeleted}
                    adjustLinkedTitle={this.adjustLinkedTitle}
                    addDerivativeChild={this.addDerivativeChild}/>)
                })}
              </div>

            </div>
          </div>
        </div>
        <div className='analysisFooter' style={{marginTop: '0px'}}>
          <button className={'blueButton'}
                  style={{marginTop: '6px'}}
                  onClick={this.closeModule}>CLOSE
          </button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setActiveModule,
    clearBERTData,
    clearWordGroups
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Analyze);