import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setActiveModule } from '../../actions/setActiveModule'
import { getTranscript } from '../../actions/Review/getTranscript'
import { setModalInfo } from '../../actions/setModalInfo'
import { clearTranscript } from '../../actions/Review/clearTranscript'
import reviewDoScoring from './reviewDoScoring'
import ReviewSelector from './ReviewSelector'
import ReviewTable from './ReviewTable'
import ReviewTranscript from './ReviewTranscript'
import ReviewFooterLeft from './ReviewFooterLeft'
import ModalExportController from './ModalExport/ModalExportController'
import ModalShowScoreReportController from './ModalExport/ModalShowScoreReportController'
import ModalScoreAllReportController from './ModalExport/ModalScoreAllReportController'
import showSystemError from '../Common/showSystemError'
import { setFilteredTable } from '../../actions/Review/setFilteredTable'
import Navbar from '../Navbar'
import _ from 'lodash'
import $ from 'jquery'

class Review extends React.Component {
  constructor (props) {
    super(props)
    let oldState = localStorage.getObject('review')
    if (oldState) {
      this.state = oldState
      localStorage.removeItem('review')
      console.log(this.state.nextSortDirection[this.state.sortCriteria])
      if (this.state.nextSortDirection[this.state.sortCriteria] === 'asc') {
        this.state.nextSortDirection[this.state.sortCriteria] = 'desc'
      } else if (
        this.state.nextSortDirection[this.state.sortCriteria] === 'desc'
      ) {
        this.state.nextSortDirection[this.state.sortCriteria] = 'asc'
      }
    } else {
      this.state = {
        visible: false,
        appReady: false,
        hideGears: true,
        currentSound: null,
        sortCriteria: 'colCompleted',
        activeTitle: 'Completed',
        nextSortDirection: {
          colName: null,
          colEmail: null,
          colSurvey: null,
          colBatch: null,
          colCompleted: 'asc'
        },
        TIN: null,
        testName: '',
        activeRow: '',
        refreshKey: 0,
        filters: {
          survey: 'All',
          batch: 'All',
          name: '',
          email: '',
          fromDate: null,
          toDate: new Date(),
          dateAltered: false
        },
        willPoll: false,
        activeRowElement: null,
        inScoreAll: false
      }
    }
  }

  componentDidMount () {
    const { setActiveModule } = this.props
    setActiveModule('Review')
    this.setState({ visible: true })
  }

  appReady = bool => {
    this.setState({
      appReady: bool,
      hideGears: false
    })
  }

  setActiveRow = id => {
    this.setState({ activeRow: id })
  }

  recycleModule = () => {
    const { history } = this.props
    console.log('reset')
    history.push('/recycle/')
  }

  openReportModal = data => {
    const { setModalInfo } = this.props
    setModalInfo(data)
  }

  resetFilters = () => {
    this.setState({
      filters: {
        survey: 'All',
        batch: 'All',
        name: '',
        email: '',
        fromDate: null,
        toDate: new Date(),
        dateAltered: false
      }
    })
  }

  filter = newFilter => {
    const { review_Table, setFilteredTable, clearTranscript } = this.props
    this.setActiveRow(null)
    clearTranscript()
    let table = review_Table.assignments
    let filters = { ...this.state.filters, ...newFilter }
    filters.toDate = new Date(filters.toDate)
    filters.fromDate = new Date(filters.fromDate)
    this.setState({ filters: filters })
    if (table) {
      //don't need to filter by survey???
      table = table.filter(row => {
        return filters.survey === 'All'
          ? typeof row.testName === 'string'
          : row.testName === filters.survey
      })
      table = table.filter(row => {
        return filters.batch === 'All'
          ? typeof row.testName === 'string'
          : row.batch === filters.batch
      })
      table = table.filter(row => {
        let dateCompleted = new Date(row.dateCompleted)
        return (
          filters.fromDate <= dateCompleted && filters.toDate >= dateCompleted
        )
      })
      table = table.filter(row => {
        return filters.name
          ? row.testTaker === filters.name
          : typeof row.testName === 'string'
      })
      table = table.filter(row => {
        return filters.email
          ? row.email === filters.email
          : typeof row.testName === 'string'
      })
      setFilteredTable(table)
      if (document.getElementById('review-info')) {
        document.getElementById('review-info').scrollTo(0, 0)
      }
    }
  }

  updateSortDirection = key => {
    //console.log("updateSortDirection key: ", key)
    this.setState({
      nextSortDirection: {
        ...{
          colName: null,
          colEmail: null,
          colSurvey: null,
          colBatch: null,
          colCompleted: null
        },
        [key]: this.state.nextSortDirection[key] !== 'desc' ? 'desc' : 'asc'
      }
    })
  }

  sortFilteredTable = e => {
    const { filteredTable, setFilteredTable } = this.props
    let id = e.currentTarget.id
    //console.log("SORTING TABLE - DIRECTION: ", this.state.nextSortDirection[id]);
    if (!this.state.inScoreAll) {
      if (id !== 'colSurvey') {
        this.setState({ sortCriteria: id })
        let sortOrder
        if (this.state.nextSortDirection[id] === null) {
          sortOrder = 'asc'
        } else {
          sortOrder = this.state.nextSortDirection[id]
        }

        this.updateSortDirection(id)

        let newTable = JSON.parse(JSON.stringify(filteredTable))
        switch (id) {
          case 'colName':
            this.setState({ activeTitle: 'Name' })
            setFilteredTable(
              _.orderBy(
                newTable,
                [
                  row => {
                    return row.testTaker.toLowerCase()
                  }
                ],
                [sortOrder]
              )
            )
            break
          case 'colEmail':
            this.setState({ activeTitle: 'Email' })
            setFilteredTable(
              _.orderBy(
                newTable,
                [
                  row => {
                    if (row.assessmentEmail) {
                      return row.assessmentEmail.toLowerCase()
                    } else if (row.email) {
                      return row.email.toLowerCase()
                    } else {
                      return ''
                    }
                  },
                  'dateCompleted'
                ],
                [sortOrder, 'asc']
              )
            )
            break
          case 'colSurvey':
            this.setState({ activeTitle: 'Survey' })
            setFilteredTable(
              _.orderBy(
                newTable,
                [
                  row => {
                    return row.testName.toLowerCase()
                  },
                  'dateCompleted'
                ],
                [sortOrder, 'asc']
              )
            )
            break
          case 'colBatch':
            this.setState({ activeTitle: 'Group/Batch' })
            setFilteredTable(
              _.orderBy(
                newTable,
                [
                  row => {
                    return row.batch.toLowerCase()
                  },
                  'dateCompleted'
                ],
                [sortOrder, 'asc']
              )
            )
            break
          case 'colCompleted':
            this.setState({ activeTitle: 'Completed' })
            setFilteredTable(
              _.orderBy(
                newTable,
                ['dateCompleted', 'testTaker'],
                [sortOrder, 'asc']
              )
            )
            break
          default:
            break
        }
      }
    }
  }

  handleRowClick = id => {
    //hoisted to here from ReviewTable so that can be
    // accessed more easily by ReviewSelector when responding
    // to a test being added via socket.io
    const { filteredTable } = this.props
    let row = filteredTable.find(row => {
      return id === row._id
    })
    this.setActiveRow(row._id)
    this.setState({ activeRowElement: row })
    this.getCurrentTranscript(row)
  }

  filterTranscript = thisTranscript => {
    delete thisTranscript.assessmentInProgress
    console.log('thisTranscript: ', thisTranscript)

    thisTranscript.dialog.forEach(item => {
      if (item.prompt) {
        Object.keys(item.prompt).forEach(key => {
          //console.log("key in prompt object: ", key)
          if (
            key !== 'promptPosition' &&
            key !== 'testName' &&
            key !== 'awaitResponse'
          ) {
            delete item.prompt[key]
          }
        })
      }
    })

    thisTranscript.dialog.forEach(item => {
      if (item.response) {
        Object.keys(item.response).forEach(key => {
          //console.log("key in prompt object: ", key)
          if (key !== 'latency' && key !== 'TIN') {
            delete item.response[key]
          }
        })
      }
    })

    // if (performance.memory) {
    //   console.log(`JS Heap Size Limit: ${performance.memory.jsHeapSizeLimit / 1048576} MB`);
    //   console.log(`Total JS Heap: ${performance.memory.totalJSHeapSize / 1048576} MB`);
    //   console.log(`Used JS Heap: ${performance.memory.usedJSHeapSize / 1048576} MB`);
    // } else {
    //   console.log('Memory information is not available in this browser.');
    // }
    //console.log("thisTranscriptranscript.dialog: ", thisTranscript.dialog)

    return thisTranscript
  }

  scoreAll = () => {
    const { getTranscript, filteredTable } = this.props
    let transcriptArray = []
    let activeRow = this.state.activeRow

    this.setState({ activeRow: '' })
    this.setState({ inScoreAll: true })

    for (var i = 0; i < this.props.filteredTable.length; i++) {
      getTranscript(
        this.props.filteredTable[i].TIN,
        this.props.filteredTable[i].testName
      ).then(
        transcript => {
          transcript = this.filterTranscript(transcript)

          transcriptArray.push(transcript)
          if (
            transcriptArray.length &&
            transcriptArray.length === this.props.filteredTable.length
          ) {
            this.appReady(true)
            this.openReportModal({
              name: 'scoreAllTranscript',
              transcriptArray: transcriptArray,
              filteredTable: this.props.filteredTable
            })
          }
          this.setActiveRow(activeRow)
        },
        err => {
          showSystemError()
        }
      )
    }
  }

  getCurrentTranscript = row => {
    const { getTranscript } = this.props
    this.setState({
      TIN: row.TIN,
      testName: row.testName
    })

    getTranscript(row.TIN, row.testName).then(
      transcript => {
        const RECENCY_LIMIT = 300000
        this.setState({ willPoll: false })
        transcript.dialog.forEach(element => {
          if (
            !element.response.responseText &&
            new Date().getTime() -
              new Date(transcript.dateCompleted).getTime() <
              RECENCY_LIMIT
          ) {
            this.setState({ willPoll: true })
          }
        })
      },
      err => {
        showSystemError()
      }
    )
  }

  confirmHandler = () => {
    this.setState({ inScoreAll: false })
  }

  componentWillUnmount () {
    if (!localStorage.getItem('review_reset')) {
      localStorage.setObject('review', this.state)
      console.log(this.state.nextSortDirection[this.state.sortCriteria])
    }
  }

  checkElipseCount = () => {
    var elipseCount = $('.speech-bubble-response').filter(function () {
      return $(this).find('img').length > 0
    }).length

    if (!elipseCount) {
      this.setState({ willPoll: false })
    }
  }

  render () {
    const { history, setModalInfo, modalInfo, user, filteredTable } = this.props
    let mainClasses = classnames('main', { mainVisible: this.state.visible })
    if (_.isEmpty(user)) {
      history.push('/')
      return null
    }

    return (
      <span>
        <Navbar history={history} />
        <div className={mainClasses}>
          <ReviewSelector
            filter={this.filter}
            setActiveRow={this.setActiveRow}
            appReady={this.appReady}
            handleRowClick={this.handleRowClick}
            sortFilteredTable={this.sortFilteredTable}
            sortCriteria={this.state.sortCriteria}
            activeRow={this.state.activeRow}
            resetFilters={this.resetFilters}
          />
          {this.state.appReady ? (
            <div className='reviewContainer'>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '70%' }}>
                  <ModalExportController
                    setModalInfo={setModalInfo}
                    modalInfo={modalInfo}
                    confirmHandler={this.confirmHandler}
                  />
                  <ModalShowScoreReportController
                    setModalInfo={setModalInfo}
                    modalInfo={modalInfo}
                    confirmHandler={this.confirmHandler}
                  />
                  <ModalScoreAllReportController
                    setModalInfo={setModalInfo}
                    modalInfo={modalInfo}
                    confirmHandler={this.confirmHandler}
                  />
                  <ReviewTable
                    handleRowClick={this.handleRowClick}
                    setActiveRow={this.setActiveRow}
                    activeRow={this.state.activeRow}
                    key={this.state.refreshKey}
                    sortFilteredTable={this.sortFilteredTable}
                    sortCriteria={this.state.sortCriteria}
                    sortDirection={
                      this.state.nextSortDirection[this.state.sortCriteria]
                    }
                    activeTitle={this.state.activeTitle}
                  />
                  <ReviewFooterLeft
                    recycleModule={this.recycleModule}
                    scoreAll={this.scoreAll}
                    hasTable={filteredTable.length}
                    appReady={this.appReady}
                  />
                </div>
                <div style={{ width: '30%', marginLeft: '3px' }}>
                  <ReviewTranscript
                    testName={this.state.testName}
                    TIN={this.state.TIN}
                    checkElipseCount={this.checkElipseCount}
                    willPoll={this.state.willPoll}
                    activeRow={this.state.activeRow}
                    filteredTable={filteredTable}
                    inScoreAll={this.state.inScoreAll}
                  />
                </div>
              </div>
            </div>
          ) : this.state.hideGears ? null : (
            <div
              style={{
                position: 'relative',
                zIndex: '10',
                top: '100px',
                width: '100%',
                height: '400px',
                textAlign: 'center',
                verticalAlign: 'middle',
                border: 'solid thick transparent'
              }}
            >
              <img
                src='animatedGears.gif'
                width='300px'
                alt=''
                style={{ marginTop: '0px', border: 'solid thin transparent' }}
              ></img>
              <div
                className='please-wait'
                style={{
                  top: '-15px',
                  zIndex: '10',
                  backgroundColor: 'white',
                  width: '500px',
                  margin: 'auto'
                }}
              >
                Please wait a moment while we organize your data.
              </div>
            </div>
          )}
        </div>
      </span>
    )
  }
}

const mapStateToProps = state => {
  return {
    review_Table: state.review_Table,
    transcript: state.transcript,
    modalInfo: state.modalInfo,
    user: state.user,
    filteredTable: state.filteredTable
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setActiveModule,
      getTranscript,
      setFilteredTable,
      clearTranscript,
      setModalInfo
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Review)
