import React from 'react';

class DeleteRow extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleCancel(e) {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  handleDelete(e) {
    const {hideModal, confirmHandler} = this.props;
    e.preventDefault();
    if (confirmHandler) {
      confirmHandler()
    }
    hideModal();
  }

  render() {
    const {modalInfo} = this.props;
    let str = `You are about to delete a
            row containing the participant code ${modalInfo.participantCode}.`
    return (
      <div>
        <div className="modal-body">
          <h2 style={{textAlign: 'center', color:'rgb(45,78,117)'}}>WARNING </h2>
          <p style={{textAlign: 'center', fontWeight: '400'}}>{str}</p>
          <p style={{
            textAlign: 'center',
            fontWeight: '400',
            color: 'rgb(0,0,102',
            fontFamily: 'Arial'
          }}>This action cannot be undone.</p>
        </div>

        <div className="modal-footer">
          <div className="btn-group pull-right" style={{margin: '5px 0'}}>

            <button className="blueButton pull-right"
                    onClick={this.handleDelete}
                    style={{marginLeft: '5px'}}>DELETE
            </button>
            <button className="blueButton pull-right"
                    onClick={this.handleCancel}>
              Cancel
            </button>

          </div>
        </div>
      </div>
    )
  }
}

export default DeleteRow;