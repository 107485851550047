import {STORE_BERTDATA, CLEAR_BERTDATA, LOGIN_USER} from '../actions/_CONSTANTS';

const BERTResponses = (state = {}, action) => {
  switch (action.type) {
    case STORE_BERTDATA:
      let newState = {};
      for (const key in state) {
        newState[key] = [...state[key]]
      }
      newState[action.payload.analysisId] = action.payload.responseData;
      return newState;
    case CLEAR_BERTDATA:
      return action.payload;
    case LOGIN_USER:
      //required since Analysis module loads data to store when dismounting.
      return {};
    default:
      return state;
  }
}

export default BERTResponses;