import {GET_ALL_ANON_ASSIGNMENTS} from '../actions/_CONSTANTS';

const allAnonAssignments = (state=[], action) => {
  switch (action.type) {
    case GET_ALL_ANON_ASSIGNMENTS:
      return action.payload;
    default:
      return state;
  }
}

export default allAnonAssignments;

