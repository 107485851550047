import $ from 'jquery';
import passwordResetCompleted from '../../components/email_templates/passwordResetCompleted'
import staffPasswordCreated from '../../components/email_templates/staffPasswordCreated'

export const resetPassword = (email, password, staff) => {
  return function(dispatch, getState) {
    return new Promise((resolve, reject) =>{
      let url = 'email/resetPassword';
      // let url = '/testerror';
      let html = staff ? staffPasswordCreated(email) : passwordResetCompleted(email);
      $.ajax ({
        url: url,
        type: "POST",
        data: {
          html: html,
          email: email,
          password: password
        },
        success: ()=>{
          resolve();
        },
        error: (err)=> {
          console.log(err);
          reject(err)
        }
      })
    })
  }
}
