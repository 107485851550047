import React from 'react';

class Checkbox extends React.Component {
  constructor(props) {
    const {initialState} = props;
    super(props);
    this.state = {checked: initialState};
  }

  handleChange = (e) => {
    const {clickHandler} = this.props;
    let status = this.state.checked;
    this.setState({checked: !status});
    if (clickHandler) {
      clickHandler(e)
    }
  }

  render() {
    const {name, id, value, className} = this.props;
    return (
      <React.Fragment>
        <input type="checkbox" name={name} id={id} value={value}
               onChange={this.handleChange}
               checked={this.state.checked}
               className={className}/>
        <label htmlFor={value}>{value}</label>
      </React.Fragment>
    )
  }
}

export default Checkbox;

