import {GET_ANONASSIGN_TABLE}  from '../_CONSTANTS';
import $ from 'jquery';


export const get_AnonAssign_Table = (group, testName, instrumentType, accountId) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = `admin/getGroupTin?test=${testName}&group=${group}&type=${instrumentType}&accountId=${accountId}`;
      // let url = '/testerror';

      $.ajax({
        url:url,
        type: "GET",
        success: (assignment)=> {
          window.socket.emit('updateRoom', `room-${getState().user.accountId}`, {
            type: "ADD_ANON_ASSIGNMENT",  //goes to socketDispatch for further routing
            result: assignment.testName
          });
          let anonAssign_Table = {
            isGroupEditable: !assignment.group,
            assignment: assignment
          }
          dispatch({
            type: GET_ANONASSIGN_TABLE,
            payload: anonAssign_Table
          })
          resolve(anonAssign_Table)
        },
        error:(err)=> {
          reject(err);
        }
      })
    })
  }
}

