import {SET_ACTIVE_MODULE} from "../actions/_CONSTANTS";

const activeModule = (state = '', action) => {
  switch (action.type) {
    case SET_ACTIVE_MODULE:
      return action.payload;
    default:
      return state
  }
};

export default activeModule;
