import React from 'react';


let modalTextSyles = {
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  paddingBottom: '10px'
}

class DeployedTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newFileName: '',
      errorMessage: ''
    }
  }

  handleCancel = (e) => {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  handleReadOnlyConfirm = (e) => {
    const {hideModal, confirmHandler, modalInfo} = this.props;
    e.preventDefault();
    confirmHandler({
      status: 'readOnly',
      testName: modalInfo.testName
    })
    hideModal();
  }

  handleChange = (e) => {
    this.setState({newFileName: e.target.value})
  }


  render() {
    const {modalInfo} = this.props;
    return (
      <div>
        <div className="modal-body">
          <h2 style={{
            textAlign: 'center',
            color: 'rgb(45, 78, 117)'
          }}>{`This survey (${modalInfo.testName}) cannot be edited because it has been deployed.`}</h2>

          <div style={modalTextSyles}>Since no one has taken this survey yet, you can
            change it's status in the 'Manage' tab.
          </div>
        </div>
        <div className="modal-footer">
          <div className="btn-group pull-right" style={{margin: '5px 0'}}>
            <button className="blueButton pull-right"
                    onClick={this.handleReadOnlyConfirm}
            >View Read Only
            </button>

            <button className="blueButton pull-right"
                    onClick={this.handleCancel}
            >Cancel
            </button>
          </div>
        </div>

      </div>
    )
  }
}

export default DeployedTest;
