import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setActiveModule} from '../../actions/setActiveModule';
import classnames from 'classnames';
import CreateSelector from './CreateSelector';
import CreateTable from './CreateTable';
import Navbar from '../Navbar';
import _ from 'lodash'
import $ from "jquery";

class Create extends Component {
  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('create');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('create');
    } else {
      this.state = {
        visible: false,
        showBody: false,
        readOnly: false,
        instrumentType: "TEST"
      }
    }
  }

  componentDidMount() {
    const {setActiveModule} = this.props;
    setActiveModule("Create");
    this.setState({
      visible: true,
    });
  }

  showTable = (bool) => {
    this.setState({
      showBody: bool,
    })
  }

  setReadOnly = () => {
    this.setState({readOnly: true})
  }

  updateExistingAssignments = (testName, emailUpdate, accountId) => {
    let url = `/admin/UpdateExistingAssignments?testName=${testName}&emailUpdate=${emailUpdate}&accountId=${accountId}`;
    // let url = emailUpdate ? `/admin/updateExistingAssignments?testName=${testName}&emailUpdate=true` : `/admin/updateExistingAssignments?testName=${testName}&accountId=${accountId}`
    $.ajax({
      url: url,
      type: 'GET',
      success: () => {
        console.log('previous open assignments updated');
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  componentWillUnmount() {
    if (!localStorage.getItem('create_reset')) {
      localStorage.setObject('create', this.state);
    }
  }

  render() {
    let {history, user} = this.props;
    let mainClasses = classnames('main', {mainVisible: this.state.visible})
    if (_.isEmpty(user)) {
      history.push('/')
      return null
    }
    return (
      <span>
       <Navbar
         history={history}
       />
    <div className={mainClasses}>
      <CreateSelector

        showTable={this.showTable}
        setReadOnly={this.setReadOnly}
        history={history}
        updateExistingAssignments={this.updateExistingAssignments}

      />

      {this.state.showBody ?
        <div>
          <CreateTable
            history={history}
            readOnly={this.state.readOnly}
            instrumentType="TEST"
            updateExistingAssignments={this.updateExistingAssignments}
          />
        </div>
        : null}
    </div>
     </span>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setActiveModule,
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);