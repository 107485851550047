import {GET_ALL_ANON_ASSIGNMENTS} from '../_CONSTANTS';
import $ from 'jquery';
import _ from 'lodash';

export const getAllAnonAssignments = (accountId, forceRefresh) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      // let url = 'https://my.api.mockaroo.com/anonassignment.json?key=54b66bf0'
      let url = `/test/getAllAnonAssignments?accountId=${accountId}`
      let allAnonAssignments = getState().allAnonAssignments;
      if (!_.isEmpty(allAnonAssignments) && !forceRefresh) {
        resolve(allAnonAssignments)
      }
      else {
        $.ajax({
          url: url,
          type: "GET",
          success: (allAnonAssignments) => {
            dispatch({
              type: GET_ALL_ANON_ASSIGNMENTS,
              payload: allAnonAssignments
            });
            resolve(allAnonAssignments);
          },
          error: (err) => {
            console.log(err);
            reject(err);
          }
        })
      }

    })
  }
}
