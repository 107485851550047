import React from 'react';
import ContentEditable from 'react-contenteditable';
import $ from 'jquery';

var preScrollMsg = "";

function validHex(str) {
  var strArray = [str.substring(0, 2), str.substring(2, 4), str.substring(4, 6)];
  if ((hexArray.includes(strArray[0])) && (hexArray.includes(strArray[1])) && (hexArray.includes(strArray[2]))) {
    return true;
  } else {
    return false;
  }
}

function decToHex(i) {
  if (i > 255) {
    return "FF"
  } else {
    return hexArray[i]
  }
}

var hexArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '0A', '0B', '0C', '0D', '0E', '0F',
  '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '1A', '1B', '1C', '1D', '1E', '1F',
  '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '2A', '2B', '2C', '2D', '2E', '2F',
  '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '3A', '3B', '3C', '3D', '3E', '3F',
  '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '4A', '4B', '4C', '4D', '4E', '4F',
  '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '5A', '5B', '5C', '5D', '5E', '5F',
  '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '6A', '6B', '6C', '6D', '6E', '6F',
  '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '7A', '7B', '7C', '7D', '7E', '7F',
  '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '8A', '8B', '8C', '8D', '8E', '8F',
  '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '9A', '9B', '9C', '9D', '9E', '9F',
  'A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF',
  'B0', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'BA', 'BB', 'BC', 'BD', 'BE', 'BF',
  'C0', 'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF',
  'D0', 'D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'DA', 'DB', 'DC', 'DD', 'DE', 'DF',
  'E0', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6', 'E7', 'E8', 'E9', 'EA', 'EB', 'EC', 'ED', 'EE', 'EF',
  'F0', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'FA', 'FB', 'FC', 'FD', 'FE', 'FF']


function hexToDec(s) {
  s = s.toString();
  if (s.length === 1) {
    s = "0" + s;
  }
  return hexArray.indexOf(s);
}

class EmbedCreate extends React.Component {

  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      textColor: "black",
      t0: "86A3A8",
      t1: "d1e0e3",
      editInstructXoffset: "10px",
      editInstructYoffset: "0px",
      messageText: 'Click to share your thoughts about...',
      cardBackground: "background:'linear-gradient(to right, #86A3A8, #d1e0e3)'",
      phoneResponses: false, //remove this DR 8/28
      computerResponses: true
    }
  }

  handleCancel(e) {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  componentDidMount() {
    console.log("DID MOUNT");
    $('#msgDiv').html('<div id="innerMsgDiv">' + this.state.messageText + '</div>');
    $('#innerMsgDiv').css('height', '140px').css('max-height', '140px').css('overflow', 'hidden').css('white-space', 'normal').css('text-overflow', 'ellipsis');
    $('#blackTextRadioBtn').prop("checked", true);
  }


  copyTemplate = (e) => {
    //can't use the simpler copy which works with an input field
    //but doesn't properly select all the contenteditable contents
    e.preventDefault();

    var id = "embed-instructions";
    var el = document.getElementById(id);
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    document.execCommand('copy');
    $('#copyIcon').css('color', '#555555');

    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }
  }

  getIframeMsgToken = () => {
    var filteredMsg = this.state.messageText;

    /* ****** prep string for URL-attachment as query string  ****** */
    filteredMsg = encodeURI(filteredMsg);
    filteredMsg = encodeURIComponent(filteredMsg);
    return filteredMsg;
  }

  handleScroll = (e) => {
    // if text entry box scrolls reset text to state before scroll
    $('.embedable-template').html(preScrollMsg);
  }

  okClicked = (e) => {
    var redLow = 0;
    var redHigh = 0;
    var greenLow = 0;
    var greenHigh = 0;
    var blueLow = 0;
    var blueHigh = 0;
    var hexLow = "191970";
    var hexHigh = "ADD8E6";
    var colorCode = $('.colorCode').val().toUpperCase();

    if (colorCode.indexOf('#') === 0) {
      colorCode = colorCode.substring(1, colorCode.length);
    }

    if (( colorCode.length === 6 ) && ( validHex(colorCode) )) {

      var red = colorCode.substring(0, 2);
      var green = colorCode.substring(2, 4);
      var blue = colorCode.substring(4, 6);

      red = parseInt(hexToDec(red), 10);
      green = parseInt(hexToDec(green), 10);
      blue = parseInt(hexToDec(blue), 10);

      redLow = Math.round(red - (0.2 * red  ));
      redHigh = Math.round(red + (0.2 * red  ));
      greenLow = Math.round(green - (0.2 * green));
      greenHigh = Math.round(green + (0.2 * green));
      blueLow = Math.round(blue - (0.2 * blue ));
      blueHigh = Math.round(blue + (0.2 * blue ));

      redLow = decToHex(Math.round(redLow));
      redHigh = decToHex(Math.round(redHigh));
      greenLow = decToHex(Math.round(greenLow));
      greenHigh = decToHex(Math.round(greenHigh));
      blueLow = decToHex(Math.round(blueLow));
      blueHigh = decToHex(Math.round(blueHigh));

      hexLow = redLow + greenLow + blueLow;
      hexHigh = redHigh + greenHigh + blueHigh;

      this.setState({t0: hexLow});
      this.setState({t1: hexHigh});

      var thisGradient = "linear-gradient(to right, #" + hexLow + ",#" + hexHigh + ")";
      $('#simCard').css('background', thisGradient);

    } else {
      $('#hexFeedback').html('Enter 6 characters, "0" to "F"');
    }
  }

  hexInputFocused = (e) => {
    $('#hexFeedback').html("");
  }

  radioClicked = (e) => {
    console.log("button changed");
    console.log(e.target.id);
    if (e.target.id === "whiteTextRadioBtn") {
      $('#innerMsgDiv').css('color', 'white');
      this.setState({textColor: "white"})
    } else {
      $('#innerMsgDiv').css('color', 'black');
      this.setState({textColor: "black"})
    }
  }

  handleEdit = (e) => {
    var msg = e.currentTarget.innerHTML;
    if (( msg.indexOf("<u>") > -1 ) || (msg.indexOf("<i>") > -1 ) || (msg.indexOf("font-weight:") > -1 )) {
      $('.embedable-template').html(this.state.messageText);
    } else {
      msg = msg.replace(/%20%20/g, "%20");
      preScrollMsg = this.state.messageText;
      this.setState({messageText: msg});
      $('#msgDiv').html('<div id="innerMsgDiv">' + msg + '</div>');
      $('#innerMsgDiv').css('height', '140px')
        .css('max-height', '140px')
        .css('color', this.state.textColor)
        .css('overflow', 'hidden')
        .css('white-space', 'normal')
        .css('text-overflow', 'ellipsis');
    }
  }


  handleCheckBoxChange = (e) => {
    if(e.target.id === "phoneCheck"){
      if (this.state.phoneResponses){this.setState({phoneResponses:false})
        } else { this.setState({phoneResponses:true})}
    } else {
      if (this.state.computerResponses){
          this.setState({computerResponses:false})
        } else { this.setState({computerResponses:true})}
    }
  }

  swatchClicked = (e) => {
    $('#simCard').css('background', $('#' + e.target.id).css('background'));
    var colors = $('#' + e.target.id).attr('colors').split(",");
    if (colors[0].indexOf('#') === 0) {
      colors[0] = colors[0].substring(1, colors[0].length);
    }
    if (colors[1].indexOf('#') === 0) {
      colors[1] = colors[1].substring(1, colors[1].length);
    }
    this.setState({t0: colors[0]});
    this.setState({t1: colors[1]});
  }

  /*   hexColorInstructionsHTML = () => {
   return (
   <div style={{fontSize: '15px', width: '455px', fontFamily: 'sans-serif'}}>
   <p>Enter a six character hex color code to specify a color other than the four above.</p>
   </div>
   )
   } */


  embedInstructions = () => {
    const {modalInfo} = this.props;
    // let URL = 'https://tmt-taker.herokuapp.com';
    let URL = modalInfo.TAKER_HOST
    var responseMode = "CP";
    if (!this.state.computerResponses){responseMode = "P"}
    if (!this.state.phoneResponses){responseMode = "C"}
    if (!this.state.phoneResponses && !this.state.computerResponses){responseMode = ""}
    return (
      <div className="embed-instructions"
           id="embed-instructions"
           data-for="embed-instructions"
           data-tip="">
        <div style={{fontStyle: 'italic'}}>The following HTML snippet should be pasted
          into your website HTML
        </div>
        <br/>
        <div
          style={{
            color: 'darkblue',
            fontFamily: 'monospace',
            paddingLeft: '1em',
            wordBreak: 'break-all'
          }}>
          &lt;iframe <br />
          <div style={{paddingLeft: '2.2em'}}>
            src="{URL}/?TIN={modalInfo.TIN}
            &testName={modalInfo.testName}
            &accountId={modalInfo.accountId}
            &mode={responseMode}
            &clr={this.state.textColor}
            &t0={this.state.t0}
            &t1={this.state.t1}
            &extUserId={'00000'}
            &extUserEmail={'00000'}
            &msg={this.getIframeMsgToken()}"

          </div>
          <br />
          &nbsp; &nbsp; width="312" height="210" frameBorder="0" allow="microphone" <br />
          &nbsp; &nbsp; &#47;&#47; style="border-radius: 12px;" <br />
          &nbsp; &nbsp; style=&#123;&#123;borderRadius: '12px'&#125;&#125; &gt;<br />&lt;/iframe&gt;
        </div>
      </div>
    )
  }

  render() {
    //console.log("phoneResponses: ", this.state.phoneResponses);
    //console.log("computerResponses: ", this.state.computerResponses);
    return (
      <div>
        <div className="emailModal-body" style={{minWidth: '830px'}}>
{/*           <table style={{fontFamily:'sans-serif',fontSize:'14px'}}><tbody><tr><td>&nbsp;Request responses by:</td>
            <td><input type="checkbox" id="compCheck"  name="compCheck" onChange= {this.handleCheckBoxChange} value="c" checked={this.state.computerResponses}></input>&nbsp;computer</td>
            <td><input type="checkbox" id="phoneCheck" name="phoneCheck" onChange= {this.handleCheckBoxChange} value="p" checked={this.state.phoneResponses}></input>&nbsp;phone</td>
          </tr></tbody></table> */}
          <div className="email-invite-subheader">
            Choose a background color and add text for your link.
            Then click the [copy] button, paste the contents into an email,
            and send it to your website technicians so they can add this element to your
            site.
          </div>

          <div className="embedCreate-template-container">
            <div className="embedModalWrkPanel">
              <table style={{width: '100%'}}>
                <tbody>
                <tr>
                  <td style={{width: '2%', border: 'solid thin transparent'}}>&nbsp;</td>
                  <td style={{minWidth: '150px', border: 'solid thin transparent'}}>
                    <div style={{paddingLeft: '0px'}}>
                      <i><span style={{position: 'relative', top: '20px'}}>Choose background color</span></i>
                    </div>
                  </td>
                  <td style={{border: 'solid thin transparent'}}>
                    <div style={{paddingLeft: '45px'}}>
                      <i><span style={{position: 'relative', top: '20px'}}>Edit your message</span></i>
                    </div>
                  </td>
                  <td style={{border: 'solid thin transparent'}}></td>
                </tr>

                <tr>
                  <td style={{width: '2%', border: 'solid thin transparent'}}>&nbsp;</td>
                  <td style={{minWidth: '150px', border: 'solid thin transparent'}}>
                    <div id="colorPickerContainer"
                         style={{marginLeft: '16%', width: '120px', height: '125px'}}>
                      <span id="blueSwatch" onClick={this.swatchClicked}
                            colors="#86A3A8,#d1e0e3" className="swatch"
                            style={{background: 'linear-gradient(to right, #86A3A8, #d1e0e3)'}}>blue</span>
                      <span id="greenSwatch" onClick={this.swatchClicked}
                            colors="#008000,#90EE90" className="swatch"
                            style={{background: 'linear-gradient(to right, #008000, #90EE90)'}}>green</span>
                      <span id="redSwatch" onClick={this.swatchClicked}
                            colors="#FF6347,#FFA07A" className="swatch"
                            style={{background: 'linear-gradient(to right, #FF6347, #FFA07A)'}}>red</span>
                      <span id="purpleSwatch" onClick={this.swatchClicked}
                            colors="#9370DB,#DDA0DD" className="swatch"
                            style={{background: 'linear-gradient(to right, #9370DB, #DDA0DD)'}}>purple</span>
                    </div>
                  </td>
                  <td style={{
                    verticalAlign: 'middle',
                    paddingTop: '4px',
                    border: 'solid thin transparent'
                  }}>
                    <div className="embedModalEditContainer">
                      <ContentEditable
                        id="email-template"
                        className="embedable-template"
                        html={this.state.messageText}
                        onChange={this.handleEdit}
                        onScroll={this.handleScroll}
                      />
                    </div>
                  </td>

                  <td style={{
                    paddingLeft: '10px',
                    textAlign: 'center',
                    border: 'solid thin transparent'
                  }}>
                    <div className='simCardContainer'>
                      <div id="simCard">
                        <div id="iconDiv" className="left-white-circle"
                             style={{position: 'relative', float: 'left', margin: '8px'}}>
                          {/*                           <img src="lex.png" alt=""
                               style={{position: 'relative', top: '10px'}}></img> */}
                               <img src="lex.svg" alt=""
                               style={{position: 'relative', top: '21px', width: '80px'}}></img>
                        </div>
                        <table style={{height: '100%'}}>
                          <tbody>
                          <tr>
                            <td id="msgText">
                              <div
                                id='msgDiv'>{/*  card text gets inserted here as jquery-managed object  */}</div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{border: 'solid thin transparent'}}>
                  </td>
                  <td style={{
                    textAlign: 'right',
                    paddingRight: '6px',
                    border: 'solid thin transparent'
                  }}>
                    <input className="colorCode"
                           title="Enter a six character hex color code to specify a color other than the four above"
                           type="text"
                           onFocus={this.hexInputFocused}
                           placeholder="hex color code"></input>

                    <button id="getColorCode" className="blueButtonSmall"
                            onClick={this.okClicked}
                            style={{position: 'relative', margin: '4px'}}>OK
                    </button>
                  </td>
                  <td id="hexFeedback"
                      style={{color: 'red', border: 'solid thin transparent'}}></td>
                  <td style={{textAlign: 'center', border: 'solid thin transparent'}}>
                    <input type="radio"
                           id="blackTextRadioBtn"
                           onChange={this.radioClicked}
                           style={{cursor: 'pointer'}}
                           name="textColor"
                           value="black"/> black text &nbsp;
                    <input type="radio"
                           id="whiteTextRadioBtn"
                           onChange={this.radioClicked}
                           style={{cursor: 'pointer'}}
                           name="textColor"
                           value="white"/> white text
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div className="embedCodePanel">
              {this.embedInstructions()}
            </div>
          </div>
        </div>

        <div className="modal-footer" style={{padding: '0px'}}>
          <table style={{width: '100%'}}>
            <tbody>
            <tr>
              <td style={{width: '300px'}}>
              </td>
              <td id="error-div"
                  style={{textAlign: 'left', color: 'red'}}>{this.state.errorText}</td>
              <td>
                <div className="btn-group pull-right" style={{margin: '5px 0'}}>

                  <button className="blueButton pull-right"
                          style={{marginRight: '20px'}}
                          onClick={this.handleCancel}>
                    Cancel
                  </button>
                  <i className="far fa-copy"
                     id="copyIcon"
                     style={{
                       paddingRight: '10px',
                       fontSize: '24px',
                       color: 'transparent'
                     }}>
                  </i>
                  <button className="orangeButton pull-right"
                          onClick={this.copyTemplate}
                          style={{marginRight: '5px'}}>COPY
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default EmbedCreate;