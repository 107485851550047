import React from 'react';
import './styles.css';
const ModalV2 = ({width, height, children}) => {
  return (
    <div id='overlay' className='overlay'>
      <div id='modalContainer' className="modalOuterContainer">
        <div id='modalInnerContainer' className="modalInnerContainer" style={{width, height}}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalV2;

