//adapted from: https://alligator.io/react/react-autocomplete/


import React, {Component, Fragment} from "react";

class Autocomplete extends Component {

  constructor(props) {
    super(props);
    const {userInput} = props; //used to re-establish name or email on re-entry to module.
    console.log('user input: ', userInput)

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: userInput
    };
  }

  clearUserInput = () => {
    console.log('clearing user input in autocomplete');
    this.setState({userInput: ''})
  }


  // Event fired when the input value is changed
  onChange = e => {
    const {suggestions, firstLetterMatch} = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    let filteredSuggestions;
    if (firstLetterMatch) {
      filteredSuggestions = suggestions.filter((suggestion) => {
          if (!suggestion) {
            return false
          }
          return suggestion.toLowerCase().indexOf(userInput.toLowerCase()) === 0
        }
      );
    }
    else {
      filteredSuggestions = suggestions.filter((suggestion) => {
          if (!suggestion) {
            return false
          }
          return suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        }
      );
    }


    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    const {clickHandler} = this.props;
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    clickHandler(e.currentTarget.innerText);
  };
  onBlur = e => {
    const {clickHandler} = this.props;
    if (!e.currentTarget.value) {
      this.setState({
        userInput: '',
        showSuggestions: false,
      });
      clickHandler('');
    }
  }


  // Event fired when the user presses a key down
  onKeyDown = e => {
    const {activeSuggestion, filteredSuggestions} = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({activeSuggestion: activeSuggestion - 1});
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({activeSuggestion: activeSuggestion + 1});
    }
  };

  render() {
    const {
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;
    const {classes, suggestionsWidth, disabled} = this.props;
    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions"
              style={{width: suggestionsWidth}}>
            {filteredSuggestions.map((suggestion, index) => {

              return (
                <li
                  className={index === activeSuggestion ? "suggestion-active" : "" }
                  key={(Math.random()*100000).toString()}
                  onClick={this.onClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input
          className={classes}
          style={{width: '100%'}}
          type="text"
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          value={userInput}
          disabled={disabled}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;