//https://recharts.org/en-US/guide
import React, {PureComponent} from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import {DropdownList, DateTimePicker}  from 'react-widgets'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar} from 'recharts';
import getBarChartData from './getBarChartData';
import Checkbox from './Checkbox';
import showSystemError from '../Common/showSystemError';

Moment.locale('en')
momentLocalizer()

// SIZE CONSTANTS
const componentWidth = 750;
const componentHeight = componentWidth / 2;
const chartWidth = componentWidth * .8;
const chartHeight = chartWidth / 2.1;

function CheckboxElement({group, index, hoveredOn, handleCheckboxSelect, checkboxStatus, chartId}) {
  let i = (index + 1).toString();
  let paddedIndex = i.length === 1 ? `0${i}` : i;
  let prefix = `G${paddedIndex}`;
  let boxName = `${prefix}: ${group}`;
  let hoveredClasses = classnames('chart-check', {active: hoveredOn === prefix});
  return (
    <div className={hoveredClasses}>
      <Checkbox
        name="groups"
        id={`${boxName}-${chartId}`}
        value={boxName}
        initialState={checkboxStatus}
        className="chart-checkbox"
        clickHandler={handleCheckboxSelect}
      />
    </div>
  )
}

function SurveySelect({testName, handleTestSelect, surveys}) {
  return (
    <div
      style={{width: '95%', paddingTop: '10px', marginRight: '5px', marginLeft: '5px'}}>
      <DropdownList
        data={surveys}
        placeholder="Select a survey to track"
        textField="testName"
        valueField="testName"
        value={testName}
        onChange={handleTestSelect}
        id="surveySelect"
        containerClassName="chartSurveySelect"
      />
    </div>
  )
}

function FromDateSelect({fromDateValue, handleFromDate, disabled, min, max}) {
  return (
    <DateTimePicker
      id={`fromDate_${new Date().getTime()}`}
      time={false}
      format="MM/DD/YYYY"
      placeholder="MM/DD/YYYY"
      disabled={disabled}
      parse={str => new Date(str)}
      onChange={handleFromDate}
      value={fromDateValue ? new Date(fromDateValue) : null}
      containerClassName="dateClass"
      min={new Date(min)}
      max={new Date(max)}

    />
  )
}

function ToDateSelect({toDateValue, handleToDate, disabled, min, max}) {
  return (
    <DateTimePicker
      id={`toDate_${new Date().getTime()}`}
      time={false}
      format="MM/DD/YYYY"
      parse={str => new Date(str)}
      placeholder="MM/DD/YYYY"
      disabled={disabled}
      onChange={handleToDate}
      value={toDateValue ? new Date(toDateValue) : null}
      containerClassName="dateClass"
      min={new Date(min)}
      max={new Date(max)}
    />
  )
}

function ChartTypeSelect({visible, chartType, onChange}) {
  let visibleClasses = classnames('chart-type', {active: visible})
  let thisName = "chart-type-" + Date.now();
  return (
    <div className={visibleClasses}>
      <label>
        <div style={{marginBottom: '4px', float: 'left'}}>
          <input type="radio"
                 name={thisName}
                 value="byGroup"
                 style={{cursor: 'pointer'}}
                 checked={chartType === 'byGroup'}
                 onChange={onChange}
          /> <span style={{fontWeight: 'bold'}}> by group</span>
        </div>
      </label>


      <label>
        <div style={{float: 'left'}}>
          <input type="radio"
                 name={thisName}
                 value="byTimeframe"
                 style={{cursor: 'pointer'}}
                 checked={chartType === 'byTimeframe'}
                 onChange={onChange}
          />
          <span style={{fontWeight: 'bold'}}> by timeframe</span>
        </div>
      </label>

    </div>
  )
}

function TimeframeSelect({visible, timeFrame, onChange}) {
  let visibleClasses = classnames('chart-timeframe', {active: visible})
  let thisName = "timeframe-" + Date.now();
  return (
    <div className={visibleClasses} style={{height: '85px'}}>
      <div style={{
        fontWeight: 'bold',
        position: 'relative',
        left: '-15px',
        padding: '3px'
      }}>
        Timeframe
      </div>
      <input type="radio"
             name={thisName}
             value="day"
             checked={timeFrame === 'day'}
             onChange={onChange}
             style={{float: 'left', cursor: 'pointer'}}
      /><label style={{float: 'left'}}>day</label><br></br>
      <input type="radio"
             name={thisName}
             value="week"
             checked={timeFrame === 'week'}
             onChange={onChange}
             style={{float: 'left', cursor: 'pointer'}}
      /><label style={{float: 'left', cursor: 'pointer'}}>week</label><br></br>
      <input type="radio"
             name={thisName}
             value="month"
             checked={timeFrame === 'month'}
             onChange={onChange}
             style={{float: 'left', cursor: 'pointer'}}
      /><label style={{float: 'left'}}>month</label><br></br>
    </div>


  )
}


function GroupsSelect({hoveredGroup, anonAssignments, visible, handleCheckboxSelect, heightOffsetInner, heightOffsetOuter, byGroup, checkboxesStatus, chartId}) {
  let visibleClasses = byGroup ? classnames('chart-group-select', {active: visible}) : classnames('chart-group-select-byTimeframe', {active: visible});
  return (
    <div className={visibleClasses}
         style={{height: `${componentHeight - heightOffsetOuter}px`}}//290
    >
      <div style={{
        textAlign: 'center',
        fontWeight: 'bold',
        width: '100%',
        marginBottom: '6px'
      }}>
        <div style={{paddingTop: '12px'}}>
          Select Groups
        </div>

      </div>
      {visible ?
        <div style={{
          overflowY: 'auto',
          height: `${componentHeight - heightOffsetInner}px`,
          maxHeight: `${componentHeight - heightOffsetInner}px`
        }}>
          {anonAssignments.groups.map((group, index) => {
            return (
              <CheckboxElement group={group}
                             index={index}
                             key={index}
                             checkboxStatus={checkboxesStatus[index]}
                             chartId={chartId}
                             hoveredOn={hoveredGroup}
                             handleCheckboxSelect={handleCheckboxSelect}/>
            )
          })}
        </div> : null}
    </div>
  )
}


class Chart extends React.Component {
  handleMouseOver = (e) => {
    const {setHovered} = this.props;
    let groupId = e.legend ? e.legend : e.value;
    setHovered(groupId)
  }

  handleMouseOut = () => {
    const {setHovered} = this.props;
    setHovered(null);
  }

  //from: https://jsfiddle.net/alidingling/5br7g9d6/
  customizedAxisTick = ({x, y, stroke, payload}) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fontSize="12" textAnchor="end" fill="#666"
              transform="rotate(-35)">{payload.value}</text>
      </g>
    );
  }

  customTooltip = ({active, payload}) => {
    if (active) {
      return (
        <div style={{
          backgroundColor: 'white', border: '1px solid gray',
          padding: '0 10px'
        }}>
          <p className="label">{payload[0].payload.tooltipName}</p>
          <p
            style={{color: `${payload[0].fill}`}}>{`${payload[0].dataKey}: ${payload[0].value}`}</p>
          <p
            style={{color: `${payload[1].fill}`}}>{`${payload[1].dataKey}: ${payload[1].value}`}</p>
        </div>
      )
    }
    return null;
  }

  render() {
    const {info} = this.props;
    return (
      <div>
        <BarChart width={chartWidth - 28} height={chartHeight - 20}
                  data={info}
                  margin={{
                    top: 20, right: 20, left: 20, bottom: 5,
                  }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3"/>
          <XAxis dataKey="legend" height={60} tick={this.customizedAxisTick}
                 onMouseOver={this.handleMouseOver}
                 onMouseOut={this.handleMouseOut}
          />
          <YAxis />
          {info.length ?
            <Tooltip content={this.customTooltip}/> : null}
          <Legend verticalAlign="bottom" height={36}/>
          <Bar dataKey="completed" stackId="a" fill="#8884d8"
               onMouseOver={this.handleMouseOver}
               onMouseOut={this.handleMouseOut}
          />
          <Bar dataKey="abandoned" stackId="a" fill="#82ca9d"
               onMouseOver={this.handleMouseOver}
               onMouseOut={this.handleMouseOut}/>
        </BarChart>
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////

class ChartCreator extends PureComponent {
  constructor(props) {
    super(props);
    const {chartId} = props;
    let oldState = localStorage.getObject(`chartCreator-${chartId}`);
    if (oldState) {
      this.state = {
        ...oldState, ...{
          fromDateValue: oldState.fromDateValue ? new Date(oldState.fromDateValue) : null,
          toDateValue: oldState.toDateValue ? new Date(oldState.toDateValue) : null,
          fromDateMax: oldState.fromDateMax ? new Date(oldState.fromDateMax) : '',
          toDateMin: oldState.toDateMin ? new Date(oldState.toDateMin) : '',
          chartMounted: false
        }
      };
      localStorage.removeItem(`chartCreator-${chartId}`);
    }
    else {
      this.state = {
        testName: '',
        fromDateValue: null, //holds date from datepicker
        toDateValue: null, //holds date from datepicker
        excludedGroups: [], //groups excluded from chart
        excludedGroupIds: [], //groupIds excluded from chart
        // visible: false,
        info: [], //main array that carries info to chartCreator - one cell for each bar
        fromDate: '', //initially first start date from survey file
        toDate: '', // initially last start date from survey file
        fromDateMax: '', //constrains from date to not go beyond to date.
        toDateMin: '', //constrains to date to not be earlier than from date.
        testSelected: false,
        hoveredGroup: null, //for highlighting group on rollover
        chartType: 'byGroup',
        timeFrame: 'week',
        checkboxesStatus: [], //holds ongoing status of checkboxes when switching chart
        // types
        chartMounted: false
      }
    }
  }

  componentDidMount() {
    this.setState({
      // visible: true,
      chartMounted: true
    });
  }

  setHovered = (id) => {
    this.setState({hoveredGroup: id});
  }

  handleTestSelect = (value) => {
    const {getAnonAssignments, user} = this.props;
    getAnonAssignments(user.accountId, value)
      .then((anonAssignments) => {
      let ngroups = anonAssignments.groups.length;
        this.setState({
          testName: value.testName,
          fromDateValue: this.state.fromDateValue || new Date(anonAssignments.firstDate),
          toDateValue: this.state.toDateValue || new Date(anonAssignments.lastDate),
          fromDateMax: this.state.fromDateMax || new Date(anonAssignments.lastDate),
          toDateMin: this.state.toDateMin || new Date(anonAssignments.firstDate),
          testSelected: true,
          checkboxesStatus: new Array(ngroups).fill(true),
          info: getBarChartData(anonAssignments, {
            excludedGroups: this.state.excludedGroups,
            excludedGroupIds: this.state.excludedGroupIds,
            fromDate: this.state.fromDateValue || new Date(anonAssignments.firstDate),
            toDate: this.state.toDateValue || new Date(anonAssignments.lastDate),
            chartType: this.state.chartType,
            timeFrame: this.state.timeFrame
          })
        })
      }, (err) => {
        console.log(err);
        showSystemError()
      })
  }

  handleCheckboxSelect = (e) => {
    const {anonAssignments} = this.props;
    let checked = e.target.checked;
    let id = e.currentTarget.id;
    id = id.slice(0, id.length - 7)
    let i = id.indexOf(':');
    let cbi = parseInt(id.slice(1, i)) - 1;
    let cbArray = [...this.state.checkboxesStatus];
    cbArray[cbi] = checked;
    let group = id.slice(5 - id.length)
    let groupId = id.slice(0, 3);
    let exGroups;
    let exIds;
    if (checked) {
      exGroups = _.without(this.state.excludedGroups, group);
      exIds = _.without(this.state.excludedGroupIds, groupId);
    }
    else {
      exGroups = [...this.state.excludedGroups];
      exGroups.push(group);
      exIds = [...this.state.excludedGroupIds];
      exIds.push(groupId);
    }
    this.setState({
      excludedGroups: exGroups,
      excludedGroupIds: exIds,
      checkboxesStatus: cbArray,
      info: getBarChartData(anonAssignments, {
        excludedGroups: exGroups,
        excludedGroupIds: exIds,
        fromDate: this.state.fromDateValue,
        toDate: this.state.toDateValue,
        chartType: this.state.chartType,
        timeFrame: this.state.timeFrame
      })
    })

  }

  handleFromDate = (value) => {
    const {anonAssignments} = this.props;
    let month = value.getMonth();
    let date = value.getDate();
    let year = value.getFullYear()
    value = new Date(year, month, date, 0, 0)
    this.setState({
      fromDateValue: value,
      toDateMin: value,
      info: getBarChartData(anonAssignments, {
        excludedGroups: this.state.excludedGroups,
        excludedGroupIds: this.state.excludedGroupIds,
        fromDate: new Date(value),
        toDate: this.state.toDateValue,
        chartType: this.state.chartType,
        timeFrame: this.state.timeFrame
      })
    })
  }

  handleToDate = (value) => {
    const {anonAssignments} = this.props;
    let month = value.getMonth();
    let date = value.getDate();
    let year = value.getFullYear()
    value = new Date(year, month, date, 23, 59)
    this.setState({
      toDateValue: value,
      fromDateMax: value,
      info: getBarChartData(anonAssignments, {
        excludedGroups: this.state.excludedGroups,
        excludedGroupIds: this.state.excludedGroupIds,
        fromDate: this.state.fromDateValue,
        toDate: new Date(value),
        chartType: this.state.chartType,
        timeFrame: this.state.timeFrame
      })
    })
  }

  handleChartType = (e) => {
    const {anonAssignments} = this.props;
    this.setState({
      chartType: e.currentTarget.value,
      info: getBarChartData(anonAssignments, {
        excludedGroups: this.state.excludedGroups,
        excludedGroupIds: this.state.excludedGroupIds,
        fromDate: this.state.fromDateValue,
        toDate: this.state.toDateValue,
        chartType: e.currentTarget.value,
        timeFrame: this.state.timeFrame
      })
    })
  }

  handleTimeFrame = (e) => {
    const {anonAssignments} = this.props;
    this.setState({
      timeFrame: e.currentTarget.value,
      info: getBarChartData(anonAssignments, {
        excludedGroups: this.state.excludedGroups,
        excludedGroupIds: this.state.excludedGroupIds,
        fromDate: this.state.fromDateValue,
        toDate: this.state.toDateValue,
        chartType: this.state.chartType,
        timeFrame: e.currentTarget.value
      })
    })
  }

  componentWillUnmount() {
    const {chartId} = this.props;
    if (!localStorage.getItem('trackAnon_reset')) {
      localStorage.setObject(`chartCreator-${chartId}`, this.state);
    }

  }

  render() {
    const {anonAssignments, chartId, surveys} = this.props;
    if (!this.state.chartMounted) {
      return null;
    }
    return (
      <div>
        <div className="component"
             style={{
               width: `${componentWidth}px`,
               height: `${componentHeight + 20}px`, //needed so bottom border included in downloaded chart
               margin: '0 auto'
             }}>
          <div className="left-box"
               style={{height: `${componentHeight}px`, width: '80%', float: 'left'}}>
            <div className="top-left"
                 style={{
                   height: `${componentHeight - chartHeight}px`,
                   width: '100%'
                 }}>
              <div className="survey-and-date">
                <div className="survey-select">
                  <SurveySelect testName={this.state.testName}
                                handleTestSelect={this.handleTestSelect}
                                surveys={surveys}


                  />
                </div>
                <div className="date-range-select-container">
                  <div className="date-range-select-inner">
                    <div className="from-date">
                      <FromDateSelect fromDateValue={this.state.fromDateValue}
                                      handleFromDate={this.handleFromDate}
                                      disabled={!this.state.testSelected}
                                      max={this.state.fromDateMax}
                                      min={new Date(anonAssignments.firstDate)}
                                      containerClassName="dateClass"
                      />
                    </div>
                    <div className='to-div'>&nbsp;&nbsp;&nbsp;To:</div>
                    <div className="to-date">
                      <ToDateSelect toDateValue={this.state.toDateValue}
                                    handleToDate={this.handleToDate}
                                    disabled={!this.state.testSelected}
                                    min={this.state.toDateMin}
                                    max={new Date(anonAssignments.lastDate)}
                      />
                    </div>
                  </div>


                </div>
              </div>
              <div className="chart-type-radio">
                <ChartTypeSelect visible={this.state.testSelected}
                                 chartType={this.state.chartType}
                                 onChange={this.handleChartType}
                                 chartId={chartId}
                />
              </div>
            </div>
            <div className="chart-container">
              <Chart info={this.state.info}
                     setHovered={this.setHovered}/>
            </div>
          </div>

          <div className="right-box"
               style={{height: `${componentHeight - 10}px`}}>
            {this.state.chartType === 'byTimeframe' ?
              <div>
                <TimeframeSelect
                  visible={this.state.chartType === 'byTimeframe'}
                  timeFrame={this.state.timeFrame}
                  onChange={this.handleTimeFrame}

                />
                <GroupsSelect
                  hoveredGroup={this.state.hoveredGroup}
                  anonAssignments={anonAssignments}
                  visible={this.state.testSelected}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                  checkboxesStatus={this.state.checkboxesStatus}
                  heightOffsetInner={157}
                  heightOffsetOuter={111}
                  byGroup={false}
                  chartId={chartId}
                />
              </div> :
              <GroupsSelect
                hoveredGroup={this.state.hoveredGroup}
                anonAssignments={anonAssignments}
                visible={this.state.chartType === 'byGroup' && this.state.testSelected}
                handleCheckboxSelect={this.handleCheckboxSelect}
                checkboxesStatus={this.state.checkboxesStatus}
                heightOffsetInner={70}
                heightOffsetOuter={25}
                byGroup={true}
                chartId={chartId}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}


export default ChartCreator;

