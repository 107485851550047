import {EDIT_CREATE_TABLE_ROW} from '../_CONSTANTS';

export const edit_Create_Table_Row = (question) => {
  return {
    type: EDIT_CREATE_TABLE_ROW,
    payload: question
  }
}


