import $ from 'jquery';
import {CsvBuilder} from 'filefy';
import showToastr from '../Common/showToastr';
import {toast} from 'react-toastify';


const exportData = (fromDateValue, toDateValue, selectedBatches, selectedGroups, test, prompt, title) => {
  return new Promise((resolve, reject) => {
    let url = '/test/getAnalysisData';
    let data = {
      fromDateValue: fromDateValue.getTime(),
      toDateValue: toDateValue.getTime(),
      selectedBatches,
      selectedGroups: selectedGroups,
      testName: test.testName,
      promptId: prompt ? prompt.promptId : '',
      accountId: test.accountId
    }

    $.ajax({
      url,
      type: "POST",
      data,
      success: (data) => {
        if (!data.dataPoints.length) {
         showToastr('fa fa-exclamation-circle',
    'There are no completed surveys to export.'
    , {type: toast.TYPE.INFO})
          resolve();
        }
        else {
          let results = data.dataPoints;
          let numberOfQuestions = data.numberOfQuestions;
          let iterableHeadings = ['Prompt', 'Response', 'Response url',
            'Chat assessment', 'Grammar assessment',
            'Pronunciation assessment', 'Sentiment assessessment'];
          let headings = ["Ticket", "Batch", "Group", "ExtID", "Email",
              "Date Completed"];
          for (let i=0;i<numberOfQuestions; i++){
            headings = [...headings, ...iterableHeadings];
          }

          let csvTitle = title || `surveydata for ${test.testName}`;
          csvTitle = `${csvTitle}-${new Date().getTime().toString().slice(-6)}.csv`

          new CsvBuilder(csvTitle)
            .setColumns(headings)
            .addRows(results)
            .exportFile()
          resolve()
        }
      },
      error: (err) => {
        console.log(err);
        reject(err);
      }
    })
  })
}

export default exportData;