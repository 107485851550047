import {EDIT_ASSIGN_TABLE_ROW} from '../_CONSTANTS';


export const edit_Assign_Table_Row = (row) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject)=>{
      window.socket.emit('updateRoom', `room-${getState().user.accountId}`, {
        type: "EDIT_ASSIGNMENT", //goes to socketDispatch for further routing
        result: row
      })
      dispatch({
        type: EDIT_ASSIGN_TABLE_ROW,
        payload: row
      })
      resolve()
    })
  }
}