import{STORE_WORDGROUPS, CLEAR_WORDGROUPS, LOGIN_USER} from '../actions/_CONSTANTS';

const wordGroups = (state = {}, action) => {
  switch (action.type) {
    case STORE_WORDGROUPS:
      let newState = {}
      for (const key in state) {
        newState[key] = [...state[key]]
      }
       newState[action.payload.analysisId] = action.payload.wordGroups;
      return newState;
    case CLEAR_WORDGROUPS:
      return action.payload;
    case LOGIN_USER:
       //required since Analysis module loads data to store when dismounting.
      return {};
    default:
      return state;
  }
}

export default wordGroups;