import {ADD_COMPLETED_ASSIGNMENT} from '../_CONSTANTS';


export const addCompletedAssignment = (assignments) => {
      return({
        type: ADD_COMPLETED_ASSIGNMENT,
        payload: assignments
      })

}
