
import {SET_ENV} from './_CONSTANTS';


export const setEnv = (env) => {
  return {
    type: SET_ENV,
    payload: env
  }
}
