import {
  GET_ALL_NAMED_ASSIGNMENTS,
  UNLOAD_TRACK,
  SOCKET_ADD_ASSIGNMENTS
} from '../actions/_CONSTANTS';

const allNamedAssignments = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_NAMED_ASSIGNMENTS:
      return action.payload;
    case UNLOAD_TRACK:
      return {};
    case SOCKET_ADD_ASSIGNMENTS:
      console.log(action.payload)
      if (state.length) {
        let newRows = [...state, ...action.payload]
        console.log(newRows);
        return [...state, ...action.payload];
      }
      else {
        return state;
      }

    default:
      return state;
  }
}

export default allNamedAssignments;

