import React from 'react';

class CsvInfo extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleCancel(e) {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  handleDelete(e) {
    const {hideModal, confirmHandler} = this.props;
    e.preventDefault();
    if (confirmHandler) {
      confirmHandler()
    }
    hideModal();
  }

  render() {
    return (
      <div>
        <div className="modal-body">


          <p style={{
            fontWeight: '400',
            color: 'rgb(0,0,102',
            fontFamily: 'Arial'
          }}>Requirements for uploading csv files</p>
          <hr/>
          <ul>
            <li className="csvModalText">Row one should contain labels for name and email.</li>
            <li className="csvModalText">Email addresses are optional: Names are required.</li>
            <li className="csvModalText">The column for email must be labeled: "EMAIL". </li>
            <li className="csvModalText">The Name can be one or two columns. If one column it must be labeled "NAME"; if two columns they must be labeled  "FIRSTNAME", "LASTNAME"/</li>
            <li className="csvModalText">You can also include an optional column named "CUSTOM".</li>
            <li className="csvModalText">This column can contain any information you want such as a customer Id or a student number.</li>
            <li className="csvModalText">This may be useful when integrating data exported from TellMeThis with prior information you have about respondents.</li>
            <li className="csvModalText">Your spreadsheet can contain other columns. They will simply be ignored.</li>
          </ul>
        </div>

        <div className="modal-footer">
          <div className="btn-group pull-right" style={{margin: '5px 0'}}>
            <button className="blueButton pull-right"
                    onClick={this.handleCancel}>
              Close
            </button>

          </div>
        </div>
      </div>
    )
  }
}

export default CsvInfo;