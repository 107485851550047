import React from 'react'

class About extends React.Component {
  render () {
    // const {user} = this.props;
    return (
      <div>
          <div>
            <h1 style={{ textAlign: 'center', paddingTop: '30px' }}>
              Welcome to{' '}
              <span style={{ fontStyle: 'italic' }}>&nbsp;TellMeThis</span>
            </h1>
          </div>
          <div
            style={{
              margin: 'auto',
              backgroundImage: 'url("../virtualInterview2.png")',
              backgroundSize: '200px 200px',
              boxShadow:
                '25px 25px 50px 0 white inset, -25px -25px 50px 0 white inset',
              width: '200px',
              height: '200px'
            }}
          >
          </div>
          <div style={{ margin: '20px', fontSize: '20px', paddingBottom: '50px' }}>
            <p>
              <span style={{ fontWeight: 'bold' }}>Speaking</span> is our most
              natural form of communication. Asking questions and hearing
              answers is how we evolved to learn quickly from and about each
              other. And recent advances in natural language processing,
              including speech synthesis, recognition, and language modeling
              make it possible to speak with one another, and even with
              chatbots, in a way that helps us to understand speech better and
              use it to greater effect.
            </p>

            <p>
              For example, while we still respond to surveys by indicating we
              "strongly agree, agree, or disagree" with a proposition, we can
              now simply share our ideas in our own words, speaking into our
              cell phone or our laptop to communicate them. With the power of
              speech recognition and large language models we can find
              commonalities across speakers even though they use different words
              to express similar ideas.
            </p>

            <p>
              Or knowing that others derive their sense of who we are by how we
              speak, we can practice different styles of speech, like speech
              appropriate for job interviews. We can respond to simulated
              interview prompts instead of jumping directly into actual
              interview situations. This has adavantages for both the
              interviewee, who will have a better chance to show their real
              abilities, and for the recruiting company. The company will see
              candidates at their best, as they have improved through practice
              and with feeback that enhances their communication skill, but
              recruiters will also know that some who have practiced have opted
              out, based on the nature of the questions, and their realization
              that they are not a great fit for a particular position, leaving a
              better pool of candidates for the company to choose among.
            </p>

            <p>
              <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                TellMeThis
              </span>{' '}
              was created to facilitate these kinds of simulations, surveys and
              tests. To get started creating your first{' '}
              <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                TellMeThis
              </span>{' '}
              dialog, click <a href='\create\'>here.</a>
            </p>
          </div>
      </div>
    )
  }
}

export default About