import {SET_COPIED_TEST} from '../_CONSTANTS';
import $ from 'jquery';
import _ from 'lodash'
const ObjectID = require("bson-objectid");

export const set_Copied_Test = (name) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {

      let _id = ObjectID().str;
      let blankRow = {
        _id: _id,
        promptPosition: '',
        promptText: '',
        promptPositionRevert: '',
        promptId: '',
        awaitResponse: true,
        soundFileURL: '',
        isBlank: true,
        isDeleted: false
      }
      let url = "/test/duplicatePrompts";
      // let url = /testerror;
      $.ajax({
        url: url,
        type: "POST",
        data: {
          testName: name,
          accountId: getState().user.accountId,
          prompts: _.dropRight(getState().create_Table.rows)
        },
        success: (newPrompts) => {
          newPrompts.push(blankRow);
          let table = getState().create_Table;
          table.rows = newPrompts;
          table.ancestor = table.testName;
          table.testName = name;
          table.batches = [];
          table.isLocked = false;

          dispatch({
            type: SET_COPIED_TEST,
            payload: table
          })
          resolve();
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}
