
function stripXMLtags (str){
 //if the prompt strings contain SSML tags, remove the tags before displaying them (must be a pair)
  if ( ((str.indexOf('<') !== -1) || (str.indexOf('&lt;') !== -1) )
       &&
       ((str.indexOf('>') !== -1) || (str.indexOf('&gt;') !== -1) ) ) {
    //first normalize all tags so they bgin and end with just '<' and '>'
    let tmp  = str.replaceAll('&lt;', '<');
    tmp  = tmp.replaceAll('&gt;', '>');
    tmp  = tmp.replaceAll('<\/', '<');
    tmp  = tmp.replaceAll('/>', '>');

    // now remove them all from the prompt strings, one at a time
    let firstLessThan = 0;
    let firstGreaterThan = 0;
    if (tmp.indexOf('<') < tmp.indexOf('>')){ //effort to catch < and > not really tags, make sure < incuded before > in text
                                              //if encounter a pair out of order quit
      while ((tmp.indexOf('<')!== -1) && (tmp.indexOf('>') !== -1)){
        if (tmp.indexOf('<') < tmp.indexOf('>')){
          firstLessThan = tmp.indexOf('<');
          firstGreaterThan = tmp.indexOf('>');
          tmp = tmp.substring(0,firstLessThan) + tmp.substring(firstGreaterThan +1, tmp.length);
          str = tmp;
        }
      }
    }
  }
return str;
}

export default stripXMLtags;