import React from 'react';
import moment from 'moment';
import RoleDropdown from './RoleDropdown';
import showSystemError from '../../Common/showSystemError';

class StaffRow extends React.Component {

  handleRoleChange = (e) => {
    const {employee, editStaff} = this.props;
    editStaff(employee.email, {
      role: e.target.value
    })
      .then(() => {
      }, () => {
        showSystemError()
      })
  }

  handleStatusChange = (e) => {
    const {employee, editStaff} = this.props;
    editStaff(employee.email, {
      active: e.target.value
    })
      .then(() => {
      }, () => {
        showSystemError()
      })
  }

  doDelete = (e) => {
    const {deleteEmployee} = this.props;
    deleteEmployee(e.currentTarget.id);
  }

  render() {
    const {employee, user} = this.props;
    if (employee.isDeleted) {
      return null;
    }
    return (
      <tr style={{backgroundColor: 'transparent', width: '95%', marginTop: '0px'}}>
        <td style={{width: '25%'}} className="staff-td">
          {employee._id === user._id ? null :
            <div className="deleteIcon" id={employee._id}
                 onClick={this.doDelete}
            >
              <span className="hover-control">
                <i className="far fa-minus-square"
                   title="delete this row"
                >
                </i>
                 <i className="fas fa-minus-square"
                    title="delete this row"
                 >
                </i>
              </span>
            </div>}
          {employee._id === user._id || (user.role === 'admin' && employee.role === 'admin') ?
            <div style={{fontWeight: 'bold'}}>{employee.name}</div> :
            <div>{employee.name}</div>}
        </td>
        <td style={{width: '20%'}} className='staff-td'>
          {employee.email}
        </td>
        <td style={{width: '20%'}} className='staff-td'>
          <RoleDropdown
            employee={employee}
            user={user}
            value={employee.role}
            onChange={this.handleRoleChange}
          />
        </td>
        <td style={{width: '15%'}} className='staff-td'>
          <select name="status"
                  id="status"
                  disabled={employee._id === user._id || (user.role === 'admin' && employee.role === 'admin')}
                  value={employee.active}
                  style={{width: '100%'}}
                  onChange={this.handleStatusChange}>
            <option value="active">active</option>
            <option value="inactive">inactive</option>
          </select>
        </td>
        <td style={{
          width: '20%',
          textAlign: 'center'
        }}
            className='staff-td'
        >
          {employee.mostRecentVisit ? moment(employee.mostRecentVisit).format('MM/DD/YYYY, h:mm a') : 'Pending'}
        </td>
      </tr>
    )
  }
}

export default StaffRow;
