import {ADD_STAFF_MEMBER, GET_STAFF, EDIT_STAFF, DELETE_STAFF} from '../actions/_CONSTANTS';
import _ from 'lodash';

const staff = (state = [], action) => {
  let newStaff = [];
  switch (action.type) {
    case GET_STAFF:
      return action.payload;
    case ADD_STAFF_MEMBER:
      newStaff = [...state, ...[action.payload]];
      newStaff = _.sortBy(newStaff,['name']);
      return newStaff;
    case EDIT_STAFF:
      newStaff = state.filter((employee) => {
        return employee._id !== action.payload._id;
      })
      newStaff.push(action.payload);
      newStaff = _.sortBy(newStaff,['name']);
      return newStaff;
    case DELETE_STAFF:
      newStaff = state.filter((employee)=>{
        return employee._id !== action.payload._id;
      })
      return newStaff;
    default:
      return state;
  }
}

export default staff;