import React from 'react';

class CreateHeader extends React.Component {

  render() {
    const {testName, instrumentType} = this.props;
    return (
      <div>
        <div className="testName">
          <span
            className="namePart">{instrumentType === "TEST" ? "Test" : "Survey"}&nbsp;Name: {testName}</span>
        </div>
        <table className="testElementsTable" style={{minHeight: '52px'}}>
          <tbody>
          <tr>
            <th className="seqNumbHdr">Sequence Number</th>
            <th className="promptTextHdr">Prompt Text</th>
            <th className="promptIdHdr">Prompt ID</th>
            <th className="spokenPromptHdr">Spoken Prompt</th>
            <th className="scoringHdr">Options</th>
            <th className="scoringHdr">Assessment</th>

          </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default CreateHeader;