import {
  GET_ASSIGN_TABLE,
  EDIT_ASSIGN_TABLE_ROW,
  DEL_ASSIGN_TABLE_ROW,
  CLEAR_ASSIGN_TABLE,
  GET_NAMED_ASSIGNMENTS,
  EDIT_INVITE_ALL,
  SOCKET_START_ASSIGNMENT,
  SOCKET_COMPLETE_ASSIGNMENT
} from "../actions/_CONSTANTS";


const assign_Table = (state, action) => {
  let emptyTable = {
    isDirty: false,
    accountId: '',
    rows: [],
    anyInvitesAvailable: false,
    testName: '',
    batch: ''
  }
  if (state === undefined) {
    state = emptyTable;
  }
  let rows;
  let newRows;
  switch (action.type) {
    case GET_ASSIGN_TABLE:
      return action.payload;

    case EDIT_ASSIGN_TABLE_ROW:
      rows = state.rows;
      newRows = [];
      rows.forEach((row) => {
        if (row._id === action.payload._id) {
          newRows.push({...row, ...action.payload});
        }
        else {
          newRows.push(row);
        }
      });
      return {...state, ...{rows: newRows}}

    case DEL_ASSIGN_TABLE_ROW:
      rows = [...state.rows];
      rows.forEach((row) => {
        if (row._id === action.payload) {
          row.isDeleted = true;
        }
      })
      return {...state, ...{rows: rows}};

    case EDIT_INVITE_ALL:
      return {...state, ...{anyInvitesAvailable: action.payload}};

    case CLEAR_ASSIGN_TABLE:
      return emptyTable;
    case GET_NAMED_ASSIGNMENTS:
      return action.payload;
    case SOCKET_START_ASSIGNMENT:
    case SOCKET_COMPLETE_ASSIGNMENT:
      if(!state.rows) {return state}
      rows = state.rows;
      newRows = [];
      rows.forEach((row) => {
        if (row.TIN === action.payload.TIN) {
          row.status = action.type === SOCKET_START_ASSIGNMENT ? 'in progress' : 'completed';
        }
        newRows.push(row);
      })
      return {...state, ...{rows: newRows}}
    default:
      return state
  }
}

export default assign_Table