import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';


const _salutation = (participantName) => {
  return (
    <div>
     Dear {participantName},

    </div>

  )
}

const salutation = (participantName) => {
  return reactElementToJSXString(_salutation(participantName))
}


export default salutation;





