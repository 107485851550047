import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {edit_Assign_Table_Row} from '../../actions/Assign/edit_Assign_Table_Row';
import {del_Assign_Table_Row} from '../../actions/Assign/del_Assign_Table_Row';
import {save_Assign_Table} from '../../actions/Assign/save_Assign_Table';
import {setModalInfo} from '../../actions/setModalInfo';
import {sendNamedInviteEmail} from '../../actions/Assign/sendNamedInviteEmail';
import AssignRow from './AssignRow';
import AssignFooter from '../Assign/AssignFooter'
import InviteAllModalController from './ModalInviteAll/InviteAllModalController';
import NamedEmailInviteController from './ModalNamedEmailInvite/NamedEmailInviteController';
import ReactTooltip from 'react-tooltip'
import showAutoSaveError from '../Common/showAutoSaveError';
import slicedTin from '../Common/slicedTin';
import $ from 'jquery';

//use of _isMounted adapted from: https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component

class AssignTable extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('assignTable');
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('assignTable');
    }
    else {
      this.state = {
        documentDirty: false,
        allInvited: false,
        editing: false
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.anyUninvited();
  }

  handleResize = () => {
    $('.AssignTableContainer').height(window.innerHeight - 406);
  }

  setDocumentDirty = (bool) => {
    this.setState({documentDirty: bool});
  }

  isRowInvalid = (data) => {
    /*
     0 - row is valid
     1 - invalid email
     2 - invite without email
     3 - email without name
     */
    const {assign_Table} = this.props;
    let row = assign_Table.rows.find((row) => {
      return row._id === data._id;
    });
    data = {...row, ...data}
    let error = 0;
    if (data.badEmail) {
      error = 1;
    }
    else {
      if (data.sendInvite && !data.email) {
        error = 2;
      }
      else {
        if (data.email && !data.participantName) {
          error = 3;
        }
      }
    }
    return error;
  }

  handleRowChange = (data) => {
    const {edit_Assign_Table_Row, save_Assign_Table} = this.props;
    this.setDocumentDirty(true)
    this.setEditingFlag(false)
    data.error = this.isRowInvalid(data)
    edit_Assign_Table_Row(data)
      .then(() => {
        this.anyUninvited();
        save_Assign_Table(true)
          .then(() => {
            if (this._isMounted) {
              this.setDocumentDirty(false)

            }
            console.log('Assign Table Saved: ', new Date());
          }, () => {
            showAutoSaveError();
          })
      })
  }

  deleteRow = (row) => {
    const {openModal} = this.props;
    openModal({
      name: 'assignDeleteRow',
      _id: row._id,
      participantCode: slicedTin(row.participantCode)

    })
  }

  setEditingFlag = (bool) => {
    this.setState({editing: bool});
  }

  documentStatus = () => {
    const {deleteDirty} = this.props;

    if (this.state.editing) {
      return <div className="saveIndicatorGreen"></div>
    }
    else {
      return this.state.documentDirty || deleteDirty ?
        <div className="saveIndicatorOrange">Saving...</div>
        :
        <div className="saveIndicatorGreen">All changes saved</div>
    }

  }

  anyUninvited = () => {
    const {save_Assign_Table} = this.props;
    save_Assign_Table()
      .then(() => console.log('all invite flag updated'))
  }

  handleInviteAll = () => {
    const {openModal} = this.props;
    openModal({
      name: 'inviteAll'
    })
  }

  doInviteAll = () => {
    const {openEditInvite} = this.props;
    openEditInvite([]);
  }

  adjustCSS = () => {
    $('.__react_component_tooltip').css('background-color','lightgoldenrodyellow').css('font-size','16px');
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.handleResize);
    if (!localStorage.getItem('assign_reset')) {
      localStorage.setObject('assignTable', this.state);
    }
    else {
      localStorage.removeItem('assign_reset') //last component to unmount so remove
    }
  }

  render() {
    const {assign_Table, history, modalInfo, setModalInfo, namedInviteTemplates, save_Named_Invite_Template, openEditInvite} = this.props;
    return (
      <div>

        <div>
          <table className="AssignTableHeader">
            <tbody>
            <tr>
              <th className="spcHeaderCol">Ticket Number</th>
              <th className="participantNameHeaderCol">Participant Name</th>
              <th className="participantEmailHeaderCol">Email (optional)</th>
              <th className="participantInviteHeaderCol">Invite All
                <button className="blueButtonSmall"
                        onClick={this.handleInviteAll}
                        disabled={!assign_Table.anyInvitesAvailable}
                        style={{marginLeft: '83px'}}>
                  <i className="fa fa-check" style={{fontSize: '10px'}}></i>
                </button>

              </th>
            </tr>
            </tbody>
          </table>
        </div>
        <ReactTooltip place="left" html={false} border={true} type="light" effect="float"
                      id="dirty-indicator"/>
        <div className="saveAssignIndicatorLocator"
             data-tip="Every change you make is automatically saved."
             data-for="dirty-indicator"
             onMouseOver={this.adjustCSS}
        >
          {this.documentStatus()}
        </div>
        <div className="AssignTableContainer"
             style={{
               overflowY: 'auto',
               backgroundColor: 'white',
               border: 'solid thin gray',
               marginLeft: '10px',
               position: 'relative',
               float: 'left',
               width: '98%',
               paddingBottom: '30px'
             }}
        >
          <table className="AssignTable">
            <tbody>
            {assign_Table.rows.map((row, index) => {
              let hashedIndex = row.inviteSent ? `0${index.toString()}` : `1${index.toString()}`
              return <AssignRow
                setEditingFlag={this.setEditingFlag}
                handleRowChange={this.handleRowChange}
                openEditInvite={openEditInvite}
                assign_Table_row={row}
                namedInviteTemplates={namedInviteTemplates}
                save_Named_Invite_Template={save_Named_Invite_Template}
                setModalInfo={setModalInfo}
                deleteRow={this.deleteRow}
                index={index}
                key={hashedIndex}
              />
            })}
            </tbody>
          </table>
        </div>
        <AssignFooter
          history={history}
        />
        <InviteAllModalController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.doInviteAll}
        />
        <NamedEmailInviteController
          setModalInfo={setModalInfo}
          modalInfo={modalInfo}
          confirmHandler={this.anyUninvited}
          // cancelHandler={this.cancelHandler}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    assign_Table: state.assign_Table,
    modalInfo: state.modalInfo,
    namedInviteTemplates: state.namedInviteTemplates
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    edit_Assign_Table_Row,
    del_Assign_Table_Row,
    save_Assign_Table,
    setModalInfo,
    sendNamedInviteEmail
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignTable);
