import React from 'react';
import $ from 'jquery';
import * as d3 from 'd3';
import svgPanZoom from 'svg-pan-zoom';

var responseDataStash = [];

class Sentiments extends React.PureComponent {
  currentMousePos = {x: -1, y: -1}; // continuously updated mouse positions available to tooltip
  thisX = 0;
  thisY = 0;
  margin = {top: 20, right: 30, bottom: 50, left: 40};
  numberBars = 11;
  horizBarLocs = [];
  barWidth = 0;
  maxHeight = 0;
  containerId = '';
  tooltip = null;
  xadj = 20;  //to separate the tooltip from the cursor to the right in screen (not svg) coordinates
  yadj = -30; //position a bit higher
  sentimentGroups = [[], [], [], [], [], [], [], [], [], [], []];
  sentimentScale = ["-5", "-4", "-3", "-2", "-1", "0", "1", "2", "3", "4", "5"];
  colorArray = ["#5271BA", "#6C87C6", "#98AAD6", "#B3C0E1", "#DBE1F0", "#EEEEEE", "#E0F0D9", "#D1E9C7", "#AFD89E", "#98CD81", "#71BA52"];

  state = {
    mounted: false
  }

  componentDidMount() {
    const {isDerivative} = this.props;

    if (!isDerivative) {
      window.startWizard = this.startWizard;
      window.stopWizard = this.stopWizard;
      window.getDerivativeData = this.getDerivativeData;
    }

    var self = this;
    this.containerId = this.refs.container.id;
    this.setState({
      mounted: true
    });

    $('#' + self.containerId).mousemove(function (event) {
      self.currentMousePos.x = event.pageX;
      self.currentMousePos.y = event.pageY;

      let p = $('#' + self.containerId).find('svg:first');
      let position = p.position();
      self.thisX = self.currentMousePos.x - position.left + self.xadj;
      self.thisY = self.currentMousePos.y - position.top + self.yadj;

      self.tooltip.style("left", self.thisX + "px")
        .style("top", self.thisY + "px")
    });
  }

  setPanAndZoom = () => {

    /* ****************  set up pan and zoom   *************************** */

    var elem = document.getElementById(this.containerId).firstChild;

    svgPanZoom(elem, {
      zoomEnabled: true,
      controlIconsEnabled: true,
      fit: false,
      center: false,
      minZoom: 0.5,
      maxZoom: 3.0,
    });
  }

  toggleBarColor = (thisClass) => {
    let index = thisClass.split("-")[1];
    if (d3.select(`#${this.containerId}`).selectAll('.' + thisClass).attr('fill') === '#c4fb8e') {
      d3.select(`#${this.containerId}`).selectAll('.' + thisClass).attr('fill', this.colorArray[index]);
    } else {
      d3.select(`#${this.containerId}`).selectAll('.' + thisClass).attr('fill', '#c4fb8e');
    }
  }

  playSound = (url) => {
    var response = new Audio();
    response.src = url;
    response.play();
  }

  doGraph = () => {
    const {width, height} = this.props;

    if (this.state.mounted) {
      let $containerId = $('#' + this.containerId);

      $containerId.empty(); //delete existing chart if any drawn already

      d3.select(`#${this.containerId}`).attr('class', 'topDiv')
        .append("svg").attr("width", width).attr("height", height).attr("cursor", "move")
        .append("g").attr("transform", "translate(0, 0), scale(1.0)");

      $containerId.css('position', 'relative').css('top', '-5px').css('max-width', width).css('background-color', 'white').css("border-color", "#EEEEEE");

      this.tooltip = d3.select(`#${this.containerId}`).append("div")
        .attr("id", "tooltip")
        .attr("class", "tooltip")
        .style("min-width", "100px")
        .style("opacity", "0.0")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("text-align", "left")
        .style("width", "175px")
        .style("height", "auto")
        .style("min-height", "30px")
        .style("padding", "4px")
        .style("font", "12px sans-serif")
        .style("background", "white")
        .style("border", "solid thin gray")
        .style("border-radius", "8px")
        .style("pointer-events", "none");
      this.setPanAndZoom();
      this.drawGraph();
    }
  }

  /* *************************** functions to call from external wizard  ***************************************** */

  startWizard = () => {
    Sentiments.wizardOn = true;
  }

  stopWizard = () => {
    Sentiments.wizardOn = false;
    this.restoreUnhilitedColors();
  }

  getDerivativeData = () => {
    let selectedTINs = [];
    let targetFillColor = "rgb(196,251,142)";
    let thisColor = "";

    d3.select(`#${this.containerId}`).selectAll('rect').each(function (d, i) {
      thisColor = d3.select(this).style('fill');
      thisColor = thisColor.replace(/ /g, '');// in case other browsers don't place spaces in rgbs
      if (thisColor === targetFillColor) {
        selectedTINs.push(d3.select(this).attr('TIN'));
      }
    });

    for (var i = 0; i < responseDataStash.length; i++) {
      if (selectedTINs.includes(responseDataStash[i].TIN)) {
        responseDataStash[i].selected = true;
      } else {
        responseDataStash[i].selected = false;
      }
    }

    window.derivativeData.push(JSON.parse(JSON.stringify(responseDataStash)));
    window.childIds.push(new Date().getTime().toString().slice(-6));

  }


  anySelections = () => {
    let fillArray = [];
    let targetFillColor = "#c4fb8e";
    d3.select(`#${this.containerId}`).selectAll('rect').each(function (d, i) {
      let thisFillColor = d3.select(this).attr('fill');
      fillArray.push(thisFillColor);
    });

    if (fillArray.includes(targetFillColor)) {
      window.areAnySelected(true);
    } else {
      window.areAnySelected(false);
    }
  }

  setMaxHeight = () => {
    var tempHeights = [];
    for (var i = 0; i < this.numberBars; i++) {
      tempHeights.push(this.sentimentGroups[i].length)
    }
    this.maxHeight = Math.max(...tempHeights);
  }

  setHorizBarLocs = () => {
    const {width} = this.props;
    let vertBarRegions = 0.93 * width / this.numberBars;
    let barPadding = vertBarRegions / 10;
    this.barWidth = vertBarRegions - (2 * barPadding);
    for (var i = 0; i < this.numberBars; i++) {
      this.horizBarLocs.push(i * (vertBarRegions + 1) + (0.052 * width ));
    }
  }

  playOrHilite = (thisClass, This) => {
    if (Sentiments.wizardOn) {
      this.toggleBarColor(thisClass);
      this.anySelections();
    } else {
      var url = d3.select(This).attr('url');
      this.playSound(url);
    }
  }

  restoreUnhilitedColors = () => {
    let self = this;
    d3.select(`#${this.containerId}`).selectAll('rect').each(function (d, i) {
      let thisClass = d3.select(this).attr('class');
      let classIndex = thisClass.split("-")[1];
      d3.select(this).attr('fill', self.colorArray[classIndex]);
    });
  }

  drawGraph = () => {

    var self = this;
    const {width, height} = this.props;

    this.setMaxHeight();
    this.setHorizBarLocs();

    // set the ranges
    var x = d3.scaleBand()
      .range([0, 0.950 * width])
      .padding(0.1);
    var y = d3.scaleLinear()
      .range([0.7692 * height, 0]);

    // Scale the range of the data in the domains
    y.domain([0, this.maxHeight]);
    x.domain(self.sentimentScale);

    var svg = d3.select(`#${this.containerId}`).select(`g.svg-pan-zoom_viewport`);
    for (var i = 0; i < this.numberBars; i++) {
      var barNumb = i;
      var thisText = "";
      var thisURL = "";
      var thisTIN = "";
      var theseWords = ""

      for (var j = 0; j < this.sentimentGroups[i].length; j++) {

        if (thisText !== undefined) {
          thisText = self.sentimentGroups[i][j].responseText
        }
        if (theseWords !== undefined) {
          theseWords = "";
          for (var k = 0; k < self.sentimentGroups[i][j].sentimentData.calculation.length; k++) {
            theseWords = theseWords + JSON.stringify(self.sentimentGroups[i][j].sentimentData.calculation[k]);
          }
        }
        if (thisURL !== undefined) {
          thisURL = self.sentimentGroups[i][j].url;
        }
        if (thisTIN !== undefined) {
          thisTIN = self.sentimentGroups[i][j].TIN;
        }
        d3.select(`#${this.containerId}`).select('.svg-pan-zoom_viewport').select('g')
          .append("rect")
          .attr("class", "rect-" + i)
          .attr("text", thisText)
          .attr("url", thisURL)
          .attr("TIN", thisTIN)
          .attr("words", theseWords)
          .attr("x", this.horizBarLocs[barNumb])
          .attr("width", this.barWidth)
          .attr("y", y(j + 1) + 41)
          .attr("height", 0.7692 * height - y(1))
          .attr("stroke", "#555555")
          .attr("fill", self.colorArray[barNumb])
          .attr("cursor", "pointer")
          .on('click', function () {
              let thisClass = d3.select(this).attr('class');
              self.playOrHilite(thisClass, this)
            } //depends on Sentiments.wizardOn true/false
          )
          .on("mouseover", function () {
            let msg = d3.select(this).attr('text') + "</br></br>" + d3.select(this).attr('words');
            d3.select(this).style('stroke-width', 3);
            self.tooltip.html(msg).style("opacity", .9);
          })
          .on("mouseout", function () {
            d3.select(this).style('stroke-width', 1);
            $('.tooltip').css('opacity', '0.0');
          });
      }

    }
    // add the x Axis
    svg.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(40," + (0.832 * height) + ")")
      .call(d3.axisBottom(x));

    // add the y Axis
    svg.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(" + (0.040 * width) + "," + (0.0616 * height) + ")")
      .call(d3.axisLeft(y));

    svg.append("text")
      .attr("x", 0.943 * width)
      .attr("y", 0.885 * height)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'san-serif')
      .text("Positive");

    svg.append("text")
      .attr("x", 0.513 * width)
      .attr("y", 0.885 * height)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'san-serif')
      .text("Neutral");

    svg.append("text")
      .attr("x", 0.060 * width)
      .attr("y", 0.885 * height)
      .attr('text-anchor', 'right')
      .attr('font-family', 'san-serif')
      .text("Negative");

    d3.selectAll('text').attr('font-size', '14px').attr('font-family', 'sans-serif');
  }

  render() {
    const {responseData, analysisId} = this.props;
    var i;


    if (responseData.length !== 0) {
      responseDataStash = JSON.parse(JSON.stringify(responseData));
    }


    if (responseDataStash[0].selected !== undefined) {     // if a derivative chart
      responseDataStash = responseDataStash.filter((item) => {  // filter out the unselected data
        return item.selected;
      })
    }

    var responsesLoaded = 0;
    for (i = 0; i < this.numberBars; i++) {
      responsesLoaded = responsesLoaded + this.sentimentGroups[i].length;
    }

    if (!responsesLoaded) {
      for (i = 0; i < responseDataStash.length; i++) {
        let index = responseDataStash[i].sentimentData.score + 5;
        this.sentimentGroups[index].push(responseDataStash[i]);

      }
    }

    return (
      <div id={`sentiment-${analysisId}`}
           ref="container"
           style={{
             position: 'relative',
             margin: 'auto',
             marginTop: '20px',
             display: 'block',
             border: '1px solid transparent'
           }}>
        {this.doGraph()}
      </div>
    )
  }
}

export default Sentiments;