import {EDIT_SURVEY} from '../_CONSTANTS';
import $ from 'jquery';

export const editSurvey = (id, value) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = '/admin/editSurvey';
      // let url = '/testerror';
      $.ajax({
        url: url,
        type: "PUT",
        data: {
          id: id,
          status: value
        },
        success: (survey) => {
          dispatch({
            type: EDIT_SURVEY,
            payload: survey
          })
          resolve(survey)
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}

