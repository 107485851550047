import {INCREMENT_VISITS_HOME} from './_CONSTANTS'
import $ from 'jquery';

export const incrementVisitsHome = (user) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {

      let url = "/admin/incrementVisits";
      // let url = /testerror;
      $.ajax({
        url: url,
        type: "POST",
        data: {
          user
        },
        success: (user) => {

          dispatch({
            type: INCREMENT_VISITS_HOME,
            payload: user
          })
          resolve();
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}