
import {SET_ENV} from '../actions/_CONSTANTS';

const env = (state={}, action) => {
  switch(action.type){
    case SET_ENV:
      return action.payload;
    default:
      return state;
  }
}

export default env;
