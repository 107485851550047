import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import DropImage from '../Common/DropImage'
import {setActiveModule} from '../../actions/setActiveModule'
import {setWhiteLabelInfo} from '../../actions/Manage/setWhiteLabelInfo'
import {getWhiteLabelInfo} from '../../actions/Manage/getWhiteLabelInfo'
import showSystemError from '../Common/showSystemError';
import $ from 'jquery';

let preface1 = 'When you create surveys, tests, or simulated interviews,'
let preface2 =
  ' you can have your own company identifiers visible to your respondents. '
let preface3 =
  ' For each of the following items, provide your company’s information and it will be included in whatever your respondents view.  For any items you leave blank,'
let preface4 = ' TellMeThis '
let preface5 = 'identifiers will be used.'

class Account extends React.Component {

  constructor(props) {
    super(props)
    this.oldState = localStorage.getObject('account')
    if (this.oldState) {
      this.state = this.oldState
      localStorage.removeItem('account')
    } else {
      this.state = {
        whiteLabelInfo: {
          companyName: '',
          companyWebsite: '',
          fromAddress: '',
          replyToAddress: '',
          hasLogo: false,
          logoDataURL: '',
        },
        changeMessage: '',
        displayState: 'none'
      }
    }
  }

  componentDidMount() {
    const {setActiveModule, getWhiteLabelInfo, user} = this.props;
    setActiveModule('Account');
    if (!this.oldState) {
      getWhiteLabelInfo(user.accountId)
        .then((whiteLabelInfo) => {
          this.setState({
            whiteLabelInfo
          })
          if (whiteLabelInfo.hasLogo) {
            let url = "https://tmt-partnerlogos.s3.us-east-1.amazonaws.com/" + user.accountId;
            let This = this;
            this.getlogoData(url, This);
          }
        }, (err) => {
          showSystemError();
        })
    }
    this.setDOMListener() //create a DOM node change listener to detect when image dataURL can be scraped
  }

  getlogoData = (url, This) => {
    const {whiteLabelInfo} = this.state;
    $.ajax({
      url: url,
      type: "GET",
      success: function (data) {
        This.setState({
          whiteLabelInfo: {...whiteLabelInfo, ...{logoDataURL: data}},
          displayState: 'inline'
        })
      },
      error: function (err) {
        console.log("image not available");
        $.get('/ImageNotAvailable.txt', function (data) {
          This.setState({
            whiteLabelInfo: {...whiteLabelInfo, ...{logoDataURL: data}},
            displayState: 'inline'
          })
        }, 'text');
      }
    })
  }

  setDOMListener = () => {  //generates event when dropped image bits are available to copy from <img> element
    let targetNode = document.getElementsByClassName('logo-uploader')[0] // define the target node
    let config = {
      childList: true,
      characterData: true,
      subtree: true,
      attributes: true
    } // configure the observer
    let callback = (mutationsList, observer) => {  //this function gets called and moves image data to state
      //  create callback function
      let imageDiv = document.getElementsByClassName('image-item')
      if (imageDiv.length > 0) {
        let imgSrc = document.querySelector('.image-item').firstElementChild.src
        if (!this.state.whiteLabelInfo.hasLogo) {
          this.setState({changeMessage: 'Click \'Save All\' to save your changes',})
        }

        this.setState({
          whiteLabelInfo: {
            ...this.props.user.whiteLabelInfo, ...{
              logoDataURL: imgSrc,
              hasLogo: true
            }
          }, displayState: 'inline'
        })
      } else {
        this.setState({
          whiteLabelInfo: {
            ...this.props.user.whiteLabelInfo, ...{
              hasLogo: false,
              logoDataURL: '',
            }
          }, displayState: 'none',
        });
      }
    }
    this.observer = new MutationObserver(callback) // Create observer instance
    this.observer.observe(targetNode, config) // pass in the target node and configuration
  }

  handleChange = e => {
    let id = e.target.id;
    let whiteLabelInfo = this.state.whiteLabelInfo;
    whiteLabelInfo[id] = e.target.value;
    this.setState({whiteLabelInfo});
    this.setState({changeMessage: 'Click \'Save All\' to save your changes'})
  }

  removeLogo = () => {
    this.setState({
      whiteLabelInfo: {
        ...this.state.whiteLabelInfo, ...{
          logoDataURL: '',
          hasLogo: false
        }},
        displayState: 'none',
        changeMessage: 'Click \'Save All\' to save your changes'

    })
  }

  handleSaveAll = () => {
    const {setWhiteLabelInfo, user} = this.props;
    const {whiteLabelInfo} = this.state;
    let accountId = user.accountId;
    setWhiteLabelInfo(accountId, whiteLabelInfo)
      .then(() => {
        this.setState({changeMessage: ''})
      });
  }

  componentWillUnmount() {
    if (!localStorage.getItem('account_reset')) {
      localStorage.setObject('account', this.state)
    }
    this.observer.disconnect();
  }

  render() {
    const {
      companyName,
      companyWebsite,
      fromAddress,
      replyToAddress,
      hasLogo,
      logoDataURL
    } = this.state.whiteLabelInfo;
    return (
      <div
        className=''
        style={{
          display: 'block',
          backgroundColor: '#d1e0e3',
          marginLeft: '50px',
          marginTop: '50px',
          width: '90%',
          borderRadius: '10px',
          overflowX: 'auto',
          overflowY: 'auto'
        }}
      >
        <div style={{padding: '25px', height: '540px'}}>
          <div
            style={{
              fontSize: '18px',
              backgroundColor: '#eef3f4',
              lineHeight: '23px',
              padding: '10px',
              borderRadius: '10px',
              border: 'solid thin  #999999'
            }}
          >
            <span>{preface1}</span>
            <span style={{fontWeight: 'bold'}}>{preface2}</span>
            <span>{preface3}</span>
            <span style={{fontWeight: 'bold'}}><i>{preface4}</i></span>
            <span>{preface5}</span>
          </div>
          <br/>
          <br/>
          <div style={{margin: '0px'}}>
            <table style={{
              width: '100%',
              border: 'solid thin transparent',
              marginTop: '-15px'
            }}>
              <tbody>
              <tr style={{width: '100%'}}>
                <td
                  style={{
                    textAlign: 'right',
                    paddingRight: '60px',
                    border: 'solid thin transparent',
                    paddingBottom: '10px'
                  }}
                >
                  <div>
                    <label htmlFor='companyName'>
                      Company Name&nbsp;&nbsp;
                    </label>
                    <input
                      type='text'
                      style={{width: '250px'}}
                      className='textInputWidgetMatch'
                      id='companyName'
                      name='companyName'
                      onChange={this.handleChange}
                      value={companyName}
                    />
                  </div>
                </td>
                <td
                  style={{
                    border: 'solid thin transparent',
                    textAlign: 'left',
                    paddingBottom: '10px'
                  }}
                >
                  <label htmlFor='companyWebsite'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company
                    Website&nbsp;&nbsp;
                  </label>
                  <input
                    className='textInputWidgetMatch'
                    type='text'
                    style={{width: '250px'}}
                    id='companyWebsite'
                    name='companyWebsite'
                    onChange={this.handleChange}
                    value={companyWebsite}
                  />
                </td>
              </tr>
              <tr style={{width: '100%'}}>
                <td
                  style={{
                    textAlign: 'right',
                    paddingRight: '60px',
                    border: 'solid thin transparent'
                  }}
                >
                  <div>
                    <label htmlFor='fromAddress'>
                      Email From Address&nbsp;&nbsp;
                    </label>
                    <input
                      className='textInputWidgetMatch'
                      type='text'
                      style={{width: '250px'}}
                      id='fromAddress'
                      name='fromAddress'
                      onChange={this.handleChange}
                      value={fromAddress}
                    />
                  </div>
                </td>
                <td
                  style={{
                    border: 'solid thin transparent',
                    textAlign: 'left'
                  }}
                >
                  <label htmlFor='replyToAddress'>
                    Email Reply-to Address&nbsp;&nbsp;
                  </label>
                  <input
                    className='textInputWidgetMatch'
                    type='text'
                    style={{width: '250px'}}
                    id='replyToAddress'
                    name='replyToAddress'
                    onChange={this.handleChange}
                    value={replyToAddress}
                  />
                </td>
              </tr>
              </tbody>
            </table>
            <br/>
            <br/>
            <div style={{
              position: 'relative',
              width: '100%',
              textAlign: 'center',
              top: '0px'
            }}
            >Company Logo: (The logo should look good in a square region 80 pixels wide)
            </div>

            <div style={{margin: 'auto', width: '100%', padding: '5px'}}>
              {!hasLogo ?
                (<div className='logo-uploader'>
                  <DropImage/>
                </div>)
                :
                (<div className='logo-uploader'>
                    <div className='DropImage'>
                      <div className='upload__image-wrapper'
                           style={{position: 'relative', top: '10px'}}>
                        <br/>
                        &nbsp;
                        <div className='image-item'>
                          <img src={logoDataURL} alt='' height='80'/>
                          <div className='image-item__btn-wrapper'>
                            <br/>
                            <button onClick={this.removeLogo}
                                    style={{display: this.state.displayState}}>Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                )}

            </div>
            <br/>
            <div style={{textAlign: 'center'}}>
              <button
                className='blueButton'
                onClick={this.handleSaveAll}
                type='submit'
                style={{
                  position: 'relative',
                  left: '5px',
                  top: '15px',
                  fontSize: '14px'
                }}
              >
                Save All
              </button>
            </div>
            <div style={{
              position: 'relative',
              top: '-45px',
              color: 'red',
              width: '100%',
              textAlign: 'center'
            }}>
              {this.state.changeMessage}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setActiveModule,
      setWhiteLabelInfo,
      getWhiteLabelInfo
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)