import React, {useContext, useState} from 'react';
import {UserContext} from '../Manage';
import CreateOptionsModal from './CreateOptionsModal';
import ModalV2 from '../../ModalV2/ModaV2';
import axios from 'axios';


function EditSettings() {
  const [modalOpen, setModalOpen] = useState(false);
  const accountId = useContext(UserContext).accountId;

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const submitModal = async (data) => {
    try {
      return await axios.put('/admin/saveSettings', {accountId, data})
    } catch (err) {
      console.log(err);
    }
  };

  const modalHeight = 600
  const width = "60%"

  return (
    <div>
      <button className="blueButton" onClick={openModal}>Edit Settings File</button>

      {modalOpen && (
        <ModalV2 width={`${width}`} height={`${modalHeight}px`}
                 style={{display: modalOpen ? 'block' : 'none'}}>
          <CreateOptionsModal onClose={closeModal}
                              onSubmit={submitModal}
                              hasHeader={true}
                              hasFooter={true}
                              hasDismissIcon={false}
                              modalHeight={modalHeight}
          />
        </ModalV2>
      )}
    </div>
  )
}

export default EditSettings;