


import React, {Component} from 'react';
import {Link} from "react-router-dom";
import $ from 'jquery';
//import { gray } from 'd3';

class Overview extends React.Component {
  constructor(props) {
    super(props);
    console.log("Link: ",Link);
    this.state = {
      quickStartVisible: true }
    }

    componentDidMount() {

    }

  simulateClick = elem => {
    let evt = new MouseEvent('click', {
       bubbles: false,
       view: elem.ownerDocument.defaultView
       //view: window
    });
    elem.dispatchEvent(evt);
  };


    hideQuickStart = () => {
      console.log("got clicked");
       if (this.state.quickStartVisible){
         this.setState({quickStartVisible: false})
        } else if (!this.state.quickStartVisible){
          this.setState({quickStartVisible: true})
        }
      }

    linkToCreateHelp = () => {
        console.log("link to Create Help");
        //let helpButtonClick = document.getElementById('help');
        //this.simulateClick(helpButtonClick);   http://localhost:3000/help/create
        console.log(window.location.origin);
        window.location.href = window.location.origin + '/help/create';
      }

      render() {
        // const {user} = this.props;
        $('.subMenuMainPanel').css('overflow','auto');
        if (!this.state.quickStartVisible) {
          $('.QuickStartContainer').css('display','none');
          $('#GetStartedHeading').css('margin-right','0px');
        } else {
          $('.QuickStartContainer').css('display','inline');
          $('#GetStartedHeading').css('margin-right','50px');
        }
        return (
          <div style={{ position: 'relative', display: 'inline', width: '1100px', marginTop: '8px'}}>
            <div>
              <span id='GetStartedHeading'
                    style={{display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      fontSize: '16px',
                      fontFamily: 'sans-serif',
                      marginTop: '22px',
                      backgroundColor: '#EEEEEE',
                      color: 'gray',
                      fontWeight: 'bold',
                      borderTop: 'solid thin lightgray',
                      borderBottom: 'solid thin lightgray',
                      paddingTop: '8px', height: '24px' }}>
                      Getting Started
                </span>
                <span title={this.state.quickStartVisible ? "hide": "show"}
                      style={{position: 'relative',
                              float: 'right',
                              fontSize: '17px',
                              paddingRight: '8px',
                              className: 'tooltiptext',
                              cursor: 'pointer',
                              top: '-25px', }}
                      onClick={this.hideQuickStart}>
                       {this.state.quickStartVisible ?
                        <i className="far fa-minus-square" style={{paddingRight: '0px'}}></i>
                        :
                        <i className= "far fa-plus-square" style={{paddingRight: '16px'}}></i>
                       }
                </span>
              </div>
          <div className="QuickStartContainer">
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{marginTop: '20px', marginLeft: '35px', padding: '15px', width: '400px', minWidth: '400px', backgroundColor: '#d1e7ee', border: 'solid thin transparent'}}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'center', fontWeight: 'bold',paddingTop: '10px'}}>
                  Phase 1: Creation and Deployment
                </div>
              <p>Think of the overall process of managing tests and surveys in three phases:</p>
                <ul>
                  <li>Phase 1: Creation and Deployment</li>
                  <li>Phase 2: Administration</li>
                  <li>Phase 3: Analysis</li>
                </ul>
              <p> In phase 1 you will be using the first 3 tabs on the top nav-bar.</p>
              <p>With the <b>CREATE</b> tab you can create as many tests or surveys as you'd like.
                <Link to={'/help/create'}className='helpLink'><u>more about Create...</u></Link>
              </p>
              <p>When you want to try a test or survey that you've worked on, you will use the <b>MANAGE</b> tab to promote your test or survey from development to deployment. You can't try a test, even one that you are just experimenting with, unless it is deployed.
                <Link to={'/help/surveys'}className='helpLink'><u>more about Manage...</u></Link></p>
              <p>Finally, you will use the <b>ASSIGN</b> tab to enable respondents to take your test or survey.
                <Link to={'/help/assign'}className='helpLink'><u>more about Assign...</u></Link></p>
            </div>  <img src="../gettingStartedOverview.png" alt="" width="650" style={{marginTop: '0px', minWidth: '650px'}}/></div>

          <div style={{height:'20px', width:'auto',borderBottom:'solid thin lightGray'}}>&nbsp;</div>

          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{marginTop: '20px', marginLeft: '15px', padding: '15px', width: '400px', minWidth: '400px', backgroundColor: '#fce5cd', border: 'solid thin transparent'}}>
              <div style={{display: 'flex', width: '100%', justifyContent: 'center', fontWeight: 'bold',paddingTop: '10px'}}> Phase 2: Administration </div>
                <p>Think of the overall process of managing tests and surveys in three phases:</p>
                <ul>
                  <li>Phase 1: Creation and Deployment</li>
                  <li>Phase 2: Administration</li>
                  <li>Phase 3: Analysis</li>
                </ul>
                <p> In phase 1 you will be using the first 3 tabs on the top nav-bar.</p>
                <p>With the <b>CREATE</b> tab you can create as many tests or surveys as you'd like.</p>
                <p>When you want to try a test or survey that you have worked on, you will use the <b>MANAGE</b> tab to promote your test or survey from development to deployment. You can't try a test, even one that you are just experimenting with, unless it is deployed.</p>
                <p>Finally, you will use the <b>ASSIGN</b> tab to enable respondents to take your test or survey.</p>
              </div> <img src="../gettingStartedPhase2.png" alt="" width="650" style={{marginTop: '0px', minWidth: '650px'}}/></div>

          <div style={{height:'20px', width:'auto',borderBottom:'solid thin lightGray'}}>&nbsp;</div>

          <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '20px'}}>
             <div style={{marginTop: '20px', marginLeft: '15px', padding: '15px', width: '400px', minWidth: '400px', backgroundColor: '#d9ead3ff', border: 'solid thin transparent'}}>
              <div style={{display: 'flex', width: '100%', justifyContent: 'center', fontWeight: 'bold',paddingTop: '10px'}}> Phase 3: Analysis </div>
                 <p>Think of the overall process of managing tests and surveys in three phases:</p>
                  <ul>
                    <li>Phase 1: Creation and Deployment</li>
                    <li>Phase 2: Administration</li>
                    <li>Phase 3: Analysis</li>
                  </ul>
                <p> In phase 1 you will be using the first 3 tabs on the top nav-bar.</p>
                <p>With the <b>CREATE</b> tab you can create as many tests or surveys as you'd like.</p>
                <p>When you want to try a test or survey that you have worked on, you will use the <b>MANAGE</b> tab to promote your test or survey from development to deployment. You can't try a test, even one that you are just experimenting with, unless it is deployed.</p>
                <p>Finally, you will use the <b>ASSIGN</b> tab to enable respondents to take your test or survey.</p>
              </div> <img src="../gettingStartedPhase3.png" alt="" width="650" style={{marginTop: '0px', minWidth: '650px'}}/></div>
            <div style={{height:'20px', width:'auto', marginBottom:'20px'}}>&nbsp;</div>
            </div>

          </div>
        )
      }
    }

    export default Overview;
