import React from 'react'
import $ from 'jquery'


var scoringMethods;

class GetAssessmentOption extends React.Component {

  componentDidMount() {
    const {modalInfo} = this.props

    $('.fa-times').css('visibility', 'hidden') //hide the 'X' for exit at the top of the modal to force users to use the bottom buttons
    $('#modal-backdrop').css('minWidth', '1010px')
    this.setStoreFromDbase(modalInfo)
  }

  constructor(props) {
    super(props)
    this.state = {
      ChatGPTDisplay: 'none', //none or block
      keyWordsDisplay: 'none', //none or block
      grammarDisplay: 'none', //none or flex
      pronDisplay: 'none', //none or flex
      sentimentDisplay: 'none',
      chatGPTchkbox: false,
      keywordchkbox: false,
      grammarchkbox: false,
      pronchkbox: false,
      sentimentCheckbox: false,
      chatGPTchkboxDisplay: 'none',
      keywordchkboxDisplay: 'none',
      grammarchkboxDisplay: 'none',
      pronchkboxDisplay: 'none',
      sentimentchkboxDisplay: 'none',
      maxPts: '',
      correctTableRowsArray: [{0: {phrase: '', pct: ''}}], //shows an empty row if no data
      currentCRowNumb: 0,
      wrongTableRowsArray: [{0: {phrase: '', pct: ''}}],
      currentWRowNumb: 0,
      chatRadioUnstructured: true,
      chatGPTstr: '',
      chatGPTassistant: '',
      chatGPTsystem: '',
      chatGPTtemperature: "0.2",
      chatGPTtop_p: "0.1",
      functionCallingDisplay: 'none',
      chatTopic: '',
      chatTopicDesc: '',
      chatElementRowsArray: [{0: {name: '', desc: ''}}],
      chatElementBackupArray: [],
      currentChatRowNumb: 1,
      chatFieldEmptyDisplay: false,
      chatGPTtemplateHTML: '',
      chatGPTlastKeyTextAreaStr: '',
      templateEditorDisplay: false,
      templateEditorMessage: "(advanced -- click to open)",
      templateEditorDirty: false,
      chatErrorMessage: "All type-in fields with * must have entries",
      showSentiment: true

    }
  }

  handleCancel = e => {
    const {hideModal, cancelHandler} = this.props
    e.preventDefault()
    if (cancelHandler) cancelHandler()
    hideModal()
  }

  unpackchatGPTobject = (obj) => {
    let tempArray = [];
    for (var i = 0; i < obj.required.length; i++) {
      let innerObj = {};
      innerObj["name"] = Object.keys(obj.properties)[i];
      innerObj["desc"] = obj.properties[innerObj["name"]].description;
      let outerObj = {};
      outerObj[i] = innerObj;
      tempArray.push(outerObj);
    }
    return tempArray;
  }

  setStoreFromDbase = (modalInfo) => {

    console.log("modalInfo: ", modalInfo);

    if (typeof modalInfo.row.chatGPTPrompt === "string") {  //we don't need this in production but it allows tests before
      //adding checkbox to chatGPT to work
      let tmpStr = JSON.parse(JSON.stringify(modalInfo.row.chatGPTPrompt));
      delete modalInfo.row.chatGPTPrompt;
      if (tmpStr > 0) {
        modalInfo.row.chatGPTPrompt = {'checkbox': true, 'gptPrompt': tmpStr}
      } else {
        modalInfo.row.chatGPTPrompt = {'checkbox': false, 'gptPrompt': ''}
      }
    }

    /* **************************** new data structure ************************** */
    scoringMethods = [
      {
        chatGPT: {
          chatGPTprompt: modalInfo.row.chatGPTPrompt.gptPrompt,
          assistant: modalInfo.row.chatGPTPrompt.assistant,
          temperature: !modalInfo.row.chatGPTPrompt.temperature ?
            "0.2" //default value for temperature
            :
            modalInfo.row.chatGPTPrompt.temperature,
          top_p: !modalInfo.row.chatGPTPrompt.top_p ?
            "0.1" //default value for top_p
            :
            modalInfo.row.chatGPTPrompt.top_p,
          system: modalInfo.row.chatGPTPrompt.system,
          useFunction: modalInfo.row.chatGPTPrompt.useFunction,
          inUse: true,
          checkbox: modalInfo.row.chatGPTPrompt.checkbox,
          functionDescription: modalInfo.row.chatGPTPrompt.functionDescription,
          emailTemplate: modalInfo.row.chatGPTPrompt.emailTemplate
        }
      },
      {
        grammar: {
          selected: modalInfo.row.checkGrammar,
          inUse: true,
          checkbox: modalInfo.row.checkGrammar
        }
      },
      {
        pronunciation: {
          selected: modalInfo.row.checkPronunciation,
          inUse: true,
          checkbox: modalInfo.row.checkPronunciation
        },
      },
      {
        sentiment: {
          selected: modalInfo.row.checkSentiment.checkbox,
          inUse: true,
          checkbox: modalInfo.row.checkSentiment.checkbox,
          displayInEmail: modalInfo.row.checkSentiment.displayInEmail
        },
      },
      {
        scoring: { //keyword scoring
          inUse: true,
          checkbox: modalInfo.row.scoringParameters.checkbox,
          maxPts: modalInfo.row.scoringParameters.maxPts,
          correctAnswers: modalInfo.row.scoringParameters.correctAnswers,
          wrongAnswers: modalInfo.row.scoringParameters.wrongAnswers
        }
      }
    ]

    /* ******************************* load scoringMethods data from modalInfo *********************************** */

    scoringMethods.forEach((item, index) => {
      let firstKey = Object.keys(item)[0]
      switch (firstKey) {
        case 'scoring':
          if (item["scoring"]["inUse"]) {
            this.setState({keywordchkboxDisplay: "inline"})
            if (item["scoring"]["checkbox"] > 0) {
              this.setState({keyWordsDisplay: 'block', keywordchkbox: true})
              this.newloadKeyPhraseScores(item)
            }
          }
          break

        case 'chatGPT':
          if (item["chatGPT"]["inUse"]) {
            this.setState({chatGPTchkboxDisplay: "inline"})
            if (item["chatGPT"]["checkbox"]) {
              let funcDesc = JSON.parse(item.chatGPT.functionDescription);
              let chatGPTfuncProps = funcDesc.parameters;
              let chatGPTfuncArray = this.unpackchatGPTobject(chatGPTfuncProps);
              console.log("chat item: ", item)
              this.setState({
                ChatGPTDisplay: 'block',
                chatGPTchkbox: true,
                chatGPTstr: item.chatGPT.chatGPTprompt,
                chatGPTassistant: item.chatGPT.assistant,
                chatGPTsystem: item.chatGPT.system,
                chatGPTtemperature: item.chatGPT.temperature,
                chatGPTtop_p: item.chatGPT.top_p,
                chatRadioUnstructured: !item.chatGPT.useFunction,
                chatTopic: funcDesc.name,
                chatTopicDesc: funcDesc.description,
                chatElementRowsArray: chatGPTfuncArray,
                currentChatRowNumb: chatGPTfuncArray.length,
                //chatGPTtemplateStr: item.chatGPT.emailTemplate
                chatGPTtemplateHTML: item.chatGPT.emailTemplate
              })

              // this.prepRenderPanel(item.chatGPT.emailTemplate);
              if (item.chatGPT.emailTemplate !== "") this.setState({templateEditorDirty: true});
              if (item.chatGPT.useFunction) {
                this.setState({functionCallingDisplay: "block"});
              } else {
                this.setState({functionCallingDisplay: "none"});
              }
            }
          }
          break

        case 'grammar':
          if (item["grammar"]["inUse"]) {
            this.setState({grammarchkboxDisplay: "inline"})
            if (item["grammar"]["checkbox"]) {
              this.setState({grammarDisplay: 'flex', grammarchkbox: true})
            }
          }
          break

        case 'pronunciation':
          if (item["pronunciation"]["inUse"]) {
            this.setState({pronchkboxDisplay: "inline"})
            if (item["pronunciation"]["checkbox"]) {
              this.setState({pronDisplay: 'flex', pronchkbox: true})
            }
          }
          break
        case 'sentiment':
          if (item["sentiment"]["inUse"]) {
            this.setState({sentimentchkboxDisplay: "inline"})
            if (item["sentiment"]["checkbox"]) {
              this.setState({sentimentDisplay: 'flex', sentimentCheckbox: true})
            }
          }
          break
        default:
          console.log('Unknown key')
      }
    })
  }

  newloadKeyPhraseScores = object => {

    let Pts = object.scoring.maxPts
    this.setState({maxPts: Pts})


    let emptyRowArray = [{0: {phrase: '', pct: ''}}]
    let rowObjArray = []
    object.scoring.correctAnswers.forEach((item, index) => {
      var key = index.toString()
      var obj = {}
      obj[key] = {phrase: item.phrase, pct: item.pct}
      rowObjArray.push(obj)
    })
    this.setState({correctTableRowsArray: rowObjArray})
    this.setState({currentCRowNumb: rowObjArray.length})


    if (rowObjArray.length === 0)
      this.setState({correctTableRowsArray: emptyRowArray})


    rowObjArray = []
    object.scoring.wrongAnswers.forEach((item, index) => {
      var key = index.toString()
      var obj = {}
      obj[key] = {phrase: item.phrase, pct: item.pct}
      rowObjArray.push(obj)
    })
    this.setState({wrongTableRowsArray: rowObjArray})
    this.setState({currentWRowNumb: rowObjArray.length})

    if (rowObjArray.length === 0)
      this.setState({wrongTableRowsArray: emptyRowArray})
  }

  scrollToTop = () => {
    const element = document.getElementById('rightPanel');
    element.scrollTop = 0;
  }

  handleSave = e => {
    const {hideModal, confirmHandler, modalInfo} = this.props
    e.preventDefault();

    /* **************  make sure all needed chatGPT fields have entries  ************ */
    let okToSave = true;
    if ((this.state.chatGPTchkbox) && (!this.state.chatRadioUnstructured)) {
      this.setState({chatFieldEmptyDisplay: false});
      if (this.state.chatGPTstr === "") okToSave = false;
      if (this.state.chatTopic === "") okToSave = false;
      if (this.state.chatTopicDesc === "") okToSave = false;

      /* **** in the elements array, we need to iterate through outer, then inner keys to check the values ***** */
      /* **** can't assume that the enumerated keys are consecutive ******************************************** */
      Object.entries(this.state.chatElementRowsArray).forEach(([firstKey, secondKey]) => {
        let tempObj = JSON.parse(JSON.stringify(secondKey));
        Object.entries(tempObj).forEach(([firstKey, secondKey]) => {
            if ((secondKey.name === "") || (secondKey.desc === "")) okToSave = false;
          }
        )
      });
    }

    if (!okToSave) {
      /* scroll to top to show incomplete field */
      this.scrollToTop();
      this.setState({chatFieldEmptyDisplay: true});
    } else if (confirmHandler) {

      // --------------  get the correct keywords and percents and update modalInfo  ----------

      let correctPhraseSet = JSON.parse(
        JSON.stringify(this.state.correctTableRowsArray)
      )

      let correctAnswerArray = []
      const correctAnswerObj = {}

      correctPhraseSet.forEach((item, index) => {
        let firstKey = Object.keys(item)[0]
        let phrase = item[firstKey].phrase.trim()
        let pct = item[firstKey].pct.trim()
        if (
          (phrase === '' || phrase === ' ') &&
          (pct === '' || pct === ' ')
        ) {
          //just skip this row assume user just left some blank rows in the table
        } else if (
          phrase === '' ||
          phrase === ' ' ||
          pct === '' ||
          pct === ' ' ||
          isNaN(pct) ||
          parseInt(pct) < 1 ||
          parseInt(pct) > 100
        ) {
          //  'Both phrase and pct must be specified for every row and the pct must be a number between 1 and 100'

        } else {
          //it's a keeper
          correctAnswerObj[index] = {
            phrase: phrase,
            pct: pct
          }
          correctAnswerArray.push(correctAnswerObj[index])
        }
      })

      let wrongPhraseSet = JSON.parse(
        JSON.stringify(this.state.wrongTableRowsArray)
      )
      let wrongAnswerArray = []
      const wrongAnswerObj = {}

      wrongPhraseSet.forEach((item, index) => {
        let firstKey = Object.keys(item)[0]
        let phrase = item[firstKey].phrase.trim()
        let pct = item[firstKey].pct.trim()
        if (
          (phrase === '' || phrase === ' ') &&
          (pct === '' || pct === ' ')
        ) {
          //just skip this row assume user just left some blank rows in the table
        } else if (
          phrase === '' ||
          phrase === ' ' ||
          pct === '' ||
          pct === ' ' ||
          isNaN(pct) ||
          parseInt(pct) < 1 ||
          parseInt(pct) > 100
        ) {
          //  'Both keyPhrase and pct must be specified for every row and the pct must be a number between 1 and 100'
        } else {
          //it's a keeper
          wrongAnswerObj[index] = {
            phrase: phrase,
            pct: pct
          }
          wrongAnswerArray.push(wrongAnswerObj[index])
        }
      })

      /* *********************************  chatGPT save support *************************** */

      let parameters = this.buildChatGPTkeyValObject();

      let emailTemplate = "";
      if (this.state.templateEditorDirty) {
        emailTemplate = this.state.chatGPTtemplateHTML;
      } else {
        emailTemplate = this.getChatDefaultTemplate();
      }

      const chatGPTParms = {
        name: this.state.chatTopic,
        description: this.state.chatTopicDesc,
        parameters: parameters
      }
      const chatGPTString = JSON.stringify(chatGPTParms);

      /* *********************************  chatGPT save support *************************** */

      modalInfo.row.scoringParameters.correctAnswers = correctAnswerArray;
      modalInfo.row.scoringParameters.wrongAnswers = wrongAnswerArray;
      modalInfo.row.scoringParameters.maxPts = this.state.maxPts;
      modalInfo.row.scoringParameters.checkbox = this.state.keywordchkbox;

      modalInfo.row.chatGPTPrompt.checkbox = this.state.chatGPTchkbox;
      modalInfo.row.chatGPTPrompt.gptPrompt = this.state.chatGPTstr;
      modalInfo.row.chatGPTPrompt.assistant = this.state.chatGPTassistant;
      modalInfo.row.chatGPTPrompt.temperature = this.state.chatGPTtemperature;
      modalInfo.row.chatGPTPrompt.top_p = this.state.chatGPTtop_p;
      modalInfo.row.chatGPTPrompt.system = this.state.chatGPTsystem;
      modalInfo.row.chatGPTPrompt.useFunction = !this.state.chatRadioUnstructured;
      modalInfo.row.chatGPTPrompt.functionDescription = chatGPTString;
      modalInfo.row.chatGPTPrompt.emailTemplate = emailTemplate;

      modalInfo.row.checkGrammar = this.state.grammarchkbox;
      modalInfo.row.checkPronunciation = this.state.pronchkbox;
      modalInfo.row.checkSentiment.checkbox = this.state.sentimentCheckbox;
      modalInfo.row.checkSentiment.displayInEmail = this.state.showSentiment;

      confirmHandler(modalInfo)

      hideModal()
    }
  }

  buildChatGPTkeyValObject = () => {
    let tempArray = this.state.chatElementRowsArray;
    const parameters = {};
    parameters["type"] = "object";
    parameters.required = [];
    parameters["properties"] = {};
    for (const object of tempArray) {
      const firstKey = Object.keys(object)[0];
      parameters.properties[object[firstKey].name] = {};
      parameters.properties[object[firstKey].name]["type"] = "string";
      parameters.properties[object[firstKey].name]["description"] = object[firstKey].desc;
      parameters.required.push(object[firstKey].name);
    }
    return parameters;
  }

  getChatDefaultTemplate = () => {
    let parameters = this.buildChatGPTkeyValObject();
    let templateStart = "<div style='color: #5f62c5; font-weight: bold;'>Assessment Elements:</div><br/>" +
      "<div style='color: black; font-weight: normal; padding-left: 15px;'>";
    let templateMiddle = "";
    let templateEnd = "</div><div style='padding-left: 15px;'>&nbsp;</div>";
    let defaultTemplate = templateStart
    for (var i = 0; i < parameters.required.length; i++) {
      if (parameters.required[i].length > 0) {
        templateMiddle = "<div>" +
          parameters.required[i] + ": " + parameters.required[i] + ":value</div>";
        defaultTemplate = defaultTemplate + templateMiddle;
      }
    }
    defaultTemplate = defaultTemplate + templateEnd;
    return defaultTemplate
  }

  /* ********************************* MaxPts = Keyword Scoring Method Functions ************************** */

  handleMaxPtsBlur = e => {
    this.setState({maxPts: e.target.innerText})
  }

  /*  ******************************** functions to handle correct answer table *************************** */

  handleAddOneCorrectRow = () => {
    let rowObj = this.createCrowObj()
    let tempArray = JSON.parse(JSON.stringify(this.state.correctTableRowsArray))
    tempArray.push(rowObj)
    this.setState({correctTableRowsArray: tempArray})
  }

  createCrowObj = () => {
    let rowObjArray = [{}]
    let nextIndex = this.state.currentCRowNumb + 1
    this.setState({currentCRowNumb: nextIndex})
    let newKey = nextIndex.toString()
    let newVal = {phrase: '', pct: ''}
    rowObjArray.forEach(obj => {
      obj[newKey] = newVal
    })

    return rowObjArray[0]
  }

  updateCrowObject(key, text, valueType) {
    let tmpArray = JSON.parse(JSON.stringify(this.state.correctTableRowsArray))
    let newArray = []
    const hasKey = (obj, key) => obj.hasOwnProperty(key)
    tmpArray.forEach((item, index) => {
      if (hasKey(item, key)) {
        if (valueType === 'phrase') {
          item[key].phrase = text
        } else {
          item[key].pct = text
        }
      }
      newArray.push(item)
    })
    this.setState({correctTableRowsArray: newArray})
  }

  deleteCTableRowByKey = key => {
    let tmpArray = JSON.parse(JSON.stringify(this.state.correctTableRowsArray))
    let newArray = []
    const hasKey = (obj, key) => obj.hasOwnProperty(key)
    tmpArray.forEach((item, index) => {
      if (!hasKey(item, key)) newArray.push(item) //only copy objs not deleted
    })
    this.setState({correctTableRowsArray: newArray})
  }

  handleCDeleteMyRow = e => {
    var elementId = e.target.id
    let objKey = elementId.substring(7, elementId.length)
    let CRowEntries = this.state.currentCRowNumb - 1;
    this.deleteCTableRowByKey(objKey)
    this.setState({currentCRowNumb: CRowEntries})
  }

  handleCPhraseBlur = e => {
    var elementId = e.target.id
    let objKey = elementId.substring(7, elementId.length)
    this.updateCrowObject(objKey, e.target.innerText, 'phrase')
  }

  handleCWeightBlur = e => {
    var elementId = e.target.id
    let objKey = elementId.substring(7, elementId.length)
    this.updateCrowObject(objKey, e.target.innerText, 'pct')
  }

  getCorrectTableRows = (element, index) => {
    const firstKey = Object.keys(element)[0]
    let iDeleteId = 'iDelObj' + firstKey
    let cPhraseId = 'cPhrase' + firstKey
    let cWeightId = 'cWeight' + firstKey
    let phrase = element[firstKey].phrase
    let pct = element[firstKey].pct

    return (
      <tr key={index} style={{width: '100%'}}>
        <td style={{width: '5%'}}>
          <div className='deleteIcon' onClick={this.handleCDeleteMyRow}>
            <span className='hover-control'>
              <i
                className='far fa-minus-square'
                id={iDeleteId}
                title='delete this row'
              ></i>
              <i
                className='fas fa-minus-square'
                id={iDeleteId}
                title='delete this row'
              ></i>
            </span>
          </div>
        </td>
        <td
          className='Ckeyphrase'
          id={cPhraseId}
          placeholder='Enter keyword or phrase'
          contenteditable='true'
          onBlur={this.handleCPhraseBlur}
          style={{
            width: '85%',
            backgroundColor: 'white',
            border: 'solid thin gray',
            textAlign: 'left',
            paddingLeft: '5px'
          }}
        >
          {phrase}
        </td>
        <td
          className='Cpct'
          id={cWeightId}
          placeholder='?'
          contenteditable='true'
          onBlur={this.handleCWeightBlur}
          style={{
            width: '10%',
            backgroundColor: 'white',
            border: 'solid thin gray',
            textAlign: 'center'
          }}
        >
          {pct}
        </td>
      </tr>
    )
  }

  /*  ******************************** functions to handle wrong answer table *************************** */

  handleAddOneWrongRow = () => {
    let rowObj = this.createWrowObj()
    let tempArray = JSON.parse(JSON.stringify(this.state.wrongTableRowsArray))
    tempArray.push(rowObj)
    this.setState({wrongTableRowsArray: tempArray})
  }

  createWrowObj = () => {
    let rowObjArray = [{}]
    let nextIndex = this.state.currentWRowNumb + 1
    this.setState({currentWRowNumb: nextIndex})
    let newKey = nextIndex.toString()
    let newVal = {phrase: '', pct: ''}
    rowObjArray.forEach(obj => {
      obj[newKey] = newVal
    })
    return rowObjArray[0]
  }

  deleteWTableRowByKey = key => {
    let tmpArray = JSON.parse(JSON.stringify(this.state.wrongTableRowsArray))
    let newArray = []
    const hasKey = (obj, key) => obj.hasOwnProperty(key)
    tmpArray.forEach((item, index) => {
      if (!hasKey(item, key)) newArray.push(item) //only copy objs not deleted
    })
    this.setState({wrongTableRowsArray: newArray})
  }

  handleWDeleteMyRow = e => {
    var elementId = e.target.id
    let objKey = elementId.substring(7, elementId.length)
    let WRowEntries = this.state.currentWRowNumb - 1;
    this.deleteWTableRowByKey(objKey)
    this.setState({currentCRowNumb: WRowEntries})
  }

  updateWrowObject(key, text, valueType) {
    let tmpArray = JSON.parse(JSON.stringify(this.state.wrongTableRowsArray))
    let newArray = []
    const hasKey = (obj, key) => obj.hasOwnProperty(key)
    tmpArray.forEach((item, index) => {
      if (hasKey(item, key)) {
        if (valueType === 'phrase') {
          item[key].phrase = text
        } else {
          item[key].pct = text
        }
      }
      newArray.push(item)
    })
    this.setState({wrongTableRowsArray: newArray})
  }

  handleWPhraseBlur = e => {
    var elementId = e.target.id
    let objKey = elementId.substring(7, elementId.length)
    this.updateWrowObject(objKey, e.target.innerText, 'phrase')
  }

  handleWWeightBlur = e => {
    var elementId = e.target.id
    let objKey = elementId.substring(7, elementId.length)
    this.updateWrowObject(objKey, e.target.innerText, 'pct')
  }

  getWrongTableRows = (element, index) => {
    const firstKey = Object.keys(element)[0]
    let iDeleteId = 'iDelObj' + firstKey
    let wPhraseId = 'wPhrase' + firstKey
    let wWeightId = 'wWeight' + firstKey
    let phrase = element[firstKey].phrase
    let pct = element[firstKey].pct

    return (
      <tr key={index} style={{width: '100%'}}>
        <td style={{width: '5%'}}>
          <div className='deleteIcon' onClick={this.handleWDeleteMyRow}>
            <span className='hover-control'>
              <i
                className='far fa-minus-square'
                id={iDeleteId}
                title='delete this row'
              ></i>
              <i
                className='fas fa-minus-square'
                id={iDeleteId}
                title='delete this row'
              ></i>
            </span>
          </div>
        </td>
        <td
          className='Wkeyphrase'
          id={wPhraseId}
          placeholder='Enter keyword or phrase'
          contenteditable='true'
          onBlur={this.handleWPhraseBlur}
          style={{
            width: '85%',
            backgroundColor: 'white',
            border: 'solid thin gray',
            textAlign: 'left',
            paddingLeft: '5px'
          }}
        >
          {phrase}
        </td>
        <td
          className='Wpct'
          id={wWeightId}
          placeholder='?'
          contenteditable='true'
          onBlur={this.handleWWeightBlur}
          style={{
            width: '10%',
            backgroundColor: 'white',
            border: 'solid thin gray',
            textAlign: 'center'
          }}
        >
          {pct}
        </td>
      </tr>
    )
  }

  /* ************************************ functions to handle chatGPT method *********************************************** */

  insertPrompt = e => {
    const {modalInfo} = this.props;
    let question = modalInfo.question;
    const clickedElement = e.target;
    const textContent = clickedElement.textContent;
    let insertStr = ' "$[' + question + ']" ';

    if (textContent.indexOf('response') !== -1) {
      insertStr = ' "$[transcribed response]" ';
    }
    const chatGPTtextarea = document.getElementById('GPTPromptTextArea')
    if (chatGPTtextarea.selectionStart !== null) {
      let start = chatGPTtextarea.selectionStart;
      let end = chatGPTtextarea.selectionEnd;
      let text = chatGPTtextarea.value;
      let newText = text.slice(0, start) + insertStr + text.slice(end);
      newText = newText.replace(/ {2,}/g, " "); //replace two adjacent space chars with one
      chatGPTtextarea.value = newText;
      this.setState({chatGPTstr: newText})
      // this.state.chatGPTstr = newText; changed from this line to line above.
    }
  }


  handleChangechatGPTchkbox = () => {
    if (this.state.ChatGPTDisplay === 'none') {
      this.setState({ChatGPTDisplay: 'block', chatGPTchkbox: true})
    } else {
      this.setState({ChatGPTDisplay: 'none', chatGPTchkbox: false})
    }
  }

  handleChangeKeywordchkbox = () => {
    if (this.state.keyWordsDisplay === 'none') {
      this.setState({keyWordsDisplay: 'block', keywordchkbox: true})
    } else {
      this.setState({keyWordsDisplay: 'none', keywordchkbox: false})
    }
  }

  handleChangeGrammarchkbox = () => {
    if (this.state.grammarDisplay === 'none') {
      this.setState({grammarDisplay: 'flex', grammarchkbox: true})
    } else {
      this.setState({grammarDisplay: 'none', grammarchkbox: false})
    }
    setTimeout(function () {
      //scroll grammer explanation box into right panel
      //timeout needed to allow time (1/10th sec) for dom to insert scrollbar
      //and adjust div height.
      var divHeight = $('.SetScoreRightPanel')[0].scrollHeight
      $('.SetScoreRightPanel').animate({scrollTop: divHeight}, 'slow')
    }, 100)
  }

  handleChangePronunchkbox = () => {
    if (this.state.pronDisplay === 'none') {
      this.setState({pronDisplay: 'flex', pronchkbox: true})
    } else {
      this.setState({pronDisplay: 'none', pronchkbox: false})
    }
    setTimeout(function () {
      //scroll grammer explanation box into right panel
      //timeout needed to allow time (1/10th sec) for dom to insert scrollbar
      //and adjust div height.
      var divHeight = $('.SetScoreRightPanel')[0].scrollHeight
      $('.SetScoreRightPanel').animate({scrollTop: divHeight}, 'slow')
    }, 100)
  }

  handleChangeSentimentchkbox = () => {
    if (this.state.sentimentDisplay === 'none') {
      this.setState({sentimentDisplay: 'flex', sentimentCheckbox: true})
    } else {
      this.setState({sentimentDisplay: 'none', sentimentCheckbox: false})
    }
    setTimeout(function () {
      //scroll sentiment explanation box into right panel
      //timeout needed to allow time (1/10th sec) for dom to insert scrollbar
      //and adjust div height.
      var divHeight = $('.SetScoreRightPanel')[0].scrollHeight
      $('.SetScoreRightPanel').animate({scrollTop: divHeight}, 'slow')
    }, 100)
  }

  handleShowSentiment = (e) => {
    const {checked} = e.target;
    console.log(checked);
    this.setState({showSentiment: checked})
  }

  getCheckBoxHTML = () => {
    return (
      <div
        className='leftPanel'
        style={{
          border: 'solid thin transparent',
          marginTop: '20px',
          width: '20%'
        }}
      >
        <span
          id='methodsTitle'
        >
          Select Scoring Method
        </span>

        <div id='methodsList'/>

        <div className='checkBoxContainer'
             style={{display: this.state.chatGPTchkboxDisplay}}
        >
          <input
            type='checkbox'
            id='chatgpt'
            name='chatgpt'
            value='1'
            checked={this.state.chatGPTchkbox}
            onChange={this.handleChangechatGPTchkbox}
          />
          <label className='checkBoxLabel'>
            &nbsp;ChatGPT &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </label>
        </div>

        <div className='checkBoxContainer'
             style={{display: this.state.grammarchkboxDisplay}}
        >
          <input
            type='checkbox'
            id='grammar'
            name='grammar'
            value='3'
            checked={this.state.grammarchkbox}
            onChange={this.handleChangeGrammarchkbox}
          />
          <label className='checkBoxLabel' style={{}}>
            &nbsp;Grammar Errors
          </label>
        </div>

        <div className='checkBoxContainer'
             style={{display: this.state.pronchkboxDisplay}}
        >
          <input
            type='checkbox'
            id='pronunciation'
            name='pronunciation'
            value='4'
            checked={this.state.pronchkbox}
            onChange={this.handleChangePronunchkbox}
          />
          <label className='checkBoxLabel' style={{}}>
            &nbsp;Pronunciation Quality
          </label>
        </div>
        <div className='checkBoxContainer'
             style={{display: this.state.sentimentchkboxDisplay}}
        >
          <input
            type='checkbox'
            id='sentiment'
            name='sentiment'
            value='5'
            checked={this.state.sentimentCheckbox}
            onChange={this.handleChangeSentimentchkbox}
          />
          <label className='checkBoxLabel' style={{}}>
            &nbsp;Sentiment Analysis
          </label>
        </div>
        <div className='checkBoxContainer'
             style={{display: this.state.keywordchkboxDisplay}}
        >
          <input
            type='checkbox'
            id='keywords'
            name='keywords'
            value='2'
            checked={this.state.keywordchkbox}
            onChange={this.handleChangeKeywordchkbox}
          />
          <label className='checkBoxLabel' style={{}}>
            &nbsp;Keywords
          </label>
        </div>
      </div>
    )
  }

  /* ************************************* Chat GPT functions ************************************* */

  removeInvalidChars = (functionName) => {
    return functionName.replace(/\W|^(?=\d)/g, '');
  }

  handleAddOneChatRow = () => {
    let rowObj = this.createChatObj()
    let tempArray = JSON.parse(JSON.stringify(this.state.chatElementRowsArray))
    tempArray.push(rowObj)
    this.setState({chatElementRowsArray: tempArray})
  }

  createChatObj = () => {
    let rowObjArray = [{}]
    let nextIndex = JSON.parse(JSON.stringify(this.state.currentChatRowNumb));
    let newKey = nextIndex.toString();
    let newVal = {name: '', desc: ''}
    rowObjArray.forEach(obj => {
      obj[newKey] = newVal
    })
    nextIndex++;
    this.setState({currentChatRowNumb: nextIndex})
    return rowObjArray[0]
  }

  deleteChatRowByKey = key => {
    let tmpArray = JSON.parse(JSON.stringify(this.state.chatElementRowsArray))
    let newArray = []
    const hasKey = (obj, key) => obj.hasOwnProperty(key)
    tmpArray.forEach((item, index) => {
      if (!hasKey(item, key)) newArray.push(item) //only copy objs not deleted
    })
    this.setState({chatElementRowsArray: newArray})
  }

  updateTemplateAfterDelete = key => {
    const hasKey = (obj, key) => obj.hasOwnProperty(key)
    let targetStr = "";
    this.state.chatElementRowsArray.forEach((item, index) => {
      if (hasKey(item, key)) {
        if (item[key].name !== "") {
          targetStr = item[key].name + ": " + item[key].name + ":value";
        }
      }
    })
    if (targetStr !== "") {
      let tempHTML = this.state.chatGPTtemplateHTML;
      let tempHTMLarray = tempHTML.split(targetStr);
      tempHTMLarray[1] = tempHTMLarray[1].slice(6);
      let tempHTMLarray2 = tempHTMLarray[0].split("<div");
      let tempHTML2 = "";
      for (var i = 1; i < tempHTMLarray2.length - 1; i++) {
        tempHTML2 = tempHTML2 + "<div" + tempHTMLarray2[i];
      }
      let modifiedChatHTML = tempHTML2 + tempHTMLarray[1];
      this.setState({chatGPTtemplateHTML: modifiedChatHTML});
    }
  }

  handleDeleteChatRow = e => {
    if (this.state.chatElementRowsArray.length > 1) { //don't delete the last array object
      var elementId = e.target.id;
      let objKey = elementId.substring(7, elementId.length);
      this.updateTemplateAfterDelete(objKey);
      this.deleteChatRowByKey(objKey);
    }
  }

  getChatIndex = (tempArray, chatObj) => {
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].hasOwnProperty(chatObj)) {
        return i;
      }
    }
    return -1; // Return -1 if no match is found
  }

  handleUpdateChatName = e => {
    let chatObj = e.target.id.substring(3);
    let tempArray = JSON.parse(JSON.stringify(this.state.chatElementRowsArray));
    let index = this.getChatIndex(tempArray, chatObj);
    let filteredName = this.removeInvalidChars(e.target.value)
    tempArray[index][chatObj].name = filteredName;
    this.setState({chatElementRowsArray: tempArray});
  }

  handleChatNameFocus = e => {
    let tempArray = JSON.parse(JSON.stringify(this.state.chatElementRowsArray));
    this.setState({chatElementBackupArray: tempArray});
    this.setState({
      chatGPTlastKeyTextAreaStr: e.target.value,
      chatFieldEmptyDisplay: false,
      chatErrorMessage: "All type-in fields with * must have entries"
    });
  }

  handleChatNameBlur = e => {
    if (this.state.chatGPTtemplateHTML === "") {
      let tempTemplate = this.getChatDefaultTemplate();
      this.setState({
        chatGPTtemplateHTML: tempTemplate
      });
    } else if (this.state.chatGPTlastKeyTextAreaStr.length === 0) { //new entry in existing template
      let insertStr = "<div>" +
        e.target.value + ": " + e.target.value + ":value</div>";

      if (!this.state.chatGPTtemplateHTML.includes(insertStr)) {  //make sure it's not there already
        //find the place of this type-in field among the set of fields
        let keysArray = [];
        let ourIndex = 0;
        let priorElementLabel = "";
        for (let i = 0; i < this.state.chatElementRowsArray.length; i++) {
          let value = Object.values(this.state.chatElementRowsArray[i])[0];
          let name = value["name"];
          if (e.target.value === name) {
            ourIndex = i;
          }
          keysArray.push(name);
        }
        let eArray = [];
        if (keysArray.length > 1) {
          for (var i = 0; i < ourIndex; i++) {
            eArray.push(keysArray[i]);
          }
          if (eArray.length === 0) { //needs to be inserted as the new first element
            //if ((ourIndex === 0) && (keysArray.length > 1)) { //more than one key, find the first one
            if ((ourIndex === 0) && (keysArray.length > 1) && (e.target.value.length > 0)) { //more than one key, find the first one
              let insertStr = "<div>" + e.target.value + ": " + e.target.value + ":value</div>";
              let tempHTML = this.state.chatGPTtemplateHTML;
              let tempArray1 = tempHTML.split(":value");
              let tempArray2 = tempArray1[0].split("<div");
              tempArray2[tempArray2.length - 2] = tempArray2[tempArray2.length - 2] + insertStr;
              let newHTML2 = "";
              for (var i = 1; i < tempArray2.length; i++) {
                newHTML2 = newHTML2 + "<div" + tempArray2[i];
              }
              let newHTML3 = "";
              for (var i = 1; i < tempArray1.length; i++) {
                newHTML3 = newHTML3 + ":value" + tempArray1[i];
              }
              tempHTML = newHTML2 + newHTML3;
              this.setState({chatGPTtemplateHTML: tempHTML});
            }
          } else if (e.target.value.length > 0) { //not the first element
            let foundTarget = false;
            eArray.reverse().forEach(function (element, index) {
              if ((element !== "") && (!foundTarget)) {
                priorElementLabel = element;
                foundTarget = true;
              }
            });
            let findStr = priorElementLabel + ": " + priorElementLabel + ":value";
            let tempHTML = this.state.chatGPTtemplateHTML;
            let splitString = findStr + "</div>";
            let tempArray = tempHTML.split(splitString);
            let newHTML = tempArray[0] + splitString + insertStr + tempArray[1];
            this.setState({chatGPTtemplateHTML: newHTML});
          }
        } else {
          let tempHTML = this.state.chatGPTtemplateHTML;
          let tempHTMLarray = tempHTML.split("</div><br/>");
          let tempHTML2 = "";
          for (var i = 1; i < tempHTMLarray.length; i++) {
            tempHTML2 = tempHTML2 + tempHTMLarray[i];
          }
          let modifiedChatHTML = tempHTMLarray[0] + "</div><br/>" + insertStr + tempHTML2;
          this.setState({chatGPTtemplateHTML: modifiedChatHTML});
        }
      } else { //this key already exists - say there can't be two of the same
        //DUPLICATE
        let chatObj = e.target.id.substring(3);
        let tempArray = JSON.parse(JSON.stringify(this.state.chatElementRowsArray));
        let index = this.getChatIndex(tempArray, chatObj);
        tempArray[index][chatObj].name = ""; //e.target.value;
        this.setState({chatElementRowsArray: tempArray});
        this.setState({
          chatErrorMessage: "This name is already used in another location.",
          chatFieldEmptyDisplay: true
        });
      }
    } else if ((this.state.chatGPTlastKeyTextAreaStr.length > 0) && (e.target.value.length > 0)) {
      // it must be a tag that was just modified but it can't have been completely erased
      let keysArray = [];
      let ourIndex = 0;

      for (let i = 0; i < this.state.chatElementRowsArray.length; i++) {
        let value = Object.values(this.state.chatElementRowsArray[i])[0];
        let name = value["name"];
        if (e.target.value === name) {
          ourIndex = i;
        }
        keysArray.push(name);
      }

      const count = keysArray.filter(item => item === e.target.value).length;
      if (count > 1) { //created DUPLICATES
        let tempArray = JSON.parse(JSON.stringify(this.state.chatElementBackupArray));
        this.setState({chatElementRowsArray: tempArray});
        this.setState({
          chatErrorMessage: "This name is already used in another location.",
          chatFieldEmptyDisplay: true
        });
      } else {  //edited existing key
        let tempHTML = this.state.chatGPTtemplateHTML;
        let findStr = this.state.chatGPTlastKeyTextAreaStr + ": " +
          this.state.chatGPTlastKeyTextAreaStr + ":value";
        let replaceStr = e.target.value + ": " + e.target.value + ":value";
        var modifiedChatHTML = tempHTML.replace(findStr, replaceStr);
        this.setState({chatGPTtemplateHTML: modifiedChatHTML});
      }

    } else if ((this.state.chatGPTlastKeyTextAreaStr.length > 0) && (e.target.value.length === 0)) {
      //text of field was deleted
      let targetStr = this.state.chatGPTlastKeyTextAreaStr + ": " + this.state.chatGPTlastKeyTextAreaStr + ":value";
      let tempHTML = this.state.chatGPTtemplateHTML;
      let tempHTMLarray = tempHTML.split(targetStr);
      tempHTMLarray[1] = tempHTMLarray[1].slice(6);
      let tempHTMLarray2 = tempHTMLarray[0].split("<div");
      let tempHTML2 = "";
      for (var i = 1; i < tempHTMLarray2.length - 1; i++) {
        tempHTML2 = tempHTML2 + "<div" + tempHTMLarray2[i];
      }
      let modifiedChatHTML = tempHTML2 + tempHTMLarray[1];
      this.setState({chatGPTtemplateHTML: modifiedChatHTML});
    }
  }

  handleUpdateChatDesc = e => {
    let chatObj = e.target.id.substring(4);
    let tempArray = JSON.parse(JSON.stringify(this.state.chatElementRowsArray));
    let index = this.getChatIndex(tempArray, chatObj);
    tempArray[index][chatObj].desc = e.target.value;
    this.setState({chatElementRowsArray: tempArray});
  }

  getChatRows = (element, index) => {
    const firstKey = Object.keys(element)[0]
    let iDeleteId = 'iDelObj' + firstKey

    return (
      <tr>
        <td className='ElementTableCol0'>
          <div className='deleteIcon' on onClick={this.handleDeleteChatRow}>
          <span className='hover-control'>
            <i
              className='far fa-minus-square'
              id={iDeleteId}
              title='delete this element'
            ></i>
            <i
              className='fas fa-minus-square'
              id={iDeleteId}
              title='delete this element'
            ></i>
          </span>
          </div>
        </td>
        <td className='ElementTableCol1'>
          <span>NAME*</span>
        </td>
        <td className='ElementTableCol2'>
          <textarea className='GPTTopicText'
                    id={"key" + firstKey}
                    value={element[firstKey].name}
                    placeholder="One word or linked by_underscores."
                    onChange={this.handleUpdateChatName}
                    onBlur={this.handleChatNameBlur}
                    onFocus={this.handleChatNameFocus}
          />
        </td>
        <td className='ElementTableCol3'>
          <span className='GPTTopicDescriptionLabel'>DESCRIPTION*</span>
        </td>
        <td className='ElementTableCol4'>
          <textarea className='GPTTopicDescriptionText'
                    id={"desc" + firstKey}
                    value={element[firstKey].desc}
                    placeholder="Enter a description of this element."
                    onChange={this.handleUpdateChatDesc}
          />
        </td>
      </tr>
    )
  }

  handleChatRadioChange = () => {
    if (this.state.chatRadioUnstructured) {
      this.setState({chatRadioUnstructured: false, functionCallingDisplay: 'block'})
    } else {
      this.setState({chatRadioUnstructured: true, functionCallingDisplay: 'none'})
    }
  }

  handleChatTextChange = e => {
    this.setState({chatGPTstr: e.target.value})
  }

  handleGPTAssistantChange = e => {
    this.setState({chatGPTassistant: e.target.value});
  }

  handleGPTtemperatureChange = e => {
    this.setState({chatGPTtemperature: e.target.value});
    console.log("temperature: ", this.state.chatGPTtemperature);
  }

  handleGPTtop_pChange = e => {
    this.setState({chatGPTtop_p: e.target.value});
    console.log("top_p: ", this.state.chatGPTtop_p);
  }

  handleGPTSystemChange = e => {
    this.setState({chatGPTsystem: e.target.value});
  }

  handleGPTTopicChange = e => {
    let filteredName = this.removeInvalidChars(e.target.value)
    this.setState({chatTopic: filteredName});
  }

  handleGPTTopicDescChange = e => {
    this.setState({chatTopicDesc: e.target.value});
  }

  handleTemplateEditorClick = e => {
    if (this.state.templateEditorDisplay) {
      this.setState({
        templateEditorDisplay: false,
        templateEditorMessage: "(advanced -- click to open)"
      });
    } else {
      this.setState({
        templateEditorDisplay: true,
        templateEditorMessage: "(click to close)"
      });
    }
  }

  handleResetClick = e => {
    // if (e.target.value.length > 0) {
    let defaultTemplate = this.getChatDefaultTemplate();
    this.setState({chatGPTtemplateHTML: defaultTemplate, templateEditorDirty: true});
    this.setState({
      templateEditorDisplay: true,
      templateEditorMessage: "(click to close)"
    });
    // }
  }

  prepRenderPanel = (html) => {
    this.setState({chatGPTtemplateHTML: html});
  }

  updateRenderPanel = e => {
    this.setState({chatGPTtemplateHTML: e.target.value, templateEditorDirty: true});
  }

  getChatGPTHTML = () => {
    return (
      <div className='ChatGPTsection' id='ChatGPTsection'
           style={{
             display: this.state.ChatGPTDisplay
           }}>
        <div id="chatGPTradioButtons">
          <span> AI response will be:&nbsp; </span>
          <label>
            <input type="radio" value=" unstructured text response"
                   checked={this.state.chatRadioUnstructured}
                   onChange={this.handleChatRadioChange}/> unstructured text response
          </label>
          <label>
            <input id="chatGPTradioButton2" type="radio"
                   value="JSON with specific named elements"
                   checked={!this.state.chatRadioUnstructured}
                   onChange={this.handleChatRadioChange}/>
            JSON with specific named elements
          </label>

        </div>
        <div className='ChatGPTContainer'>
        <span className='GPTpromptLink' id='promptLink' onClick={this.insertPrompt}>
            <u>insert prompt-text</u>
          </span>
          <span className='GPTresponseLink' id='responseLink' onClick={this.insertPrompt}>
            <u>insert respondent's transcribed response</u>
          </span>
        </div>

        <table id="noFuncTable">
          <tbody>
          <tr>
            <td id='GPTPromptTextLabel' className='GPTPromptTextLabel'>
              <span>PROMPT*</span>
            </td>
            <td className="chatTextAreas">
                    <textarea id='GPTPromptTextArea' className='GPTPromptTextArea'
                              value={this.state.chatGPTstr}
                              placeholder="Enter text instructing ChatGPT how to respond. Include the question for respodent and/or the respondent's future response transcription."
                              onChange={this.handleChatTextChange}/>
            </td>
          </tr>
          <tr>
            <td id='GPTAssistantTextLabel' className='GPTAssistantTextLabel'>
              <span>ADVICE to the AI</span>

            </td>
            <td className="chatTextAreas">
                    <textarea id='GPTAssistantTextArea' className='GPTAssistantTextArea'
                              value={this.state.chatGPTassistant}
                              onChange={this.handleGPTAssistantChange}
                              placeholder="Guide the AI as to style, tone, syntax to use in this specific context when responding to the prompt. Provide an explicit example of what its response might be."
                    />
            </td>
          </tr>
          <tr>
            <td id='GPTSystemTextLabel' className='GPTSystemTextLabel'>
              <span>ChatGPT persona</span>

            </td>
            <td className="chatTextAreas">
                    <textarea id='GPTSystemtextArea' className='GPTSystemtextArea'
                              value={this.state.chatGPTsystem}
                              onChange={this.handleGPTSystemChange}
                              placeholder="Description of the persona or role ChatGPT is playing as it gives its feedback. This is more general than the advice to the AI."
                    />
            </td>
          </tr>
          <tr>
            <td id='GPTSystemTextLabel' colspan="2" className='GPTSystemTextLabel' style={{textAlign:'left'}}>
              <span>&nbsp;RESULTS CONSISTENCY <br></br>&nbsp;(the lower the value, the more repeatable the results)&nbsp;&nbsp;</span>
              <select name="numbers"
                value={this.state.chatGPTtemperature}
                onChange={this.handleGPTtemperatureChange}
              >
                <option value="0.0">0.0</option>
                <option value="0.1">0.1</option>
                <option value="0.2">0.2</option>
                <option value="0.3">0.3</option>
                <option value="0.4">0.4</option>
                <option value="0.5">0.5</option>
                <option value="0.6">0.6</option>
                <option value="0.7">0.7</option>
                <option value="0.8">0.8</option>
                <option value="0.9">0.9</option>
                <option value="1.0">1.0</option>
              </select>
            </td>
          </tr>
          <tr>
            <td id='GPTSystemTextLabel' colspan="2" className='GPTSystemTextLabel' style={{textAlign:'left'}}>
              <span>&nbsp;SUCCINTNESS <br></br>&nbsp;(the lower the value, the more focused the results)&nbsp;&nbsp;</span>
              <select name="succintness"
                value={this.state.chatGPTtop_p}
                onChange={this.handleGPTtop_pChange}
              >
                <option value="0.0">0.0</option>
                <option value="0.1">0.1</option>
                <option value="0.2">0.2</option>
                <option value="0.3">0.3</option>
                <option value="0.4">0.4</option>
                <option value="0.5">0.5</option>
                <option value="0.6">0.6</option>
                <option value="0.7">0.7</option>
                <option value="0.8">0.8</option>
                <option value="0.9">0.9</option>
                <option value="1.0">1.0</option>
              </select>
            </td>
          </tr>

          </tbody>
        </table>

        <table id="useFuncTable"
               style={{display: this.state.functionCallingDisplay}}>
          <tbody>
          <tr>
            <td className='TopicTableCol1'>
              <span id='GPTTopicLabel'>TOPIC*</span>
            </td>
            <td className='TopicTableCol2'>
                    <textarea id='GPTTopicText' className='GPTTopicText'
                              value={this.state.chatTopic}
                              placeholder="Provide a topic label."
                              onChange={this.handleGPTTopicChange}
                    />
            </td>
            <td className='TopicTableCol3'>
              <span className='GPTTopicDescriptionLabel'>DESCRIPTION*</span>
            </td>
            <td className='TopicTableCol4'>
                    <textarea id='GPTTopicDescriptionText'
                              className='GPTTopicDescriptionText'
                              value={this.state.chatTopicDesc}
                              placeholder="Enter a description of this topic."
                              onChange={this.handleGPTTopicDescChange}
                    />
            </td>
          </tr>
          <tr>
            <td id="moreElements" onClick={this.handleAddOneChatRow}>Element +</td>
            <td colSpan="4" id="namedElemsHdr" style={{textAlign: "center"}}>
              <i>Use one word for TOPIC and element NAMEs (underscores ok). At least one
                element is required.</i></td>
          </tr>
          {this.state.chatFieldEmptyDisplay ?
            <tr>
              <td colSpan="5"
                  style={{textAlign: "center", color: "red", fontWeight: 'bold'}}>
                {this.state.chatErrorMessage}
                {/* All type-in fields with * must have entries */}
              </td>
            </tr>
            :
            null
          }
          </tbody>
        </table>
        <table id="useFuncTable1"
               style={{display: this.state.functionCallingDisplay}}>
          <tbody>
          {this.state.chatElementRowsArray.map(
            (currentValue, index) =>
              this.getChatRows(currentValue, index)
          )}
          </tbody>
        </table>
        {!this.state.chatRadioUnstructured ?
          <div>
            <div id="clickForEditor" style={{marginRight: "10px"}}
            >
           <span id="htmlEditor1"
                 onClick={this.handleTemplateEditorClick}
           >JSON template editor </span>
              <span id="htmlEditor2"
                    onClick={this.handleTemplateEditorClick}
              >{this.state.templateEditorMessage}</span>
              {this.state.templateEditorDisplay ?
                <span id="resetTemplate"
                      style={{
                        position: "relative",
                        float: "right",
                        color: "blue",
                        textDecoration: "underline"
                      }}
                      onClick={this.handleResetClick}
                >Reset default template</span>
                : null}
            </div>
          </div>
          : null
        }

        {this.state.templateEditorDisplay ?
          <div id="chatGPTTemplateEditor"
               style={{height: "200px", padding: "0px", display: "block"}}>
            <textarea id="chatGPTeditPanel"
                      placeholder='edit chatGPT response template here'
                      onChange={this.updateRenderPanel}
                      value={this.state.chatGPTtemplateHTML}>
            </textarea>
            <div id="chatGPTrenderPanel">
              <span dangerouslySetInnerHTML={{__html: this.state.chatGPTtemplateHTML}}/>
            </div>
          </div>
          : null
        }
      </div>
    )
  }
  /* ************************************************************************************** */

  getKeywordTableHTML = () => {
    return (
      <tbody>
      <tr style={{border: 'solid thin transparent'}}>
        <td>
          {/* ------------------------------------- left table ----------------------------------------------- */}
          <div className='correctAnswTable'>
            <table className='wtestElementsTableHdr'>
              <tbody>
              <tr>
                <th className='keywordsTablehdr'>
                      <span
                        className='keywordsAddRow'
                        onClick={this.handleAddOneCorrectRow}
                      >
                        <u>row +</u>
                      </span>
                  <span className='keywordsHdrTitle'>
                        {' '}
                    Correct keywords/phrases
                      </span>
                  <span className='keywordsMaxPts'> % Max Pts</span>
                </th>
              </tr>
              </tbody>
            </table>
            <div className='correctTableContainer'>
              <table className='wtestElementsTable'>
                <tbody id='correctTableBody'>
                {this.state.correctTableRowsArray.map(
                  (currentValue, index) =>
                    this.getCorrectTableRows(currentValue, index)
                )}
                </tbody>
              </table>
            </div>
          </div>
          {/* --------------------------------------- right table ------------------------------------------- */}
          <div className='wrongAnswTable'>
            <table className='wtestElementsTableHdr'>
              <tbody>
              <tr>
                <th className='keywordsTablehdr'>
                      <span
                        className='keywordsAddRow'
                        onClick={this.handleAddOneWrongRow}
                      >
                        <u>row +</u>
                      </span>
                  <span className='keywordsHdrTitle'>
                        Wrong keywords/phrases
                      </span>
                  <span className='keywordsMaxPts' style={{color: 'red'}}>
                        % Max Pts
                      </span>
                </th>
              </tr>
              </tbody>
            </table>
            <div className='wcorrectTableContainer'>
              <table className='wtestElementsTable'>
                <tbody id='incorrectTableBody'>
                {this.state.wrongTableRowsArray.map((currentValue, index) =>
                  this.getWrongTableRows(currentValue, index)
                )}
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    )
  }

  render() {
    const {modalInfo} = this.props
    let question = modalInfo.question

    return (
      // ------------------------------------- header   ----------------------------------------- //
      <div>
        <div className='createScoringContainer'>
          <div
            className='modal-body'
            style={{
              marginTop: '10px',
              marginBottom: '-90px',
              maxWidth: '1124px'
            }}
          >
            {/* ------------------------------------- method checkboxes -------------------------------- */}

            {this.getCheckBoxHTML()}
          </div>

          {/* ----------------------------------------  the question   ------------------------------- */}

          <div className='createScoringQuestionSpec'>{question}</div>

          {/* -------------------------------------------- right panel ------------------------------- */}

          <div id='rightPanel' className='SetScoreRightPanel'>
            {/* -------------------------------------------- ChatGPT UI --------------------------------- */}
            {this.getChatGPTHTML()}

            {/* ------------------------------------- grammar --------------------------------------------- */}
            <div
              className='grammarDisplay'
              style={{
                textAlign: 'center',
                display: this.state.grammarDisplay
              }}
            >
              The transcribed response to this question will be scored for
              grammar errors.
            </div>

            {/* ------------------------------------- pronunciation --------------------------------------------- */}
            <div
              className='pronunciationDisplay'
              style={{
                textAlign: 'center',
                display: this.state.pronDisplay
              }}
            >
              The transcribed response to this question will be evaluated for
              pronunciation quality.
            </div>
            {/* -------------------------------------sentiment--------------------------------------------- */}
            <div
              className='sentimentDisplay'
              style={{
                textAlign: 'center',
                display: this.state.sentimentDisplay,
                flexDirection: 'column'
              }}>
              <div>The transcribed response to this question will be evaluated for the
                sentiment of the response.
              </div>
              <div style={{paddingTop: '5px'}}>
                <div className='checkBoxContainer'>
                  <input type="checkbox"
                         name='showSentiment'
                         value='1'
                         checked={this.state.showSentiment}
                         onChange={this.handleShowSentiment}


                  />
                  <label className='checkBoxLabel' style={{paddingLeft: '3px'}}>Show the
                    results of this assessment in
                    the email to the respondent.&nbsp; &nbsp;</label>

                </div>
              </div>
            </div>
            {/* -------------------------------------------- KeyWord Scoring UI ------------------------- */}
            <div id="keyWordsSection"
                 style={{
                   width: '96.8%',
                   textAlign: 'center',
                   display: this.state.keyWordsDisplay
                 }}
            >
              <div id='maxPtsInstruction' style={{float: 'left'}}>
                Maximum point value for this question:&nbsp;
              </div>
              <div
                id='maxPts'
                className='maxPtsBox'
                contenteditable='true'
                onBlur={this.handleMaxPtsBlur}
                placeholder='?'
              >
                {this.state.maxPts}
              </div>
              <table style={{width: '100%', marginTop: '20px'}}>
                {this.getKeywordTableHTML()}
              </table>
            </div>
            {/* ------------------------------------- footer --------------------------------------------- */}
          </div>

          <div className='modal-footer' style={{height: '40px'}}>
            <div
              className='btn-group pull-right'
              style={{
                position: 'relative',
                top: '-30px',
                margin: '5px',
                marginBottom: '0px',
                marginRight: '130px'
              }}
            >
              <button
                className='blueButton pull-right'
                onClick={this.handleSave}
                style={{
                  marginLeft: '10px',
                  marginRight: '20px',
                  marginTop: '10px'
                }}
              >
                SAVE and EXIT
              </button>
              <button
                className='blueButton pull-right'
                style={{marginTop: '10px'}}
                onClick={this.handleCancel}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GetAssessmentOption