import React from 'react';
import $ from 'jquery';
import Hashids from 'hashids'

class Export extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel(e) {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  //see 3rd entry: http://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  copyLink = (e) => {
    e.preventDefault();
    let $link = $('#link');
    $link.attr('disabled', false);
    $link[0].select()
    document.execCommand('copy') // copied to clipboard
    $link.attr('disabled', false);
  }

  render() {
    const {modalInfo} = this.props;
   let hashids = new Hashids();
   let hash = hashids.encode(modalInfo.TIN,1) //'1' indicates to show the explanatory
    // message

    let loc = window.location.hostname === 'localhost' ? `http://localhost:5001/${hash}` : `${window.location.origin}/${hash}`
    return (
      <div>
        <div className="modal-body">
          <h2 className="export-prompt" style={{padding:'10px'}}>Copy the link below to share this transcript.
          </h2>
          <div className="export-clipboard" style={{background: '#d1e0e3', margin: 'auto', width: '370px', padding: '30px', border: 'solid thin gray', boxShadow: '5px 5px 2px #999999'}}
               >
            <div style={{display: 'inline'}}>
              <input id="link" type="text" style={{width: '300px', background: 'white', paddingLeft: '8px', padding: '3px'}} value={loc} disabled/>
            </div>

            <div style={{marginLeft: '10px', display: 'inline'}}>
              <button
                className="orangeButtonSmall"
                style={{position:'relative',float:'right',top:'-24px'}}
                onClick={this.copyLink}
              >
                COPY
              </button>
            </div>
          </div>
          <div className="export-preview" style={{textAlign:'center'}}>
            <div style={{display: 'inline', marginRight: '15px'}}>click here for a
              preview:
            </div>
            <div style={{display: 'inline'}}>
              <button
                className="orangeButtonSmall"
                style={{position:'relative', top:'-2px'}}
                onClick={() => {
                  window.open(loc)
                }}
              >SHOW ME
              </button>
            </div>

          </div>

        </div>

       <div className="modal-footer" style={{padding: '0px'}}>
          <div className="btn-group pull-right" style={{margin: '5px 15px'}}>
            <button className="blueButton"
                    onClick={this.handleCancel}>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    )
  }
}

export default Export;