
import {DELETE_STAFF} from '../_CONSTANTS';
import $ from 'jquery';

export const deleteStaff = (id) => {
  return function(dispatch, getState) {
    return new Promise((resolve, reject)=> {
      let url = '/admin/deleteStaff';
      // let url = '/testerror';
      $.ajax({
        url: url,
        type: "PUT",
        data: {
          id
        },
        success: (employee)=>{
          dispatch({
            type: DELETE_STAFF,
            payload: employee
          })
          resolve(employee);
        },
        error:(err)=>{
          console.log(err);
          reject(err);
        }
      })
    })
  }
}
