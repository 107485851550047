import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';


const _passwordReset = (email) => {
  return (
    <div>
      <p>
        <strong>To:</strong>
        {email}
      </p>
      <br/>
      <p>You are receiving this email because you requested a password reset.</p>
      <p>To reset your password click on this [url].</p>
      <p>Doing so will take you to a page where you can enter a new password for your
        account.</p>
      <p>This link will be good for 24 hours. After that you will need to request a new
        password.
      </p>
      <br/>
      <p>If this email was sent in error or you do not want to reset your password,
        just disregard this email.</p>

    </div>

  )
}

const passwordReset = (email) => {
  return reactElementToJSXString(_passwordReset(email));
}


export default passwordReset;





