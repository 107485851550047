import React from 'react';
import DOMPurify from 'dompurify';

/* ***************  simulate click lets us use button to click hidden link rather than hyperlink *********** */
/* let mouseClickEvents = ['mousedown', 'click', 'mouseup'];
function simulateMouseClick(element){
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
      })
    )
  );
} */

class GetAdvancedQuestionOptions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      awaitResponse: true,
      questionDelay: 10,
      instructionDelay: 2,
      replayOnSilence: true,
      audioOnly: true,
      mediaOption: 'image',
      previewImageOpen: false,
      previewVideoOpen: false,
      previewHTMLOpen: false,
      imageURL: '',
      videoURL: '',
      htmlText: '',
      htmlTextBuffer: '',
      imagePreviewURL: '',
      videoPreviewURL: '',
      htmlPreview: '',
      previewWidth: 0,
      previewHeight: 0,
      spokenURL: '',
      repeatArrow: true,
      nextArrow: true,
      eggTimerEnabled: false,
      eggTimer: 0,
      spokenURLEnabled: false,
      htmlEditorActive: false
    }
    let images = ['Question.png', 'Instruction.png', 'Audio.png', "Image.png", "Video.png", "Text_html.png", "Repeat.png", "Next.png", "InvalidURL.png"]
    images.forEach((image) => {
      let img = new Image();
      img.src = image;
    })
  }

  componentDidMount() {
    const {row} = this.props.modalInfo;
    this.setState({
      awaitResponse: row.awaitResponse,
      questionDelay: parseInt(row.questionDelay),
      instructionDelay: parseInt(row.instructionDelay),
      replayOnSilence: row.replayOnSilence,
      audioOnly: row.audioOnly,
      mediaOption: row.mediaOption,
      imageURL: row.imageURL,
      videoURL: row.videoURL,
      htmlText: row.htmlText,
      spokenURL: row.spokenURL,
      repeatArrow: row.repeatArrow,
      nextArrow: row.nextArrow,
      eggTimerEnabled: row.eggTimerEnabled,
      eggTimer: parseInt(row.eggTimer),
      spokenURLEnabled: row.spokenURLEnabled

    })
  }

  handleClose = (e) => {
    const {hideModal} = this.props;
    e.preventDefault()
    hideModal();
  }

  handleSubmit = (e) => {
    const {hideModal, confirmHandler, modalInfo} = this.props;
    e.preventDefault();
    modalInfo.row = {
      ...modalInfo.row, ...{
        awaitResponse: this.state.awaitResponse,
        questionDelay: this.state.questionDelay.toString(),
        instructionDelay: this.state.instructionDelay.toString(),
        replayOnSilence: this.state.replayOnSilence,
        audioOnly: this.state.audioOnly,
        mediaOption: this.state.mediaOption,
        imageURL:
          this.state.mediaOption === 'image' || this.state.audioOnly ? this.state.imageURL : '',
        videoURL:
          this.state.mediaOption === 'video' || this.state.audioOnly ? this.state.videoURL : '',
        htmlText:
          this.state.mediaOption === 'text' || this.state.audioOnly ? this.state.htmlText : '',
        spokenURL: this.state.spokenURLEnabled ? this.state.spokenURL : '',
        repeatArrow: this.state.repeatArrow,
        nextArrow: this.state.nextArrow,
        eggTimerEnabled: this.state.eggTimerEnabled,
        eggTimer: this.state.eggTimer.toString(),
        spokenURLEnabled: this.state.spokenURLEnabled
      }
    };

    //temporary code to support old taker client.

    modalInfo.row.delay = this.state.awaitResponse ? this.state.questionDelay : this.state.instructionDelay;
    if (this.state.mediaOption === 'video') {
      modalInfo.row.imageURL = this.state.videoURL;
    }
    modalInfo.row.toggleLabel = this.state.mediaOption === 'text' ? 'text' : "image/MP4";
    modalInfo.row.displayText = this.state.mediaOption === 'text' ? this.state.htmlText : '';

    if (confirmHandler) {
      confirmHandler()
    }
    hideModal();
  }

  isPositiveNumber = (number) => {
    return /^\d+$/.test(number) && number >= 0;
  }

  handleQuestionOrInstruction = (e) => {
    const id = e.currentTarget.id;
    console.log('id: ', id)
    if (id === 'questionContainer') {
      this.setState({awaitResponse: true});
    } else {
      this.setState({awaitResponse: false})
    }
  }

  handleNumericInputChange = (e) => {
    const {value, name} = e.target;
    if (this.isPositiveNumber(value) || value === '') {
      this.setState({[name]: value})
    }
  }

  handleAudioOnlyOrEnhanced = (e) => {
    const id = e.currentTarget.id;
    console.log('id: ', id);
    if (id === 'audioOnlyContainer') {
      this.setState({audioOnly: true})
    } else {
      this.setState({audioOnly: false})
    }
  }

  handleMediaTypeChange = (e) => {
    const value = e.target.value;
    this.setState({mediaOption: value})
  }

  handleCheckboxChange = (e) => {
    const {checked, name} = e.target;
    this.setState({...this.state, ...{[name]: checked}})
  }

  handleMediaTypeInfoChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const sanitizedHTML = DOMPurify.sanitize(e.target.value)
    this.setState({htmlPreview: sanitizedHTML, [name]: value});
    //this.setState({[name]: value});
    //var element = document.querySelector('#previewButton');
    //simulateMouseClick(element);
  }

  handleSpokenURLChange = (e) => {
    this.setState({spokenURL: e.target.value});
  }

  /////////////////////////////promptType render functions//////////////////////////////

  promptTypeQuestion = () => {
    const {isDeployed} = this.props.modalInfo;
    let inactiveCss = isDeployed ? '3px solid #eee' : '3px solid transparent';
    let activeCss = isDeployed ? '3px dashed #4b4bc5' : '3px solid #4b4bc5';
    let displayedCss = '';
    if (this.state.awaitResponse) {
      displayedCss = activeCss;
    } else {
      displayedCss = inactiveCss;
    }
    return (
      <div id='questionContainer'
           className={isDeployed ? 'questOptionSelectorDisabled' : 'questOptionSelector'}
           style={{
             ...{border: `${displayedCss}`}
           }}
           onClick={this.handleQuestionOrInstruction}
      >
        <div id="questionInnerContainer" style={{}}>
          <div id="questionIconContainer"
               style={{width: '33.3%', margin: '3px'}}>
            <div id="questionIcon" style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px'
            }}><img src="Question.png" style={{height: '30px', width: '30px'}} alt=""/>
            </div>
            <div id="questionText" style={{
              fontSize: '18px', fontWeight: 'bold', display: 'flex',
              justifyContent: 'center', marginTop: '4px',
              color: '#4b4bc5'
            }}>Question
            </div>
          </div>
          <div id="questionDelayContainer"
               style={{width: '33.3%', margin: '3px'}}>
            <div id='questionDelayInput' style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
              <input type="number" name='questionDelay'
                     value={this.state.questionDelay}
                     onChange={this.handleNumericInputChange}
                     style={{
                       width: '37px',
                       border: 'solid thin black',
                       textAlign: 'right'
                     }}
              />
            </div>
            <div id='questionDelaytext'
                 style={{
                   fontSize: '16px', display: 'flex',
                   justifyContent: 'center'
                 }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <div>seconds of</div>
                <div>silence before</div>
                <div>advancing</div>
              </div>
            </div>
          </div>
          <div id="repeatContainer"
               style={{width: '33.3%', margin: '3px'}}>
            <div id='repeatText'
                 style={{
                   fontSize: '16px', display: 'flex',
                   flexDirection: 'column',
                   alignItems: 'center',
                   justifyContent: 'center'
                 }}>
              <div id='autoRepeat' style={{
                border: '1px solid black',
                marginTop: '3px',
                marginBottom: '5px',
                padding: '3px'
              }}>
                <div>Auto-repeat</div>
                <div>once if no</div>
                <div>response</div>
              </div>
            </div>
            <div id="replayOnSilence" style={{display: 'flex', justifyContent: 'center'}}>
              <input type="checkbox"
                     name='replayOnSilence'
                     checked={this.state.replayOnSilence}
                     onChange={this.handleCheckboxChange}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  promptTypeInstruction = () => {
    const {isDeployed} = this.props.modalInfo;
    let inactiveCss = isDeployed ? '3px solid #eee' : '3px solid transparent';
    let activeCss = isDeployed ? '3px dashed #4b4bc5' : '3px solid #4b4bc5';
    let displayedCss = '';
    if (!this.state.awaitResponse) {
      displayedCss = activeCss;
    } else {
      displayedCss = inactiveCss;
    }


    return (
      <div id='instructionContainer'
           className={isDeployed ? 'questOptionSelectorDisabled' : 'questOptionSelector'}
           style={{
             ...{border: `${displayedCss}`}
           }}
           onClick={this.handleQuestionOrInstruction}>
        <div id="instructionInnerContainer" style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <div id='instructionIconContainer'
               style={{width: '50%', margin: '3px'}}>
            <div id='instructionIcon' style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px'
            }}><img src="Instruction.png" style={{width: '30px', height: '30px'}} alt=""/>
            </div>
            <div id='instructionText' style={{
              fontSize: '18px', fontWeight: 'bold', display: 'flex',
              justifyContent: 'center', marginTop: '4px',
              color: '#4b4bc5'
            }}>Instruction
            </div>
          </div>
          <div id='instructionDelayContainer'
               style={{width: '50%', margin: '3px'}}>
            <div id='instructionDelayInput' style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
              <input type="number" name='instructionDelay'
                     value={this.state.instructionDelay}
                     onChange={this.handleNumericInputChange}
                     style={{
                       width: '37px',
                       border: 'solid thin black',
                       textAlign: 'right'
                     }}
              />
            </div>
            <div id='instructionDelaytext'
                 style={{
                   fontSize: '16px', display: 'flex',
                   justifyContent: 'center'
                 }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <div>seconds</div>
                <div>elapsed before</div>
                <div>advancing</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  promptSection = (e) => {
    const {isDeployed} = this.props.modalInfo;
    return (
      <div id="promptTypeOuterContainer"
           style={{margin: '10px'}}>
        <div id='promptTitle' style={{
          width: '58%',
          fontWeight: '600',
          fontSize: '18px',
          marginBottom: '7px',
          cursor: 'default',
          color: '#4b4bc5'
        }}>Prompt Type:
        </div>
        <div id='promptTypeInnerContainer'
             title={isDeployed ? "Cannot be changed after deployment" : ''}
             style={{
               display: 'flex', justifyContent: 'space-between', width: '100%',
               cursor: isDeployed ? 'not-allowed' : 'default'
             }}>
          {this.promptTypeQuestion()}
          {this.orContainer()}
          {this.promptTypeInstruction()}
        </div>
      </div>)
  }

  ///////////////////////////// end promptType render functions//////////////////////////

  orContainer = () => {
    return (
      <div id='orContainer' style={{
        height: '105px',
        width: '30px',
        cursor: 'default'
      }}>
        <div id='orInnerContainer' style={{
          textAlign: 'center',
          fontWeight: '600',
          fontSize: '20px',
          marginTop: '40px',
          color: '#4b4bc5'
        }}>or
        </div>
      </div>
    )
  }

  ////////////////////////////////presentPromptsVia Section//////////////////////////////


  presentPromptsViaAudio = () => {
    return (
      <div id='audioOnlyContainer' className='questOptionSelectorShort' style={{
        ...{border: this.state.audioOnly ? '3px solid #4b4bc5' : '3px solid #eee'}
      }}
           onClick={this.handleAudioOnlyOrEnhanced}>
        <div id="audioOnlyIconContainer"
             style={{width: '100%', margin: '3px'}}>
          <div id="audioOnlyIcon" style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px'
          }}><img src="Audio.png" alt="" style={{width: '30px', height: '30px'}}/></div>
          <div id="audioOnlyText" style={{
            fontSize: '18px', fontWeight: 'bold', display: 'flex',
            justifyContent: 'center', marginTop: '4px',
            color: '#4b4bc5'
          }}>Audio Only
          </div>
        </div>
      </div>
    )
  }

  presentPromptsViaAudioPlus = () => {
    return (
      <div id='audioPlusContainer' className='questOptionSelectorShort' style={{
        ...{border: !this.state.audioOnly ? '3px solid #4b4bc5' : '3px solid #eee'}
      }}
           onClick={this.handleAudioOnlyOrEnhanced}
      >
        <div id="audioPlusInnerContainer" style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <div id='audioOnlyIconContainer' style={{width: '24%', margin: '3px'}}>
            <div id="audioOnlyIcon" style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '30px'
            }}><img src="Audio.png" alt="" style={{width: '30px', height: '30px'}}/></div>
            <div id="audioPlusText" style={{
              fontSize: '18px', fontWeight: 'bold', display: 'flex',
              justifyContent: 'center', marginTop: '4px',
              color: '#4b4bc5'
            }}>Audio Plus
            </div>
          </div>
          <div id='plusSignContainer' style={{
            width: '4%', margin: '3px'
          }}>
            <div id='plusSign' style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '38px',
              fontWeight: 'bold'
            }}>+
            </div>
          </div>
          <div id='audioPlusImageContainer' style={{
            width: '20%', margin: '3px'
          }}>
            <div id="audioPlusImageIcon" style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px'
            }}><img src="Image.png" alt="" style={{width: '30px', height: '30px'}}/>
            </div>
            <div
              style={{display: 'flex', justifyContent: 'center', marginTop: '2px'}}>image
            </div>
            <div id='audioPlusImage' style={{
              display: 'flex',
              justifyContent: 'center', marginTop: '6px'
            }}>
              <input type="radio"
                     name='audioPlus'
                     style={{cursor: 'pointer'}}
                     checked={this.state.mediaOption === 'image'}
                     value='image'
                     onChange={this.handleMediaTypeChange}
              />
            </div>
          </div>
          <div id='audioPlusVideoContainer' style={{
            width: '24%', margin: '3px'
          }}>
            <div id="audioPlusVideoIcon" style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px'
            }}><img src="Video.png" alt="" style={{width: '30px', height: '30px'}}/>
            </div>
            <div
              style={{display: 'flex', justifyContent: 'center', marginTop: '2px'}}>video
            </div>
            <div id='audioPlusVideo' style={{
              display: 'flex',
              justifyContent: 'center', marginTop: '6px'
            }}>
              <input type="radio"
                     name='audioPlus'
                     style={{cursor: 'pointer'}}
                     checked={this.state.mediaOption === 'video'}
                     value='video'
                     onChange={this.handleMediaTypeChange}
              />
            </div>
          </div>
          <div id='audioPlusTextContainer' style={{
            width: '24%', margin: '3px'
          }}>
            <div id="audioPlusTextIcon" style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px'
            }}><img src="Text_html.png" alt="" style={{width: '30px', height: '30px'}}/>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2px'
            }}>HTMLdoc
            </div>
            <div id='audioPlusText' style={{
              display: 'flex',
              justifyContent: 'center', marginTop: '6px'
            }}>
              <input type="radio"
                     name='audioPlus'
                     style={{cursor: 'pointer'}}
                     checked={this.state.mediaOption === 'text'}
                     value='text'
                     onChange={this.handleMediaTypeChange}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  onEditClick = (e) => {
    if (this.state.mediaOption === 'text') {
      const sanitizedHTML = DOMPurify.sanitize(this.state.htmlText)
      this.setState({
        htmlEditorActive: true,
        htmlTextBuffer: this.state.htmlText,
        htmlPreview: sanitizedHTML
      })
    }
  }

  presentPromptsGetURLorText = () => {
    return (
      <div id="getURLorTextInnerContainer"
           style={{
             width: '70%', height: '40px',
             marginLeft: '210px',
             marginTop: '3px', visibility: this.state.audioOnly ? 'hidden' : 'visible'
           }}>
        <div id='getURLorText' style={{
          height: '44px',
          width: '411px',
          float: 'left'
        }}
        >
          <input type="text"
                 name='imageURL'
                 id='audioPlusImage'
                 value={this.state.imageURL}
                 onChange={this.handleMediaTypeInfoChange}
                 placeholder='Enter image url here'
                 style={{
                   boxSizing: 'border-box',
                   display: this.state.mediaOption === 'image' ? 'block' : 'none',
                   width: '100%',
                   padding: '5px',
                   border: '1px solid',
                   borderRadius: '4px',
                   marginTop: '10px'
                 }}
          />
          <input type="text"
                 name='videoURL'
                 id='audioPlusVideo'
                 value={this.state.videoURL}
                 onChange={this.handleMediaTypeInfoChange}
                 placeholder='Enter video url here'
                 style={{
                   boxSizing: 'border-box',
                   display: this.state.mediaOption === 'video' ? 'block' : 'none',
                   width: '100%',
                   padding: '5px',
                   border: '1px solid',
                   borderRadius: '4px',
                   marginTop: '10px'
                 }}/>
        </div>
        <div id='previewRollover' style={{
          marginLeft: this.state.mediaOption === 'text' ? '30px' : '8px',
          float: 'left',
        }}>
          <div style={{paddingTop: '8px', float: 'right'}}>
            <button className="blueButton"
                    disabled={this.state.audioOnly}
                    onMouseEnter={this.showPreview}
                    onMouseLeave={this.hidePreview}
                    onClick={this.onEditClick}
            >{this.state.mediaOption === 'text' ? 'Edit' : 'Preview'}
            </button>
          </div>
        </div>
      </div>
    )
  }

  presentPromptsSection = () => {
    return (
      <div id="presentPromptsOuterContainer" className='questionOptionsContainer'
           style={{margin: '45px 10px 10px 10px'}}>
        <div style={{
          width: '58%',
          fontWeight: '600',
          fontSize: '18px',
          marginBottom: '7px',
          cursor: 'default',
          color: '#4b4bc5'
        }}>Present prompts via:
        </div>
        <div id="presentPromptsInnerContainer">
          <div
            style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            {this.presentPromptsViaAudio()}
            {this.orContainer()}
            {this.presentPromptsViaAudioPlus()}
          </div>
          {this.presentPromptsGetURLorText()}
        </div>
      </div>
    )
  }

  ////////////////////////////////end presentPromptsVia Section/////////////////////////

  ////////////////////////////////////recommended/optional Elements///////////////////

  recommendedElements = () => {
    return (
      <div id='recommendedElementsContainer' style={{
        height: '105px',
        width: '28%',
        background: 'white',
        border: '3px solid #eee',
        display: 'flex',
        justifyContent: 'space-around'
      }}>
        <div style={{width: '50%', margin: '3px'}}>
          <div id="repeatIcon" style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          }}><img src="Repeat.png" alt="" style={{width: '40px', height: '40px'}}/>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'center', marginTop: '12px'
          }}>
            <input type="checkbox"
                   name='repeatArrow'
                   checked={this.state.repeatArrow}
                   onChange={this.handleCheckboxChange}
            />
          </div>
        </div>
        <div style={{width: '50%', margin: '3px'}}>
          <div id="nextIcon" style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          }}><img src="Next.png" alt="" style={{width: '40px', height: '40px'}}/>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center', marginTop: '12px'
          }}>
            <input type="checkbox"
                   name='nextArrow'
                   checked={this.state.nextArrow}
                   onChange={this.handleCheckboxChange}
            />
          </div>
        </div>
      </div>
    )
  }

  playPrompt = () => {
    if (this.state.spokenURL) {
      const voicePrompt = new Audio(this.state.spokenURL);
      voicePrompt.play()
        .then(() => {
          console.log('sound played')
        }, (err) => {
          console.log(err)
        })

    }
  }

  optionalElements = () => {
    return (
      <div id='optionalElementsContainer' style={{
        height: '105px',
        width: '58%',
        background: 'white',
        border: '3px solid #eee',
        display: 'flex',
        justifyContent: 'space-around'
      }}>
        <div id='eggTimerContainer'
             style={{width: '25%', textAlign: "center", margin: '3px'}}>
          Seconds
          <div id='eggTimer'
               style={{display: 'flex', justifyContent: 'center', marginTop: '0px'}}>
            <div id="circularBorder"
                 title="Countdown timer suggests seconds an appropriate answer requires.">
              <input type="number"
                     id="eggTimerInput"
                     name='eggTimer'
                     onChange={this.handleNumericInputChange}
                     value={this.state.eggTimer}
                     disabled={!this.state.eggTimerEnabled}/>
            </div>
          </div>
          <div id='eggTimerEnabled' style={{
            display: 'flex',
            justifyContent: 'center', marginTop: '6px'
          }}>
            <input type="checkbox"
                   name='eggTimerEnabled'
                   checked={this.state.eggTimerEnabled}
                   onChange={this.handleCheckboxChange}
            /><label>&nbsp;Timer</label>

          </div>
        </div>
        <div id='spokenPromptsOuterContainer' style={{
          width: '75%',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <div id='spokenPromptsInnerContainer'
               style={{
                 display: 'flex',
                 justifyContent: 'center',
                 flexDirection: 'column',
               }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}>
              <input type="text"
                     style={{
                       width: '250px',
                       boxSizing: 'border-box',
                       display: 'block',
                       padding: '6px',
                       border: '1px solid',
                       borderRadius: '4px',
                       background: this.state.spokenURLEnabled ? 'white' :
                         'rgb(238 238 238)',
                       height: '26px'
                     }}
                     name='spokenURL'
                     id='spokenURL'
                     placeholder='URL of human-voiced audio'
                     value={this.state.spokenURL}
                     onChange={this.handleSpokenURLChange}
                     disabled={!this.state.spokenURLEnabled}

              />
              <div style={{
                width: '40px',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '5px'
              }}>
                <i className='fas fa-play-circle' style={{color: 'black'}}
                   onClick={this.playPrompt}>
                </i>
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center', marginTop: '17px', marginRight: '50px'
            }}>
              <input type="checkbox"
                     name='spokenURLEnabled'
                     checked={this.state.spokenURLEnabled}
                     onChange={this.handleCheckboxChange}
              />
            </div>
          </div>
        </div>
      </div>)
  }

  optionalorRecommendedElementsSection = () => {
    return (
      <div id="optionalRecommendedElementsOuterContainer"
           className='questionOptionsContainer'
           style={{margin: '10px'}}>
        <div id='optionalRecommendedTitles' style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <div style={{
            width: '38%',
            fontWeight: '600',
            fontSize: '18px',
            marginBottom: '7px',
            cursor: 'default',
            color: '#4b4bc5'
          }}>Recommended elements
          </div>
          <div style={{
            width: '58%',
            fontWeight: '600',
            fontSize: '18px',
            marginBottom: '7px',
            cursor: 'default',
            color: '#4b4bc5'
          }}>Optional elements
          </div>
        </div>
        <div id="optionalElementsInnerContainer"
             style={{
               display: 'flex',
               justifyContent: 'space-between',
               width: '100%',
             }}>
          {this.recommendedElements()}
          {this.optionalElements()}
        </div>
      </div>
    )
  }

  ///////////////////////////////end recommended/optional Elements///////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  /*
  The functions below control the showing and hiding of previews for images, videos
  or
  html used as optional objects that can be shown when questions are asked in the
  player.

  The basic logic is that when the user hovers over the 'preview button' showPreview
  is invoked. Likewise when the user ceases to hover on that button 'hidePreview' is
  invoked. 'showPreview' in turn calls 'showImagePreview', 'showVideoPreview' or
  'showHTMLPreview' based upon the selected media type. These functions do the
  appropriate pre-flighting for the data type, places the resulting data in one
  of the state variables, imagePreviewURL, videoPreviewURL, htmlPreview as
  appropriate. The functions previewImage, previewVideo and previewHTML than
  format and display the preview.
  */

  showImagePreview = () => {
    let img = new Image();
    let url = this.state.imageURL;
    const maxWidth = 640;
    const maxHeight = 600;
    if (url) {
      img.src = url;
      img.onload = () => {
        let previewWidth = img.width;
        let previewHeight = img.height;
        if (previewWidth > maxWidth) {
          let reduction = maxWidth / img.width;
          previewWidth = maxWidth;
          previewHeight = img.height * reduction;
        }
        if (previewHeight > maxHeight) {
          let reduction = maxHeight / previewHeight;
          previewHeight = previewHeight * reduction;
          previewWidth = previewWidth * reduction;
        }
        this.setState({
          imagePreviewURL: url,
          previewWidth,
          previewHeight,
        })
      }
      img.onerror = () => {
        this.setState({
          imagePreviewURL: 'https://s3.us-east-1.amazonaws.com/dev.devstore/InvalidURL.png',
          previewHeight: 179,
          previewWidth: 214

        })
      }
    }
  }

  showVideoPreview = async () => {
    const videoExtensions = ['mp4', 'mkv', 'wmv', 'mov', 'avi', 'flv']
    const url = this.state.videoURL;
    if (url) {
      const extension = url.slice(-3);
      if (!videoExtensions.includes(extension)) {
        this.setState({
          videoPreviewURL: 'https://s3.us-east-1.amazonaws.com/dev.devstore/InvalidURL.png',
          previewHeight: 179,
          previewWidth: 214
        })
      } else {
        try {
          const response = await fetch(url);
          if (response.ok) {
            this.setState({videoPreviewURL: this.state.videoURL})
          } else {
            this.setState({
              videoPreviewURL: 'https://s3.us-east-1.amazonaws.com/dev.devstore/InvalidURL.png',
              previewHeight: 179,
              previewWidth: 214
            })
          }
        } catch (err) {
          this.setState({
            videoPreviewURL: 'https://s3.us-east-1.amazonaws.com/dev.devstore/InvalidURL.png',
            previewHeight: 179,
            previewWidth: 214
          })
        }
      }
    }
  }
  // in new version this shows up in separate html preview pane.
  showHTMLPreview = () => {
    if (this.state.htmlTextBuffer) {

      const sanitizedHTML = DOMPurify.sanitize(this.state.htmlTextBuffer)
      this.setState({htmlPreview: sanitizedHTML});

    }
  }

  showPreview = () => {
    switch (this.state.mediaOption) {
      case 'text':
        break;
      case 'image':
        this.setState({previewImageOpen: true})
        this.showImagePreview();
        break;
      case 'video':
        this.showVideoPreview();
        this.setState({previewVideoOpen: true})
        break;
      default:
        break;
    }
  }

  hidePreview = () => {
    this.setState({
      previewHTMLOpen: false,
      previewImageOpen: false,
      previewVideoOpen: false,
      imagePreviewURL: '',
      videoPreviewURL: '',
      htmlPreview: '',
      previewHeight: 0,
      previewWidth: 0
    })
  }

  previewImage = () => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div id="previewImage" style={{
          border: 'solid thin gray',
          display: this.state.previewImageOpen ? 'block' : 'none'
        }}>
          <img src={this.state.imagePreviewURL}
               width={this.state.previewWidth}
               height={this.state.previewHeight}
               alt=""
          />
        </div>
      </div>
    )
  }

  previewVideo = () => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div id="previewVideo" style={{
          display: this.state.previewVideoOpen ? 'block' : 'none'
        }}>
          {this.state.videoPreviewURL.slice(-14) === 'InvalidURL.png' || !this.state.videoPreviewURL ?
            <img src={this.state.videoPreviewURL}
                 width={this.state.previewWidth}
                 height={this.state.previewHeight}
                 alt=""
            /> :
            <video src={this.state.videoPreviewURL} width={320} height={240}
                   controls></video>}
        </div>
      </div>
    )
  }

  onHtmlEditorCancel = () => {
    this.setState({
      htmlTextBuffer: '',
      htmlEditorActive: false
    })
  }

  onHtmlEditorSave = () => {
    this.setState({
      htmlText: this.state.htmlTextBuffer,
      htmlEditorActive: false
    })
  }

  htmlEditor = () => {
    return (
      <div id='html-editor-container'
           className='html-editor-container '
      >
        <div className='html-editor-subheading'><u>HTML EDITOR</u>
        </div>
        <div id='html-editor-preview' className='html-editor-preview'>
          <div dangerouslySetInnerHTML={{__html: this.state.htmlPreview}}></div>
        </div>
        <div className='html-editor-input'>
          <textarea placeholder='Enter your HTML code here.' rows='13' cols='91'
                    style={{resize: 'none',
                    border: 'solid thin gray',
                    borderRadius: '10px',
                    padding: '5px'
                    }}
                    value={this.state.htmlTextBuffer}
                    name="htmlTextBuffer"
                    onChange={this.handleMediaTypeInfoChange}
          />
        </div>
        <div className='html-editor-button-container'>
          <div className="html-editor-button-bar">
            {/* <button id="previewButton" className="blueButton" onClick={this.showHTMLPreview}>Preview</button> */}
            <button className="blueButton" style={{marginLeft: '4px'}}
                    onClick={this.onHtmlEditorCancel}>Cancel
            </button>
            <button className="blueButton" style={{marginLeft: '4px'}}
                    onClick={this.onHtmlEditorSave}>Save
            </button>
          </div>
        </div>
      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div>
        <div style={{paddingLeft: '15px', paddingRight: '15px', margin: 'auto'}}>
          {this.state.htmlEditorActive ?
            <div style={{
              zIndex: '4',
              background: 'rgb(245, 243, 238)',
              width: '700px',
              height: '590px'
            }}>
              {this.htmlEditor()}
            </div>

            :

            <div id='modal-panel'>
              {this.previewImage()}
              {this.previewVideo()}
              {/*{this.previewHTML()}*/}
              <div>
                {this.promptSection()}
                {this.presentPromptsSection()}
                {this.optionalorRecommendedElementsSection()}

                <div id='modalFooter' className="modal-footer"
                     style={{boxSizing: 'border-box', width: '100%'}}>
                  <div className="btn-group pull-right" style={{margin: '5px 0'}}>
                    <button className='blueButton' id='submitButton'
                            style={{float: 'right', marginLeft: '8px'}}
                            onClick={this.handleSubmit}>Save
                    </button>
                    <button className='blueButton' style={{float: 'right'}}
                            onClick={this.handleClose}>Close
                    </button>
                  </div>
                </div>
              </div>
            </div>}
        </div>
      </div>
    )

  }
}

export default GetAdvancedQuestionOptions