import thunk from 'redux-thunk';
import multi from 'redux-multi';
import combineReducers from './reducers/index';
import {createStore, applyMiddleware} from 'redux';
import {LOGOUT_USER} from './actions/_CONSTANTS';

import logger from "redux-logger";


/* *********  just this if no middleware logging ********** */
/* */
let middleware = [thunk, multi];
let debug = false;
if (debug) {
  middleware.push(logger)
}
/* ******************************************************** */

               /*  NOT BOTH! */

/* ********* just this for middleware logging on ********** */
/* 
const debug = true;
const consoleLogMiddleware = store => next => action => {
  if(debug) {
    console.log(next(action));
  }
  return next(action);
}
let middleware = [thunk, multi];
if (debug) {
  middleware.push(logger)
}
/* ******************************************************** */

/* ******************************************************** */
//how to reset the store on user logout;

//https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store

const rootReducer = (state, action) => {
  if(action.type === LOGOUT_USER) {
    state = undefined;
  }
  return combineReducers(state, action)
}


export const store = createStore(rootReducer, applyMiddleware(...middleware));
