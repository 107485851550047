import React from 'react'
import $ from 'jquery'
import { CSVLink } from 'react-csv'

/* ***************  simulate click lets us use button to click hidden link *********** */
let mouseClickEvents = ['mousedown', 'click', 'mouseup']
function simulateMouseClick (element) {
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1
      })
    )
  )
}

function jsonObjectToString(obj) {
  let result = "";
  for (const [key, value] of Object.entries(obj)) {
      result += `${key}: ${value}; `;
  }
  return result;
}

/* ************************************************************************************** */

let assessmentsUsed = {
  keyword: false,
  chatGPT: false,
  pronunciation: false,
  grammar: false,
  sentiment: false
}

class ShowScoreReport extends React.Component {
  headerHTML = []
  bodyHTML = []
  footerHTML = []
  csvData = []
  fileName = ''
  cumulativeScore = 0;

  constructor (props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this)
  }

  componentDidMount () {
    const { modalInfo } = this.props;
    this.populateTables(modalInfo);
    document.body.style.cursor = 'default';
  }

  populateTables = (modalInfo) => {
    /* **************************  header ******************************************************* */
    $('#testName').text('Test name: ' + modalInfo.transcriptArray[0].dialog[0].prompt.testName)
    $('#testTIN').text('Test ID number: ' + modalInfo.transcriptArray[0].dialog[0].response.TIN)

    /* **************************  keyword scoring  ********************************************** */
    if (assessmentsUsed.keyword) {
      for (var i = 0; i < modalInfo.allScores[0].length; i++) {

        // question item with keyword scoring requested
        let maxScore = modalInfo.allScores[0][i].maxScore;
        let itemScore = modalInfo.allScores[0][i].itemScore;
        let explanation = modalInfo.allScores[0][i].explanation;
        let backgroundStyle = 'background: #ffffff;';

        //add "missing scoring parameters to explanation"
        let addExplain = "<span style='font-style: italic;'>The response did not contain specified right/wrong answers, or required keyword scoring parameters are missing.</span>";
        if ((explanation.includes("NO CORRECT OR INCORRECT ANSWERS FOUND")) &&
           (!explanation.includes("MAXPTS")||
            !explanation.includes("PERCENT CONTRIBUTION")||
            !explanation.includes("POINTS CONTRIBUTION"))) {
                explanation = explanation.replace('NO CORRECT OR INCORRECT ANSWERS FOUND', addExplain);
                itemScore = "";
        }

        //instruction item
        if (!modalInfo.transcriptArray[0].dialog[i].prompt.awaitResponse ) { //instructional item
          maxScore = "";
          itemScore = "";
          explanation = "instruction";
          backgroundStyle = 'background: #eeeeee; font-style: italic;'
        }

        //no keyword scoring requested
        if (maxScore === "NA") {
          maxScore = "";
          itemScore = "";
          explanation = "not requested";
          backgroundStyle = 'font-style: italic;';
        }

        $('#scoreTable').append(
          '<tr>' +
            '<td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
              (i + 1) +
            '</td>' +
            '<td style="border: solid thin gray; padding:5px; width: 10%;' +  backgroundStyle + ' text-align: center;">' +
              maxScore +
            '</td>' +
            '<td style="border: solid thin gray; padding:5px; width: 10%;' +  backgroundStyle + ' text-align: center;">' +
              itemScore +
            '</td>' +
            '<td style="border: solid thin gray; padding:5px; width: 70%; ' + backgroundStyle + ' text-align: left; width: 300px;">' +
              explanation +
            '</td>' +
          '</tr>'
        )
      }
    }

    /* ***************************************  ChatGPT scoring  ********************************************** */
    if (assessmentsUsed.chatGPT){
      Object.values(modalInfo.transcriptArray[0].dialog).forEach((element, index) => {
          if (element.assessments.chatAssessment) {
            let firstChar = element.assessments.chatAssessment[0];
            if(firstChar !== "{") { //not functional chat
              $('#AIscoreTable').append(
                '<tr><td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
                (index + 1) +
                '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px;">' +
                element.assessments.chatAssessment +
                '</td></tr>'
              )
            } else { // it is functional chat
              let ChatGPTobj = JSON.parse(element.assessments.chatAssessment);
              let jsonObjStr = jsonObjectToString(ChatGPTobj);
              $('#AIscoreTable').append(
                '<tr><td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
                (index + 1) +
                '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px;">' +
                jsonObjStr +
                '</td></tr>'
              )
            }
        } else {
          let instruction = "not requested";
          let style = '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px; font-style: italic;">'
          if (! element.prompt.awaitResponse) {
            instruction = "instruction";
            style = '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px; background: #eeee; font-style: italic;">';
          }
          $('#AIscoreTable').append(
            '<tr><td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
              (index + 1) + style + instruction +
            '</td></tr>'
          )
        }
      })
    }

    /* ************************************  Total Score (sum of Chat + Keyword)  ************************************ */

    if (this.cumulativeScore !== "") {
      $('#cumScore').append("<span style='background-color: white; border: solid thin gray; padding: 5px;'>" +
          this.cumulativeScore  + "</span>");
    } else {
      $('#cumScoreContainer').remove();
    }

    /* ******************************************  Pronunciation scoring  ******************************************* */
    if (assessmentsUsed.pronunciation){
      Object.values(modalInfo.transcriptArray[0].dialog).forEach((element, index) => {
          if (element.assessments.pronunciationAssessment) {
            let wordsString = "";
            let wordList =  JSON.parse(element.assessments.pronunciationAssessment);
            for (var i = 0; i < wordList.lowScores.length; i++){
              wordsString = wordsString + wordList.lowScores[i].word + ", ";
            }
            if (!wordsString.length) {
              wordsString = 'No mispronounced words identified.'
            } else {
              wordsString = 'Words difficult to transcribe: ' + wordsString
            }
            $('#PronunciationTable').append(
              '<tr><td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
                (index + 1) +
                '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px;">' +
                  wordsString +
                '</td></tr>'
            )
        } else {
          let instruction = "not requested";
          let style = '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px; font-style: italic;">'
          if (! element.prompt.awaitResponse) {
            instruction = "instruction";
            style = '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px; background: #eeee; font-style: italic;">';
          }
          $('#PronunciationTable').append(
            '<tr><td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
              (index + 1) + style + instruction +
            '</td></tr>'
          )
        }
      })
    }

    /* *********************************************  Grammar scoring  ********************************************** */
    if (assessmentsUsed.grammar){
      Object.values(modalInfo.transcriptArray[0].dialog).forEach((element, index) => {
          if (element.assessments.grammarAssessment) {
            $('#GrammarTable').append(
              '<tr><td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
                (index + 1) +
                '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px;">' +
                element.assessments.grammarAssessment +
                '</td></tr>'
            )
        } else {
          let instruction = "not requested";
          let style = '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px; font-style: italic;">'
          if (! element.prompt.awaitResponse) {
            instruction = "instruction";
            style = '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px; background: #eeee; font-style: italic;">';
          }
          $('#GrammarTable').append(
            '<tr><td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
              (index + 1) + style + instruction +
            '</td></tr>'
          )
        }
      })
    }

      /* *********************************************  Sentiment scoring  ********************************************** */
      if (assessmentsUsed.sentiment){
        Object.values(modalInfo.transcriptArray[0].dialog).forEach((element, index) => {
            if (element.assessments.sentimentAssessment) {
              let sentimentObj = JSON.parse(element.assessments.sentimentAssessment);
              let positives = sentimentObj.positives.toString().replaceAll(",",", ");
              let negatives = sentimentObj.negatives.toString().replaceAll(",",", ");
              let sentiment = sentimentObj.sentiment.toString();
              $('#SentimentTable').append(
                '<tr><td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
                  (index + 1) +
                  '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px;">' +
                    "positives: " + positives + '<br>' +
                    "negatives: " + negatives + '<br>' +
                    "overall sentiment: " + sentiment +
                  '</td></tr>'
              )
          } else {
            let instruction = "not requested";
            let style = '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px; font-style: italic;">'
            if (! element.prompt.awaitResponse) {
              instruction = "instruction";
              style = '</td><td style="border: solid thin gray; padding:5px; width: 90%; text-align: left; width: 300px; background: #eeee; font-style: italic;">';
            }
            $('#SentimentTable').append(
              '<tr><td  style="border: solid thin gray; padding:5px; width: 10%; text-align: center;">' +
                (index + 1) + style + instruction +
              '</td></tr>'
            )
          }
        })
      }
    /* ************************************************************************************************************** */
  }

  handleCancel (e) {
    const { hideModal, cancelHandler } = this.props
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler()
    }
    hideModal()
  }

  downloadCSV = () => {
    var element = document.querySelector('#CSVLINK')
    simulateMouseClick(element)
  }

  removeBreaks = brksStr => {
    //noop for now
    return brksStr
  }

  setEmptyReport = modalInfo => {
    this.bodyHTML.push(
      <div>
        <div className='modal-body' style={{ minHeight: '150px', maxHeight: '500px', overflow: 'auto' }} >
          <div className='keywordScoreHeading'
            style={{ fontSize: '18px',  padding: '50px',  textAlign: 'center', backgroundColor: 'white', border: 'solid thin lightgray', borderRadius: '10px'}}
          >
            No scoring requested
          </div>
        </div>
      </div>
    )
  }

  setTestReport = modalInfo => {
    this.bodyHTML.push(
      <div style={{ paddingLeft: '35px', marginBottom: '20px' }}>
        ANOTHER BUNCH OF STUFF GOES HERE
      </div>
    )
  }

  setHeader = modalInfo => {
    this.csvData.push(['Test Name: ' + modalInfo.transcriptArray[0].dialog[0].prompt.testName])
    this.csvData.push(['Test ID Number: ' + modalInfo.transcriptArray[0].dialog[0].response.TIN])

    this.headerHTML.push(
      <div>
        <div id='testName' style={{ paddingLeft: '30px' }}></div>
        <div id='testTIN' style={{ paddingLeft: '30px', paddingBottom: '10px' }} ></div>
      </div>
    )
  }

  setFooter = modalInfo => {
    this.fileName =
      modalInfo.transcriptArray[0].dialog[0].response.TIN +
      '_' +
      modalInfo.transcriptArray[0].dialog[0].prompt.testName
    this.footerHTML.push(
      <div className='modal-footer' style={{ padding: '0px' }}>
        <CSVLink id='CSVLINK' style={{ color: 'transparent', fontZize: '0.1px' }}
                              data={this.csvData}
                              filename={this.fileName}></CSVLink>
        <div className='btn-group pull-left' style={{ margin: '5px 15px' }}>
          <button className='blueButton' style={{}} onClick={this.downloadCSV}>
            Download .csv file
          </button>
        </div>
        <div className='btn-group pull-right' style={{ margin: '5px 15px' }}>
          <button id='' className='blueButton' onClick={this.handleCancel}>
            CLOSE
          </button>
        </div>
      </div>
    )
  }

  setKeywordReport = modalInfo => {
    /* ********************************** prepare data for download to csv file ******************************** */
    this.csvData.push([' '])
    this.csvData.push(['KEYWORD SCORES'])
    this.csvData.push([ 'Item Number', 'Maximum Score', 'Assigned Score', 'Explanation' ])

    /* *************************************************** */

    for (var i = 0; i < modalInfo.allScores[0].length; i++) {
      let maxScore    = modalInfo.allScores[0][i].maxScore;
      let itemScore   = modalInfo.allScores[0][i].itemScore;
      let explanation = modalInfo.allScores[0][i].explanation;
          explanation = explanation.replace(/\r|\n/g, " ");
          explanation = explanation.replace(/,/g, ' –');
          explanation = explanation.replace(/<br>/g, '; ');
          explanation = explanation.replace(/;   ;/g, '; ');

      if (!modalInfo.transcriptArray[0].dialog[i].prompt.awaitResponse ) { //instructional item
        maxScore = "";
        itemScore = "";
        explanation = "instruction";
      }

      if (maxScore === "NA") {
        maxScore = "";
        explanation = "not requested";
        itemScore = "";
      }

       //add "missing scoring parameters to explanation"
       let addExplain = "One or more scoring parameters are missing for this item.";
       if (( explanation.includes("NO CORRECT OR INCORRECT ANSWERS FOUND")) &&
           (!explanation.includes("MAXPTS")||
            !explanation.includes("PERCENT CONTRIBUTION")||
            !explanation.includes("POINTS CONTRIBUTION"))) {
              explanation = explanation.replace('NO CORRECT OR INCORRECT ANSWERS FOUND', addExplain);
              itemScore = "";
            }
        this.csvData.push([i + 1, maxScore, itemScore, explanation]);
    }


    /* ********************************** set HTML for on page score display ******************************** */
    this.bodyHTML.push(
      <div>
        <div className='keyword-body' style={{ margin: '30px' /* minHeight:'250px', maxHeight:'500px', overflow: 'auto'*/ }} >
          <div className='keywordScoreHeading' style={{}}>
            KEYWORD SCORES
          </div>
          <table style={{ backgroundColor: 'white', width: '100%'  }}><tbody id='scoreTable'><tr>
                <th style={{ border: 'solid thin gray', padding: '5px', width: '10%'}} >Item Number</th>
                <th style={{border:  'solid thin gray', padding: '5px', width: '10%'}}>Maximum Score</th>
                <th style={{border:  'solid thin gray', padding: '5px', width: '10%'}}>Assigned Score</th>
                <th style={{border:  'solid thin gray', padding: '5px', width: '70%'}}>Explanation</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  setChatGPTReport = modalInfo => {
  /* ********************************** prepare data for download to csv file ******************************** */
    this.csvData.push([' '])
    this.csvData.push(['AI SCORES'])
    this.csvData.push(['Item Number', 'AI Score'])

    Object.values(modalInfo.transcriptArray[0].dialog).forEach((element, index) => {
      if (element.assessments.chatAssessment) {
        let firstChar = element.assessments.chatAssessment[0];
        if(firstChar !== "{") { //not functional chat
          this.csvData.push([index + 1, element.assessments.chatAssessment])
        } else { //is a functional chat
          let ChatGPTobj = JSON.parse(element.assessments.chatAssessment);
          let jsonObjStr = jsonObjectToString(ChatGPTobj);
          this.csvData.push([index + 1, jsonObjStr])
        }
      } else {
        let instruction = "not requested";
        if (! element.prompt.awaitResponse) instruction = "instruction";
        this.csvData.push([index + 1, instruction])
      }
    })
    /* ********************************** set HTML for on page score display ******************************** */
    this.bodyHTML.push(
      <div>
        <div className='ChatGPT-body' style={{ margin: '30px' /* minHeight:'250px', maxHeight:'500px', overflow: 'auto'*/ }} >
          <div className='ChatGPTScoreHeading' style={{}}> AI ASSESSMENTS </div>
            <table style={{ backgroundColor: 'white', width: '100%' }}><tbody id='AIscoreTable'><tr>
                <th style={{ border: 'solid thin gray', padding: '5px', width: '10%' }} >Item Number</th>
                <th style={{ border: 'solid thin gray', padding: '5px', width: '90%' }} >Assessment</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  setCumScoreReport(modalInfo) {
    let targetIndex = modalInfo.filteredTable.findIndex(obj => obj.TIN === modalInfo.transcriptArray[0].TIN);
    this.cumulativeScore = modalInfo.filteredTable[targetIndex].cumulativeScore;
    if (this.cumulativeScore !== "") {
      this.csvData.push([' '])
      this.csvData.push(['CUMULATIVE SCORE', this.cumulativeScore])
    }
    // add the html stem;  either score will be appended or stem will be removed depending on score existence
      this.bodyHTML.push(
        <div id="cumScoreContainer">
          <div className='CumScore-body' style={{ margin: '30px'}} >
            <div id='cumScore' style={{}}>CUMULATIVE SCORE: </div>
          </div>
        </div>
      )
  }

  setPronunciationReport(modalInfo) {
  /* ********************************** prepare data for download to csv file ******************************** */
  this.csvData.push([' '])
  this.csvData.push(['PRONUNCIATION SCORES'])
  this.csvData.push(['Item Number', 'PRONUNCIATION'])

  Object.values(modalInfo.transcriptArray[0].dialog).forEach((element, index) => {
    let wordsString = "";
    if (element.assessments.pronunciationAssessment) {
      let wordList =  JSON.parse(element.assessments.pronunciationAssessment);
      for (var i = 0; i < wordList.lowScores.length; i++){
        wordsString = wordsString + wordList.lowScores[i].word + ", ";
      }
    } else {
      wordsString = "";
    }

    if (!wordsString.length) {
      wordsString = 'No mispronounced words identified.'
    } else {
      wordsString = 'Words difficult to transcribe: ' + wordsString
    }

    if (element.assessments.pronunciationAssessment) {
      this.csvData.push([index + 1, wordsString])
    } else {
      let instruction = "not requested";
      if (! element.prompt.awaitResponse) instruction = "instruction";
      this.csvData.push([index + 1, instruction])
    }
  })
  /* ********************************** set HTML for on page score display ******************************** */
  this.bodyHTML.push(
    <div>
      <div className='Pronunciation-body' style={{ margin: '30px'}} >
        <div className='PronunciationHeading' style={{}}>PRONUNCIATION</div>
          <table style={{ backgroundColor: 'white', width: '100%'  }}><tbody id='PronunciationTable'><tr>
              <th style={{ border: 'solid thin gray', padding: '5px', width: '10%' }} >Item Number</th>
              <th style={{ border: 'solid thin gray', padding: '5px', width: '90%' }} >Pronunciation</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )}

  setGrammarReport(modalInfo) {
  /* ********************************** prepare data for download to csv file ******************************** */
  this.csvData.push([' '])
  this.csvData.push(['GRAMMAR SCORES'])
  this.csvData.push(['Item Number', 'GRAMMAR'])

  Object.values(modalInfo.transcriptArray[0].dialog).forEach((element, index) => {
    if (element.assessments.grammarAssessment) {
      let noCRCLstr = element.assessments.grammarAssessment.replace(/\r|\n/g, ".  ");
      noCRCLstr = noCRCLstr.replace(/:./g, ':');
      noCRCLstr = noCRCLstr.replace(/,/g, ' –');
      noCRCLstr = noCRCLstr.replaceAll(/:/g, ": ");
      noCRCLstr = noCRCLstr.replace(/"/g, "'");
      this.csvData.push([index + 1, noCRCLstr])
    } else {
      let instruction = "not requested";
      if (! element.prompt.awaitResponse) instruction = "instruction";
      this.csvData.push([index + 1, instruction])
    }
  })
  /* ********************************** set HTML for on page score display ******************************** */
  this.bodyHTML.push(
    <div>
      <div className='Grammar-body' style={{ margin: '30px' /* minHeight:'250px', maxHeight:'500px', overflow: 'auto'*/ }} >
        <div className='GrammarScoreHeading' style={{}}> GRAMMAR </div>
          <table style={{ backgroundColor: 'white', width: '100%'  }}><tbody id='GrammarTable'><tr>
              <th style={{ border: 'solid thin gray', padding: '5px', width: '10%' }} >Item Number</th>
              <th style={{ border: 'solid thin gray', padding: '5px', width: '90%' }} >Grammar</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )}

  setSentimentReport = (modalInfo) => {
    /* ********************************** prepare data for download to csv file ******************************** */
    this.csvData.push([' '])
    this.csvData.push(['SENTIMENT SCORES'])
    this.csvData.push(['Item Number', 'SENTIMENT'])
    Object.values(modalInfo.transcriptArray[0].dialog).forEach((element, index) => {
      if (element.assessments.sentimentAssessment) {
        let sentimentObj = JSON.parse(element.assessments.sentimentAssessment);
        let positives = sentimentObj.positives.toString().replaceAll(",",", ");
        let negatives = sentimentObj.negatives.toString().replaceAll(",",", ");
        let sentiment = sentimentObj.sentiment.toString();
        let sentimentStr =  "positives: " + positives + ';  ' +
                            "negatives: " + negatives + ';  ' +
                            "overall sentiment: " + sentiment;

        this.csvData.push([index + 1, sentimentStr])
      } else {
        let instruction = "not requested";
        if (! element.prompt.awaitResponse) instruction = "instruction";
        this.csvData.push([index + 1, instruction])
      }
    })
    /* ********************************** set HTML for on page score display ******************************** */
    this.bodyHTML.push(
      <div>
        <div className='Sentiment-body' style={{ margin: '30px' /* minHeight:'250px', maxHeight:'500px', overflow: 'auto'*/ }} >
          <div className='SentimentScoreHeading' style={{}}> SENTIMENT </div>
            <table style={{ backgroundColor: 'white', width: '100%'  }}><tbody id='SentimentTable'><tr>
                <th style={{ border: 'solid thin gray', padding: '5px', width: '10%' }} >Item Number</th>
                <th style={{ border: 'solid thin gray', padding: '5px', width: '90%' }} >Sentiment</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  )}

  setAssessmentsUsed = transcriptArray => {
    /*  keyword scoring  */
    Object.values(transcriptArray[0].dialog).forEach(element => {
      if (element && element.assessments && element.assessments.scoringAssessment) assessmentsUsed.keyword = true;
    })

    /*  ChatGPT scoring */
    Object.values(transcriptArray[0].dialog).forEach(element => {
      if (element && element.assessments && element.assessments.chatAssessment) assessmentsUsed.chatGPT = true;
    })

    /*  Pronunciation scoring   */
    Object.values(transcriptArray[0].dialog).forEach(element => {
      if (element && element.assessments && element.assessments.pronunciationAssessment) assessmentsUsed.pronunciation = true;
    })

    /*  Grammar scoring  */
    Object.values(transcriptArray[0].dialog).forEach(element => {
      if (element && element.assessments && element.assessments.grammarAssessment) assessmentsUsed.grammar = true;
    })

    /*  Sentiment scoring  */
    Object.values(transcriptArray[0].dialog).forEach(element => {
      if (element && element.assessments && element.assessments.sentimentAssessment) assessmentsUsed.sentiment = true;
    })
  }

  render () {
    const { modalInfo } = this.props
    this.headerHTML = []
    this.bodyHTML = []
    this.footerHTML = []
    this.csvData = []

    this.setAssessmentsUsed(modalInfo.transcriptArray)

    this.setHeader(modalInfo)
    if ( !assessmentsUsed.keyword && !assessmentsUsed.chatGPT && !assessmentsUsed.pronunciation && !assessmentsUsed.grammar) {
      this.setEmptyReport(modalInfo) //no assessments were requested
    }
    else { //build the report for any assessments that were requested
      if (assessmentsUsed.chatGPT) this.setChatGPTReport(modalInfo)
      if (assessmentsUsed.keyword) this.setKeywordReport(modalInfo)
          this.setCumScoreReport(modalInfo) // will add row to report with cum score if it is in the assignment record
      if (assessmentsUsed.pronunciation) this.setPronunciationReport(modalInfo)
      if (assessmentsUsed.grammar) this.setGrammarReport(modalInfo)
      if (assessmentsUsed.sentiment) this.setSentimentReport(modalInfo)
    }
    this.setFooter(modalInfo)

    return (
      <div id='fullReport'>
        <div id='header'>{this.headerHTML}</div>
        <div id='body' style={{ overflow: 'auto', maxHeight: '500px' }}>
          {this.bodyHTML}
        </div>
        <div id='footer'>{this.footerHTML}</div>
      </div>
    )
  }
}

export default ShowScoreReport
