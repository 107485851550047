import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';

const _staffSignup = (email, role) => {
  return (
    <div>
      <p>
        <strong>To:</strong>
        {email}
      </p>
      <br/>
      <p>You have been authorized as a TellMeThis user, in the role of {role}.</p>
      <p>To complete your registration please click on this [url].</p>
      <p>This link will be good for 24 hours. After that you will need to contact your supervisor to resubmit your authorization.
</p>
      <br/>
      <p>If this email was sent in error just disregard this email.</p>

    </div>

  )
}

const staffSignup = (email, role) => {
  return reactElementToJSXString(_staffSignup(email, role));
}


export default staffSignup;