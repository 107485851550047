import {RETRIEVE_SAVED_PROMPT} from '../_CONSTANTS'
import md5 from "md5";
import $ from 'jquery';

export const retrieve_Saved_Prompt = (toBeReplacedId, retrievedPrompt) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      const testName = getState().create_Table.testName;
      const accountId = getState().create_Table.accountId;
      retrievedPrompt.promptId = md5(retrievedPrompt.promptText).substring(0, 23) + (new Date().getTime()).toString(36);
      retrievedPrompt.testName = testName;
      retrievedPrompt = JSON.stringify(retrievedPrompt)
      $.ajax({
        url: '/test/replacePrompt',
        // url:'/testerror',
        type: "POST",
        data: {
          toBeReplacedId, retrievedPrompt, testName, accountId
        },
        success: function (prompt) {
          prompt.scoringParameters = JSON.parse(prompt.scoringParameters);
            dispatch({
              type: RETRIEVE_SAVED_PROMPT,
              payload: prompt
            })
          resolve(prompt);
        },
        error: function (err) {
          reject(err);
        }

      })
    })
  }
}