
import {GET_NAMED_INVITE_TEMPLATES} from '../_CONSTANTS';
import $ from 'jquery'

export const get_Named_Invite_Templates = (accountId) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject)=>{
      let url = `/test/getNamedInviteTemplates?accountId=${accountId}`;
      // let url = '/testerror';

      $.ajax({
        url: url,
        type: "GET",
        success: (namedInviteTemplates) => {
          dispatch({
            type:GET_NAMED_INVITE_TEMPLATES,
            payload: namedInviteTemplates
          });
          resolve(namedInviteTemplates)
        },
        error: (err) => {
          console.log(err);
          reject(err);
        }
      })
    })
  }
}