import React from 'react';

class InviteAll extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleCancel(e) {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  handleConfirm(e) {
    const {hideModal, confirmHandler} = this.props;
    e.preventDefault();
     hideModal();
    if (confirmHandler) {
      confirmHandler()
    }

  }

  render() {
    return (
      <div>
        <div className="modal-body">
          {/*<h2 style={{textAlign: 'center', color:'rgb(45,78,117)'}}>WARNING </h2>*/}
          <p style={{textAlign: 'center', fontWeight: '400'}}>
            Proceeding will send invitation emails to all particpants who meet the following criteria:
          </p>

          <ol type="1" style={{marginLeft:'130px'}}>
            <li>Have their 'participant name' entered.</li>
            <li>Have a valid email address entered.</li>
            <li>Have not already been sent an invitation email.</li>
          </ol>

          <p style={{
            textAlign: 'center',
            fontWeight: '400',
            color: 'rgb(0,0,102',
            fontFamily: 'Arial'
          }}>Do you want to proceed?</p>
        </div>

        <div className="modal-footer">
          <div className="btn-group pull-right" style={{margin: '5px 0'}}>

            <button className="blueButton pull-right"
                    onClick={this.handleConfirm}
                    style={{marginLeft: '5px'}}>OK
            </button>
            <button className="blueButton pull-right"
                    onClick={this.handleCancel}>
              Cancel
            </button>

          </div>
        </div>
      </div>
    )
  }
}

export default InviteAll;