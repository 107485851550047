import React, {Component} from 'react';
import md5 from 'md5';
import classnames from 'classnames';
import TMTContentEditable from '../Common/TMTContentEditable';
import ReactTooltip from 'react-tooltip';

class CreateRow extends Component {
  voicePrompt = null;

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      promptText: '',
      promptId: '',
      promptPosition: '',
      isBlank: true,
      isDeleted: false,
      URL: null,
      savePromptEnabled: true,
      retrievedPromptEnabled: true
    };
  }

  componentDidMount() {
    const {row} = this.props;
    this.setState({
      _id: row._id,
      promptText: row.promptText,
      imageURL: row.imageURL,
      promptId: row.promptId,
      promptPosition: row.promptPosition,
      isBlank: row.isBlank,
      isDeleted: row.isDeleted,
      URL: row.URL,
      savePromptEnabled: row.promptText,
      retrievedPromptEnabled: !!row.promptPosition && !this.props.isDeployed
    })
    //this mechanism for playing sound from: https://webdesign.tutsplus.com/tutorials/create-a-customized-html5-audio-player--webdesign-7081
    this.voicePrompt = new Audio(row.URL);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.row.promptText !== this.props.row.promptText) {
      this.setState({promptText: this.props.row.promptText, savePromptEnabled: true});
    }
  }


  handleSavePromptEnabled = () => {
    this.setState({savePromptEnabled: !!this.state.promptText})
  }

  handleRetrievePromptEnabled = () => {
    const {isDeployed} = this.props;
      this.setState({retrievedPromptEnabled: !!this.state.promptPosition && !isDeployed});
  }

  handleDelete = () => {
    const {deleteRow, setDocumentDirty} = this.props;
    deleteRow(this.state._id, this.state.promptPosition, this.state.promptText);
    setDocumentDirty();
  }

  handleSavePromptToBank = (e) => {
    if (this.state.savePromptEnabled) {
      const {savePromptToBank, row} = this.props;
      savePromptToBank(row);
    }
  }

  handleRetrievePrompts = () => {
    const {retrievePrompts, user, isDeployed} = this.props;
    if(!isDeployed) {
      retrievePrompts(user.accountId, this.state._id);
    }

  }

  handlePromptTextChange = (e) => {
    const {createNewRow, setDocumentDirty} = this.props;
    //let promptText = e.target.value.replace(/&nbsp;/gi, ' '); //seems no longer necessary
    let promptText = e.target.value;
    if (this.state.isBlank) {
      createNewRow();
      this.setState({isBlank: false});
    }
    this.setState({
      promptText: promptText
    }, () => {
      this.handleSavePromptEnabled();
    });
    setDocumentDirty();
  }

  handlePromptTextBlur = (e) => {
    const {handleRowChange} = this.props;
    let data = {...this.state};

    data.promptText = data.promptText.replace(/<br>/gi, ''); //for firefox
    data.promptText = data.promptText.replace(/<div>/gi, '');
    data.promptText = data.promptText.replace(/<\/div>/gi, '');
    data.promptText = data.promptText.replace(/&nbsp;/gi, ''); //for chrome
    this.setState({promptText: data.promptText});

    if (this.state.promptText) {
      // data.promptId = md5(this.state.promptText)  in a test the prompt can be the
      // same and md5 creates duplicate ids
      data.promptId = md5(this.state.promptText).substring(0, 23) + (new Date().getTime()).toString(36);
      data.URL = `${window.URLPrefix}${data.promptId}.mp3`
      data.isBlank = false;
    }
    handleRowChange(data, "promptText");
    this.setState({...data},()=>{this.handleSavePromptEnabled()});
  }

  handlePromptPositionChange = (e) => {
    const {createNewRow, setDocumentDirty} = this.props;
    let promptPosition = e.target.value;
    if (this.state.isBlank) {
      createNewRow();
      this.setState({isBlank: false});
    }
    this.setState({
      promptPosition: promptPosition,
    });
    setDocumentDirty();
  }

  handlePromptPositionBlur = (e) => {
    const {handleRowChange} = this.props;
    let data = {...this.state};
    let isNumber = /^\d+$/.test(this.state.promptPosition);
    if (isNumber) {
      data.isBlank = false;
      data.promptPosition = this.state.promptPosition;
      let anyDups = handleRowChange(data, "promptPosition");
      if (anyDups) {
        this.setState({promptPosition: ""});
        data.promptPosition = "";
        handleRowChange(data, "promptPosition");
      }
      this.setState({...data}, () => {
        this.handleSavePromptEnabled();
        this.handleRetrievePromptEnabled();
      });
    } else { //promptPosition can only be empty string here. Filtered by numeric setting of TMTContentEditable
      handleRowChange(data, "promptPosition");
      this.handleSavePromptEnabled();
      this.handleRetrievePromptEnabled();
    }
  }


  playSound = (e) => {
    if (!this.state.isBlank && this.state.promptText && this.state.promptPosition) {
      this.voicePrompt.src = this.state.URL;
      this.voicePrompt.play()
        .then(() => {
            console.log('sound played')
          },
          (err) => {
            console.log(err)
          });
    }
  }

  handleGetAssessmentOptions = () => {
    const {row, getAssessmentOptions} = this.props;
    if (row.awaitResponse) {
      getAssessmentOptions({...row});
    }
  }

  handleCreateOptions = (e) => {
    const {row, handleCreateOptionsModal} = this.props;
    handleCreateOptionsModal(row)
  }

  getIndicatorText = () => {
    if (this.state.isBlank) {
      return ''
    } else if ((this.state.promptText === "") || (this.state.promptPosition === "")) {
      return 'A prompt and a unique sequence number are required';
    }
    return '';
  }

  getIndicatorClass = () => {
    if (this.state.isBlank) {
      return 'lineIndicatorTransparent'
    } else if ((this.state.promptText === "") || (this.state.promptPosition === "")) {
      return 'lineIndicatorOrange';
    }
    return 'lineIndicatorGreen';
  }

  getIndicatorCursorType = () => {
    if (this.state.isBlank) {
      return 'none'
    } else if ((this.state.promptText === "") || (this.state.promptPosition === "")) {
      return 'pointer';
    } else return 'none';
  }

  getAudioActive = () => {
    if ((this.state.isBlank) || (this.state.promptText === "") || (this.state.promptPosition === "")) {
      return 'gray'
    } else {
      return 'black'
    }
  }

  render() {
    const {row, readOnly, instrumentType, isDeployed} = this.props;
    if (row.isDeleted) {
      return null
    }
    let cursorStyle = readOnly ? 'default' : 'text';
    let rowClasses = classnames('', {row_invisible: this.state.isDeleted})
    let iconSaveClasses = classnames(' fa-solid fa-piggy-bank iconSave', {inactive: !this.state.savePromptEnabled})
    let iconRetrieveClasses = classnames(' fa-solid fa-piggy-bank iconRetrieve',
      {inactive: !this.state.retrievedPromptEnabled})
    let audioActive = this.getAudioActive();
    let indicatorText = this.getIndicatorText();
    let indicatorClass = this.getIndicatorClass();
    let indicatorCursorType = this.getIndicatorCursorType();
    var audioCursorType = 'pointer';
    var audioIconClass = 'hover-control';
    if (audioActive === 'gray') {
      audioCursorType = 'default'
      audioIconClass = 'unhover-control';
    }
    let scoreIconColor = 'orange';
    let assessmentCursor = 'pointer';
    if (!row.awaitResponse) {
      scoreIconColor = 'transparent';
      assessmentCursor = 'default';
    } else if (row.scoringParameters.checkbox || row.chatGPTPrompt.checkbox ||
      row.checkGrammar || row.checkPronunciation || row.checkSentiment.checkbox) {
      scoreIconColor = 'green'
    }

    return (

      <tr className={rowClasses}
          style={{visibility: this.state.promptText.length === 0 && readOnly ? 'hidden' : 'visible'}}
      >
        <td className="seqNumb">
          <ReactTooltip id="row-indicator" place="right" type="dark"/>
          <div className={indicatorClass}
               style={{cursor: indicatorCursorType}}
               data-tip={indicatorText}
               data-for="row-indicator">

          </div>
          <div className="deleteIcon">
            {this.state.isBlank || readOnly ? null :
              <span className="hover-control">
                <i className="far fa-minus-square"
                   onClick={this.handleDelete}
                   title="delete this row">
                </i>
                 <i className="fas fa-minus-square"
                    onClick={this.handleDelete}
                    title="delete this row">
                </i>
              </span>
            }
          </div>
          <TMTContentEditable
            text={this.state.promptPosition.toString()}
            classes="seqNumbDiv"
            style={{cursor: cursorStyle}}
            onChange={this.handlePromptPositionChange}
            onBlur={this.handlePromptPositionBlur}
            numeric={true}
            highlightText={true}
            disabled={readOnly}
          />
        </td>
        <td className="iconBank">
          <span title="save prompt">
          <i className={iconSaveClasses}
             onClick={this.handleSavePromptToBank}
          ></i>
          </span>
        </td>
        <td className="iconBank">
          <span title="retrieve prompt">
          <i className={iconRetrieveClasses}
             onClick={this.handleRetrievePrompts}
          ></i>
          </span>
        </td>
        <td className="promptText">
          <TMTContentEditable
            text={this.state.promptText}
            classes="promptDiv"
            style={{cursor: cursorStyle}}
            onChange={this.handlePromptTextChange}
            onBlur={this.handlePromptTextBlur}
            disabled={readOnly}
          />
        </td>
        <td className="promptId">
          <div>{this.state.promptId}</div>
        </td>


        <td className="spokenPrompt">
          {this.state.isBlank ? null :
            <span title="play audio prompt" className={audioIconClass}>
              <i className="far fa-play-circle"
                 style={{color: audioActive, cursor: audioCursorType}}
                 onClick={this.playSound}
              ></i>
             <span><i className="fas fa-play-circle"
                      style={{color: audioActive, cursor: audioCursorType}}
                      onClick={this.playSound}
             ></i>
               </span>
            </span>
          }
        </td>
        <td className="scoring">
          {this.state.isBlank ? null :
            <span title='click to enter options'>
              <i className="fas fa-tasks"
                 style={{cursor: assessmentCursor}}
                 onClick={this.handleCreateOptions}
              ></i>
          </span>
          }
        </td>
        <td className="scoring">
          {this.state.isBlank ? null :
            <span title='click to enter assessment options'>
            <i className="fas fa-edit"
               style={{cursor: assessmentCursor, color: scoreIconColor}}
               onClick={this.handleGetAssessmentOptions}
            ></i>
          </span>
          }
        </td>

      </tr>
    )
  }
}

export default CreateRow;