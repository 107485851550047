import {LOGIN_USER} from '../_CONSTANTS';
import $ from 'jquery';

export const loginUser = (data) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let url = '/auth/login';
      //let url = '/testerror';
      $.ajax({
        url: url,
        type: "POST",
        data: data,
        success: (user) => {
          if (!user || !user.verified || user.active === 'inactive') {
            resolve(null);
          }
          else {
            dispatch({
              type: LOGIN_USER,
              payload: user
            });
            localStorage.clear();
          }
          resolve(user);
        },
        error: (err) => {
          if (err.status === 401) {
            resolve(null)
          }
          else {
            reject(err);
          }
        }
      })
    })
  }
}

