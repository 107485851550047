import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classnames from 'classnames';
import {setActiveModule} from '../../actions/setActiveModule';
import {setModalInfo} from '../../actions/setModalInfo';
import {save_Invite_Template} from '../../actions/AnonAssign/save_Invite_Template';
import AnonAssignSelector from './AnonAssignSelector';
import AnonAssignTable from './AnonAssignTable';
import Navbar from '../Navbar';
import AnonAssignFooter from "./AnonAssignFooter";
import EmailInviteController from './ModalEmailInvite/EmailInviteController';
import EmbedCreateController from './ModalEmbedCreator/EmbedCreateController';
import _ from 'lodash';



class AnonAssign extends Component {
  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('anonAssign');
    if (oldState){
      this.state = oldState;
      localStorage.removeItem('anonAssign');
    }
    else {
      this.state = {
        visible: false,
        showBody: false,
        existingGroups: []
      }
    }
  }

  componentDidMount() {
    const {setActiveModule} = this.props;
    setActiveModule('AnonAssign');
    this.setState({
      visible: true,
    });
  }


  showTable = (bool) => {
    this.setState({showBody: bool})
  }

  saveExistingGroups = (existingGroups) => {
    this.setState({existingGroups: existingGroups});
  }

  openEmailModal = (assignment) => {
    const {setModalInfo, inviteTemplates, save_Invite_Template, env} = this.props;
    let activeTemplate = inviteTemplates.find((template) => {
      return template.name === 'default';
    })
    setModalInfo({
      name: 'anonInviteEmail',
      TIN: assignment.TIN,
      testName: assignment.testName,
      accountId: assignment.accountId,
      inviteTemplates: inviteTemplates,
      activeTemplate: activeTemplate,
      saveTemplate: save_Invite_Template,
      //TAKER_HOST: env.TAKER_HOST
      TAKER_HOST: window.TAKER_HOST
    });
  }

  openEmbedModal = (TIN, testName) => {
    const {setModalInfo, user, env} = this.props;
    setModalInfo({
      name: 'anonEmbedCreate',
      TIN: TIN,
      testName: testName,
      accountId: user.accountId,
      //TAKER_HOST: env.TAKER_HOST
      TAKER_HOST: window.TAKER_HOST
    });
  }

  cancelHandler = () => {
    const {modalInfo} = this.props;
    switch (modalInfo.name) {
      case "anonInviteEmail":
        console.log('cancel handler called.')
        break;
      default:
        console.log("cancel handler fallthrough - shouldn't get here")
    }
  }

  confirmHandler = () => {
    const {modalInfo} = this.props;
    switch (modalInfo.name) {
      case "anonInviteEmail":
        console.log('confirm handler called.')
        break;
      default:
        console.log("confirm handler fallthrough - shouldn't get here")
    }
  }

  embedCancelHandler = () => {
    const {modalInfo} = this.props;
    switch (modalInfo.name) {
      case "anonEmbedCreate":
        console.log('cancel handler called.')
        break;
      default:
        console.log("cancel handler fallthrough - shouldn't get here")
    }
  }

  embedConfirmHandler = () => {
    const {modalInfo} = this.props;
    switch (modalInfo.name) {
      case "anonEmbedCreate":
        console.log('confirm handler called.')
        break;
      default:
        console.log("confirm handler fallthrough - shouldn't get here")
    }
  }

  componentWillUnmount (){
    if(!localStorage.getItem('anonAssign_reset')) {
      localStorage.setObject('anonAssign', this.state);
    }
  }

  render() {
    let {history, user} = this.props;
    let mainClasses = classnames('main', {mainVisible: this.state.visible})
    const {setModalInfo, modalInfo} = this.props;
    if(_.isEmpty(user)) {
      history.push('/')
      return (
        null
      )
    }

    return (
      <span>
        <Navbar
          history={history}
        />
        <div className={mainClasses}>

          <AnonAssignSelector
            showTable={this.showTable}
            saveExistingGroups={this.saveExistingGroups}
          />
          {this.state.showBody ?
            <div>
              <AnonAssignTable
                existingGroups={this.state.existingGroups}
                openEmailModal={this.openEmailModal}
                openEmbedModal={this.openEmbedModal}
              />
              <AnonAssignFooter
                 history = {history}
                  />
            </div>
            : null }
        </div>
         <EmailInviteController
           setModalInfo={setModalInfo}
           modalInfo={modalInfo}
           confirmHandler={this.confirmHandler}
           cancelHandler={this.cancelHandler}
         />
         <EmbedCreateController
           setModalInfo={setModalInfo}
           modalInfo={modalInfo}
           confirmHandler={this.embedConfirmHandler}
           cancelHandler={this.embedCancelHandler}
         />
    </span>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    modalInfo: state.modalInfo,
    inviteTemplates: state.inviteTemplates,
    user: state.user,
    env: state.env
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setActiveModule,
    setModalInfo,
    save_Invite_Template
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonAssign);