import React from 'react';
import Autosuggest from 'react-autosuggest';

// Imagine you have a list of fruits that you'd like to autosuggest.
const fruits = [
  'banana', 'baseball', 'apple', 'pinapple', 'orange'

];
const selectedFruits = [];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return !inputLength ? [] : fruits.filter((fruit) => {
      return fruit.toLowerCase().includes(inputValue);
      // return fruit.toLowerCase().slice(0, inputLength) === inputValue
    }
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

// const getSuggestionValue = (suggestion) => suggestion.name;
const getSuggestionValue = (suggestion) => {
  return suggestion
}

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion}
  </div>
);

class CreateSurvey extends React.Component {
  constructor(props) {
    super(props);

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: []
    };
  }

  onChange = (event, {newValue, method}) => {
    console.log(method);
    this.setState({
      value: newValue
    });
  };

  onKeyPress = e => {
    console.log('ke pressed');
    // if (e.key === 'Enter') {
    //   // Add text to the list of displayed tags
    //   console.log('return')
    // }
    console.log(e.key);
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({value}) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (e) => {
    const currentSelection = (e.currentTarget.childNodes[0].innerHTML);
    selectedFruits.push(currentSelection);
    console.log(selectedFruits);
  }

  render() {
    const {value, suggestions} = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Fruits',
      value,
      onChange: this.onChange,
    };

    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onKeyDown={this.onKeyPress}
      />
    );
  }
}

export default CreateSurvey;