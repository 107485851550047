
import { SET_SOCKET } from '../actions/_CONSTANTS';

export default (state, action) => {
  if(state === undefined) {
    state = {};
  }
  switch (action.type) {
    case SET_SOCKET:
      return action.payload;
    default:
      return state;
  }
};