import React from 'react';
import {toast} from 'react-toastify';

//docs for react-toastify are at: https://github.com/fkhadra/react-toastify#installation

//demo: https://fkhadra.github.io/react-toastify/

function toastrMessage(faIconString, message) {
    return (
      <div style={{display: 'flex'}}>
        <div style={{width: '10%'}}>
          <i className={faIconString}></i>
        </div>
        <div style={{width: '85%', marginLeft: '3px'}}>
          {message}
        </div>
      </div>
    )
  }

function showToastr(faIconString, message, options) {


  let msg = toastrMessage(faIconString, message)
  toast(msg, {
    ...{type: toast.TYPE.ERROR, closeButton: false},
    ...options
  })
}

export default showToastr;