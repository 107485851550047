import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';


class _Home extends React.Component {
  componentDidMount() {
    const {user, history, subPath} = this.props;

    if (_.isEmpty(user)) {
      history.replace('/home')
    } else if (subPath) {
      history.replace(`/manage/${subPath}`)
    } else {
      const managePath = user.visitsHome ? 'surveys' : 'about'
      history.replace(`/manage/${managePath}`)
    }

  }

  //  componentDidMount() {
  //   const {user, history, subPath} = this.props;
  //     _.isEmpty(user) ? history.replace('/home') : history.replace(`/manage/${subPath || 'about'}`)
  //
  // }


  render() {
    return (
      <span>
      </span>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    subPath: state.subPath
  };
};


export default connect(mapStateToProps, null)(_Home);