import React from 'react';
import $ from 'jquery';
import mark from 'mark.js';

var responseDataStash = [];

class Search extends React.PureComponent {
  currentMousePos = {x: -1, y: -1}; // continuously updated mouse positions available to tooltip
  show = 'showall'; //or showselected
  hitArray = [];
  containerId = '';
  //responseData = [];
  wizardOn = false;

  state = {
    mounted: false
  }

  componentDidMount() {

    const {isDerivative} = this.props;
    if (!isDerivative) {
      window.startWizard = this.startWizard;
      window.stopWizard = this.stopWizard;
      window.getDerivativeData = this.getDerivativeData;
    }

    this.containerId = this.refs.container.id;
    this.setState({
      mounted: true
    });
  }


  /* *************************** functions to call from external wizard  ***************************************** */

  startWizard = () => {
    this.wizardOn = true;
    this.turnSelectionsGreen();
    return !!this.hitArray.length
  }

  stopWizard = () => {
    this.wizardOn = false;
    this.turnSelectionsYellow();
  }

  getDerivativeData = () => {
    window.derivativeData.push(JSON.parse(JSON.stringify(responseDataStash)));
    window.childIds.push(new Date().getTime().toString().slice(-6));
  }

  /* ********************************************************************************************************** */

  setSelectedStatus = () => {
    if (this.hitArray.length > 0) {
      window.areAnySelected(true);
    } else {
      window.areAnySelected(false);
    }
  }

  turnSelectionsGreen = () => {
    const {analysisId} = this.props;
    $('#search-' + analysisId).find($("td")).each(function (index) {
      if ($(this).css('background-color') === "rgb(255, 255, 0)") {
        $(this).css('background-color', '#c4fb8e');
      }
      $(this).find('mark').css('background-color', '#c4fb8e');
    });
  }

  turnSelectionsYellow = () => {
    const {analysisId} = this.props;
    $('#search-' + analysisId).find($("td")).each(function (index) {
      if ($(this).css('background-color') === "rgb(196, 251, 142)") {
        $(this).css('background-color', 'yellow');
      }
      $(this).find('mark').css('background-color', 'yellow');
    });
  }

  doTable = () => {
    const {width, height, analysisId} = this.props;
    var self = this;
    if (this.state.mounted) {
      let $containerId = $('#' + this.containerId);
      let thisContainer = $('#' + this.containerId)[0];

      $containerId.empty(); //delete existing chart if any drawn already
      $containerId.css('position', 'relative').css('top', '-5px').css('height', height + 30).css('width', width).css('background-color', 'rgb(247,247,247)');//.css("border-color", "#EEEEEE");
      $containerId.append("<table id='containerTable' style='position: relative;'>" +
        "<tr>" +
        "<td style='width:26%; border:solid thin transparent;'>SEARCH</td>" +
        "<td id='spacer' style='width: 3%; border:solid thin transparent;'>&nbsp;</td>" +
        "<td style='width:76%; border:solid thin transparent;'>RESPONSES</td>" +
        "</tr>" +
        "<tr>" +
        "<td style='height: 650px; max-height: 650px; vertical-align: top; padding-top: 8px; width:25%; border:solid thin transparent;'>" +
        "<div class='searchDiv' id='searchDiv-" + analysisId + "'  contenteditable = 'true' placeholder='Enter text to search for ...'></div>" +
        "<div style='text-align: center;'><button id='showButton-" + analysisId + "' class='blueButton'>show selected</button></div>" +
        "</td>" +
        "<td style='width: 5%; border:solid thin transparent;'>&nbsp;</td>" +
        "<td style='width:75%; border:solid thin transparent; vertical-align: top; '>" +
        "<div style='overflow-y: auto; width: auto; border: solid thin #CCCCCC; background-color: white; width:680px; min-width:680px;max-width:680px;height: 645px; max-height:645px;'>" +
        "<table class='ResponseTable' id='ResponseTable-" + analysisId + "' ></table>" +
        "</div></td>" +
        "</tr>" +
        "</table>");

      $('#showButton-' + analysisId).on('click', function () {
        if ($('#showButton-' + analysisId).text() === 'show all') {
          $('#showButton-' + analysisId).text('show selected');
          self.show = "showall";
          self.showall();
        } else {
          $('#showButton-' + analysisId).text('show all');
          self.show = "showselected";
          self.showselected();
        }
        $('#showButton-' + analysisId).css('outline', 'none');
      });

      $(function ($) { //for placeholder text in search div
        $('#searchDiv-' + analysisId).on('focusout', function () {
          var element = $(this);
          if (!element.text().replace(" ", "").length) {
            element.empty();
          }
        });
      });
      this.prepData(thisContainer);
    }
  }

  showall = () => {
    let self = this;
    const {analysisId} = this.props;
    $('#search-' + analysisId).find($('.responseTableRow')).css('display', 'table-row');
    self.setBoxColors();
  }

  showselected = () => {
    const {analysisId} = this.props;
    let self = this;
    $('#search-' + analysisId).find($('.responseRowNumb')).css('background-color', 'transparent');
    $('#search-' + analysisId).find($('.responseTableRow')).each(function (i) {
      if (self.hitArray.includes(i)) {
        $('#search-' + analysisId).find($('.responseTableRow')).eq(i).css('display', 'table-row');
      } else {
        $('#search-' + analysisId).find($('.responseTableRow')).eq(i).css('display', 'none');
      }
    });
  }


  addTableRow = (containerId, tableId, text, index, URL) => {
    const {analysisId} = this.props;
    let thisId = 'box-' + analysisId + "-" + index;
    $('#' + containerId).find($('#' + tableId)).append("<tr class='responseTableRow'>" +
      "<td id='" + thisId + "' class='responseRowNumb' style='width:30px; border: solid thin darkgray;'>" + (index + 1) + "</td>" +
      "<td class='responseColLeft' >" + text + "</td>" +
      "<td class='responseColRight'>" +
      "<i class='far fa-play-circle' URL=" + URL + "></i>" +
      "</td></tr>");
  }

  playSound = (url) => {
    var response = new Audio();
    response.src = url;
    response.play();
  }

  prepData = (thisContainer) => {
    const {analysisId} = this.props;
    // let $containerId = $('#' + this.containerId);
    let self = this;
    for (var i = 0; i < responseDataStash.length; i++) { //populate ORGANIZATION column
      self.addTableRow(this.containerId, "ResponseTable-" + analysisId, responseDataStash[i].responseText, i, responseDataStash[i].url)
    }
    $('.fa-play-circle').on('mouseover', function () {
      $(this).css('cursor', 'pointer');
      $(this).removeClass("far").addClass("fas");
    });
    $('.fa-play-circle').on('mouseout', function () {
      $(this).css('cursor', 'normal');
      $(this).removeClass("fas").addClass("far");
    });
    $('.fa-play-circle').on('click', function () {
      var URL = $(this).attr('URL');
      self.playSound(URL);
    });


    $('#searchDiv-' + analysisId).on("keyup", function (e) {
      /* **************************************************************  */
      /*  timer callback keeps input from processing on each keypress    */
      /*  when user is typing frequently                                 */
      var Timeout = null;
      if (Timeout != null) clearTimeout(Timeout);

      Timeout = setTimeout(function () {
        var context = thisContainer.querySelectorAll(".responseColLeft");
        var instance = new mark(context);

        instance.unmark($('#searchDiv-' + analysisId).text());
        instance.mark($('#searchDiv-' + analysisId).text(), {accuracy: 'partially'});

        if (self.wizardOn === true) {
          $('mark').css('background-color', '#c4fb8e');
        } else {
          $('mark').css('background-color', 'yellow');
        }

        self.findSelects(context);
      }, 400);
    });


    $('#searchDiv-' + analysisId).on("paste", function (e) { //for pasting text into search box
      var context = thisContainer.querySelectorAll(".responseColLeft");
      var instance = new mark(context);
      let thisText = $('#searchDiv-' + analysisId).text();

      self.insertTextAtCaret(thisText); //allows paste at cursor within existing text
      thisText = $('#searchDiv-' + analysisId).text(); //removes any tags, extracts just text
      $('#searchDiv-' + analysisId).html("<div style='text-align: left;'>" + thisText + "</div>"); //resets text within single div

      instance.mark($('#searchDiv-' + analysisId).text(), {accuracy: 'partially'});

      if (self.wizardOn === true) {
        $('mark').css('background-color', '#c4fb8e');
      } else {
        $('mark').css('background-color', 'yellow');
      }
      self.findSelects(context);
    });
  }

  insertTextAtCaret = (text) => {
    var sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(text));
      }
    } else if (document.selection && document.selection.createRange) {
      document.selection.createRange().text = text;
    }
  }

  setSelected = (index) => {
    responseDataStash[index].selected = true;
  }

  clearSelected = (index) => {
    responseDataStash[index].selected = false;
  }

  findSelects = (context) => {
    let self = this;
    self.hitArray = [];
    context.forEach(
      function (currentValue, currentIndex) {
        if ($(currentValue).html().includes("</mark>")) {
          self.hitArray.push(currentIndex);
          self.setSelected(currentIndex);
        }
        else {
          self.clearSelected(currentIndex);
        }
      },
      '');

    if (self.show === "showselected") {
      self.filterRows();
    } else {
      self.setBoxColors();
    }
    /*  as selection changes need to let wizard no if there remain some selections */
    if (self.wizardOn && self.hitArray.length > 0) {
      window.areAnySelected(true);
    } else if (self.wizardOn) {
      window.areAnySelected(false);
    }
  }

  filterRows = () => {
    var self = this;
    $('.responseRowNumb').css('background-color', 'transparent');
    $('.responseTableRow').each(function (i) {
      if (self.hitArray.includes(i)) {
        $('.responseTableRow').eq(i).css('display', 'table-row');
      } else {
        $('.responseTableRow').eq(i).css('display', 'none');
      }
    });
  }

  setBoxColors = () => {
    const {analysisId} = this.props;

    var self = this;
    let targetColor = "yellow";
    if (self.wizardOn === true) {
      targetColor = "#c4fb8e"
    } else ( targetColor = "yellow" );
    $('#search-' + analysisId).find($('.responseRowNumb')).css('background-color', 'transparent');
    for (var i = 0; i < this.hitArray.length; i++) {
      $('#search-' + analysisId).find($('#box-' + analysisId + "-" + this.hitArray[i])).css('background-color', targetColor);
    }
  }

  addNodeList = () => { //a polyfill for browsers not supporting this
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
  }

  render() {
    const {responseData, analysisId} = this.props;

    if (responseData.length !== 0) {
      responseDataStash = JSON.parse(JSON.stringify(responseData));
    }


    if (responseDataStash[0].selected !== undefined) {     // if a derivative chart
      responseDataStash = responseDataStash.filter((item) => {  // filter out the unselected data
        return item.selected;
      })
    }


    return (
      <div id={`search-${analysisId}`}
           ref="container"
           style={{
             position: 'relative',
             margin: 'auto',
             marginTop: '20px',
             display: 'block',
             border: '1px solid transparent'
           }}>

        {this.addNodeList()}
        {this.doTable()}
      </div>
    )
  }
}

export default Search;